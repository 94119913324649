import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import bannerSvg from 'assets_vacanted/banner_svg.svg';
import { Helmet } from 'react-helmet';
import featureService from '../../services/featureService';
import SupportLogos from '../../components/SupportLogos';
// import ExperienceSelector from '../../components/ExperienceSelector';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import paths from '../../routes/paths';
import BenefitsBanner from '../Experience/src/components/benefits-banner';
import Button from '../Experience/src/components/button';
import TestimonialsCarousel from './testimonials';
import HowItWorks from './howItWorks';

import bannerImg1440 from '../../assets_vacanted/banner_home_1440.png';
import './styles.scss';

const Home = ({ defaultLoginStep }) => {
  const navigate = useNavigate();
  const shouldBeVertical = useMediaQuery({ query: '(max-width: 905px)' });
  const videoRef = useRef(null);
  const imgRef = useRef(null);

  const [experiencesEnabled, setExperiencesEnabled] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const handleSearch = async () => {
    navigate('/vacantest');
  };

  /* const handleSeeExperiences = async () => {
    const div = document.getElementById('experience-selector-title');
    div?.scrollIntoView({ behavior: 'smooth' });
  }; */

  const onStartSearch = () => {
    navigate(paths.NEW_EXPERIENCE);
  };

  const setVideoSource = () => {
    if (window && window.screen.width < 480) {
      videoRef.current.src = 'https://images-dev.vacanted.com.uy/media/landing/animation_360p.mp4';
    } else if (window && window.screen.width < 720) {
      videoRef.current.src = 'https://images-dev.vacanted.com.uy/media/landing/animation_480p.mp4';
    } else if (window && window.screen.width < 1080) {
      videoRef.current.src = 'https://images-dev.vacanted.com.uy/media/landing/animation_720p.mp4';
    } else if (window && window.screen.width < 1440) {
      videoRef.current.src = 'https://images-dev.vacanted.com.uy/media/landing/animation_1080p.mp4';
    } else if (window && window.screen.width >= 1440) {
      videoRef.current.src = 'https://images-dev.vacanted.com.uy/media/landing/animation_1440p.mp4';
    }
  };

  const playVideo = () => {
    videoRef.current.play = true;
    videoRef.current.loop = true;
    videoRef.current.addEventListener('loadedmetadata', () => {
      if (imgRef.current) imgRef.current.style.display = 'none';
      if (videoRef.current) videoRef.current.style.display = 'block';
    });
  };

  useEffect(() => {
    setVideoSource();
    if (videoRef.current) {
      playVideo();
    }
    featureService.experiencesEnabled().then(setExperiencesEnabled);
  }, []);

  return (
    <main className="home">
      <Header defaultLoginStep={defaultLoginStep} />
      <Helmet>
        <title>Encontrá el centro educativo ideal para tus hijos - VacantED</title>
        <meta name="description" content="Te recomendamos los jardines, colegios privados, escuelas o liceos que mejor se adapten a tus preferencias y tu capacidad de pago." />
      </Helmet>
      <div className="home__video-banner">
        <div className="home__video-banner__container">
          <img className="banner-image" style={{ display: 'block' }} ref={imgRef} src={bannerImg1440} alt="" />
          <video className="banner-video" ref={videoRef} style={{ display: 'none' }} autoPlay loop muted playsInline preload="metadata" />
          <div className="banner-background" />
          <div className="banner-content">
            <h1>
              Encontrá el
              {' '}
              <span>centro educativo</span>
              {' '}
              ideal para tus hijos
            </h1>
            {/* <h3>
              <span>Realizá nuestro test de compatibilidad</span>
              {' '}
              para comenzar con una pre&#8209;selección inteligente.
            </h3> */}
            {experiencesEnabled && (
              <>
                <h3>
                  Comenzá tu búsqueda con nuestras
                  <br />
                  <span>recomendaciones personalizadas</span>
                </h3>
                <Button hirachy="primary" size="large" onClick={onStartSearch}>Comenzar búsqueda</Button>
              </>
            )}
            {!experiencesEnabled && (
              <>
                <h3>
                  <span>Comenzá tu búsqueda con nuestro test de compatibilidad</span>
                  {' '}
                  para obtener una pre&#8209;selección inteligente.
                </h3>
                <Button hirachy="primary" size="large" onClick={handleSearch}>Comenzar búsqueda</Button>
              </>
            )}

          </div>
          {false && <img src={bannerSvg} alt="" className="banner-icon" />}
        </div>
      </div>
      <div className="home__section home__benefits-section">
        <div className="experience-benefits-banner-container">
          <BenefitsBanner
            openFromOutside={openModal}
            setOpenFromOutside={setOpenModal}
          />
        </div>
      </div>
      <SupportLogos />

      {/* Testimonials */}
      <section className="home__section home__simple-section">
        <div className="home__simple-section__content vertical">
          <div className="home__simple-section__content__readable">
            <div className="home__simple-section__content__readable__text">
              <div className="home__simple-section__content__readable__text__header">
                <span>TESTIMONIOS REALES</span>
              </div>
              <div className="home__simple-section__content__readable__text__title">
                <span>
                  Ayudamos a miles de familias a encontrar el mejor futuro para sus hijos.
                </span>
              </div>
            </div>
          </div>
          <div className="home__simple-section__content__complement">
            <TestimonialsCarousel />
          </div>
        </div>
      </section>

      {/* How it works */}
      <section className="home__section home__simple-section howitworks">
        <div className="home__simple-section__content vertical">
          <div className="home__simple-section__content__readable">
            <div className="home__simple-section__content__readable__text">
              <div className="home__simple-section__content__readable__text__header">
                <span>¿CÓMO FUNCIONA?</span>
              </div>
              <div className="home__simple-section__content__readable__text__title">
                <span>
                  Tu búsqueda, fácil, ágil y personalizada
                </span>
              </div>
            </div>
          </div>
          <div className="home__simple-section__content__complement">
            <HowItWorks orientation={shouldBeVertical ? 'vertical' : 'horizontal'} />
          </div>
        </div>
      </section>

      {/* Recommendations description */}
      <section className="home__section home__simple-section">
        <div className={`home__simple-section__content ${shouldBeVertical ? 'vertical' : ''}`}>
          <div className="home__simple-section__content__readable">
            <div className="home__simple-section__content__readable__text">
              <div className="home__simple-section__content__readable__text__header">
                <span>RECOMENDACIONES PERSONALIZADAS</span>
              </div>
              <div className="home__simple-section__content__readable__text__title">
                <span>
                  Obtené recomendaciones completando tu perfil de búsqueda
                </span>
              </div>
              <div className="home__simple-simple-section__content__readable__text__subtitle">
                <span>
                  Completa la información sobre tus preferencias y te brindaremos
                  recomendaciones personalizadas de centros educativos basadas
                  en información específica de VacantED.
                </span>
              </div>
            </div>
          </div>
          <div className="home__simple-section__content__illustration">
            <div className="home__simple-section__content__illustration__wrapper">
              <img
                className="home__simple-section__content__illustration__wrapper__image"
                src="https://vacanted-assets-dev.s3.amazonaws.com/images/home_recommendations.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/* Candidates and benefits description  */}
      <section className="home__section home__simple-section">
        <div className={`home__simple-section__content reverse ${shouldBeVertical ? 'vertical' : ''}`}>
          <div className="home__simple-section__content__readable">
            <div className="home__simple-section__content__readable__text">
              <div className="home__simple-section__content__readable__text__header">
                <span>GESTIONÁ TUS CENTROS EDUCATIVOS</span>
              </div>
              <div className="home__simple-section__content__readable__text__title">
                <span>
                  Analizá tus candidatos, confirmá tu inscripción en VacantED
                  y reclamá tus beneficios
                </span>
              </div>
              <div className="home__simple-section__content__readable__text__subtitle">
                <span>
                  Cuando tomes la decisión, confirma en nuestra web a qué centro educativo
                  inscribiste y recibe los beneficios que tanto los centros como nosotros
                  tenemos para vos.
                </span>
              </div>
            </div>
            <div className="home__simple-section__content__readable__action">
              <Button
                hirachy="primary"
                onClick={() => setOpenModal(true)}
              >
                Ver beneficios
              </Button>
            </div>
          </div>
          <div className="home__simple-section__content__illustration">
            <div className="home__simple-section__content__illustration__wrapper">
              <img
                className="home__simple-section__content__illustration__wrapper__image"
                src="https://vacanted-assets-dev.s3.amazonaws.com/images/home_candidates.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/* Schools section */}
      <section className="home__section home__simple-section colored">
        <div className={`home__simple-section__content ${shouldBeVertical ? 'vertical' : ''}`}>
          <div className="home__simple-section__content__readable">
            <div className="home__simple-section__content__readable__text">
              <div className="home__simple-section__content__readable__text__header">
                <span>PARA CENTROS EDUCATIVOS</span>
              </div>
              <div className="home__simple-section__content__readable__text__title">
                <span>
                  Sumate a la mayor red de búsqueda
                </span>
              </div>
              <div className="home__simple-section__content__readable__text__subtitle">
                <span>
                  Mejorá tu visibilidad, editá tu ficha informativa, recibí consultas y
                  solicitudes de entrevista, y generá tu propio marketing dentro de VacantED.
                  Contactános!
                </span>
              </div>
            </div>
            <div className="home__simple-section__content__readable__action">
              <Button
                hirachy="primary"
                onClick={() => navigate('/auth/school')}
              >
                Más información
              </Button>
            </div>
          </div>
          <div className="home__simple-section__content__illustration">
            <div className="home__simple-section__content__illustration__wrapper">
              <img
                className="home__simple-section__content__illustration__wrapper__image"
                src="https://vacanted-assets-dev.s3.amazonaws.com/images/home_network.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
};

Home.propTypes = {
  defaultLoginStep: PropTypes.string,
};

Home.defaultProps = {
  defaultLoginStep: null,
};

export default Home;
