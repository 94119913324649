import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputLogin from '../input/LoginInput';
import FormCommons from './commons';
import LoginPasswordInput from '../input/LoginPasswordInput';

const PasswordError = 'Ingrese su contraseña';
const PassowrdErrorRepeat = 'Las contraseñas no coinciden';
const MinNameError = 'Debe ingresar mas de 3 caracteres';
const NameError = 'Debe ingresar un nombre y apellido';
const userNameError = 'Debe ingresar un correo eléctronico';

const SignInForm = ({ onSubmit, userName, loading }) => (
  <Formik
    initialValues={{
      name: '',
      userName,
      password: '',
      passowrdRepeat: '',
    }}
    onSubmit={async (values) => {
      onSubmit(values);
    }}
    validationSchema={Yup.object({
      name: Yup.string().min(4, MinNameError).required(NameError),
      userName: Yup.string().email(userNameError).required(userNameError),
      password: Yup.string()
        .required(PasswordError)
        .matches(FormCommons.passwordRegex, FormCommons.passwordFormatError),
      passowrdRepeat: Yup.string()
        .required(PasswordError)
        .equals([Yup.ref('password')], PassowrdErrorRepeat)
        .matches(FormCommons.passwordRegex, FormCommons.passwordFormatError),
    })}
  >
    {
      ({ errors }) => (
        <Form className="login-modal__form-signin">
          <InputLogin className={`input-text ${errors.userName ? 'has-error' : ''}`} name="userName" placeholder="Correo eléctronico" disabled />
          <InputLogin className={`input-text ${errors.name ? 'has-error' : ''}`} name="name" type="text" placeholder="Nombre y Apellido" autoFocus />
          <LoginPasswordInput className={`input-text ${errors.password ? 'has-error' : ''}`} name="password" placeholder="Contraseña" />
          <LoginPasswordInput className={`input-text ${errors.passowrdRepeat ? 'has-error' : ''}`} name="passowrdRepeat" placeholder="Repita la contraseña" />
          <button type="submit" className="button-lading" disabled={loading}>
            Continuar
            {' '}
            {
              loading && (<i className="fa fa-spinner fa-pulse fa-2x" />)
            }
          </button>
        </Form>
      )
    }
  </Formik>
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SignInForm;
