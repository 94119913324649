import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { BiDollar } from 'react-icons/bi';
import Dropdown from '../Dropdown/VacantestDropdown';
import SimpleInput from '../Input/SimpleInput';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import familyCombos from '../familyInfoConstants';
import specificStyles from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';
import moneyMask from '../../../../utils/MoneyMask/moneyMask';

const PageSeven = ({ card, disableNext }) => {
  const [familyInfoData, setFamilyInfoData] = useState([]);
  const [parent, setParent] = useState([{ name: 'Agregar integrante familiar' }]);
  const [age, setAge] = useState([]);
  const [entryType, setEntryType] = useState([{ name: 'Selecciona tipo de ingreso' }]);
  const [monthlyIncome, setMonthlyIncome] = useState([]);
  const [dropdownController, setDropdownController] = useState({ active: 1, max: 10 });

  const [contributorInfoData, setContributorInfoData] = useState([]);
  const [contributor, setContributor] = useState([]);
  const [contributionType, setContributionType] = useState([]);
  const [monthlyContribution, setMonthlyContribution] = useState([]);
  const [contributorDdController, setContributorDdController] = useState({ active: 0, max: 4 });

  const famKey = 1;
  const entryKey = 3;
  const ingresoKey = 6;
  const contributorKey = 4;
  const familyCollection = familyCombos[famKey];
  const entryCollection = familyCombos[entryKey];
  const ingresoCollection = familyCombos[ingresoKey];
  const contributorCollection = familyCombos[contributorKey];

  const didMountRef = useRef(null);

  const getInitialData = () => {
    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);

      if (defaultValues && defaultValues.family?.length > 0) {
        const parents = [];
        const ages = [];
        const types = [];
        const incomes = [];

        const contributors = [];
        const contributionTypes = [];
        const contributionIncomes = [];

        defaultValues.family.forEach((dv) => {
          parents.push(dv.parent);
          ages.push(dv.age);
          types.push(dv.entryType);
          incomes.push(dv.monthlyIncome);
        });

        setDropdownController((dd) => ({ ...dd, active: defaultValues.family.length }));
        setParent([...parents]);
        setAge([...ages]);
        setEntryType([...types]);
        setMonthlyIncome([...incomes]);

        if (defaultValues.contributor && defaultValues.contributor?.length > 0) {
          defaultValues.contributor.forEach((dv) => {
            contributors.push(dv.contributor);
            contributionTypes.push(dv.contributionType);
            contributionIncomes.push(dv.monthlyContribution);
          });

          setContributorDdController((dd) => ({ ...dd, active: defaultValues.contributor.length }));
          setContributor([...contributors]);
          setContributionType([...contributionTypes]);
          setMonthlyContribution([...contributionIncomes]);
        }
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => getInitialData(), []);

  const saveFamilyInfoData = (parentInfo, ageInfo, entryTypeInfo, monthlyIncomeInfo) => {
    const treatedFamilyInfo = parentInfo.map((p, i) => ({
      parent: { ...p },
      age: ageInfo[i],
      entryType: { ...entryTypeInfo[i] },
      monthlyIncome: monthlyIncomeInfo[i],
    }));
    setFamilyInfoData(treatedFamilyInfo);
  };

  useEffect(() => {
    saveFamilyInfoData(parent, age, entryType, monthlyIncome);
  }, [parent, age, entryType, monthlyIncome]);

  const saveContributorsInfoData = (
    contributorInfo,
    contributionTypeInfo,
    monthlyContributionInfo,
  ) => {
    const treatedContributorInfo = contributorInfo.map((c, i) => ({
      contributor: { ...c },
      contributionType: { ...contributionTypeInfo[i] },
      monthlyContribution: monthlyContributionInfo[i],
    }));
    setContributorInfoData(treatedContributorInfo);
  };

  useEffect(() => {
    saveContributorsInfoData(contributor, contributionType, monthlyContribution);
  }, [contributor, contributionType, monthlyContribution]);

  const isCardDataValid = () => {
    if (parent.length > 0) {
      let errCounter = 0;

      let familyCounter = 0;

      while (familyCounter < dropdownController.active) {
        if (typeof familyInfoData[familyCounter]?.parent.id !== 'number') errCounter += 1;
        if (!familyInfoData[familyCounter]?.age
          || Number(familyInfoData[familyCounter]?.age < 1)) errCounter += 1;
        if (typeof familyInfoData[familyCounter]?.entryType.id !== 'number') errCounter += 1;
        if (!familyInfoData[familyCounter]?.monthlyIncome
          || Number(familyInfoData[familyCounter]?.monthlyIncome < 1)) errCounter += 1;
        familyCounter += 1;
      }

      if (contributorInfoData.length > 0) {
        contributorInfoData.map((contribution) => {
          if (typeof contribution?.contributor.id !== 'number') errCounter += 1;
          if (typeof contribution?.contributionType.id !== 'number') errCounter += 1;
          if (!contribution?.monthlyContribution
            || Number(contribution?.monthlyContribution < 1)) {
            errCounter += 1;
          }

          return errCounter;
        });
      }

      return errCounter < 1;
    }
    return false;
  };

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (familyData, contributorData) => {
    const cardData = { family: familyData, contributor: contributorData };
    localStorageFamilyInfo.save(card, cardData);
    disableNext(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (!isCardDataValid()) removeCardFromLocalStorage();
      else saveCardInLocalStorage(familyInfoData, contributorInfoData);
    }
  }, [familyInfoData, contributorInfoData]);

  const addLevelDropdown = () => {
    if (dropdownController.active < dropdownController.max) {
      setDropdownController({ ...dropdownController, active: dropdownController.active + 1 });
    }
  };

  const addContributorLevelDropdown = () => {
    if (contributorDdController.active < contributorDdController.max) {
      setContributorDdController({
        ...contributorDdController,
        active: contributorDdController.active + 1,
      });
    }
  };

  const renderAddDropdownButton = () => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => addLevelDropdown()}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlinePlus size={25} color="#333" />
      </div>
      <p>Agrega otro integrante</p>
    </button>
  );

  const renderAddContributorDropdownButton = () => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => addContributorLevelDropdown()}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlinePlus size={25} color="#333" />
      </div>
      <p>Agrega otro aportante</p>
    </button>
  );

  const removeLevelDropdown = (id) => {
    const auxP = parent;
    const auxA = age;
    const auxET = entryType;
    const auxMI = monthlyIncome;
    auxP.splice(id - 1, 1);
    auxA.splice(id - 1, 1);
    auxET.splice(id - 1, 1);
    auxMI.splice(id - 1, 1);
    setParent([...auxP]);
    setAge([...auxA]);
    setEntryType([...auxET]);
    setMonthlyIncome([...auxMI]);
    setDropdownController((dd) => ({ ...dd, active: dd.active - 1 }));
  };

  const removeContributorLevelDropdown = (id) => {
    const auxC = contributor;
    const auxCT = contributionType;
    const auxMC = monthlyContribution;
    auxC.splice(id - 1, 1);
    auxCT.splice(id - 1, 1);
    auxMC.splice(id - 1, 1);
    setContributor([...auxC]);
    setContributionType([...auxCT]);
    setMonthlyContribution([...auxMC]);
    setContributorDdController((dd) => ({ ...dd, active: dd.active - 1 }));
  };

  const renderRemoveDropdownButton = (id) => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => removeLevelDropdown(id)}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlineMinus size={25} color="#333" />
      </div>
      <p>Quita un integrante</p>
    </button>
  );

  const renderRemoveContributorDropdownButton = (id) => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => removeContributorLevelDropdown(id)}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlineMinus size={25} color="#333" />
      </div>
      <p>Quita un aportante</p>
    </button>
  );

  const saveParent = (parentObj, id) => {
    const aux = parent;
    aux[id - 1] = parentObj;
    setParent([...aux]);
  };

  const validateNumbers = (value) => {
    const numberRegex = /\D+/g;
    const onlyNumbers = value;
    if (value) {
      return onlyNumbers.replace(numberRegex, '');
    }

    return value;
  };

  const saveAge = (ageEle, id) => {
    const aux = age;
    aux[id - 1] = validateNumbers(ageEle.target.value);
    setAge([...aux]);
  };

  const saveEntryType = (entryObj, id) => {
    const aux = entryType;
    aux[id - 1] = entryObj;
    setEntryType([...aux]);
  };

  const saveContributor = (contributorObj, id) => {
    const aux = contributor;
    aux[id - 1] = contributorObj;
    setContributor([...aux]);
  };

  const saveContributionType = (contributionObj, id) => {
    const aux = contributionType;
    aux[id - 1] = contributionObj;
    setContributionType([...aux]);
  };

  const saveMonthlyIncome = (monthlyIncomeEle, id) => {
    const aux = monthlyIncome;
    aux[id - 1] = validateNumbers(monthlyIncomeEle);
    setMonthlyIncome([...aux]);
  };

  const saveMonthlyContribution = (monthlyContributionEle, id) => {
    const aux = monthlyContribution;
    aux[id - 1] = validateNumbers(monthlyContributionEle);
    setMonthlyContribution([...aux]);
  };

  const checkAddDropdownConditions = (id) => {
    if (dropdownController.active >= dropdownController.max) return false;
    if (familyInfoData.length < 1) return false;
    if (id !== dropdownController.active) return false;
    const lastData = familyInfoData[dropdownController.active - 1];
    if (!lastData) return false;
    const lastParent = lastData.parent;
    const lastAge = lastData.age;
    const lastType = lastData.entryType;
    const lastIncome = lastData.monthlyIncome;
    if (typeof (lastParent?.id) !== 'number') return false;
    if (Number(lastAge) <= 0 || !lastAge) return false;
    if (typeof (lastType?.id) !== 'number') return false;
    if (Number(lastIncome) <= 0 || !lastIncome) return false;

    return true;
  };

  const checkAddContributorDropdownConditions = (id) => {
    if (contributorDdController.active >= contributorDdController.max) return false;
    if (contributorInfoData.length < 1) return false;
    if (id !== contributorDdController.active) return false;
    const lastData = contributorInfoData[contributorDdController.active - 1];
    if (!lastData) return false;
    const lastContributor = lastData.contributor;
    const lastType = lastData.contributionType;
    const lastIncome = lastData.monthlyContribution;
    if (typeof (lastContributor?.id) !== 'number') return false;
    if (typeof (lastType?.id) !== 'number') return false;
    if (Number(lastIncome) <= 0 || !lastIncome) return false;

    return true;
  };

  const checkRemoveDropdownConditions = (id) => {
    if (dropdownController.active <= 1) return false;
    if (dropdownController.active !== id) return false;

    return true;
  };

  const checkRemoveContributorDropdownConditions = (id) => {
    if (contributorDdController.active < 1) return false;
    if (contributorDdController.active !== id) return false;

    return true;
  };

  const renderWithOutContributor = () => {
    if (contributorDdController.active === 0) {
      return (
        <div className="mt-5">
          <hr />
          {renderAddContributorDropdownButton()}
        </div>
      );
    }

    return <></>;
  };

  const renderDropdown = () => {
    const dropdonwsArray = [];

    for (let i = 1; i <= dropdownController.active; i += 1) {
      dropdonwsArray.push(i);
    }

    return dropdonwsArray.map((id) => (
      <div
        style={{
          marginTop: '50px',
        }}
        key={id}
      >
        <p className={specificStyles.bold__subtitle} style={{ marginTop: '-30px' }}>{`Integrante ${id}`}</p>
        <div className={specificStyles.first_dropdown}>
          <p className={rStyles.dropdown__label}>Integrante familiar</p>
          <Dropdown
            option={parent[id - 1] || { name: 'Agregar integrante familiar' }}
            setOption={(e) => saveParent(e, id)}
            options={familyCollection}
          />
        </div>
        <div className={specificStyles.simple__dropdown}>
          <p className={rStyles.dropdown__label}>Edad</p>
          <SimpleInput
            placeholder="0"
            inputValue={age[id - 1] || ''}
            setInputValue={(e) => saveAge(e, id)}
            maxLength={2}
          />
        </div>
        <div className={specificStyles.first_dropdown}>
          <p className={rStyles.dropdown__label}>Tipo de ingreso</p>
          <Dropdown
            option={entryType[id - 1] || { name: 'Selecciona tipo de ingreso' }}
            setOption={(e) => saveEntryType(e, id)}
            options={ingresoCollection}
          />
        </div>
        <div>
          <p className={rStyles.dropdown__label}>
            Promedio mensual
          </p>
          <div className={specificStyles.input__with__icon}>
            <SimpleInput
              placeholder="0.000,00"
              inputValue={moneyMask(monthlyIncome[id - 1])}
              setInputValue={(e) => saveMonthlyIncome(e.target.value, id)}
              maxLength={9}
            />
            <div className={specificStyles.img__container}>
              <BiDollar size={20} color="black" />
            </div>
          </div>
        </div>
        <hr />
        {checkAddDropdownConditions(id) && renderAddDropdownButton()}
        {checkRemoveDropdownConditions(id) && renderRemoveDropdownButton(id)}
      </div>
    ));
  };

  const renderContributorDropdown = () => {
    const dropdonwsArray = [];

    for (let i = 1; i <= contributorDdController.active; i += 1) {
      dropdonwsArray.push(i);
    }

    return dropdonwsArray.map((id) => (
      <div
        style={{
          marginTop: '50px',
        }}
      >
        <p className={specificStyles.bold__subtitle} style={{ marginTop: '-30px' }}>{`Aportante ${id}`}</p>
        <div className={specificStyles.first_dropdown}>
          <p className={rStyles.dropdown__label}>Aportante</p>
          <Dropdown
            option={contributor[id - 1] || { name: 'Agregar aportante' }}
            setOption={(e) => saveContributor(e, id)}
            options={contributorCollection}
          />
        </div>
        <div className={specificStyles.first_dropdown}>
          <p className={rStyles.dropdown__label}>Tipo de aporte</p>
          <Dropdown
            option={contributionType[id - 1] || { name: 'Selecciona tipo de aporte' }}
            setOption={(e) => saveContributionType(e, id)}
            options={entryCollection}
          />
        </div>
        <div>
          <p className={rStyles.dropdown__label}>
            Aporte promedio mensual
          </p>
          <div className={specificStyles.input__with__icon}>
            <SimpleInput
              placeholder="0.000,00"
              inputValue={moneyMask(monthlyContribution[id - 1])}
              setInputValue={(e) => saveMonthlyContribution(e.target.value, id)}
              maxLength={9}
            />
            <div className={specificStyles.img__container}>
              <BiDollar size={20} color="black" />
            </div>
          </div>
        </div>
        <hr />
        {checkAddContributorDropdownConditions(id) && renderAddContributorDropdownButton()}
        {checkRemoveContributorDropdownConditions(id) && renderRemoveContributorDropdownButton(id)}
      </div>
    ));
  };

  return (
    <div className={rStyles.vacantest__card__content}>
      <div className={specificStyles.page__title}>
        <h3>Composición familiar restante e ingresos económicos (Máximo 10 integrantes)</h3>
        <p>
          A continuación, agrega los restantes integrantes del núcleo familiar.
          Indica sus edades y sus contribuciones económicas al presupuesto familiar.
        </p>
      </div>
      {renderDropdown()}
      <div className={specificStyles.page__title}>
        <h3>Otros aportantes al presupuesto educativo de la familia (Máximo 4)</h3>
        <p>
          A continuación, agrega las personas por fuera del núcleo familiar o las
          instituciones que también contribuyen al presupuesto educativo familiar.
        </p>
      </div>
      {renderContributorDropdown()}
      {renderWithOutContributor()}
    </div>
  );
};

export default PageSeven;

PageSeven.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
