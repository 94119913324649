import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../loading';

import './styles.scss';

const ExperiencePageTitle = ({
  title, subtitle, loading,
}) => (
  <>
    <h1 className="experience-page__title">
      {title}
      <Loading size="small" show={!!loading} />
    </h1>
    {subtitle && (
    <h2 className="experience-page__subtitle">
      {subtitle}
    </h2>
    )}
  </>
);

ExperiencePageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
};

ExperiencePageTitle.defaultProps = {
  subtitle: '',
  loading: false,
};

export default ExperiencePageTitle;
