import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'hooks/ApplicationContext';
import { useUserData } from 'hooks/UserContext';
import { registerAnalytics } from 'Authentication/Data/Services/firebase';
import Button from '../../button';
import StarIcon from '../../../../../../components/Icons/StarIcon';
import experienceService from '../../../../../../services/experienceService';
import ExperienceConstants from '../../../../../../constants/experiences';
import AppComponents from '../../../../../../constants/components';

const { ExperienceCandidateSource } = ExperienceConstants;

const AddRemoveCandidate = ({
  schoolId,
  source,
}) => {
  const {
    activeExperience,
    updateActiveExperience,
  } = useUserData();
  const experienceId = activeExperience?.id;
  const {
    snackbarContext: { setSnackbar, closeSnackbar },
    loadingContext: { setPageLoading },
  } = useAppContext();

  const isCandidate = !!activeExperience?.candidates?.filter(({ active }) => !!active)?.some(
    (candidate) => candidate.school.id === schoolId,
  );

  const onClickAddCandidates = (theSchoolId) => {
    setPageLoading(true);
    const currentComponent = AppComponents.inferFromUrlPath(window.location.pathname);
    const sourceToUse = (
      source || currentComponent?.candidateSource || ExperienceCandidateSource.UNKNOWN
    );
    experienceService.addCandidate(
      experienceId,
      theSchoolId,
      sourceToUse,
    ).then(() => {
      updateActiveExperience();
      setSnackbar({
        hirachy: 'success',
        text: 'Centro agregado a candidatos.',
        action: 'Entendido',
        onClick: closeSnackbar,
      });
      registerAnalytics('add_candidate', {
        experience_id: experienceId,
        school_id: theSchoolId,
        source: sourceToUse,
        page: currentComponent?.name || window.location.pathname,
      });
    }).catch(() => {
      setSnackbar({
        hirachy: 'error',
        text: 'No se pudo agregar el centro a candidatos.',
      });
    })
      .finally(() => setPageLoading(false));
  };

  const onClickDeleteCandidate = (theSchoolId) => {
    setPageLoading(true);
    const currentComponent = AppComponents.inferFromUrlPath(window.location.pathname);
    experienceService.deleteCandidate(
      experienceId,
      theSchoolId,
    ).then(() => {
      updateActiveExperience();
      setSnackbar({
        hirachy: 'success',
        text: 'Centro eliminado de candidatos.',
        action: 'Entendido',
        onClick: closeSnackbar,
      });
      registerAnalytics('delete_candidate', {
        experience_id: experienceId,
        school_id: theSchoolId,
        page: currentComponent?.name || window.location.pathname,
      });
    }).catch(() => {
      setSnackbar({
        hirachy: 'error',
        text: 'No se pudo eliminar el centro de candidatos.',
      });
    })
      .finally(() => setPageLoading(false));
  };

  if (!activeExperience) return null;

  return (
    <>
      {!isCandidate && (
        <Button
          id={`add-candidate-${schoolId}`}
          hirachy="default"
          size="small"
          onClick={
            () => onClickAddCandidates(schoolId)
          }
        >
          <StarIcon className="icon--stroke" />
          Agregar a candidatos
        </Button>
      )}
      {isCandidate && (
        <Button
          hirachy="default"
          size="small"
          onClick={
            () => onClickDeleteCandidate(schoolId)
          }
        >
          <StarIcon className="icon--stroke icon--fill" />
          Quitar de candidatos
        </Button>
      )}
    </>
  );
};

AddRemoveCandidate.propTypes = {
  schoolId: PropTypes.number.isRequired,
  source: PropTypes.number,
};

AddRemoveCandidate.defaultProps = {
  source: null,
};

export default AddRemoveCandidate;
