const save = (key, data) => {
  const savedData = localStorage.getItem('familyInfo');
  const updatedData = { ...JSON.parse(savedData), [key]: data };
  localStorage.setItem('familyInfo', JSON.stringify(updatedData));
};

const check = (card) => {
  const savedData = JSON.parse(localStorage.getItem('familyInfo'));

  if (savedData) return Object.keys(savedData).includes(card.toString());

  return false;
};

const get = (card) => {
  if (check(card)) return JSON.parse(localStorage.getItem('familyInfo'))[card];

  return false;
};

const remove = (card) => {
  if (check(card)) {
    const vacantest = JSON.parse(localStorage.getItem('familyInfo'));
    const updatedVacantest = { ...vacantest };

    delete updatedVacantest[card];

    localStorage.setItem('familyInfo', JSON.stringify(updatedVacantest));
  }
};

const clear = () => localStorage.removeItem('familyInfo');

const localStorageVacantest = {
  save,
  check,
  get,
  remove,
  clear,
};

export default localStorageVacantest;
