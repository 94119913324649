import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Chevron from './Chevron';
import checkboxes from '../SmartFilters/checkboxFilters';
import './Accordion.css';

function Accordion({ title, content }) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');

  const elemRef = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${elemRef.current.scrollHeight}px`,
    );
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate',
    );
  }

  return (
    <div className="accordion__section">
      <button type="button" className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">{title}</p>
        <Chevron className={`${setRotate}`} width={10} fill="#455a64" />
      </button>
      <div
        ref={elemRef}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {content.table && checkboxes.renderCheckboxes(
          content.table,
          content.handleFilters,
          content.schools,
          content.filterId,
          content.filters,
          content.loading,
          content.stepsData,
          content.customClass || '',
        )}
      </div>
    </div>
  );
}

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({
    table: PropTypes.arrayOf(PropTypes.shape({})),
    handleFilters: PropTypes.func,
    schools: PropTypes.arrayOf(PropTypes.shape({})),
    filterId: PropTypes.string,
    customClass: PropTypes.string,
    filters: PropTypes.shape({}),
    loading: PropTypes.bool.isRequired,
    stepsData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Accordion.defaultProps = {
  content: {
    customClass: '',
  },
};
