import React, { useState, useEffect } from 'react';
import pagesConfig from 'pagesConfig';
import { FaSearch } from 'react-icons/fa';
import { GiCrosshair } from 'react-icons/gi';
import { useUserData } from 'hooks/UserContext';
import useBouncer from 'hooks/useBouncer';
import { fetchAddressByCoordinates } from 'utils/Coordinates/coordinatesFunctions';
import { getCoordsInSessionStorage } from 'utils/Storage/storageFunctions';
import {
  getSchoolsByCoordinates, updateSchools,
  updateFilteredSchools,
} from 'utils/schools/schoolFunctions';
import sidebarConstants from '../sidebarConstants';
import { useSearch } from '../../../hooks/SearchContext';
import { useSchools } from '../../../hooks/SchoolContext';
import renderFAIcon from '../../../utils/getFAIcon';
import 'font-awesome/css/font-awesome.min.css';
import filterSchoolsByDistance from '../../../utils/SearchFilters/distanceFilter';
import locationStyles from './LocationFilters.module.css';
// import SaveFilters from '../SaveFilters/index';

function LocationFilters() {
  const [locationFilters, setLocationFilters] = useState({ address: '' });
  const [showFilters, toggleShowFilters] = useState({ location: true });
  const [predictions, setPredictions] = useState([]);
  const [fetchError, setFetchError] = useState({ status: false, msg: '' });
  const [fixButtons, setFixButtons] = useState(false);
  const [filtersOn, setFiltersOn] = useState(false);
  const rangeDefaultValue = 5;
  const [filters, setFilters] = useState({ 'range-value': rangeDefaultValue });
  const findMeTooltipMsg = 'Es necesario permitir que el navegador acceda a su ubicación';

  const { setSchoolsData, setSchoolsLoadingState, schoolsLoadingState } = useSchools();

  const {
    updateAddressSuggestions, locationInfo, setLocationInfo,
    getCoordinatesByPlaceId, querySchoolsWithCoordinates, setUsedCoordinates, refFilter,
  } = useSearch();
  const { getLocation, appLanguage: lang } = useUserData();

  // set the name to show default in case no query is made
  useEffect(() => {
    setFilters({ 'range-value': rangeDefaultValue });
    setLocationFilters({ ...locationFilters, address: locationInfo.name });
  }, [locationInfo]);

  const searchWithDebounce = useBouncer(
    async (value) => updateAddressSuggestions(value, setPredictions), 400,
  );
  function handleSearchAddress(address) {
    searchWithDebounce(address);
  }

  function handleChange({ target }) {
    const { name, value } = target;
    if (value === '') setPredictions([]);
    handleSearchAddress(value);
    setLocationFilters({ ...locationFilters, [name]: value });
  }

  // find my location click
  async function handleClickLocation(getUserLocation, updateCoordinates) {
    setSchoolsLoadingState(true);
    getUserLocation(updateCoordinates);
    const coords = getCoordsInSessionStorage();

    const range = await filters['range-value'];

    if (coords) {
      updateCoordinates(coords);
      const schools = await getSchoolsByCoordinates('/school/radius', coords);
      const defaultSortedSchools = schools.sort((s1, s2) => s1.distance - s2.distance);
      const schoolsWithinRange = await filterSchoolsByDistance(coords, defaultSortedSchools, range);
      updateFilteredSchools(schools, schoolsWithinRange, setSchoolsData);
      // in case any school returns, update the filters with respective table_steps
      if (schools.length > 0) {
        const { country_id: countryId, city_id: cityId } = schools[0];
        const { table_step: tableStep } = schools[0].state;
        const { name } = schools[0].city;
        setLocationInfo({
          ...locationInfo, cityId, countryId, table_step: tableStep, name,
        });
      }

      updateSchools(schools, schoolsWithinRange, setSchoolsData);
      const address = await fetchAddressByCoordinates(coords);
      setLocationFilters({
        ...locationFilters,
        address: `${address.data.results[0].address_components[1].long_name}, ${address.data.results[0].address_components[0].long_name}`,
      });
    } else {
      setFetchError({ status: true, msg: 'Perdón, pero el acceso a su ubicación está bloqueado' });
      setTimeout(() => setFetchError({ status: false }), 5000);
    }

    setSchoolsLoadingState(false);
  }

  async function handleClickPrediction(description, placeId) {
    setLocationFilters({ address: description });
    setPredictions([]);
    const coordinates = await getCoordinatesByPlaceId(placeId);

    querySchoolsWithCoordinates('/school/radius', coordinates);
  }

  const renderPrediction = (description, placeId) => (
    <button
      type="button"
      key={description}
      className={locationStyles.predictions__button}
      onClick={() => handleClickPrediction(description, placeId)}
    >
      {`${description}`}
    </button>
  );

  const renderPredictions = () => (
    predictions && predictions.length > 0 && (
      <div className="dropdown-options">
        <div className={locationStyles.dropdown__predictions__content}>
          <div className={locationStyles.dropdown__predictions__header}>
            <p>UBICACION</p>
          </div>
          <div className={locationStyles.dropdown__predictions__body}>
            {predictions
              .map(({ description, place_id: placeId }) => renderPrediction(description, placeId))}
          </div>
        </div>
      </div>
    )
  );

  function activeFilters() {
    if (refFilter.current.style.display === 'block') {
      refFilter.current.style.display = 'none';
      setFiltersOn(false);
    } else {
      refFilter.current.style.display = 'block';
      setFiltersOn(true);
    }
  }

  function renderError() {
    return fetchError.status
      && (
        <p className={locationStyles.error__msg}>
          {`${fetchError.msg}. `}
          <a
            href={`/${pagesConfig.howItWorks}`}
            className={locationStyles.error__link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Haga clic aquí para saber cómo proceder
          </a>
        </p>
      );
  }

  const errorStyles = {
    color: '#dc3545',
  };

  const changeButtons = () => {
    if (window.scrollY >= 259) {
      setFixButtons(true);
    } else {
      setFixButtons(false);
    }
  };

  const scrollToResults = () => {
    window.scrollTo({
      top: 1200,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // if (filtersOn === false) {
    //   window.scrollTo({
    //     top: 250,
    //     behavior: 'smooth',
    //   });
    // } else {
    //   window.scrollTo({
    //     top: 330,
    //     behavior: 'smooth',
    //   });
    // }
  }, [filtersOn]);

  window.addEventListener('scroll', changeButtons);

  return (
    <div className="sidebar-big-container shadow">
      <div className="sidebar-header">
        <h5 className="sidebar-title">{sidebarConstants.setYourLocation[lang]}</h5>
        <div className={fixButtons ? 'responsive-buttons fixed' : 'responsive-buttons'}>
          <div className="upper-buttons">
            {/* <SaveFilters /> */}
            <button type="button" className="turn_filters" onClick={activeFilters}>{filtersOn ? 'Desactivar Los Filtros' : 'Activar Los Filtros'}</button>
          </div>

          <div className={filtersOn ? 'down-buttons' : 'responsive-none'}>
            <button type="button" className="turn_filters" onClick={scrollToResults}>Ver resultados de búsqueda</button>
          </div>

        </div>
        {renderFAIcon('location', showFilters, toggleShowFilters)}
      </div>
      {showFilters.location
        && (
          <>
            <hr />
            <div className={locationStyles.address__filters__container}>
              <p className={locationStyles.input_up}>Ingresa calle y número:</p>
              <div className={locationStyles.input__with__image}>
                <input
                  type="text"
                  name="address"
                  autoComplete="off"
                  placeholder="Busca por dirección (calles)"
                  disabled={schoolsLoadingState}
                  value={locationFilters.address}
                  onChange={handleChange}
                />
                <FaSearch
                  className={locationStyles.fa__icon__holder}
                  size={20}
                  color="#757c83"
                />
              </div>
              <div className={locationStyles.dropdown__predictions}>
                <div className={`${locationStyles.dropdown__predictions__container} location-filter-dropdown`}>{renderPredictions()}</div>
              </div>
              <div className={`${locationStyles.findMeCrosshairs} last-input mt-1`}>
                <GiCrosshair size={20} />
                <button
                  className={`${locationStyles.find__me} ${locationStyles.locateUserButton}`}
                  onClick={() => handleClickLocation(getLocation, setUsedCoordinates)}
                  type="button"
                >
                  Localizar instituciones cercanas
                  <div className={locationStyles.find__me__tooltip}>
                    <p className={errorStyles}>{findMeTooltipMsg}</p>
                  </div>
                </button>
              </div>
              <div className={locationStyles.error__container}>{renderError()}</div>
            </div>
          </>
        )}
    </div>
  );
}

export default LocationFilters;
