import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogo from './imgs/google.png';

import './styles/google-button.scss';

const GoogleButton = ({
  onClick, disabled, loading, className,
}) => (
  <button id="google-button" type="button" className={`google-button ${className ?? ''}`} onClick={onClick} disabled={disabled}>
    <img className="google-logo" src={GoogleLogo} alt="Google" />
    Continuar con Google
    {' '}
    {
      loading && (<i className="fa fa-spinner fa-pulse fa-2x" />)
    }
  </button>
);

GoogleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

GoogleButton.defaultProps = {
  disabled: false,
  loading: false,
  className: null,
};

export default GoogleButton;
