import React from 'react';
import PropTypes from 'prop-types';

import './experience-progress-bar.scss';

const ExperienceProgressFooterBar = ({ progress }) => {
  const style = {
    width: `${progress}%`,
  };

  return (
    <div className="experience-progress-bar">
      <div className="experience-progress-bar--progress" style={style} />
    </div>
  );
};

ExperienceProgressFooterBar.propTypes = {
  progress: PropTypes.number,
};

ExperienceProgressFooterBar.defaultProps = {
  progress: 0,
};

export default ExperienceProgressFooterBar;
