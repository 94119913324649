import { apiPasswordRecovery } from '../../Data/Services/api';

const passwordRecoveryRequest = async (payload) => {
  try {
    const response = await apiPasswordRecovery.passwordRecovery(payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default passwordRecoveryRequest;
