import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { assignValueById } from 'utils/Activities/activitiesRemoveDuplicates';
import { activityData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import ProfileSection from '../ProfileSection';

export default function EventsActivities({ isFetching }) {
  const schoolActivities = useSelector(activityData);
  const [eventsActivities, setEventsActivities] = useState({});

  useEffect(() => {
    assignValueById(schoolActivities, 18).then(setEventsActivities);
  }, []);

  if (isFetching) {
    return <>...loading</>;
  }

  return (
    <>
      {eventsActivities.length > 0 ? (
        <ProfileSection
          title="Eventos Institucionales"
          subtitleLyrics={{
            subtitle: '',
            lyrics: eventsActivities[0]?.description,
          }}
        />
      ) : null}
    </>
  );
}

EventsActivities.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};
