import api from 'services/api';
import { parsedDataInStorage } from '../Storage/storageFunctions';

const saveFiltersSearch = async (filtersData) => {
  const {
    searchFilters,
    searchSaveName,
    searchFiltersSchools,
    setLoading,
    setIsSaveModalOpen,
  } = filtersData;
  const { token } = parsedDataInStorage('vacanted');

  const config = {
    headers: {
      authorization: token,
    },
  };

  let schoolsDataToSave = [];
  searchFiltersSchools.forEach((school) => {
    const modifiedSchedules = school.schedules.map((sched) => {
      const modifiedScheds = {
        ages: sched.ages,
        level: sched.level,
      };

      return modifiedScheds;
    });

    const {
      id,
      name: schoolName,
      distance,
      public: publicObject,
      rating,
      user_ratings_total: userRatingsTotal,
      religious,
      religion_id: religionId,
      language1_id: language1id,
      language2_id: language2id,
      language3_id: language3id,
      languages,
      postulate,
    } = school;

    schoolsDataToSave = [
      ...schoolsDataToSave,
      schoolsDataToSave[schoolName] = {
        id,
        name: schoolName,
        distance,
        public: publicObject.name,
        rating,
        userRatingsTotal,
        religious,
        religionId,
        schedules: modifiedSchedules,
        language1id,
        language2id,
        language3id,
        languages,
        postulate,
      },
    ];
  });

  const data = {
    type: 'filter',
    country_id: searchFiltersSchools[0].country_id,
    name: searchSaveName,
    context: searchFilters,
    results: schoolsDataToSave,
    savedAt: Date().toString(),
  };

  await api.post('/collection', data, config);

  if (setLoading) {
    setLoading(false);
    setIsSaveModalOpen(false);
  }
};

const saveFiltersAction = async (filtersData) => {
  const { userData } = filtersData;
  if (userData.loginStatus) saveFiltersSearch(filtersData);
};

export default saveFiltersAction;
