import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import vStyles from '../../FamilyInfo.module.scss';
import specificStyle from './specificStyle.module.scss';

const tips = [
  {
    title: 'Aclaración',
    content: 'Podrás establecer los requisitos más importantes para las siguientes áreas: ',
    options: ['Valores', 'Idiomas', 'Religión', 'Costo', 'Infraestructura', 'Otros'],
  },
];

const PageThreeTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
        <ol className={specificStyle.ol__li}>
          {tip.options.map((opt) => (
            <li>{opt}</li>
          ))}
        </ol>
      </div>
    </div>
  ))
);

export default PageThreeTips;
