function getTypeOfLanguage(languages) {
  const qtyPrimaryLanguages = languages.reduce((accLanguages, crrLanguage) => {
    let acc = accLanguages;
    // eslint-disable-next-line no-return-assign
    if (crrLanguage.name) return acc += 1;
    return acc;
  }, 0);
  const languageMap = {
    0: '',
    1: 'Lengua Primaria',
    2: 'Bilingüe',
    3: 'Trilingüe',
  };
  return languageMap[qtyPrimaryLanguages];
}

export default {
  getTypeOfLanguage,
};
