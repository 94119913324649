import Leaflet from 'leaflet';
import mapMarkerImg from '../../assets_vacanted/center.png';

const centerIcon = Leaflet.icon({
  iconUrl: mapMarkerImg,

  iconSize: [30, 30],
  iconAnchor: [15, 30],
  // popupAnchor: [0, -60],
});

export default centerIcon;
