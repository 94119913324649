/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ActivationForm = ({ onSubmit, loading }) => {
  const [value, setValue] = useState('');
  const onChange = (e) => {
    const newValue = e?.target?.value?.slice(0, 6) ?? '';
    if (/^\d+$/.test(newValue)) {
      setValue(newValue);
    } else {
      setValue('');
    }

    if (newValue.length === 6) {
      onSubmit({ activationdNumber: newValue });
    }
  };

  return (
    <form className="login-modal__form-activation">
      <div className="login-modal_activation-inptus">
        <input
          name="activationdNumber"
          className="input-text"
          onChange={onChange}
          value={value}
          maxLength={6}
          autoFocus
        />
      </div>
      <button type="button" onClick={() => onSubmit({ activationdNumber: value })} className="button-lading" disabled={loading || value.length !== 6}>
        Activar Usuario
        {' '}
        {
          loading && (<i className="fa fa-spinner fa-pulse fa-2x" />)
        }
      </button>
    </form>
  );
};

ActivationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ActivationForm;
