import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function AsideScroll({ props }) {
  const [scrollTop, setScrollTop] = useState();

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <div style={{ width: '33%' }}>{props}</div>
  );
}

export default AsideScroll;

AsideScroll.propTypes = {
  props: PropTypes.shape().isRequired,
};
