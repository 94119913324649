import utils from '../selectorsUtils';

const getAvailableLanguages = (languages, foreignLanguages) => {
  let availableLanguages = languages.reduce((accLanguages, crrLanguage) => {
    if (crrLanguage.name) {
      return [...accLanguages, {
        ...crrLanguage,
        multiLingualType: utils.getTypeOfLanguage(languages),
      }];
    }
    return accLanguages;
  }, []);

  availableLanguages = [...availableLanguages].concat(foreignLanguages);
  if (availableLanguages.length > 6) return availableLanguages.slice(0, 6);
  return availableLanguages;
};

const getTooltip = (isPrimary) => {
  if (isPrimary) return 'Una parte o todas las materias se dictan en este idioma, procurando dominio total, oral y escrito.';
  return 'Se dictan determinadas horas semanales de forma regular.';
};

const mutateForeignLanguages = (foreignLanguages) => foreignLanguages.map((lng) => ({
  ...lng,
  name: lng.lang.name,
  multiLingualType: 'Lengua extranjera',
  icon: lng.lang.icon,
}));

const mutateLanguages = (languages) => languages.map((lng) => ({
  ...lng,
  tooltip: getTooltip(lng.isPrimary),
  icon: lng.icon,
  isPrimary: lng.isPrimary || false,

}));

export const selectLanguageData = (state) => {
  const foreignLanguages = state.languages;
  const mutatedForeignLanguages = mutateForeignLanguages(foreignLanguages);

  const primaryLanguages = [
    {
      ...state.language1,
      isPrimary: true,
    },
    {
      ...state.language2,
      isPrimary: true,
    },
    {
      ...state.language3,
      isPrimary: true,
    },
  ];
  const availableLanguages = getAvailableLanguages(primaryLanguages, mutatedForeignLanguages);
  const mutatedLanguages = mutateLanguages(availableLanguages);
  return mutatedLanguages;
};

export default selectLanguageData;
