import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import specificStyle from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';

const PageThree = ({ card, disableNext }) => {
  const [familyInfoData, setFamilyInfoData] = useState('');
  const didMountRef = useRef(false);

  const getInitialData = () => {
    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);

      if (defaultValues) {
        setFamilyInfoData(defaultValues);
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => getInitialData(), []);

  const isCardDataValid = () => familyInfoData !== '';

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageFamilyInfo.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (!isCardDataValid()) removeCardFromLocalStorage();
      else saveCardInLocalStorage(familyInfoData);
    }
  }, [familyInfoData]);

  return (

    <div className={rStyles.vacantest__card__content}>
      <>
        <div>
          <h3>Presentación de la Familia</h3>
          <p>
            Por favor, realiza una presentación detallada de tu familia.
          </p>
        </div>
        <div
          className={rStyles.vacantest__section__container}
          style={{
            padding: '0',
            marginTop: '0',
          }}
        >
          <p className={specificStyle.text__area__label}>
            Descripción de la familia
          </p>
          <textarea
            value={familyInfoData}
            onChange={(e) => setFamilyInfoData(e.target.value)}
            className={specificStyle.text__area}
            placeholder="Descripción"
          />
        </div>
      </>
    </div>
  );
};

export default PageThree;

PageThree.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
