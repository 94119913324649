import React from 'react';

import styles from './DefaultDescription.module.css';

function DefaultDescription() {
  return (
    <div className={styles.description__container}>
      <div className={styles.center}>
        <div className={styles.paragraph__container}>
          <div className={styles.description__top__holder}>
            <div className={styles.icon__holder}>
              <i className="now-ui-icons media-2_sound-wave" />
            </div>
            <h3>Cuenta Gratuita</h3>
          </div>
          <div className={styles.description__text}>
            <p>
              Crea tu cuenta de familia en forma gratuita.
            </p>
          </div>
        </div>
        <div className={styles.paragraph__container}>
          <div className={styles.description__top__holder}>
            <div className={styles.icon__holder}>
              <i className="now-ui-icons media-1_button-pause" />
            </div>
            <h3>Área privada Familia</h3>
          </div>
          <div className={styles.description__text}>
            <p>
              Con tu cuenta podrás acceder a tu propia área privada,
              donde tendrás almacenada toda la información importante
              para evaluar las mejores opciones de instituciones disponibles.
            </p>
          </div>
        </div>
        {/* <div className={styles.paragraph__container}>
          <div className={styles.description__top__holder}>
            <div className={styles.icon__holder}>
              <i className="now-ui-icons users_single-02" />
            </div>
            <h3>Administra tus instituciones educativas favoritas</h3>
          </div>
          <div className={styles.description__text}>
            <p>
              Desde tu Área privada podrás acceder a tus búsquedas guardadas,
              instituciones favoritas y a los centros recomendados por VACANTED™.

            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default DefaultDescription;
