import { useState, useRef } from 'react';

const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState(null);
  const timer = useRef(null);

  const onCancelTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const onCloseSnackbar = () => {
    setSnackbar(null);
  };

  const onSetSnackbar = (payload) => {
    setSnackbar(payload);
    if (!payload.requireAck) {
      timer.current = setTimeout(() => {
        setSnackbar(null);
      }, payload.timeout || 4000);
    }
  };

  const onScheduleCloseSnackbar = (timeout) => {
    timer.current = setTimeout(() => {
      setSnackbar(null);
    }, timeout || 4000);
  };

  return {
    setSnackbar: onSetSnackbar,
    closeSnackbar: onCloseSnackbar,
    cancelTimer: onCancelTimer,
    scheduleCloseSnackbar: onScheduleCloseSnackbar,
    snackbar,
  };
};

export default useSnackbar;
