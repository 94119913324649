import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import specificStyles from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';

const PageTwelve = ({ card, disableNext }) => {
  const [familyInfoData, setFamilyInfoData] = useState({});
  const [specialNeeds, setSpecialNeeds] = useState();
  const [specialNeedsDescription, setSpecialNeedsDescription] = useState('');
  const [parentsEducation, setParentsEducation] = useState('');
  // const [otherQuestion, setOtherQuestion] = useState('');

  const didMountRef = useRef(false);

  const getInitialData = () => {
    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);

      if (defaultValues) {
        setSpecialNeeds(defaultValues.specialNeeds || false);
        setSpecialNeedsDescription(defaultValues.specialNeedsDescription || '');
        setParentsEducation(defaultValues.parentsEducation || '');
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => getInitialData(), []);

  const isCardDataValid = () => {
    if (typeof specialNeeds !== 'boolean') return false;
    if (specialNeeds && specialNeedsDescription.length < 3) {
      return false;
    }
    if (parentsEducation.length < 3) return false;

    return true;
  };

  useEffect(() => {
    setFamilyInfoData({
      specialNeeds, specialNeedsDescription, parentsEducation,
    });
  }, [specialNeeds, specialNeedsDescription, parentsEducation]);

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageFamilyInfo.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (!isCardDataValid()) removeCardFromLocalStorage();
    else saveCardInLocalStorage(familyInfoData);
  }, [familyInfoData]);

  return (

    <div className={rStyles.vacantest__card__content}>
      <>
        <div className={specificStyles.page__title}>
          <h3>La institución valorará conocer a tu familia un poco más...</h3>
          <p>
            ¿Algún integrante de la familia tiene capacidades diferentes?
          </p>
        </div>
        <div className={rStyles.vacantest__section__container}>
          <form action="">
            <div className={specificStyles.radio_inputs}>
              <input
                className={specificStyles.vacantest__checkbox}
                type="radio"
                checked={specialNeeds}
                name="specialNeedsRadio"
                onChange={(e) => setSpecialNeeds(e.target.checked)}
              />
              <p>Si</p>
            </div>
            <div className={specificStyles.radio_inputs}>
              <input
                className={specificStyles.vacantest__checkbox}
                type="radio"
                name="specialNeedsRadio"
                checked={specialNeeds === false}
                onChange={() => setSpecialNeeds(false)}
              />
              <p>No</p>
            </div>
            <br />
            <div
              className={rStyles.vacantest__section__container}
              style={{
                padding: '0',
                marginTop: '-10px',
              }}
            >
              {specialNeeds && (
                <>
                  <p>
                    Por favor, detalle qué integrante tiene capacidades
                    diferentes y cual es su situación educativa.
                  </p>
                  <textarea
                    type="text"
                    value={specialNeedsDescription}
                    onChange={(e) => setSpecialNeedsDescription(e.target.value)}
                    className={specificStyles.text__area}
                    placeholder="Descripción"
                  />
                </>
              )}
              <hr />
            </div>
          </form>
          <div
            className={rStyles.vacantest__section__container}
            style={{
              padding: '0',
              marginTop: '-30px',
            }}
          >
            <p className={specificStyles.text__title}>
              Educación de los padres o personas a cargo
            </p>
            <p className={specificStyles.text__description}>
              Por favor, detalle la educación recibida por los padres
              <br />
              o personas a cargo del núcleo familiar.
            </p>
            <textarea
              type="text"
              value={parentsEducation}
              onChange={(e) => setParentsEducation(e.target.value)}
              className={specificStyles.text__area}
              placeholder="Descripción"
            />
            <hr />
          </div>
        </div>
      </>
    </div>
  );
};

export default PageTwelve;

PageTwelve.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
