import React, { useContext } from 'react';
import SchoolItemContext from '../SchoolItemContext';

const SchoolItemScore = () => {
  const { schoolAffinity } = useContext(SchoolItemContext);
  return (
    <div className="school-item__affinity">
      <div className="school-item__affinity-bage">
        Afinidad
        {' '}
        {schoolAffinity?.totalAffinity}
        %
      </div>
    </div>
  );
};

export default SchoolItemScore;
