import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineArrowDown } from 'react-icons/ai';
import styles from './styles.module.css';

function Accordion({
  children, firstColumn, secondColumn, disabled,
}) {
  const acordionRef = useRef();
  const iconRef = useRef();

  const isAcordionActive = () => {
    if (acordionRef.current) {
      return acordionRef.current.classList.contains(styles.isActive);
    }
    return false;
  };

  const toogleAccordion = () => {
    if (isAcordionActive()) {
      acordionRef.current.classList.add(styles.isInactive);
      acordionRef.current.classList.remove(styles.isActive);
      acordionRef.current.parentElement.classList.remove(styles.accordion__Container_active);

      iconRef.current.classList.remove(styles.rotate);
      iconRef.current.classList.add(styles.rotationDefault);
    } else if (!disabled) {
      acordionRef.current.classList.add(styles.isActive);
      acordionRef.current.classList.remove(styles.isInactive);
      acordionRef.current.parentElement.classList.add(styles.accordion__Container_active);
      iconRef.current.classList.add(styles.rotate);
      iconRef.current.classList.remove(styles.rotationDefault);
    }
  };

  const handleClick = () => {
    toogleAccordion();
  };

  return (
    <div>
      <div
        className={`${styles.accordion__Container} ${isAcordionActive()
          ? styles.accordion__Container_active : ''} `}
      >
        <div
          className={`row ${disabled ? styles.disabled : styles.enabled}`}
          role="button"
          tabIndex={0}
          onKeyPress={handleClick}
          onClick={handleClick}
        >
          <div className="d-flex col-4 p-0 align-items-center   ">
            <p className="mb-0">{firstColumn}</p>
          </div>
          <div className="d-flex col-6 p-0 align-items-center ">
            <p className="mb-0">{secondColumn}</p>
          </div>
          <div className={`${styles.rotationDefault} col-2 p-0`} ref={iconRef}>
            <AiOutlineArrowDown size={16} disabled={disabled} />
          </div>
        </div>
        <div
          className={`${styles.isInactive} ${styles.contentContainer} row`}
          ref={acordionRef}
        >

          {children}
        </div>
      </div>
    </div>
  );
}

export default Accordion;

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  firstColumn: PropTypes.string.isRequired,
  secondColumn: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,

};
