import React from 'react';
import Carousel from 'react-material-ui-carousel';

import Button from '../Experience/src/components/button';

const items = [
  {
    text: 'La verdad súper recomendable la plataforma. La búsqueda de colegios nos tenía un poco abrumados a mi esposo y a mí, porque es una decisión muy importante y hay muchas opciones de colegios. Pero el asesoramiento y la guía que recibí de parte de VacantED fue de mucha ayuda...',
    author: 'María Virginia Suárez',
    link: 'https://maps.app.goo.gl/ABpjLu5RGH8CQQQR7',
  },
  {
    text: 'VacantED es un emprendimiento único, no pensé que Uruguay existiera algo así. Les escribí, me contactaron y agendamos una reunión en la cual relevaron lo que yo necesitaba. Luego me contactaron en varias oportunidades para brindarme las opciones...',
    author: 'Virginia Cappetta',
    link: 'https://maps.app.goo.gl/iFTGf3f5UutT66uj7',
  },
  {
    text: 'Conocí VacantED por una nota en el diario y me dio curiosidad. Si bien no pensaba cambiar de institución, pude comparar los servicios y actividades que ofrecen las instituciones que me quedan cercanas. A su vez anunciaban que se sorteaba el pago de un matrícula y salí favorecida. Muchas gracias!',
    author: 'Milagros Sierra',
    link: 'https://maps.app.goo.gl/5Qn9J883eCTuuWLC9',
  },
  {
    text: 'Conocí al querido equipo de VacantED en la web, cuando realizaba una interminable búsqueda desde hacía más de dos años, recorriendo digitalmente y personalmente todos los centros educativos cercanos a nuestro hogar. Estaré eternamente agradecida por la constancia, el ánimo, la escucha humana y las palabras cálidas de aliento...',
    author: 'Marianella Garcia',
    link: 'https://maps.app.goo.gl/TBFoiEP4ubnuJUB96',
  },
  {
    text: 'Excelente plataforma para encontrar una institución que se adapte a la realidad y expectativas de cada familia. Muy ameno y profesional el trato, siempre con un asesoramiento muy humano y sensato que hacía a uno visualizar otros aspectos no considerados en ocasiones...',
    author: 'Valeria Novo',
    link: 'https://maps.app.goo.gl/VdgH7isrmzkzFFAY8',
  },
];

const TestimonialsCarousel = () => (
  <Carousel
    className="testimonials"
    autoPlay={false}
    navButtonsAlwaysVisible
    animation="slide"
    fullHeightHover={false}
    activeIndicatorIconButtonProps={{
      style: {
        color: '#0096ED',
      },
    }}
    navButtonsWrapperProps={{
      style: {
        bottom: '0px',
        top: 'unset',
        height: 'fit-content',
      },
    }}
    indicatorContainerProps={{
      style: {
        height: '30px',
        marginTop: '50px',
        marginBottom: '5px',
      },
    }}
    navButtonsProps={{
      style: {
        backgroundColor: 'transparent',
        border: '1.5px solid #B3B3B3',
        color: '#1A1A1A',
        marginBottom: '5px',
      },
    }}
  >
    {
      items.map(({ text, author, link }) => (
        <div className="testimonials__testimonial">
          <img
            className="testimonials__testimonial__icon"
            src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_quotes.png"
            alt="cita"
          />
          <div className="testimonials__testimonial__content">
            <p className="testimonials__testimonial__content__text">
              {text}
            </p>
            <p className="testimonials__testimonial__content__author">
              {author}
            </p>
            <Button
              className="testimonials__testimonial__content__action"
              size="small"
              hirachy="transparent"
              chevron="right"
              onClick={link ? () => window.open(link, '_blank') : null}
            >
              Ver testimonio completo
            </Button>
          </div>
        </div>
      ))
    }
  </Carousel>
);

export default TestimonialsCarousel;
