import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useResponsive from '../../hooks/useResponsive';

import BenefitsBanner from '../benefits-banner';

import './experience-body.scss';

const ExperienceBody = ({ children, className, benefitsBanner }) => {
  const { isNotDesktop } = useResponsive();
  const classNameMain = classNames('experience-main-content', {
    'experience-main-content--mobile': isNotDesktop,
    'with-footer': className === 'with-footer',
  });
  const classNameBody = classNames('experience-body', className, {
    'experience-body--mobile': isNotDesktop,
  });

  return (
    <div className={classNameMain}>
      {benefitsBanner && (
        <div className="experience-benefits-banner-container">
          <BenefitsBanner />
        </div>
      )}
      <div className={classNameBody}>
        {children}
        {/* <div className="blur-overlay" /> */}
      </div>
    </div>
  );
};

ExperienceBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  benefitsBanner: PropTypes.bool,
};

ExperienceBody.defaultProps = {
  className: '',
  children: null,
  benefitsBanner: false,
};

export default ExperienceBody;
