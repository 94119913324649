const miniCardConstants = {
  verifiedInstitucionMsg: {
    es: 'Información verificada por la institución',
    pt: 'Informação verificada pela instituição',
  },
  notVerifiedInstitucionMsg: {
    es: 'Información no verificada por la institución',
    pt: 'Informação não verificada pela instituição',
  },
};

export default miniCardConstants;
