import React, { useContext } from 'react';
import SchoolItemContext from '../SchoolItemContext';

const SchoolItemReligion = () => {
  const { school } = useContext(SchoolItemContext);
  const { religion } = school || {};
  return (
    <div className="school-feature school-item__religion">
      <img src="https://vacanted-assets-dev.s3.us-east-1.amazonaws.com/icons/icon_religion.png" alt="religion" />
      {religion?.name || 'Laica'}
    </div>
  );
};

export default SchoolItemReligion;
