/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import useAuthentication from 'components/LoginModal/src/hooks/useAuthentication';
import regex from 'utils/Regex/regex';
import ExprienceMain from '../../../../components/main/view';
import GoogleButton from '../../../../../../../components/Buttons/GoogleButton';
import FacebookButton from '../../../../../../../components/Buttons/FacebookButton';
import './styles.scss';
import ExperienceLoading from '../../../../components/loading';
import useExperience from '../../../../hooks/useExperience';
import { useUserData } from '../../../../../../../hooks/UserContext';
import FlowContext from '../../../../components/flow/context';
import paths from '../../../../../../../routes/paths';

const configs = {
  recommendations: {
    loading: 'Estamos buscando los centros educativos más afines a tu búsqueda...',
    finish: '¡Encontramos los 15 centros educativos más afines a tu búsqueda!',
    icon: true,
  },
  email: {
    loading: 'Enviando recomendaciones...',
    finish: '¡Te enviamos un correo con las recomendaciones!',
    icon: true,
  },
  registeredUser: {
    loading: 'Estamos guardando tu experiencia de búsqueda...',
    finish: '¡Tus recomendaciones están disponibles!',
    icon: true,
  },
  alreadyHasExperience: {
    finish: 'Ya tenés una búsqueda en curso. Podés continuarla o finalizarla y crear otra.',
  },
};

const CongratsExperience = ({ optionData }) => {
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [config, setConfig] = useState(configs.recommendations);
  const [experienceWasCreated, setExperienceWasCreated] = useState(false);
  const [creationError, setCreationError] = useState();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [wppPhone, setWppPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);

  const validUyCellPhoneRegex = /^\+598 0?9\d \d{3} \d{3}$/;
  const isValid = (newValue) => (newValue.startsWith('+598')
    ? validUyCellPhoneRegex.test(newValue)
    : matchIsValidTel(newValue));
  const handlePhoneChange = (newValue) => {
    setWppPhone(newValue);
    setPhoneError(!isValid(newValue));
  };
  const mountSaveDone = useRef(false);

  const { onCreateExperience } = useExperience();
  const { currentData: newExperience, setCurrentPageId } = useContext(FlowContext);

  const navigate = useNavigate();
  const { userData, setActiveExperience } = useUserData();

  const goToExperience = (event) => {
    if (event) event.preventDefault();
    navigate(paths.EXPERIENCE_RECOMMENDATIONS);
  };

  const createExperienceWithEmail = (userEmail, newExp, successConfig, redirect) => {
    setLoadingSave(true);
    onCreateExperience({
      ...newExp,
      email: userEmail,
      phone: phoneError ? null : wppPhone.replace(/\s+/g, ''),
    }, optionData)
      .then((createdExperience) => {
        if (createdExperience) {
          setExperienceWasCreated(true);
          setActiveExperience(createdExperience);
          if (redirect) {
            goToExperience();
          } else {
            setConfig(successConfig);
          }
        }
      })
      .catch((err) => {
        setCreationError(err?.response?.data?.errorType || err);
        console.log(err);
      })
      .finally(() => setLoadingSave(false));
  };

  const createForUser = (theUserData, newExp, redirect) => {
    if (theUserData.loginStatus && !loadingSave && !experienceWasCreated) {
      const { email: userEmail } = theUserData.data.user;
      createExperienceWithEmail(userEmail, newExp, configs.registeredUser, redirect);
    }
  };

  useEffect(() => {
    if (!mountSaveDone.current && userData.loginStatus) {
      mountSaveDone.current = true;
      createForUser(userData, newExperience);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const onSendEmail = (event, newExp) => {
    event.preventDefault();
    createExperienceWithEmail(email, newExp, configs.email);
  };

  const {
    loading: providerLoading,
    handleGoogleClick,
    handleFacebookTokenAfterClick,
  } = useAuthentication();

  useEffect(() => {
    if (creationError) {
      if (creationError === 'EXPERIENCE_USER_ALREADY_HAS_ONE') {
        setConfig(configs.alreadyHasExperience);
      } else {
        setConfig(configs.recommendations);
      }
      // setCreationError(null);
    }
  }, [creationError]);

  if (setCurrentPageId) setCurrentPageId('congrats');

  const chevronDown = () => (
    <svg
      className="chevron"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2203 11.0636L13.6146 7.66939C13.7299 7.554 13.875 7.49497 14.0496 7.4923C14.2243 7.48962 14.372 7.54865 14.4927 7.66939C14.6135 7.79011 14.6738 7.93647 14.6738 8.10847C14.6738 8.28047 14.6135 8.42683 14.4927 8.54755L10.7475 12.2927C10.6696 12.3707 10.5873 12.4258 10.5007 12.4578C10.4142 12.4899 10.3207 12.5059 10.2203 12.5059C10.1199 12.5059 10.0264 12.4899 9.93987 12.4578C9.85333 12.4258 9.77106 12.3707 9.69308 12.2927L5.94789 8.54755C5.8325 8.43218 5.77347 8.28715 5.77081 8.11247C5.76813 7.9378 5.82715 7.79011 5.94789 7.66939C6.06861 7.54865 6.21497 7.48828 6.38697 7.48828C6.55897 7.48828 6.70533 7.54865 6.82606 7.66939L10.2203 11.0636Z"
        fill="black"
      />
    </svg>
  );

  const chevronUp = () => (
    <svg
      className="chevron"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2231 8.93053L6.8288 12.3248C6.71343 12.4401 6.5684 12.4992 6.39372 12.5018C6.21905 12.5045 6.07136 12.4455 5.95064 12.3248C5.8299 12.204 5.76953 12.0577 5.76953 11.8857C5.76953 11.7137 5.8299 11.5673 5.95064 11.4466L9.69582 7.7014C9.77381 7.6234 9.85607 7.56838 9.94261 7.53634C10.0291 7.50428 10.1226 7.48825 10.2231 7.48825C10.3235 7.48825 10.417 7.50428 10.5035 7.53634C10.59 7.56838 10.6723 7.6234 10.7503 7.7014L14.4955 11.4466C14.6109 11.562 14.6699 11.707 14.6726 11.8817C14.6752 12.0563 14.6162 12.204 14.4955 12.3248C14.3747 12.4455 14.2284 12.5059 14.0564 12.5059C13.8844 12.5059 13.738 12.4455 13.6173 12.3248L10.2231 8.93053Z"
        fill="black"
      />
    </svg>
  );

  const successIcon = () => (
    <img
      src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_success.png"
      className="experience-congrats__icon"
      alt="beneficios"
    />
  );

  const loginAndSave = (onClick) => {
    onClick().then((success) => {
      if (success) {
        createForUser(userData, newExperience, true);
      }
    });
  };

  return (
    <ExprienceMain>
      <ExperienceLoading show={loading}>
        <span>
          {config.loading}
        </span>
      </ExperienceLoading>
      {!loading && (
        <div className="experience-congrats">
          {config.icon && successIcon()}
          <div
            className="experience-congrats__message"
          >
            {creationError === 'EXPERIENCE_USER_ALREADY_HAS_ONE'
              ? configs.alreadyHasExperience.finish
              : config.finish}
          </div>
          {experienceWasCreated && !userData?.loginStatus && (
            <div className="experience-congrats__form">
              <div className="experience-congrats__form__toggle">
                <form className="experience-congrats__form__email" onSubmit={() => navigate('/')}>
                  <button type="submit" className="button-send">
                    Volver al inicio
                  </button>
                </form>
              </div>
            </div>
          )}
          {experienceWasCreated && userData?.loginStatus && (
            <div className="experience-congrats__form">
              <div className="experience-congrats__form__toggle">
                <form className="experience-congrats__form__email" onSubmit={goToExperience}>
                  <button type="submit" className="button-send">
                    Ver recomendaciones
                  </button>
                </form>
              </div>
            </div>
          )}
          {!experienceWasCreated && userData?.loginStatus && (
            <div className="experience-congrats__form">
              <div className="experience-congrats__form__toggle">
                {creationError === 'EXPERIENCE_USER_ALREADY_HAS_ONE' && (
                  <form className="experience-congrats__form__email" onSubmit={goToExperience}>
                    <button type="submit" className="button-send">
                      Continuar búsqueda anterior
                    </button>
                  </form>
                )}
                {creationError !== 'EXPERIENCE_USER_ALREADY_HAS_ONE' && (
                  <form
                    className="experience-congrats__form__email"
                    onSubmit={(event) => {
                      event.preventDefault();
                      createForUser(userData, newExperience, true);
                    }}
                  >
                    <button type="submit" className="button-send" disabled={loadingSave}>
                      Ver recomendaciones
                      {' '}
                      {loadingSave && (<i className="fa fa-spinner fa-pulse fa-2x" />)}
                    </button>
                  </form>
                )}
              </div>
            </div>
          )}
          {!experienceWasCreated && !userData?.loginStatus && (
            <>
              <div className="experience-congrats__providers">
                <GoogleButton
                  onClick={() => loginAndSave(handleGoogleClick)}
                  disabled={providerLoading.google}
                  loading={providerLoading.google}
                  className="button-send"
                />
                {false && (
                  <FacebookButton
                    onClick={() => loginAndSave(handleFacebookTokenAfterClick)}
                    handleToken={handleFacebookTokenAfterClick}
                    disabled={providerLoading.facebook}
                    loading={providerLoading.facebook}
                    className="button-send"
                  />
                )}
              </div>
              <div className="experience-congrats__separator">
                <div className="separator__line" />
                <div className="separator__or">ó</div>
                <div className="separator__line" />
              </div>
              <div className="experience-congrats__form">
                <div className="experience-congrats__form__toggle">
                  <div
                    className="button-toggle"
                    onClick={() => setShowEmailForm(!showEmailForm)}
                  >
                    Recibir recomendaciones por e-mail
                    {!showEmailForm && (
                      chevronDown()
                    )}
                    {!!showEmailForm && (
                      chevronUp()
                    )}
                  </div>
                  <div className={`experience-congrats__form__toggle__content ${showEmailForm ? 'shown' : ''}`}>
                    <Box
                      component="form"
                      autoComplete="off"
                      noValidate
                      onSubmit={(e) => onSendEmail(e, newExperience)}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                      }}
                    >
                      <TextField
                        required
                        className="congrats__form__input"
                        InputProps={{
                          className: 'congrats__form__input',
                        }}
                        label="Ingresá tu e-mail"
                        name="email"
                        variant="outlined"
                        size="medium"
                        value={email}
                        onBlur={() => {
                          if (!regex.emailRegex.test(email.trim())) {
                            setEmailError('La dirección no es válida');
                          }
                        }}
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setEmailError(null);
                        }}
                        error={!!emailError || creationError === 'EXPERIENCE_USER_ALREADY_HAS_ONE'}
                        helperText={
                          creationError === 'EXPERIENCE_USER_ALREADY_HAS_ONE'
                            ? 'Ya existe una búsqueda en curso con este correo. Podés continuarla o finalizarla desde tu cuenta.'
                            : emailError
                        }
                      />
                      {!newExperience.receiveOpportunitiesData?.phoneData?.isValid && (
                        <MuiTelInput
                          InputProps={{
                            className: 'congrats__form__input',
                            size: 'medium',
                            name: 'phone',
                          }}
                          fullWidth
                          label="Ingresá tu WhatsApp (Opcional)"
                          defaultCountry="UY"
                          value={wppPhone}
                          onChange={handlePhoneChange}
                        />
                      )}
                      <button
                        id="experience-button-save-email"
                        type="submit"
                        className="button-send"
                        disabled={loadingSave}
                      >
                        Enviar
                        {' '}
                        {loadingSave && (<i className="fa fa-spinner fa-pulse fa-2x" />)}
                      </button>
                    </Box>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </ExprienceMain>
  );
};

CongratsExperience.propTypes = {
  optionData: PropTypes.shape({}).isRequired,
};

export default CongratsExperience;
