const upperLeftActionsConstants = {
  website: {
    es: 'Sitio Web',
    pt: 'Site',
  },
  review: {
    es: 'Escriba una reseña',
    pt: 'Escreva uma avaliação',
  },
};

export default upperLeftActionsConstants;
