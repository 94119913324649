import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 15.833 5.342
      L 14.658 4.167
      L 10 8.825
      L 5.342 4.167
      L 4.167 5.342
      L 8.825 10
      L 4.167 14.658
      L 5.342 15.833
      L 10 11.175
      L 14.658 15.833
      L 15.833 14.658
      L 11.175 10
      z"
      fill="#1a1a1a"
    />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
};

export default Icon;
