import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import vStyles from '../../FamilyInfo.module.scss';

const tips = [
  {
    title: '¿No encuentras tu ciudad?',
    content: 'Estamos trabajando para sumar más ciudades en los próximos meses. Te mantendremos informado.',
  },
];

const VacantestLocationTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
      </div>
    </div>
  ))
);

export default VacantestLocationTips;
