import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'hooks/ApplicationContext';
import { useUserData } from 'hooks/UserContext';
import { registerAnalytics } from 'Authentication/Data/Services/firebase';
import Button from '../../button';
import TrashIcon from '../../../../../../components/Icons/TrashIcon';
import experienceService from '../../../../../../services/experienceService';
import AppComponents from '../../../../../../constants/components';

const RemoveCandidate = ({
  schoolId,
}) => {
  const {
    activeExperience,
    updateActiveExperience,
  } = useUserData();
  const experienceId = activeExperience?.id;
  const {
    snackbarContext: { setSnackbar, closeSnackbar },
    loadingContext: { setPageLoading },
  } = useAppContext();

  const calculatePageName = () => AppComponents.inferFromUrlPath(window.location.pathname)?.name
    || window.location.pathname;

  const onClickDeleteCandidate = (theSchoolId) => {
    setPageLoading(true);
    experienceService.deleteCandidate(
      experienceId,
      theSchoolId,
    ).then(() => {
      updateActiveExperience();
      setSnackbar({
        hirachy: 'success',
        text: 'Centro eliminado de candidatos.',
        action: 'Entendido',
        onClick: closeSnackbar,
      });
      registerAnalytics('delete_candidate', {
        experience_id: experienceId,
        school_id: theSchoolId,
        page: calculatePageName(),
      });
    }).catch(() => {
      setSnackbar({
        hirachy: 'error',
        text: 'No se pudo eliminar el centro de candidatos.',
      });
    })
      .finally(() => setPageLoading(false));
  };

  if (!activeExperience) return null;

  return (
    <>
      <Button
        hirachy="default"
        size="small"
        onClick={
          () => onClickDeleteCandidate(schoolId)
        }
        iconOnly
      >
        <TrashIcon className="icon--fill" />
      </Button>
    </>
  );
};

RemoveCandidate.propTypes = {
  schoolId: PropTypes.number.isRequired,
};

export default RemoveCandidate;
