import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsChevronDown } from 'react-icons/bs';
import cStyles from './Dropdown.module.css';

const VacantestDropdown = ({
  option, setOption, options, disabled,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleItem = (item) => {
    setOption(item);
    setIsActive(false);
  };

  return (
    <div className={cStyles.dropdown}>
      <button
        type="button"
        className={disabled ? `${cStyles.dropdown__button_disabled} ${cStyles.dropdown__button}` : cStyles.dropdown__button}
        onClick={() => setIsActive(!isActive)}
        disabled={disabled}
        style={{ backgroundColor: disabled && '#fafafa' }}
      >
        <p>{option.name}</p>
        <div className={cStyles.dropdown__icon}>
          <BsChevronDown size={20} />
        </div>
      </button>
      {isActive && (
        <div className={cStyles.dropdown__content}>
          {options.map((item) => (
            <button
              type="button"
              className={cStyles.dropdown__item}
              onClick={() => handleItem(item)}
              key={item.id}
            >
              {item.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default VacantestDropdown;

VacantestDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  option: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    PropTypes.string,
  ]),
  setOption: PropTypes.func,
  disabled: PropTypes.bool,
};

VacantestDropdown.defaultProps = {
  option: { name: 'Error', id: 0 },
  setOption: () => 1,
  options: [{ name: 'Error' }],
  disabled: false,
};
