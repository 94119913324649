import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { BiDollar } from 'react-icons/bi';
import { FiPercent } from 'react-icons/fi';
import percentualLimit from 'utils/percentualLimit/percentualLimit';
import moneyMask from 'utils/MoneyMask/moneyMask';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import specificStyles from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';
import SimpleInput from '../Input/SimpleInput';

const PageEleven = ({ card, disableNext }) => {
  const [familyInfoData, setFamilyInfoData] = useState({
    familyIncome: null,
    percentualIncome: null,
    costLimit: null,
    extraEarnings: null,
    maximunCapacity: null,
  });
  const [familyIncome, setFamilyIncome] = useState('');
  const [percentualIncome, setPercentualIncome] = useState('');
  const [costLimit, setCostLimit] = useState('');
  const [extraEarnings, setExtraEarnings] = useState('');
  const [maximunCapacity, setMaximunCapacity] = useState('');

  const didMountRef = useRef(false);

  const familyInfoStorage = localStorageFamilyInfo.get(7);

  let familyBudget = 0;
  let contributorBudget = 0;

  const getInitialData = () => {
    Object.keys(familyInfoStorage).forEach((key) => {
      if (key === 'family') {
        familyBudget = familyInfoStorage[key].map((parent) => parent.monthlyIncome);
      }

      if (key === 'contributor') {
        contributorBudget = familyInfoStorage[key].map((parent) => parent.monthlyContribution);
      }
    });

    const sumValues = (a, b) => Number(a) + Number(b);

    const sumFamilyBudget = familyBudget.reduce(sumValues);
    setFamilyIncome(sumFamilyBudget);

    let sumContributorBudget;
    if (contributorBudget.length !== 0) {
      sumContributorBudget = Number(contributorBudget.reduce(sumValues));
      setExtraEarnings(sumContributorBudget);
    }

    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);

      if (defaultValues) {
        setPercentualIncome(Number(defaultValues.percentualIncome));
        setFamilyInfoData(defaultValues);
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    const percentCost = familyIncome * (percentualIncome / 100);
    setCostLimit(percentCost);
  }, [percentualIncome]);

  useEffect(() => {
    const newMaxCapacity = costLimit + Number(extraEarnings);
    setMaximunCapacity(newMaxCapacity);
  }, [costLimit]);

  const isCardDataValid = () => {
    if (familyIncome
      && percentualIncome
      && costLimit
      // && extraEarnings
      && maximunCapacity
    ) {
      return familyIncome
      && percentualIncome
      && costLimit
      // && extraEarnings
      && maximunCapacity;
    }
    return false;
  };

  useEffect(() => {
    setFamilyInfoData({
      familyIncome, percentualIncome, costLimit, extraEarnings, maximunCapacity,
    });
  }, [familyIncome, percentualIncome, costLimit, extraEarnings, maximunCapacity]);

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageFamilyInfo.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (!isCardDataValid()) removeCardFromLocalStorage();
    else saveCardInLocalStorage(familyInfoData);
  }, [familyInfoData]);

  return (
    <div className={rStyles.vacantest__card__content}>
      <div className={specificStyles.page__title}>
        <h3>Capacidad de pago - Resumen</h3>
      </div>
      <div
        className={rStyles.vacantest__section__container}
        style={{ marginLeft: '-20px' }}
      >
        <form action="">
          <div>
            <p className={rStyles.dropdown__bolder__label}>Ingresos líquidos del núcleo familiar</p>
            <div className={specificStyles.input__with__icon}>
              <SimpleInput
                inputValue={moneyMask(familyIncome)}
                maxLength={9}
                disabled
                backgroundColor="#E8ECF0"
              />
              <div className={specificStyles.img__container}>
                <BiDollar size={20} color="black" />
              </div>
            </div>
          </div>

          <hr />

          <div>
            <p className={rStyles.dropdown__bolder__label}>
              Seleccione el porcentaje sobre los ingresos que a su entender puede destinar
              como máximo a la educación de todos los integrantes de su familia.
            </p>
            <div className={specificStyles.input__with__icon}>
              <SimpleInput
                placeholder="0"
                inputValue={percentualIncome}
                setInputValue={(e) => setPercentualIncome(percentualLimit(Number(e.target.value)))}
                maxLength={3}
              />
              <div className={specificStyles.img__container}>
                <FiPercent size={20} color="black" />
              </div>
            </div>
          </div>

          <hr />

          <div>
            <p className={rStyles.dropdown__bolder__label}>
              Su capacidad de pago mensual del núcleo familiar asignada al costo educativo es:
            </p>
            <div className={specificStyles.input__with__icon}>
              <SimpleInput
                placeholder="0"
                inputValue={moneyMask(costLimit)}
                maxLength={9}
                disabled
                backgroundColor="#E8ECF0"
              />
              <div className={specificStyles.img__container}>
                <BiDollar size={20} color="black" />
              </div>
            </div>
          </div>

          <hr />

          <div>
            <p className={rStyles.dropdown__bolder__label}>
              Ingresos líquidos por fuera del núcleo familiar
            </p>
            <div className={specificStyles.input__with__icon}>
              <SimpleInput
                placeholder="0"
                inputValue={moneyMask(extraEarnings)}
                maxLength={9}
                disabled
                backgroundColor="#E8ECF0"
              />
              <div className={specificStyles.img__container}>
                <BiDollar size={20} color="black" />
              </div>
            </div>
          </div>

          <hr />

          <div>
            <p className={rStyles.dropdown__bolder__label}>
              Su capacidad de pago asignada al costo educativo total familiar es:
            </p>
            <div className={specificStyles.input__with__icon}>
              <SimpleInput
                placeholder="0"
                inputValue={moneyMask(maximunCapacity)}
                maxLength={9}
                disabled
                backgroundColor="#E8ECF0"
              />
              <div className={specificStyles.img__container}>
                <BiDollar size={20} color="black" />
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

export default PageEleven;

PageEleven.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
