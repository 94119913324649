import React, { useContext } from 'react';
import SchoolItemContext from '../SchoolItemContext';

const getDescription = (classLangs, allLangs) => {
  if (classLangs.length === 2) return 'Bilingüe';
  if (classLangs.length === 3) return 'Trilingüe';
  if (allLangs.length > 1) return 'Idiomas';
  return allLangs[0].name;
};

const SchoolItemLanguageFlags = () => {
  const { school } = useContext(SchoolItemContext);
  const classLanguages = [
    school?.language1,
    school?.language2,
    school?.language3,
  ].filter((l) => !!l);
  const allLanguages = [...classLanguages];
  school?.languages?.slice(0, 4)?.forEach(({ lang }) => allLanguages.push(lang));
  const langsToDisplay = [...classLanguages];
  if (langsToDisplay.length === 1) {
    school?.languages?.slice(0, 4)?.forEach(({ lang }) => langsToDisplay.push(lang));
  }
  return (
    <div className="school-feature school-item__languages">
      <div className={`flag-container flag-container-${langsToDisplay.length}`}>
        {langsToDisplay.map((lang, ix) => (
          <img key={`${lang.name}`} src={lang.icon} alt={lang.name} className={`flag flag-${ix + 1}`} />
        ))}
      </div>
      <div className="description">{getDescription(classLanguages, allLanguages)}</div>
    </div>
  );
};

export default SchoolItemLanguageFlags;
