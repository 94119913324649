/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import sessionStorageServices from 'services/sessionStorageServices';
import { useUserData } from 'hooks/UserContext';
import closeIcon from '../../assets_vacanted/close.svg';
import { useSearch } from '../../hooks/SearchContext';
import styles from './styles.module.css';

Modal.setAppElement('#root');

function NotLoggedModal({ Property, Action }) {
  const {
    notLoggedOpen,
    setNotLoggedOpen,
  } = useSearch();
  const { setIsLoginModalOpen } = useUserData();

  function closeModal() {
    sessionStorageServices.removeItem('userFrom');
    sessionStorageServices.removeItem('temp_save');
    setNotLoggedOpen(false);
  }

  function redirect() {
    setIsLoginModalOpen(true);
    setNotLoggedOpen(false);
  }

  return (
    <Modal
      isOpen={notLoggedOpen}
      overlayClassName={styles.react_modal_overlay}
      className={styles.react_modal_content}
    >
      <div className={styles.header_keep}>
        <h2>
          {Action}
          {' '}
          {Property}
        </h2>
        <button
          type="button"
          className={styles['modal-close']}
        >
          <img src={closeIcon} alt="fechar modal" onClick={closeModal} />
        </button>
      </div>
      <p>
        Para
        {' '}
        {Action.toLowerCase()}
        {' '}
        {Property.toLowerCase()}
        {' '}
        debes iniciar sesión:
      </p>
      <button type="button" className={styles.keep_button} onClick={redirect}>Inicia Sesión</button>
      <p>
        ¿No tienes cuenta?
        {' '}
        <button
          type="button"
          className={styles.mark}
          onClick={redirect}
        >
          Regístrate ahora
        </button>
      </p>
    </Modal>
  );
}

NotLoggedModal.propTypes = {
  Property: PropTypes.string.isRequired,
  Action: PropTypes.string.isRequired,
};

export default NotLoggedModal;
