import React, { useEffect, useRef, useState } from 'react';
import KeepSearchModal from 'components/KeepSearchModal';
import { useUserData } from 'hooks/UserContext';
import NotLoggedModal from 'components/NotLogged';
// import { registerAnalytics } from 'Authentication/Data/Services/firebase';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import CardList from '../../components/CardList/CardList';
import MapOrSchoolToggle from '../../components/MapOrSchoolToggle/MapOrSchoolToggle';
import MapView from '../../components/MapView/MapView';
import headerImg from '../../assets_vacanted/header.jpg';
import Footer from '../../components/Footer';
import Spinner from '../../components/Spinner';
import { useSearch } from '../../hooks/SearchContext';

import './styles.css';

const AdvancedSearch = () => {
  const [show, toggleShow] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { userData } = useUserData();
  const {
    schoolsLoadingState,
    usedCoordinates,
    // notLoggedName,
    querySchoolsWithCoordinates,
    setDefaultQuery,
  } = useSearch();

  // get the params
  const urlSearchParams = new URLSearchParams(window.location.search);
  const coordParams = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    setIsOpen(true);

    if (Object.keys(coordParams).length) {
      const route = '/school/radius';
      querySchoolsWithCoordinates(route, coordParams);
    } else {
      setDefaultQuery();
    }
  }, []);

  const resultsRef = useRef(null);

  return (
    <div className="main-wrapper container-xl">
      <Header fixed />
      <Helmet>
        <title>Búsqueda Inteligente - VacantED</title>
        <meta name="description" content="Encontrá los centros educativos de la zona geográfica de tu interés. Configurá los filtros según tus preferencias." />
      </Helmet>
      <div className="navi-wrapper">
        <div className="nav-image-wrapper">
          <img src={headerImg} alt="header" />
          <p>Búsqueda Inteligente</p>
        </div>
      </div>
      <div className="body-wrapper row">
        <Sidebar />
        <div className="body-content col-lg-8 col-sm-12" ref={resultsRef}>
          <div className="switch-wrapper">
            <MapOrSchoolToggle toggle={toggleShow} />
          </div>
          <div className="card-map-container">
            {schoolsLoadingState && <Spinner props={{ schoolsLoadingState, size: 50 }} />}
            {!userData.loginStatus
              ? !schoolsLoadingState && show && <CardList />
              : !schoolsLoadingState
                && show
                && <CardList />}
            {!schoolsLoadingState && !show && (
            <MapView
              latitude={usedCoordinates.latitude}
              longitude={usedCoordinates.longitude}
              heightSize="900px"
              widthSize="1115px"
              pane
            />
            )}
          </div>
        </div>
        <KeepSearchModal isOpen={isOpen} />
      </div>
      <div className="footer-container">
        <Footer />
      </div>
      {/* <NotLoggedModal Property="Colegios" Action="Contactar o guardar" /> */}
      <NotLoggedModal Property="Colegios" Action="Guardar" />
    </div>

  );
};

export default AdvancedSearch;
