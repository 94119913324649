import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import { useUserData } from 'hooks/UserContext';
import { registerAnalytics } from 'Authentication/Data/Services/firebase';
import SchoolsList from '../../../components/schools-List/desktop';
import PageTitle from '../../../components/page-title';
import SearchProfileRecomendations from './SearchProfileRecomendations';
import ExperienceMain from '../../../components/main/view';
import Button from '../../../components/button';
import AddRemoveCandidateButton from '../../../components/buttons/add-remove-candidate';
import RequestVisitButton from '../../../components/buttons/request-visit';
import {
  SchoolItemInfoName,
  SchoolItemDistance,
  SchoolItemReligion,
  SchoolItemLanguageFlags,
  SchoolItemPublicType,
} from '../../../components/schools-List/desktop/src/schoolItem';
import InfoMessage from '../../../components/selector/src/InfoMessage';
import RecommendationsContext from '../context/Context';
import SchoolItemContext from '../../../components/schools-List/desktop/src/SchoolItemContext';
import paths from '../../../../../../routes/paths';
import { isTooltipShown, setTooltipShown } from '../../../../../../utils/Storage/storageFunctions';

import './recommendations-styles.scss';

const Recommendations = () => {
  const mounted = useRef();
  const navigate = useNavigate();
  const [showOnboarding, setShowOnboarding] = useState();
  const {
    activeExperience,
    experienceLoading,
  } = useUserData();

  const {
    // searchProfiles,
    dataLoadingUpdate,
    collapsedProfiles,
    onCollapsedToggle,
    /* onClickAddCandidates,
    onClickViewSchool, */
  } = useContext(RecommendationsContext);

  useEffect(() => {
    if (!mounted.current && !isTooltipShown('recommendations')) {
      setTimeout(() => {
        setShowOnboarding(true);
      }, 1000);
    }
    mounted.current = true;
  }, []);

  const onClickCreate = () => {
    navigate(paths.NEW_PROFILE);
  };

  const getPublicSelectionDescription = (publicSelection) => {
    const res = publicSelection.values.map(({ name }) => name).join(' - ');
    return res;
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) && type === 'tour:end') {
      setTooltipShown('recommendations');
      setShowOnboarding(false);
      registerAnalytics('onboarding_action', {
        page: 'recommendations',
        action: status,
      });
    }
  };

  return (
    <ExperienceMain benefitsBanner>
      <div className="recommendations">
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={!!showOnboarding}
          scrollOffset={300}
          showProgress
          scrollDuration={100}
          hideCloseButton
          showSkipButton
          locale={{
            skip: 'Saltar', next: 'Siguiente', back: 'Anterior', last: 'Finalizar', close: 'Cerrar',
          }}
          steps={[
            {
              target: '.serach-profile-recommendations, .no-profiles-message',
              title: 'Listado de recomendaciones',
              content: `Aquí se muestran los centros educativos que hemos encontrado según tu perfil de búsqueda. 

Podés solicitar entrevistas y agregar como candidatos aquellos que sean de tu interés.`,
              disableBeacon: true,
              placement: 'top',
            },
            {
              target: '#create-profile',
              title: 'Descubrí nuevos centros',
              content: `Creá otros perfiles de búsqueda y obtené más recomendaciones para los niveles educativos que estás buscando.

Por el momento, para buscar en otros niveles, debés finalizar la búsqueda actual y comenzar una nueva.`,
              disableBeacon: true,
              placement: 'bottom',
            },
          ]}
          styles={{
            tooltip: {
              maxWidth: '90%',
            },
            tooltipTitle: {
              textAlign: 'left',
              fontSize: 18,
              padding: '0px 10px',
            },
            tooltipContent: {
              textAlign: 'left',
              fontSize: 14,
              whiteSpace: 'pre-line',
              padding: '16px 10px',
            },
            buttonSkip: {
              color: '#666666',
              fontSize: 14,
              fontWeight: 400,
            },
            buttonBack: {
              color: '#666666',
              fontSize: 14,
              fontWeight: 500,
            },
            buttonNext: {
              backgroundColor: '#0096ED',
              fontSize: 14,
              fontWeight: 500,
            },
            options: {
              zIndex: 10000,
            },
          }}
        />
        <PageTitle
          title="Recomendaciones de centros educativos"
          subtitle="Aquí se muestran las recomendaciones generadas en cada perfil de búsqueda."
          loading={!!experienceLoading || !!dataLoadingUpdate}
        />

        <div className="pre-content">
          <Button id="create-profile" hirachy="primary" onClick={onClickCreate}>
            Crear nuevo perfil de búsqueda
          </Button>
          {!activeExperience?.search_profiles?.length && (
            <div className="no-profiles-message">
              Aún no creaste perfiles de búsqueda.
            </div>
          )}
        </div>
        {activeExperience?.search_profiles?.sort((a, b) => b.id - a.id)?.map((searchProfile) => (
          <SearchProfileRecomendations
            key={`search-profile-${searchProfile.id}`}
            onCollapsedToggle={onCollapsedToggle}
            searchProfile={searchProfile}
            collapsed={collapsedProfiles.includes(searchProfile.id)}
          >
            <SchoolsList>
              {!searchProfile.results?.length && (
                <InfoMessage>
                  No se encontraron centros educativos cercanos que coincidan
                  con los tipos que seleccionaste (
                  {getPublicSelectionDescription(searchProfile.context.publics)}
                  ) y dicten los niveles educativos de tu búsqueda.
                  Podés crear otro perfil de búsqueda con distintos criterios.
                </InfoMessage>

              )}
              {searchProfile.results?.map((result) => (
                <SchoolItemContext.Provider key={result.school.name} value={result}>
                  <div className="school-item">
                    <div className="school-image-container">
                      <a href={`/school/${result.school.id}`} target="_blank" rel="noreferrer">
                        <img className="school-image" src={result.school.logo} alt={result.school.name} />
                      </a>
                    </div>
                    <div className="school-data-actions-container">
                      <div className="school-info">
                        <SchoolItemInfoName includeAffinity />
                        <div className="school-features">
                          <SchoolItemDistance />
                          <SchoolItemPublicType />
                          <SchoolItemReligion />
                          <SchoolItemLanguageFlags />
                        </div>
                      </div>
                      <div className="school-item__actions">
                        <div className="school-item__actions__main__buttons">
                          <RequestVisitButton
                            schoolId={result.school.id}
                            disabled={
                              result.school.contact_disabled || result.school.public?.is_public
                            }
                          />
                          <AddRemoveCandidateButton
                            schoolId={result.school.id}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SchoolItemContext.Provider>
              ))}
            </SchoolsList>
          </SearchProfileRecomendations>
        ))}
      </div>
    </ExperienceMain>
  );
};

export default Recommendations;
