import { getCoordsBasedOnRoute, calculateDistance } from '../Coordinates/coordinatesFunctions';
// import filterSchoolsByDistance from '../SearchFilters/distanceFilter';
import api from '../../services/api';
// import countryService from '../../services/countryService';
import useTable from '../../hooks/useTable';

function mutateSchoolWithDistance(school, coordinates) {
  if (school?.latitude && school?.longitude && coordinates) {
    const schoolCoordinates = {
      latitude: school.latitude,
      longitude: school.longitude,
    };
    const distance = calculateDistance(coordinates, schoolCoordinates);
    return {
      ...school, distance,
    };
  }
  return school;
}

function mutateSchedulesByGroup(schedules) {
  const morning = 'morning';
  const afternoon = 'afternoon';
  const evening = 'evening';
  const doubleSchedule = 'double_schedule';
  const timeExtensions = 'time_extension';
  const level = 'level';

  const morningValue = schedules.some((s) => s[morning]);
  const afternoonValue = schedules.some((s) => s[afternoon]);
  const eveningValue = schedules.some((s) => s[evening]);
  const doubleScheduleValue = schedules.some((s) => s[doubleSchedule]);
  const timeExtensionsValue = schedules.some((s) => s[timeExtensions]);
  const levelValue = schedules.some((s) => s[level]);

  const mutatedSchedules = schedules.reduce((acc, schedule) => [
    ...acc,
    ({
      ...schedule,
      morning: morningValue,
      afternoon: afternoonValue,
      evening: eveningValue,
      double_schedule: doubleScheduleValue,
      time_extension: timeExtensionsValue,
      level: levelValue,
    }),
  ], []);

  return mutatedSchedules.filter((ms) => ms.level);
}

function filterSchoolSchedulesByGroup(school, steps, groupIndex) {
  const schedulesByGroup = school.schedules?.filter((schedule) => {
    const age = schedule.ages.split(' años');
    const ageNum = Number(age[0]);

    return ageNum >= Number(steps[groupIndex][0])
      && ageNum <= Number(steps[groupIndex][1]);
  });

  const filteredSchedulesByGroup = mutateSchedulesByGroup(schedulesByGroup);

  return filteredSchedulesByGroup;
}

// Creates consistency to schedules info in case it was not properly filled in form
function generateSchedulesByTableSteps(school, steps) {
  const stepsIdentifiers = steps?.map((s) => s.id?.split('~'));
  // stepsIdentifiers.splice(0, 1);

  // g1 = 0~1
  // g2 = 2~2
  // g3 = 3~3
  // g4 = 4~5
  // g5 = 6~11
  // g6 = 12~14
  // g7 = 15~17

  const g1 = filterSchoolSchedulesByGroup(school, stepsIdentifiers, 0);
  const g2 = filterSchoolSchedulesByGroup(school, stepsIdentifiers, 1);
  const g3 = filterSchoolSchedulesByGroup(school, stepsIdentifiers, 2);
  const g4 = filterSchoolSchedulesByGroup(school, stepsIdentifiers, 3);
  const g5 = filterSchoolSchedulesByGroup(school, stepsIdentifiers, 4);
  const g6 = filterSchoolSchedulesByGroup(school, stepsIdentifiers, 5);
  const g7 = filterSchoolSchedulesByGroup(school, stepsIdentifiers, 6);

  const groups = [g1, g2, g3, g4, g5, g6, g7].flatMap((g) => g);

  /*
  console.log('school', school.id, school);
  console.log('steps', steps);
  console.log('stepsIdentifiers', stepsIdentifiers);
  console.log('gs', school.id, [g1, g2, g3, g4, g5, g6, g7]);
  console.log('groups', school.id, groups);
  */

  return groups;
}

// Generates arrays to be manipulated by steps && periods filters
function mutateSchoolsWithStepsAndPeriods(schools, steps) {
  return schools.reduce((acc, school) => [
    ...acc,
    ({
      ...school,
      filteredPeriods: generateSchedulesByTableSteps(school, steps),
      filteredSteps: generateSchedulesByTableSteps(school, steps),
      onlyActivePeriods: generateSchedulesByTableSteps(school, steps),
      onlyActiveSteps: generateSchedulesByTableSteps(school, steps),
      onlyMatchedSchedules: generateSchedulesByTableSteps(school, steps),
    }),
  ], []);
}

function mutateSchoolsWithDistanceByRoute(schools, searchRoute) {
  const coordinates = getCoordsBasedOnRoute(searchRoute, schools);

  const mutatedSchools = schools.map((school) => mutateSchoolWithDistance(school, coordinates));
  return mutatedSchools;
}

function mutateSchoolsWithDistanceByCoords(schools, coordinates) {
  return schools.map((school) => mutateSchoolWithDistance(school, coordinates));
}

export function updateSchools(schools, filteredSchools, updater) {
  updater({
    filteredData: filteredSchools,
    rawData: schools,
  });
}

export function updateRawSchools(allSchools, rawSchools, updater) {
  updater({
    ...allSchools,
    rawData: rawSchools,
  });
}

export function updateFilteredSchools(allschools, filteredSchools, updater) {
  updater({
    rawData: allschools,
    filteredData: filteredSchools,
  });
}

async function fetchSchools(route) {
  try {
    const response = await api.get(route);
    if (!response || !response?.data) throw new Error();
    return response.data.schools;
  } catch (err) {
    return [];
  }
}

async function fetchSchoolsWithCoordinates(route, { latitude, longitude }) {
  try {
    const response = await api.get(`${route}?lat=${latitude}&lng=${longitude}`);

    if (!response || !response?.data) throw new Error();

    return response.data;
  } catch (err) {
    return [];
  }
}

async function getTableStepsByRegionSearched(school) {
  const { country_id: countryId, city_id: cityId } = school;
  const { table_step: tableStep } = school.state;
  const { name } = school.city;
  const tableInfo = {
    countryId, cityId, table_step: tableStep, name,
  };

  const { steps } = await useTable(tableInfo);

  return steps;
}

export async function getSchools(searchRoute) {
  const schools = await fetchSchools(searchRoute);
  if (schools?.length > 0) {
    const steps = await getTableStepsByRegionSearched(schools[0]);
    const schoolsWithDistance = mutateSchoolsWithDistanceByRoute(
      schools,
      searchRoute,
    );
    const mutatedSchools = mutateSchoolsWithStepsAndPeriods(schoolsWithDistance, steps);
    return mutatedSchools;
  }

  return [];
}

export async function getSchoolsByCoordinates(searchRoute, coordinates) {
  const schools = await fetchSchoolsWithCoordinates(searchRoute, coordinates);

  if (schools?.length > 0) {
    const steps = await getTableStepsByRegionSearched(schools[0]);
    const schoolsWithDistance = mutateSchoolsWithDistanceByCoords(schools, coordinates);
    const mutatedSchools = mutateSchoolsWithStepsAndPeriods(schoolsWithDistance, steps);
    return mutatedSchools;
  }

  return [];
}

function getCountryAgesMessage(schedules) {
  // Todo: Redo logic to not be tied to any country
  const filteredSchedules = schedules.filter((s) => s.level);
  const ages = filteredSchedules.map((fs) => Number(fs.ages.replace(' años', '')));
  const group1 = { label: 'Inicial', value: false };
  const group2 = { label: 'Primaria', value: false };
  const group3 = { label: 'Secundaria', value: false };

  // ignores age 12 so it does not have to worry about steps
  const groups = ages.reduce((_acc, a) => {
    if (a >= 0 && a <= 5) group1.value = true;
    if (a > 5 && a <= 11) group2.value = true;
    if (a > 12) group3.value = true;

    return [group1, group2, group3];
  }, []);

  let msg = '';
  const trueGroups = groups.filter((g) => g.value).map((fg) => fg.label);
  const count = trueGroups.length;
  if (count > 0) msg = `${trueGroups[0]}`;
  if (count > 1) msg = `${trueGroups[0]} y ${trueGroups[1]}`;
  if (count > 2) msg = `${trueGroups[0]}, ${trueGroups[1]} y ${trueGroups[2]}`;

  return msg;
}

export function getAgesMessageToShow(schedules) {
  return getCountryAgesMessage(schedules);
}

export function getCenterDescription(name, schedules, countryId) {
  return `Centro ${name} de Educación ${getAgesMessageToShow(
    schedules,
    countryId,
  )}`;
}

export function sortSchools(schools, type, updater) {
  let sortedSchools;
  let sortedRawSchools;
  switch (type) {
    case 0:
      sortedSchools = schools.filteredData.sort((s1, s2) => s1.distance - s2.distance);
      sortedRawSchools = schools.rawData.sort((s1, s2) => s1.distance - s2.distance);
      break;
    case 1:
      sortedSchools = schools.filteredData.sort((s1, s2) => s1.distance - s2.distance);
      sortedRawSchools = schools.rawData.sort((s1, s2) => s1.distance - s2.distance);
      break;
    case 2:
      sortedSchools = schools.filteredData.sort((s1, s2) => s1.name.localeCompare(s2.name));
      sortedRawSchools = schools.rawData.sort((s1, s2) => s1.name.localeCompare(s2.name));
      break;
    case 3:
      sortedSchools = schools.filteredData.sort((s1, s2) => {
        if (s1.name < s2.name) return 1;
        if (s1.name > s2.name) return -1;
        return 0;
      });
      sortedRawSchools = schools.rawData.sort((s1, s2) => {
        if (s1.name < s2.name) return 1;
        if (s1.name > s2.name) return -1;
        return 0;
      });
      break;
    case 6:
      sortedSchools = schools.filteredData
        .sort((s1, s2) => Number(s2.rating) - Number(s1.rating));
      sortedRawSchools = schools.rawData
        .sort((s1, s2) => Number(s2.rating) - Number(s1.rating));
      break;
    default:
      return null;
  }

  updateRawSchools(sortedRawSchools, sortedRawSchools, updater);
  return updateFilteredSchools(sortedRawSchools, sortedSchools, updater);
}

function getStepsAvailable(schedule) {
  const stepsAvailable = [];
  if (schedule.morning) {
    stepsAvailable.push('Matutino');
  }
  if (schedule.afternoon) {
    stepsAvailable.push('Vespertino');
  }
  if (schedule.evening) {
    stepsAvailable.push('Nocturno');
  }
  if (schedule.time_extension) {
    stepsAvailable.push('Extensión Horaria');
  }
  if (schedule.double_schedule) {
    stepsAvailable.push('Doble horario');
  }
  if (stepsAvailable.length > 1) {
    return stepsAvailable.join(' - ');
  }
  return stepsAvailable.join('');
}

const checkForMultipleAges = (numericAge) => {
  if (numericAge?.includes('-')) {
    return false;
  }
  return true;
};

const modifyAges = (ages, step) => {
  const numericAge = step.split('(')[1];
  const hasMultipleAges = checkForMultipleAges(numericAge);
  if (numericAge && !hasMultipleAges) {
    return `(${numericAge}`;
  }
  return ages;
};

const modifyScheduleShape = (schedule) => ({
  ...schedule,
  step: schedule.step.split('(')[0],
  ages: modifyAges(schedule.ages, schedule.step),
  stepsAvailable: getStepsAvailable(schedule),

});

const modifySchedulesWithoutDuplicates = (schedules, schedule) => {
  if (schedules?.length >= 1) {
    const hasRepeatedStep = schedules.find((sch) => sch?.ages === schedule.ages);
    if (!hasRepeatedStep) {
      return [...schedules, schedule];
    }
    return [...schedules];
  }

  return [schedule];
};

export function reduceSchedulesIntoCategories(schedules, schedule) {
  const modifiedSchedule = modifyScheduleShape(schedule);
  return modifySchedulesWithoutDuplicates(schedules, modifiedSchedule);
}

export const splitSchedulesIntoPreschoolAndAbove = (schoolSchedules) => {
  const toReturn = {
    preschool: [],
    notPreschool: [],
  };
  schoolSchedules.filter(({ level }) => !!level).forEach((schedule) => {
    const age = schedule.ages.split(' años')[0];
    const ageNum = Number(age);

    if (ageNum <= 5) {
      toReturn.preschool.push(schedule);
    } else {
      toReturn.notPreschool.push(schedule);
    }
  });
  return toReturn;
};
