import React, {
  useState,
  useEffect,
  createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';
import updateUserData from 'Authorization/storage/updateUserData';
import startSession from 'utils/Session/sessionMethods';
import { getLocation } from 'utils/Coordinates/coordinatesFunctions';
import experienceService from '../services/experienceService';

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userCoordinates, setUserCoordinates] = useState({ latitude: '0', longtitude: '0' });
  const [appLanguage, setAppLanguage] = useState('es');
  const [errorCoordinates, setErrorCoordinates] = useState('');
  const [userFilters, setUserFilters] = useState({});
  const [userSavedSchool, setUserSavedSchool] = useState('');
  const [schoolRegisterModal, setSchoolRegisterModal] = useState(false);
  const [userData, setUserData] = useState({ loginStatus: false, data: {} });
  const [favoritedSchools, setFavoritedSchools] = useState({});
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const [experienceLoading, setExperienceLoading] = useState(false);
  const [activeExperience, setActiveExperience] = useState();

  const [schoolToRegister, setSchoolToRegister] = useState();

  const updateActiveExperience = () => {
    setExperienceLoading(true);
    experienceService.getActiveUserExperience()
      .then((experience) => {
        // console.log('Setting active experience', experience);
        setActiveExperience(experience);
        updateUserData({ active_experience: experience });
      })
      .finally(() => setExperienceLoading(false));
  };

  useEffect(() => {
    getLocation(setUserCoordinates, setErrorCoordinates);
    startSession();
  }, []);

  useEffect(() => {
    if (userData?.loginStatus) {
      if (userData.data.active_experience) {
        // console.log('Reusing active experience...', userData.data.active_experience);
        setActiveExperience(userData.data.active_experience);
      } else {
        updateActiveExperience();
      }
    } else if (userData.data?.loggedOut) {
      setActiveExperience(null);
    }
  }, [userData]);

  return (
    <UserContext.Provider value={{
      userCoordinates,
      errorCoordinates,
      getLocation,
      userFilters,
      setUserFilters,
      userSavedSchool,
      setUserSavedSchool,
      schoolRegisterModal,
      setSchoolRegisterModal,
      userData,
      setUserData,
      isUserLoading,
      setIsUserLoading,
      favoritedSchools,
      setFavoritedSchools,
      appLanguage,
      setAppLanguage,
      isLoginModalOpen,
      setIsLoginModalOpen,
      activeExperience,
      experienceLoading,
      updateActiveExperience,
      setActiveExperience: (exp) => {
        if (userData?.loginStatus) setActiveExperience(exp);
      },
      schoolToRegister,
      setSchoolToRegister,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserData = () => {
  const context = useContext(UserContext);
  return context;
};

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
