import React from 'react';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import saveFiltersAction from 'utils/Favorites/saveFilters';
import { useNavigate } from 'react-router-dom';
import { useUserData } from 'hooks/UserContext';
import { saveDataInStorage } from 'utils/Storage/storageFunctions';
import closeIcon from '../../assets_vacanted/close.svg';
import { useSearch } from '../../hooks/SearchContext';
import modalStyles from './KeepSearchModal.module.css';

Modal.setAppElement('#root');

function KeepSearchModal() {
  const {
    isSaveModalOpen,
    setIsSaveModalOpen,
    filters,
    schoolsData,
  } = useSearch();
  const { userData } = useUserData();
  const schools = schoolsData.filteredData;
  const navigate = useNavigate();

  const searchNameRef = React.useRef(null);

  const handleCloseModal = () => {
    setIsSaveModalOpen(false);
  };

  const loginAndSaveSearch = (filtersData) => {
    saveDataInStorage({ ...filtersData, type: 'filter' }, 'temp_save');
    navigate('/auth/login');
  };

  const [loading, setLoading] = React.useState(false);

  const saveSearch = async () => {
    const filtersData = {
      searchFilters: filters,
      searchSaveName: searchNameRef.current.value,
      searchFiltersSchools: schools,
      userData,
      // history,
    };

    if (userData.loginStatus) {
      setLoading(true);
      saveFiltersAction({ ...filtersData, setLoading, setIsSaveModalOpen });
    } else {
      setIsSaveModalOpen(false);
      loginAndSaveSearch(filtersData);
    }
  };

  return (
    <Modal
      isOpen={isSaveModalOpen}
      onRequestClose={handleCloseModal}
      overlayClassName={modalStyles.react_modal_overlay}
      className={modalStyles.react_modal_content}
    >
      <div className={modalStyles.header_keep}>
        <h2>GUARDAR MI BÚSQUEDA</h2>
        <button
          type="button"
          onClick={handleCloseModal}
          className={modalStyles['modal-close']}
        >
          <img src={closeIcon} alt="cerrar modal" />
        </button>
      </div>
      <form>
        <input
          ref={searchNameRef}
          className={modalStyles.name_input}
          type="text"
          placeholder="Nombre de mi búsqueda"
        />
        <div className={modalStyles.loading__container}>
          <ClipLoader loading={loading} color="#3e95e6" width="100%" />
          {!loading
            && <button type="button" className={modalStyles.keep_button} onClick={saveSearch}>Guardar</button>}
        </div>
      </form>
    </Modal>
  );
}

export default KeepSearchModal;
