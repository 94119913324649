import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

import './row-then-column.scss';

const RowThenColumn = ({
  minRowWidth, className, rowProps, columnProps, children,
}) => {
  const isRow = useMediaQuery({ minWidth: minRowWidth || 479 });
  const propsToSpread = isRow ? rowProps : columnProps;

  return (
    <div
      className={`row-then-column ${isRow ? 'flex-row' : 'flex-column'} ${className || ''}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...propsToSpread}
    >
      {children}
    </div>
  );
};

RowThenColumn.propTypes = {
  minRowWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  rowProps: PropTypes.shape({}),
  columnProps: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

RowThenColumn.defaultProps = {
  className: null,
  rowProps: {},
  columnProps: {},
  children: null,
};

export default RowThenColumn;
