const generateComparison = () => ({

  isObjsEqual(obj1, obj2) {
    if (obj1 !== null && obj2 !== null) {
      const propsObj1 = Object.keys(obj1);
      // const propsObj2 = Object.keys(obj2);
      const comparisonResult = propsObj1
        .reduce((acc, prop) => acc && obj1[prop] === (obj2[prop] || false), true);
      return comparisonResult;
    }
    return null;
  },

});
export default generateComparison;
