import filterFunctions from 'utils/SearchFilters/filterFunctions';
import filterSchoolsByDistance from '../utils/SearchFilters/distanceFilter';

async function useFilters(schoolsData, setSchoolsData, filters, tableData, usedCoords) {
  let filteredData = schoolsData.rawData;

  Object.entries(filters).map((filter) => {
    const key = filter[0];
    const keyName = key.substring(0, key.indexOf('-'));
    const id = key.substring(key.indexOf('-') + 1);
    const value = filter[1];

    if (keyName === 'religious') {
      if (value && id === 'secular') filteredData = filteredData.filter((school) => !school.religious);
      if (value && id === 'religious') filteredData = filteredData.filter((school) => school.religious);
    } else if (keyName === 'language1_id' && value) {
      filteredData = filteredData.filter((school) => school[keyName] === Number(id)
        || school.language2_id === Number(id)
        || school.language3_id === Number(id));
    } else if (keyName === 'language_id' && value) {
      filteredData = filteredData.filter((school) => school.languages
        .map((language) => language[keyName])
        .includes(Number(id)));
    } else if (keyName === 'schedules' && value) {
      filteredData = filterFunctions.filterByPeriods(filteredData, filters, id);
    } else if (keyName === 'steps' && value) {
      filteredData = filterFunctions.filterBySteps(filteredData, filters, id);
    } else if (value && keyName !== 'range') {
      filteredData = filteredData.filter((school) => school[keyName] === Number(id));
    }

    const rangeValue = filters['range-value'];
    filteredData = filterSchoolsByDistance(usedCoords, filteredData, rangeValue);

    return setSchoolsData({ ...schoolsData, filteredData });
  });
}

export default useFilters;
