import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const HowItWorksStepper = ({ orientation }) => (
  <Stepper
    activeStep={null}
    orientation={orientation || 'horizontal'}
    alternativeLabel={orientation === 'horizontal'}
  >
    <Step key="1">
      <StepLabel>
        <div className="step-title">
          Obtené recomendaciones
        </div>
        <div className="step-subtitle">
          Ingresá tus preferencias y descubrí los centros más afines a tu búsqueda.
        </div>
      </StepLabel>
    </Step>
    <Step key="2">
      <StepLabel>
        <div className="step-title">
          Analizá tus candidatos
        </div>
        <div className="step-subtitle">
          Podrás compararlos, realizarles consultas y solicitar una entrevista.
        </div>
      </StepLabel>
    </Step>
    <Step key="3">
      <StepLabel>
        <div className="step-title">
          Confirmá tu inscripción
        </div>
        <div className="step-subtitle">
          Informanos qué centro educativo elegiste y reclamá tus beneficios.
        </div>
      </StepLabel>
    </Step>
  </Stepper>
);

HowItWorksStepper.propTypes = {
  orientation: PropTypes.string,
};

HowItWorksStepper.defaultProps = {
  orientation: 'horizontal',
};

export default HowItWorksStepper;
