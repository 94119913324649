/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './experience-modal.scss';
import classNames from 'classnames';

const ExperienceModal = ({
  isOpen, onClose, children, title, subtitle, size,
}) => {
  const classNmaes = classNames('experience-modal', {
    'experience-modal--large': size === 'large',
    'experience-modal--medium': size === 'medium',
    'experience-modal--small': size === 'small',
    'experience-modal--header-present': title || subtitle,
  });

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (!onClose) return null;

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    isOpen && (
    <div className="experience-modal-overlay">
      <div ref={containerRef} className={classNmaes}>
        <div className="experience-modal-header">
          <div className="experience-modal-header__title">
            {(!!title) && (<h3 className="experience-modal-title">{title}</h3>)}
            {(!!subtitle) && (<p className="experience-modal-subtitle">{subtitle}</p>)}
          </div>
          <button type="button" className="experience-modal-close-button" onClick={onClose}>
            <i className="fa fa-times fa-lg" aria-hidden="true" />
          </button>
        </div>
        <div className="experience-modal-body">
          {children}
        </div>
      </div>
    </div>
    )
  );
};

ExperienceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
};

ExperienceModal.defaultProps = {
  isOpen: false,
  children: null,
  title: '',
  subtitle: '',
  size: 'medium',
};

export default ExperienceModal;
