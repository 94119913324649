/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice, createDraftSafeSelector, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import normalizeSchool from './SchoolNormalize';
import { reduceSchedulesIntoCategories } from '../../utils/schools/schoolFunctions';
import selectors from './Selectors';

function isSchoolFulfilled(action) {
  return action.type.endsWith('fulfilled');
}

export const fetchSchoolById = createAsyncThunk('school/data', async (id) => {
  const response = await api.get(`/school/${id}?completed`);
  return response.data;
});

const SchoolProfileSlice = createSlice({
  name: 'schools',
  initialState: {
    loading: 'pending',
    entities: {
      coords: {},
      miniCard: {},
    },

  },
  reducers: {

  },
  extraReducers: (builder) => {
    // Add extra reducers here
    builder.addCase(fetchSchoolById.pending, (state) => {
      state.loading = 'pending';
    });

    builder.addCase(fetchSchoolById.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = 'idle';
    });

    builder.addMatcher(isSchoolFulfilled, (state, action) => {
      state.entities = normalizeSchool(action.payload);
      state.loading = 'idle';
    });
  },

});

const selectSchoolsEntities = (state) => state.schools.entities;
const selectSchools = (state) => state.schools;
const selectSchedules = (state) => state.schools.entities.schedules;

/* redux output data
  selector is a function that takes a state and returns a value
  selector can also be a constant, in which case it will return the constant

  ex: imagine que um selector é uma pessoa que voce pede pra buscar algo no armazem(store).
  pra ela buscar algo voce deve fornecer os parametros deste algo,
  para que ela retorne com o objeto de desejo.

  to provide data you have to create a selector to use in a  component
  to use in react-component you have to wrap the selector in the hook useSelector
*/
export const selectMiniCard = createDraftSafeSelector(selectSchoolsEntities,
  (state) => state.miniCard);

export const loadingStore = createDraftSafeSelector(selectSchools,
  (state) => state.loading === 'pending');

export const DescriptionData = createDraftSafeSelector(selectSchoolsEntities,
  (state) => state.description);

export const languageData = createDraftSafeSelector(selectSchoolsEntities,
  selectors.selectLanguageData);

export const languageDescription = createDraftSafeSelector(selectSchoolsEntities,
  (state) => state.language_description);

export const MainFeaturesData = createDraftSafeSelector(selectSchoolsEntities,
  selectors.selectMainFeatures);

export const stepsData = createDraftSafeSelector(selectSchedules,
  (state) => state.reduce(reduceSchedulesIntoCategories, []));

export const schedulesData = createDraftSafeSelector(selectSchoolsEntities,
  (state) => state.schedules);

export const activityData = createDraftSafeSelector(selectSchoolsEntities,
  (state) => state.schoolactivities);

export const selectCoords = createDraftSafeSelector(selectSchoolsEntities,
  (state) => ({ lat: state.latitude, lng: state.longitude }));

export const selectSchoolInfo = createDraftSafeSelector(selectSchoolsEntities,
  (state) => ({
    schoolName: state.name,
    schoolManagement: state.public_id,
    calendar: state.calendar,
    schoolAffinity: state.schoolAffinity,
    postulate: state.postulate,
    website: state.website,
    contactDisabled: state.contact_disabled,
  }));

export const selectSchool = createDraftSafeSelector(selectSchoolsEntities,
  (state) => state);

export default SchoolProfileSlice.reducer;
