export default function percentualLimit(number) {
  if (!number) {
    return '';
  } if (number < 0) {
    return 0;
  } if (number > 100) {
    return 100;
  }
  return number;
}
