import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';
import { sortSchools } from 'utils/schools/schoolFunctions';
import { useSchools } from 'hooks/SchoolContext';
import { useSearch } from '../../hooks/SearchContext';
import './styles.css';

const MapOrSchoolToggle = (props) => {
  const { toggle } = props;
  const { schoolsData, setSchoolsData } = useSchools();
  const { schoolsLoadingState } = useSearch();
  const schoolsLength = schoolsData.filteredData.length;

  const selected = {
    backgroundColor: '#e8ecf0',
    color: '#333333',
  };

  const unSelected = {
    backgroundColor: '#fff',
    color: '#333333',
  };

  const [btnStyles, setBtnStyles] = useState({
    cards: selected,
    map: unSelected,
  });

  function renderMessage() {
    if (schoolsLength === 1) return ' Institución encontrada';

    return ' Instituciones encontradas';
  }

  function handleClick({ target }) {
    const { name } = target;

    if (name === 'cards') {
      toggle(true);
      setBtnStyles({ cards: selected, map: unSelected });
    } else {
      toggle(false);
      setBtnStyles({ cards: unSelected, map: selected });
    }
  }

  function getNrOfSchools() {
    return (schoolsLoadingState) ? <ClipLoader color="#0096ed" loading={schoolsLoadingState} size={15} />
      : schoolsLength;
  }

  const renderToggleOptions = () => {
    const selectOptions = [
      { id: 0, label: 'Más relevantes' },
      { id: 1, label: 'Distancia' },
      { id: 2, label: 'Orden ascendente' },
      { id: 3, label: 'Orden descendente' },
      // { id: 4, label: 'Mayor precio' },
      // { id: 5, label: 'Menor precio' },
      { id: 6, label: 'Reseñas Google' },
    ];

    return selectOptions.map((o) => <option key={o.id} value={o.id}>{o.label}</option>);
  };

  function handleSelect({ target }) {
    const { value } = target;

    sortSchools(schoolsData, Number(value), setSchoolsData);
  }

  return (
    <div className="toggle-container shadow">
      <div className="nr__of__schools">
        <p>
          <span>{getNrOfSchools()}</span>
          {renderMessage()}
        </p>
      </div>
      <div className="toggle__buttons__container">
        <button
          name="cards"
          type="button"
          className="map__toggle__button"
          style={btnStyles.cards}
          onClick={handleClick}
        >
          Vista Fichas
        </button>
        <button
          name="map"
          type="button"
          className="map__toggle__button"
          style={btnStyles.map}
          onClick={handleClick}
        >
          Vista Mapa
        </button>
      </div>
      <hr className="toggle__line__break" />
      <div className="toggle__select">
        <p className="label__toggle__select">Ordenar por:</p>
        <select className="sort-toogle-select" onChange={handleSelect}>
          {renderToggleOptions()}
        </select>
      </div>
    </div>
  );
};

export default MapOrSchoolToggle;

MapOrSchoolToggle.propTypes = {
  toggle: PropTypes.func.isRequired,
};
