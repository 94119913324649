function generateOptionsForPasswordBar(nrOfRegexPassed) {
  const emptyOptions = { fill: '#f8f9fa', msg: '' };
  const weakOptions = { fill: '#dc3545', msg: 'Débil' };
  const avgOptions = { fill: '#FF9800', msg: 'Mediana' };
  const strongOptions = { fill: '#ffc107', msg: 'Fuerte' };
  const veryStrongOptions = { fill: '#5cb85c', msg: 'Muy fuerte' };

  let fill;
  let msg;
  switch (nrOfRegexPassed) {
    case 0:
      fill = emptyOptions.fill;
      msg = emptyOptions.msg;
      break;
    case 1:
      fill = weakOptions.fill;
      msg = weakOptions.msg;
      break;
    case 2:
      fill = avgOptions.fill;
      msg = avgOptions.msg;
      break;
    case 3:
      fill = strongOptions.fill;
      msg = strongOptions.msg;
      break;
    case 4:
      fill = veryStrongOptions.fill;
      msg = veryStrongOptions.msg;
      break;
    default:
      fill = emptyOptions.fill;
      msg = emptyOptions.msg;
  }

  return { fill, msg };
}

function generatePassedOptions(passwordLength, passedRegex) {
  let passedOptions;
  passedOptions = passedRegex;
  if (passedOptions === 4 && passwordLength < 8) passedOptions -= 1;
  if (passedOptions === 3 && passwordLength < 6) passedOptions -= 1;
  if (passedOptions === 2 && passwordLength < 4) passedOptions -= 1;

  return passedOptions;
}

function validateEmailPattern(email, updater) {
  const emailPattern = /^[^ ]+@[^ ]+\.[a-z]+$/;
  updater((email) ? { valid: emailPattern.test(email), showWarning: false }
    : { valid: false, showWarning: false });
}

function validatePassword(password, updater) {
  const passwordLength = password.length;

  const passwordRegex = [];
  passwordRegex.push('[A-Z]');
  passwordRegex.push('[a-z]');
  passwordRegex.push('[0-9]');
  passwordRegex.push('[$@$!%*#?&.]');

  let passedRegex = 0;
  passwordRegex.forEach((pr) => {
    if (RegExp(pr).test(password)) passedRegex += 1;
  });

  const passedOptions = generatePassedOptions(passwordLength, passedRegex);

  const optionsForPasswordBar = generateOptionsForPasswordBar(passedOptions);
  const { fill, msg } = optionsForPasswordBar;

  const validatedPassword = passedOptions === 4 && passwordLength >= 8;
  updater((validatedPassword)
    ? {
      valid: true,
      bar: { fill, count: passedOptions, msg },
      showWarning: false,
    }
    : {
      valid: false,
      bar: { fill, count: passedOptions, msg },
      showWarning: false,
    });
}

const regControl = {
  generateOptionsForPasswordBar,
  generatePassedOptions,
  validateEmailPattern,
  validatePassword,
};

export default regControl;
