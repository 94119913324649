import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';

import '../benefits-modal/benefits-modal-styles.scss';
import './benefits-warning-modal-styles.scss';

const BenefitsModal = ({ close, accept }) => (
  <div className="experience-benefits-warning-modal">
    <div className="experience-benefits-warning-modal__title">
      <div className="experience-benefits-warning-modal__title__image__container">
        <img
          src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_gift_warning.png"
          className="experience-benefits-warning-modal__image"
          alt="beneficios"
        />
      </div>
      <h1 className="experience-benefits-warning-modal__title__text">
        Para poder aplicarte los beneficios necesitamos que completes todos los datos.
      </h1>
    </div>
    <div className="experience-benefits-warning-modal__content">
      <div className="experience-benefits-warning-modal__content__actions">
        <div className="experience-benefits-warning-modal__content__actions__text">
          ¿Cómo querés continuar?
        </div>
        <div className="experience-benefits-warning-modal__content__actions__buttons">
          <Button
            hirachy="primary"
            size="small"
            onClick={close}
          >
            Ingresar datos
          </Button>
          <Button
            hirachy="default"
            size="small"
            onClick={accept}
          >
            Finalizar de todas formas
          </Button>
        </div>
      </div>
    </div>
  </div>
);

BenefitsModal.propTypes = {
  close: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
};

export default BenefitsModal;
