import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className }) => (
  <svg className={className} width="86" height="80" viewBox="0 0 86 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2402 63.3334C11.6974 63.3334 11.2357 63.1434 10.8552 62.7634C10.4752 62.3834 10.2852 61.9217 10.2852 61.3784C10.2852 60.8356 10.4752 60.3848 10.8552 60.0259C11.2357 59.667 11.6974 59.4876 12.2402 59.4876H16.9518V21.2817C16.9518 20.0456 17.4121 18.9676 18.3327 18.0476C19.2527 17.127 20.3307 16.6667 21.5668 16.6667H65.6702C66.0329 16.6667 66.3374 16.7912 66.5835 17.0401C66.829 17.2895 66.9518 17.5984 66.9518 17.9667C66.9518 18.3351 66.829 18.6379 66.5835 18.8751C66.3374 19.1123 66.0329 19.2309 65.6702 19.2309H21.5668C20.969 19.2309 20.4777 19.4231 20.0927 19.8076C19.7082 20.1926 19.516 20.684 19.516 21.2817V59.4876H35.8943C36.4193 59.4876 36.8657 59.674 37.2335 60.0467C37.6013 60.4195 37.7852 60.872 37.7852 61.4042C37.7852 61.9548 37.6013 62.414 37.2335 62.7817C36.8657 63.1495 36.4193 63.3334 35.8943 63.3334H12.2402ZM48.5543 63.3334C47.9004 63.3334 47.3524 63.1123 46.9102 62.6701C46.4679 62.2279 46.2468 61.6798 46.2468 61.0259V28.7184C46.2468 28.0645 46.4679 27.5162 46.9102 27.0734C47.3524 26.6312 47.9004 26.4101 48.5543 26.4101H68.0418C68.6957 26.4101 69.2438 26.6312 69.686 27.0734C70.1282 27.5162 70.3493 28.0645 70.3493 28.7184V61.0259C70.3493 61.6798 70.1282 62.2279 69.686 62.6701C69.2438 63.1123 68.6957 63.3334 68.0418 63.3334H48.5543ZM48.811 59.4876H67.7852V28.9742H48.811V59.4876Z"
      fill="#5F6368"
    />
    <circle cx="72.0006" cy="60.7142" r="11.4286" fill="white" stroke="white" strokeWidth="2.28571" />
    <path
      d="M72.0006 65.8362C72.2483 65.8362 72.456 65.7524 72.6237 65.5847C72.7913 65.4171 72.8751 65.2094 72.8751 64.9616C72.8751 64.7139 72.7913 64.5062 72.6237 64.3385C72.456 64.1711 72.2483 64.0874 72.0006 64.0874C71.7528 64.0874 71.5451 64.1711 71.3775 64.3385C71.2098 64.5062 71.126 64.7139 71.126 64.9616C71.126 65.2094 71.2098 65.4171 71.3775 65.5847C71.5451 65.7524 71.7528 65.8362 72.0006 65.8362ZM72.0008 61.8803C72.2311 61.8803 72.4239 61.8024 72.5793 61.6467C72.7348 61.4911 72.8126 61.2983 72.8126 61.0682V56.196C72.8126 55.966 72.7347 55.7731 72.579 55.6173C72.4233 55.4618 72.2304 55.384 72.0003 55.384C71.77 55.384 71.5772 55.4618 71.4219 55.6173C71.2663 55.7731 71.1885 55.966 71.1885 56.196V61.0682C71.1885 61.2983 71.2664 61.4911 71.4221 61.6467C71.5779 61.8024 71.7708 61.8803 72.0008 61.8803ZM72.0025 70.9999C70.5798 70.9999 69.2425 70.7299 67.9908 70.19C66.739 69.6501 65.6501 68.9174 64.7242 67.9919C63.7983 67.0663 63.0652 65.9779 62.525 64.7267C61.9849 63.4754 61.7148 62.1386 61.7148 60.7161C61.7148 59.2934 61.9848 57.9562 62.5247 56.7044C63.0646 55.4526 63.7973 54.3637 64.7229 53.4379C65.6484 52.512 66.7368 51.7789 67.988 51.2386C69.2393 50.6985 70.5762 50.4285 71.9987 50.4285C73.4213 50.4285 74.7586 50.6984 76.0104 51.2383C77.2622 51.7782 78.351 52.511 79.2769 53.4365C80.2028 54.362 80.9359 55.4504 81.4761 56.7017C82.0162 57.9529 82.2863 59.2898 82.2863 60.7123C82.2863 62.135 82.0163 63.4722 81.4764 64.724C80.9365 65.9758 80.2038 67.0646 79.2782 67.9905C78.3527 68.9164 77.2643 69.6495 76.0131 70.1898C74.7618 70.7299 73.425 70.9999 72.0025 70.9999Z"
      fill="#ED8C46"
    />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
};

export default Icon;
