import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

function ProfileSection({ children, title, subtitleLyrics }) {
  function renderSubTitle() {
    if (!subtitleLyrics) return null;
    if (!subtitleLyrics.lyrics) return null;

    return (
      <div className={styles.sectionContentWrapper}>
        <div className={styles.subtitleSection}>
          <h3 className={styles.title}>
            {subtitleLyrics.subtitle}
          </h3>
          <p>
            {subtitleLyrics.lyrics}
          </p>
        </div>
      </div>
    );
  }

  return (
    <section className={`${styles.section} shadow`}>
      <h3 className={styles.sectionTitle}>
        {title}
      </h3>
      <hr className={styles.sectionLine} />
      {renderSubTitle()}
      <div className={styles.sectionContentWrapper}>
        {children}
      </div>
    </section>
  );
}

export default ProfileSection;

ProfileSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitleLyrics: PropTypes.shape({
    subtitle: PropTypes.string,
    lyrics: PropTypes.string,
  }),
}.isRequired;
