import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const searchbarFullWidth = useMediaQuery({ query: '(max-width: 596px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  return {
    isDesktop,
    isNotDesktop: !isDesktop,
    isPortrait,
    searchbarFullWidth,
    isMobile,
  };
};

export default useResponsive;
