import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { assignValueById } from 'utils/Activities/activitiesRemoveDuplicates';
import { activityData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import ProfileSection from '../ProfileSection';

import styles from './styles.module.css';
import renderListOfActivitiesTypes from '../utils/renderListOfActivitiesTypes';

export default function SecuritiesActivities({ isFetching }) {
  const [useAllactivities, setUseAllActivities] = useState([]);
  const schoolActivities = useSelector(activityData);
  const [securitiesActivities, setSecuritiesActivities] = useState({});
  const [count, setCount] = useState(0);

  const useEffect1 = async () => {
    const covid19Activities = {
      properties: await assignValueById(schoolActivities, 20),
      title: 'Medidas Covid-19',
    };
    const otherSecuritiesActivities = {
      properties: await assignValueById(schoolActivities, 21),
      title: 'Otras Medidas de Seguridad',
    };

    const securityActv = await assignValueById(schoolActivities, 19);
    setSecuritiesActivities(securityActv);

    if (covid19Activities.length === 0
      && otherSecuritiesActivities.length === 0
    ) {
      setUseAllActivities(null);
    } else {
      setUseAllActivities([
        covid19Activities,
        otherSecuritiesActivities,
      ]);
    }
  };

  useEffect(() => {
    useEffect1();
  }, []);

  useEffect(() => {
    useAllactivities.forEach((activity) => {
      if (activity.properties.length > 0) {
        setCount((prevCount) => prevCount + 1);
      }
    });
  }, [useAllactivities]);

  if (isFetching) {
    return <>...loading</>;
  }
  if (!count) {
    return <>{' '}</>;
  }

  return (
    <ProfileSection
      title="Medidas de Seguridad"
      subtitleLyrics={{
        subtitle: '',
        lyrics: securitiesActivities[0]?.description,
      }}
    >
      <div className={styles.sectionsContainer}>
        {
          renderListOfActivitiesTypes(useAllactivities)
        }
      </div>
    </ProfileSection>
  );
}

SecuritiesActivities.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};
