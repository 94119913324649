import {
  removeDataInSessionStorage,
  getDataInSessionStorage,
  saveDataInSessionStorage,
} from 'utils/Storage/storageFunctions';

import { v4 as uuidv4 } from 'uuid';

function startSession() {
  const sessionId = getDataInSessionStorage('sessionId');
  if (!sessionId) {
    const id = uuidv4();
    saveDataInSessionStorage('sessionId', id);
  }
}

export function endSession() {
  removeDataInSessionStorage('sessionId');
}

export function updateSession() {
  const sessionId = getDataInSessionStorage('sessionId');
  if (sessionId) {
    endSession();
    startSession();
  }
}

export default startSession;
