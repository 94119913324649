import { getUrl } from 'urlConfig';
import api from 'services/api';
import { parsedDataInStorage, removeDataInStorage } from 'utils/Storage/storageFunctions';
import sessionStorageServices from 'services/sessionStorageServices';

// cards
const location = 0;
const range = 1;
const publicCard = 2;
const religion = 3;
const educationalLevels = 4;
const languages = 5;
const mainFactors = 6;
const costs = 7;
const yearAndDemographicInfo = 8;

export const validateVacantest = (test) => {
  if (!test) return false;
  if (!test[location].coords?.latitude) return false;
  if (typeof (test[location].coords?.latitude) !== 'number') return false;
  if (!test[location].coords?.longitude) return false;
  if (typeof (test[location].coords?.longitude) !== 'number') return false;
  if (!test[range].range) return false;
  if (typeof (test[range].range) !== 'number') return false;
  if (!test[publicCard]) return false;
  if (!Object.keys(test[publicCard]).length) return false;
  if (!test[religion]) return false;
  if (!Object.keys(test[religion]).length) return false;
  if (!test[educationalLevels]) return false;
  if (!Object.keys(test[educationalLevels]).length) return false;
  if (!test[languages]) return false;
  if (!Object.keys(test[languages]).length) return false;

  return true;
};

const mutateReligions = (religiousArr) => {
  const aux = religiousArr;
  aux.religious = religiousArr.religious[1].checked;

  return aux;
};

const spreadYearAndDemographicSelections = (yearAndDemographicSelections) => ({
  year: {
    startDate: yearAndDemographicSelections.startDate,
    exploring: yearAndDemographicSelections.exploring,
  },
  demographic: {
    gender: yearAndDemographicSelections.gender,
    age: yearAndDemographicSelections.age,
  },
  acceptsInformation: yearAndDemographicSelections.acceptsInformation,
});

export const dataToSave = (vacantest) => ({
  coords: vacantest[location].coords,
  ...vacantest[range],
  public: vacantest[publicCard],
  religion: mutateReligions(vacantest[religion]),
  educLevel: vacantest[educationalLevels],
  language: vacantest[languages],
  // Added
  mainFactors: vacantest[mainFactors],
  costs: vacantest[costs],
  ...spreadYearAndDemographicSelections(vacantest[yearAndDemographicInfo]),
});

const saveVacantestInDB = async (vacantest) => {
  const { user, token } = parsedDataInStorage('vacanted');
  const vacantestFromLS = parsedDataInStorage('vacantest');
  const { country_id: countryId } = vacantestFromLS['4'][0];

  const date = Date().toString();

  const config = {
    headers: {
      authorization: token,
    },
  };

  const data = {
    type: 'vacantest',
    name: `${user.email} - ${date}`,
    country_id: countryId,
    context: vacantest,
    results: null,
    savedAt: date,
  };

  return api.post('/collection', data, config)
    .then((r) => r)
    .catch((e) => e);
};

async function saveVacantest(setLoader) {
  let vacantest = {};

  const tempSave = sessionStorageServices.getItem('vacantest');
  if (tempSave) {
    const { vacantest: vacantestFromSession } = tempSave;
    vacantest = vacantestFromSession;
  } else {
    const vacantestFromLS = parsedDataInStorage('vacantest');
    vacantest = vacantestFromLS;
  }

  const validTest = validateVacantest(vacantest);
  if (!validTest) return false;

  const data = dataToSave(vacantest);

  if (setLoader) setLoader(true);
  const response = await saveVacantestInDB(data);
  if (setLoader) setLoader(false);

  if (response?.data) {
    const panelUrl = getUrl('panel');
    const { token } = parsedDataInStorage('vacanted');
    const familyDashboardEndpoint = `${panelUrl}/${token}/vacantests`;

    removeDataInStorage('vacantest');
    window.location.replace(familyDashboardEndpoint);
  }

  return response;
}

export default saveVacantest;
