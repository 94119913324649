const agesMappings = {
  '0 años': 'Inicial - Hasta 12 meses',
  '1 años': 'Inicial - 1 año',
  '2 años': 'Inicial - 2 años',
  '3 años': 'Inicial - 3 años',
  '4 años': 'Inicial obligatoria - 4 años',
  '5 años': 'Inicial obligatoria - 5 años',
  '6 años': 'Primaria - 1er año',
  '7 años': 'Primaria - 2do año',
  '8 años': 'Primaria - 3er año',
  '9 años': 'Primaria - 4to año',
  '10 años': 'Primaria - 5to año',
  '11 años': 'Primaria - 6to año',
  '12 años': 'Secundaria - 1er año',
  '13 años': 'Secundaria - 2do año',
  '14 años': 'Secundaria - 3er año',
  '15 años': 'Secundaria - 4to año',
  '16 años': 'Secundaria - 5to año',
  '17 años': 'Secundaria - 6to año',
};

const ageToLevelName = (age) => agesMappings[age] || age;

const levelNameToAge = (levelName) => Object.entries(agesMappings).find(
  ([_, value]) => value === levelName,
)?.[0];

export default { ageToLevelName, levelNameToAge };
