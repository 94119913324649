import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SchoolItemContext from '../SchoolItemContext';

const SchoolItemDistance = ({ distance: distanceProp }) => {
  const { distance: distanceContext } = useContext(SchoolItemContext);
  const distance = distanceProp || distanceContext;
  return (
    <div className="school-feature school-item__distance">
      <img src="https://vacanted-assets-dev.s3.us-east-1.amazonaws.com/icons/icon_location.png" alt="distancia" />
      {`${distance} ${distance === 1 ? 'km' : 'kms'}`}
    </div>
  );
};

SchoolItemDistance.propTypes = {
  distance: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
  }),
};

SchoolItemDistance.defaultProps = {
  distance: null,
};

export default SchoolItemDistance;
