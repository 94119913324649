import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import hardArrays from './hardArrays';

function getCount(schoolsTotal, id, filter) {
  if (filter === 'steps') {
    if (id === undefined) return null;

    const minMaxAgeId = id.split('~');
    const minAgeId = Number(minMaxAgeId[0]);
    const maxAgeId = Number(minMaxAgeId[1]);

    return schoolsTotal?.filter((school) => school.onlyMatchedSchedules.length > 0
      && school.onlyActivePeriods.some((schedule) => {
        const age = Number(schedule.ages.replace(' años', ''));
        return age >= minAgeId && age <= maxAgeId;
      })).length;
  }

  if (filter === 'schedules') {
    if (id === undefined) return null;

    return schoolsTotal?.filter((s) => s.onlyMatchedSchedules.length > 0
      && s.onlyActiveSteps.every((step) => step[id])).length;
  }

  if (filter === 'language1_id') {
    return schoolsTotal.filter((s) => s[filter] === id
      || s.language2_id === id
      || s.language3_id === id).length;
  }

  if (filter === 'language_id') {
    return schoolsTotal.filter((s) => s.languages.map((l) => l[filter]).includes(id)).length;
  }

  return schoolsTotal.filter((s) => s[filter] === id).length;
}

// get the count for secular or religious institutions
function getReligiousCount(value, schoolsData) {
  if (value === 'secular') return schoolsData.filteredData.filter((s) => !s.religious).length;

  return schoolsData.filteredData.filter((s) => s.religious).length;
}

// get the checkbox value in order to clear all with the clear all button
function getCheckedValue(filters, filterName) {
  if (filters[filterName] !== undefined) return filters[filterName];

  return false;
}

// check if is disabled or not
function checkIfEnabled(schoolsTotal, id, filter, filters, loading) {
  if (loading) return true;

  return getCount(schoolsTotal, id, filter, filters) < 1;
}

function checkIfReligiousBoolEnabled(name, schoolsData, loading) {
  if (loading) return true;

  return getReligiousCount(name, schoolsData) < 1;
}

function renderCheckboxes(
  checkboxesArray,
  handleFilters,
  schoolsTotal,
  filter,
  filters,
  loading,
  stepsData,
  customClass = '',
) {
  return (
    <div className={`sidebar-checkboxes-${customClass}`}>
      {checkboxesArray.map(({ name, id }) => {
        if (name === '' || name === undefined) {
          return null;
        }

        return (
          <div className="sidebar-checkboxes-row" key={name}>
            <label htmlFor={`checkbox-${filter}-${id}`} className="label-container">
              <input
                type="checkbox"
                disabled={checkIfEnabled(schoolsTotal, id, filter, filters, loading)}
                id={`checkbox-${filter}-${id}`}
                name={`${filter}-${id}`}
                className="checkmark"
                checked={getCheckedValue(filters, `${filter}-${id}`)
                  && !checkIfEnabled(schoolsTotal, id, filter, filters, loading)}
                onChange={(e) => handleFilters(e.target.name, e.target.checked)}
              />
              <span>{name}</span>
            </label>
            <span className="schools-counter">
              {!loading && getCount(schoolsTotal, id, filter, filters, stepsData)}
              {loading && <ClipLoader color="#0096ed" loading={loading} size={15} />}
            </span>
          </div>
        );
      })}
    </div>
  );
}

function renderBoolReligionCheckboxes(
  handleShowCheckboxes,
  schoolsData,
  filters,
  loading,
) {
  return (
    <div className="sidebar-checkboxes-">
      {hardArrays.religiousArr.map(({
        label,
        name,
        type,
        rValue,
      }) => (
        <div className="sidebar-checkboxes-row" key={name}>
          <label htmlFor={label} className="label-container">
            <input
              type="checkbox"
              disabled={checkIfReligiousBoolEnabled(name, schoolsData, loading)}
              id={label}
              name={`${type}-${name}`}
              className="checkmark"
              checked={getCheckedValue(filters, `${type}-${name}`)
                && !checkIfReligiousBoolEnabled(name, schoolsData, loading)}
              onChange={(e) => {
                handleShowCheckboxes(
                  name,
                  rValue,
                  e.target.checked,
                  e.target.name,
                );
              }}
            />
            <span>{label}</span>
          </label>
          <span className="schools-counter">
            {getReligiousCount(name, schoolsData)}
          </span>
        </div>
      ))}
    </div>
  );
}

export default { renderCheckboxes, renderBoolReligionCheckboxes };
