/* eslint-disable react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');
require('./style.scss');

const { useState, useEffect } = React;

const Snackbar = ({
  message, duration = 3000, autoClose = false, onClose, type,
}) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(() => {
        handleClose();
      }, duration);

      return () => {
        clearTimeout(timer);
      };
    }
    return null;
  }, [duration, autoClose]);

  return visible ? (
    <div
      className={`snackbar snackbar--${type}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <span className="snackbar-message">{message}</span>
      <button type="button" className="snackbar-close" onClick={handleClose} aria-label="Cerrar">
        &times;
      </button>
    </div>
  ) : null;
};

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  duration: PropTypes.number,
  autoClose: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string.isRequired,
};

export default Snackbar;
