const getSeasonId = (year) => {
  const seasonSelection = year?.value;
  return seasonSelection ? Number(seasonSelection) : null;
};

const getPublic = ({ typeSchool, onlyPublicSchools }, optionData) => {
  const values = typeSchool.map((publicId) => {
    const optionLevel = optionData.typeSchool.find((option) => option.value === publicId);
    return { id: Number(publicId), name: optionLevel?.label };
  });
  return {
    values,
    only_public_schools: !!onlyPublicSchools,
  };
};

const getLevels = ({ level: levels, onlyPreschool }, optionData) => {
  const ages = [];
  levels?.forEach((level) => {
    const optionLevel = optionData.level.find((option) => option.value === level.value);
    if (!optionLevel) return;
    ages.push({
      id: Number(level.value),
      name: optionLevel.age,
      level_name: optionLevel.label,
      qty: level.count,
    });
  });
  return {
    ages,
    only_preschool: !!onlyPreschool,
  };
};

const getOwnCandidates = (schoolRecomended) => schoolRecomended?.map((recomended) => ({
  id: recomended.id,
  name: recomended.name,
})) || [];

const getCosts = ({ costs }, optionData) => {
  const { initCost, schoolCost } = costs;
  const preschool = initCost?.value
    ? optionData.initCost.find((cost) => cost.value === initCost.value)?.toMap
    : null;
  const school = schoolCost?.value
    ? optionData.schoolCost.find((cost) => cost.value === schoolCost.value)?.toMap
    : null;

  return {
    preschool,
    school,
  };
};

const getReligion = (religion) => {
  const indifferent = religion?.value === '';
  if (indifferent) return { indifferent };

  const religiousSelected = religion?.value === 'yes';
  const religionId = religiousSelected ? Number(religion.list[0]) : Number(religion?.value);

  return {
    value: {
      id: religionId,
    },
  };
};

const getLanguages = (languages, optionData) => {
  const values = languages.map((value) => {
    const typeOption = optionData.languages.find((rel) => rel.value === value);
    return {
      id: Number(typeOption.value),
      name: typeOption.label,
    };
  });

  return values;
};

export const searchProfileMapper = (experience, optionData) => (
  {
    context: {
      publics: getPublic(experience, optionData),
      religion: !experience.religion || experience.onlyPublicSchools
        ? null
        : getReligion(experience.religion, optionData),
      languages: !experience.languages || experience.onlyPublicSchools ? null : {
        all: getLanguages(experience.languages, optionData),
        with_classes: getLanguages(experience.languagesClass, optionData),
      },
      costs: experience.onlyPublicSchools ? null : getCosts(experience, optionData),
      location: {
        coords: {
          latitude: experience.address?.latitude,
          longitude: experience.address?.longitude,
        },
        address: experience.address?.addressTxt,
        range_kms: experience.address?.range,
      },
      preschool_or_school: experience.onlyPreschool ? experience.preschoolOrSchool?.value : null,
      accepts_private_suggestions: experience.onlyPublicSchools ? experience.acceptsPrivateSuggestions?.value === '1' : null,
      accepts_private_suggestions_budget: experience.onlyPublicSchools && experience.acceptsPrivateSuggestions?.value === '1'
        ? experience.acceptsPrivateSuggestionsBudget
        : null,
      accepts_private_suggestions_phone: experience.onlyPublicSchools && experience.acceptsPrivateSuggestions?.value === '1'
        ? experience.acceptsPrivateSuggestionsPhone
        : null,
    },
  }
);

export const experienceMapper = (experience, optionData) => {
  const experienceMapped = {
    email: experience.email,
    phone: experience.phone,
    season_id: getSeasonId(experience.year),
    context: {
      basic: {
        levels: getLevels(experience, optionData),
      },
      own_candidates: getOwnCandidates(experience.schoolRecomended),
      receive_opportunities: !experience.onlyPublicSchools ? {
        interested: experience.receiveOpportunities?.value === '1',
        data: experience.receiveOpportunitiesData,
      } : null,
    },
    search_profile: searchProfileMapper(experience, optionData),
  };

  return experienceMapped;
};
