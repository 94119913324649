import api from '../services/api';

async function mutateTable(table, locationInfo) {
  const stepsFromState = table.steps
    .filter((ts) => ts.table_step === locationInfo.table_step);

  // Sets the info which will be used to filter
  function agesIntoIds(string) {
    const start = string.split('').indexOf('(') + 1;
    const end = string.split('').indexOf(')') - 5;
    const ids = string.substring(start, end);
    let trueIds = ids.replace('-', '~');

    if (trueIds.length < 2) trueIds = `${trueIds}~${trueIds}`;

    return trueIds;
  }

  const mutatedSteps = stepsFromState.map((step) => ({
    ...step,
    name: `Ed. ${step.name}`,
    _id: step.id,
    id: agesIntoIds(step.name),
  }));

  // console.log('mutateTable', { ...table, steps: mutatedSteps });

  return { ...table, steps: mutatedSteps };
}

async function getApiInfo(locationInfo) {
  const { data } = await api.get(`/table/${locationInfo.countryId}`);

  return { table: data };
}

const useTable = async (locationInfo) => {
  const { table } = await getApiInfo(locationInfo);

  return mutateTable(table, locationInfo);
};

export default useTable;
