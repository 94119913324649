import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/index';
import topImg from '../../assets/img/terms-conditions.jpg';
import styles from './styles.module.scss';

const PrivacyPolicies = () => (
  <div>
    <Header />
    <Helmet>
      <title>Política de privacidad - VacantED</title>
    </Helmet>
    <div className={styles.content}>
      <div className={styles.img_content}>
        <img className={styles.img_file} src={topImg} alt="computer" />
      </div>
      <div className={styles.text_content}>
        <div className={styles.main_header}>
          <h4>Política de privacidad de VACANTED™</h4>
        </div>
        <div className={styles.terms_wrapper}>
          <div className={styles.term}>
            <span className={styles.term_header}>I. Alcance</span>
            <p>
              Por medio de la Política de Privacidad se determina el
              tratamiento que el sitio Web de VACANTED™ dará a los datos
              recibidos por parte de los usuarios, y que estará en un todo
              de acuerdo a lo establecido en la Ley N° 18.331 de Protección de
              Datos Personales y Acción de Habeas Data de Uruguay.
              <br />
              De esta manera, en aquellos casos que los usuarios ingresen datos personales
              o información confidencial en el sitio, están aceptando las presentes políticas
              de privacidad y los Términos y Condiciones de uso. Para ello, el sitio Web proporciona
              a los usuarios los recursos adecuados para que tomen conocimiento de las presentes
              Políticas de Privacidad, los Términos y Condiciones de uso y Protección de Datos
              Personales. Asimismo, las mismas tienen por objeto establecer los criterios para
              el uso del sitio Web por medio del cual se podrán utilizar los servicios
              que brinda VACANTED™.
            </p>
          </div>
          <div className={styles.term}>
            <span className={styles.term_header}>II. Las presentes políticas describen:</span>
            <ol type="A" className={styles.term_list}>
              <li>
                Cuáles serán los datos recolectados, cómo serán utilizados y
                la finalidad para los que serán tratados.
              </li>
              <li>
                Las consecuencias que deriven de proporcionar los datos o de la
                inexactitud de los mismos.
              </li>
              <li>
                Facultad y modo de ejercer los derechos de acceso, rectificación, actualización
                y supresión de los datos.
              </li>
              <li>
                La existencia de archivo, registro, base o banco de datos, electrónico o de
                cualquier otro tipo de que se trate y la identidad y domicilio de su responsable.
              </li>
            </ol>
            <span className={styles.term_header}>
              A. Cuáles serán los datos recolectados, cómo serán utilizados y la finalidad para los
              que serán tratados
            </span>
            <p>
              El sitio Web no recoge ningún tipo de información personal por parte de los usuarios
              sin que la misma sea explícitamente provista voluntariamente. Al crear su cuenta de
              usuario deberá ingresar con su cuenta de Google o Meta, o bien
              consignar sus datos en un formulario que contiene campos obligatorios
              y campos opcionales. Los datos solicitados serán utilizados exclusivamente para su
              inclusión en una base de datos de VACANTED™, y serán utilizados para:
              <br />
              (1) compartir con los centros educativos que sean de su interés y que usted haya
              solicitado pueda ser contactado por los mismos;
              <br />
              (2) Compartir con los centros educativos que VACANTED™ considere afines a su búsqueda,
              excepto datos de contacto, como el correo electrónico o el número de teléfono.
              <br />
              (3) Para enviarle al usuario sugerencias de centros educativos afines a su perfil de
              búsqueda. Las cuales pueden enviarse por correo electrónico o ser sugeridas en la web.
              <br />
              (4) tratados de conformidad con los fines
              establecidos en el presente documento. VACANTED™ nunca usará sus datos personales
              para ningún fin distinto al indicado en el sitio VACANTED™.com.
            </p>
            <p>
              Los únicos datos necesarios para acceder a ciertos contenidos del sitio web
              de VACANTED™ son nombres de usuario, contraseñas y direcciones de correo
              electrónico que se usan exclusivamente para ciertas funciones de gestión
              de usuarios como puede ser la recuperación de contraseñas o el acceso a
              su área privada personal. Otros datos de carácter personal podrán ser facilitados
              voluntariamente por los usuarios que en todo momento tienen acceso
              para modificarlos o cancelarlos personalmente.
            </p>
            <p>
              Toda información que sea recibida, determinada por ley y
              referida a los usuarios, es confidencial y no puede hacerse
              pública sin el consentimiento previo de aquellos, salvo que
              sea requerida por la autoridad competente, cumpliendo con las
              normas y recaudos establecidos para la protección de datos personales.
              Ante cualquier duda, queja o sugerencia acerca de este documento o el
              tratamiento de sus datos por parte de VACANTED™, por favor póngase en
              contacto con nosotros en soporte@vacanted.com.
            </p>
            <p>
              En ningún caso se utilizarán los datos que usted nos suministre a
              través del formulario para campañas de marketing, publicidad o campaña
              política, o ningún otro uso.
              VACANTED™ se reserva el derecho de modificar el contenido del documento
              de privacidad de datos personales con el objeto de adaptarlo a las novedades
              legislativas o jurisprudenciales, así como a las que pudieran derivarse de
              códigos tipo existentes en la materia.
            </p>
            <p>
              VACANTED™ entiende que desde el momento en que un menor de edad accede al
              sitio web de VACANTED™, cuenta con el permiso de sus padres, de su tutor o
              de su representante legal. Siempre que el sitio web de VACANTED™ le solicite
              datos de carácter personal, incluirá un enlace vinculado al presente documento
              con el propósito de informarle de sus derechos y obligaciones en materia de protección
              de datos de carácter personal.
            </p>
            <span className={styles.term_header}>
              B. Las consecuencias que deriven de proporcionar los datos o de la inexactitud de
              los mismos
            </span>
            <p>
              La Ley N° 18.331 establece que ninguna persona puede ser obligada a proporcionar datos
              sensibles, entendiendo por tales a aquellos datos personales que revelan origen racial
              y étnico, opiniones políticas, convicciones religiosas, filosóficas o morales,
              afiliación sindical e información referente a la salud o a la vida sexual,
              pudiendo ser objeto de tratamiento cuando medien razones de interés general o
              autorizadas por ley. Del mismo modo, esta ley tiene por objeto la protección integral
              de los datos personales asentados en archivos, registros, bancos de datos u otros
              medios técnicos, sean públicos o privados, garantizando el derecho al honor y a la
              intimidad de las personas, conforme al artículo 72 de la Constitución de la República.
              La información que se reciba será tratada en forma confidencial. Sólo podrán darse a
              conocer los datos que el marco normativo autorice y aquellos que expresamente
              consienta el usuario, a excepción de:
            </p>
            <ul className={styles.term_list}>
              <li>Un pedido formal de autoridades judiciales.</li>
              <li>Un pedido formal de otras autoridades con competencia suficiente.</li>
            </ul>
            <span className={styles.term_header}>
              C. Facultad y modo de ejercer los derechos de acceso, rectificación,
              actualización y supresión de los datos
            </span>
            <p>
              El derecho de acceso es uno de los derechos establecidos por la Ley N° 18.331
              – Protección de Datos Personales, que permite a los ciudadanos
              (usuarios en este caso), acceder a la información registrada, pudiendo administrar
              así, el uso que se hace de sus datos personales.
            </p>
            <p>
              El usuario podrá ejercer los siguientes derechos ante VACANTED™ y
              a través del sitio Web:
            </p>
            <ul className={styles.term_list}>
              <li>
                Solicitar información al organismo de control, relativa a la existencia
                de archivos, registros, bases o bancos de datos personales, sus finalidades
                y la identidad de sus responsables. El registro que se lleve a tal efecto
                será de consulta pública y gratuita.
              </li>
              <li>
                Solicitar y obtener información de sus datos personales incluidos en los
                bancos de datos públicos, o privados destinados a proveer informes.
              </li>
              <li>
                Solicitar la modificación de sus datos personales cuando ellos no sean
                correctos o no estén actualizados, si fuere procedente, y;
              </li>
              <li>
                Solicitar la rectificación, actualización, supresión, confidencialidad de
                los datos personales de los que sea titular y que se encuentren incluidos en
                un banco de datos
              </li>
            </ul>
            <span className={styles.term_header}>
              D. La existencia de archivo, registro, base o banco de datos, electrónico
              o de cualquier otro tipo de que se trate y la identidad y domicilio de su responsable
            </span>
            <p>
              En cada oportunidad en que el usuario haga uso de los servicios incluidos en el
              Sitio Web, existe la posibilidad que, de manera automática, obtenga y almacene
              información en los registros del servidor. Estos datos podrán incluir:
            </p>
            <ul className={styles.term_list}>
              <li>información detallada sobre utilización del servicio.</li>
              <li>dirección de IP,</li>
              <li>cookies, que permitirán identificar tu navegador o tu cuenta.</li>
            </ul>
            <p>
              El sitio Web aplica medidas de seguridad, destinadas a diseñar procedimientos,
              métodos y técnicas a los fines de conseguir un sistema de información seguro y
              confiable.
            </p>
            <p>
              Se establecen una serie de estándares, protocolos, métodos, reglas, herramientas
              y leyes concebidas para minimizar los posibles riesgos a la infraestructura o a la
              información. Se pretende con ello la protección de la infraestructura y todo lo
              relacionado con esta y, especialmente, la información contenida o circulante.
            </p>
            <p>
              A los fines de contactarse con VACANTED™ a cerca de los alcances de las presentes
              Políticas de Privacidad, podrá enviarnos un email a
              <span className={styles.email}> info@vacanted.com</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="foter_wrapper">
      <Footer />
    </div>
  </div>
);

export default PrivacyPolicies;
