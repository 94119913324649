import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.3776 10.625H5.21094C5.03385 10.625 4.88542 10.565 4.76562 10.4452C4.64583 10.3254 4.58594 10.1769 4.58594 9.9997C4.58594 9.82254 4.64583 9.67413 4.76562 9.55447C4.88542 9.43482 5.03385 9.375 5.21094 9.375H9.3776V5.20833C9.3776 5.03124 9.43752 4.88281 9.55735 4.76302C9.6772 4.64322 9.8257 4.58333 10.0029 4.58333C10.18 4.58333 10.3284 4.64322 10.4481 4.76302C10.5677 4.88281 10.6276 5.03124 10.6276 5.20833V9.375H14.7942C14.9713 9.375 15.1198 9.43491 15.2395 9.55475C15.3593 9.67459 15.4192 9.82309 15.4192 10.0002C15.4192 10.1774 15.3593 10.3258 15.2395 10.4455C15.1198 10.5651 14.9713 10.625 14.7942 10.625H10.6276V14.7916C10.6276 14.9687 10.5676 15.1171 10.4478 15.2369C10.328 15.3567 10.1795 15.4166 10.0023 15.4166C9.82515 15.4166 9.67674 15.3567 9.55708 15.2369C9.43743 15.1171 9.3776 14.9687 9.3776 14.7916V10.625Z"
      fill={fill}
    />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
  fill: '#1A1A1A',
};

export default Icon;
