import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header/Header';
import Footer from 'components/Footer';
import PasswordReset from '../components/PasswordReset';
import DefaultDescription from '../components/DefaultDescription/DefaultDescription';
import passwordStyles from './login.module.scss';

function passwordReset() {
  const { search } = useLocation();

  return (
    <>
      <Header />
      <div className={passwordStyles.background}>
        <div className={passwordStyles.background__overlay}>
          <div className={passwordStyles.body__content}>
            <div
              className={passwordStyles.description__container}
            >
              <DefaultDescription />
            </div>
            <div
              className={passwordStyles.form__container}
              style={{ padding: '10px 0' }}
            >
              <PasswordReset props={search} />
            </div>
          </div>
        </div>
      </div>
      <div className={passwordStyles.footer__container}>
        <Footer />
      </div>
    </>
  );
}

passwordReset.propTypes = {};

export default passwordReset;
