import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useResponsive from '../../hooks/useResponsive';
import SchoolItem from '../school-item';
import SchoolPicture from '../school-item/src/school-item/SchoolPicture';
import SchoolAction from '../school-item/src/school-item/SchoolAction';
import SchoolNameAddress from '../school-item/src/school-item/SchoolNameAddress';
import SchoolSearch from '../school-search';
import FlowContext from '../flow/context';

import './school-winner-selector.scss';

const SchoolWinnerSelector = ({
  title, onChange, favorites, hiddenSelected, winner,
}) => {
  const { isDesktop, isNotDesktop } = useResponsive();
  const { increasePage } = useContext(FlowContext);
  const [schoolSelected, setSchoolSelected] = useState(winner);

  const onSelectWinnerSchool = (school, _action, onReset) => {
    setSchoolSelected(school);
    if (onChange) onChange(school);
    if (onReset) onReset();
    if (increasePage) increasePage();
  };

  return (
    <div className="school-finder">
      <div className="school-finder__title">{title}</div>
      <SchoolSearch
        onClick={onSelectWinnerSchool}
        selected={[]}
        customPositiveAction="Seleccionar"
      />
      {
        schoolSelected && (
          <div className="school-finder__school-list">
            <div className="school-finder__school-list__title">
              <b>Centro elegido:</b>
            </div>
            <SchoolItem school={schoolSelected} key={schoolSelected.id} highlighted>
              <SchoolPicture />
              <SchoolNameAddress />
            </SchoolItem>
          </div>
        )
      }
      {!hiddenSelected && !schoolSelected && (
        <div className="school-finder__school-list">
          {!!favorites?.length && (
            <>
              <div className="school-finder__school-list__title">
                Tus candidatos:
              </div>
              {favorites.map((school) => (
                <SchoolItem
                  school={school}
                  key={school.id}
                  onClick={isNotDesktop ? () => onSelectWinnerSchool(school) : null}
                >
                  <SchoolPicture />
                  <SchoolNameAddress />
                  <SchoolAction
                    onClick={isDesktop ? () => onSelectWinnerSchool(school) : () => {}}
                  >
                    Seleccionar
                  </SchoolAction>
                </SchoolItem>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

SchoolWinnerSelector.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  favorites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hiddenSelected: PropTypes.bool,
  winner: PropTypes.shape({}),
};

SchoolWinnerSelector.defaultProps = {
  hiddenSelected: false,
  winner: null,
};

export default SchoolWinnerSelector;
