import React from 'react';
import rStyles from '../DefaultcardStyles.module.scss';
import specificStyle from './specificStyle.module.scss';

const PageOne = () => (
  <div className={rStyles.vacantest__card__content}>
    <div className="header">
      <h3>
        Hola, bienvenido/a.
        Estás iniciando el proceso de creación o actualización de tu INFORME DE FAMILIA
      </h3>
      <p>
        Completa o actualiza paso a paso la información requerida y
        VACANTED™ creará un nuevo Informe de Familia para entregar a
        la institución que desees postular.
      </p>
    </div>
    <hr />
    <div className="explanation">
      <p>
        El objetivo de este formulario es construir o actualizar
        un Informe de Familia que tendrá las siguientes secciones:
      </p>
      <ul className={specificStyle.list_of_topics}>
        <li>1 - Presentación de quien completa el formulario.</li>
        <li>2 - Presentación de la familia.</li>
        <li>3 - Objetivos educacionales para tus hijos.</li>
        <li>4 - Requerimientos de la familia.</li>
        <li>5 - Localización geográfica.</li>
        <li>6 - Estructura familiar y su capacidad de pago.</li>
        <li>7 - Varios.</li>
      </ul>
    </div>
  </div>
);

export default PageOne;
