import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import vStyles from '../../FamilyInfo.module.scss';

const tips = [
  {
    title: 'Aclaración',
    content:
      'Es importante que comprendas y aceptes que en este formulario te consultaremos información detallada sobre aspectos del entorno de tu familia, ya que el Informe será entregado a la Institución Educativa que tú pretendas postular intentando suplir una típica entrevista presencial con la institución. Podrás advertir en el formulario que evitamos, dentro de lo posible, solicitar datos personales que no sean realmente imprescindibles para el análisis a realizar por la institución. No solicitamos localización geográfica exacta, nombres, apellidos ni fechas de nacimiento.',
  },
];

const PageOneTips = () => tips
  .map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
      </div>
    </div>
  ));

export default PageOneTips;
