export default {
  // Public
  NEW_EXPERIENCE: '/elegir-colegio',
  SCHOOL_LOGIN: '/auth/school',
  // Private
  END_EXPERIENCE: '/finalizar-experiencia',
  NEW_PROFILE: '/elegir-colegio/nueva-busqueda',
  EXPERIENCE_RECOMMENDATIONS: '/experience',
  EXPERIENCE_CANDIDATES: '/experience-candidates',
};
