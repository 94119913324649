import { apiPasswordReset } from '../../Data/Services/api';

const passwordResetRequest = async (payload) => {
  try {
    const response = await apiPasswordReset.passwordReset(payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default passwordResetRequest;
