import api from 'services/api';

export default async function getGoogleReview(Id, prefix) {
  const response = await api.post('/google/reviewsmyplace', {
    placeId: Id,
    language: prefix,
  });

  return response.data;
}
