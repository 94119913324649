import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SchoolItemContext from '../../context/school-item-context';

const SchoolNameAddress = ({ name: nameProp, address: addressProp }) => {
  const { name: nameContext, address: addressContext } = useContext(SchoolItemContext);
  const name = nameProp || nameContext;
  const address = addressProp || addressContext;
  return (
    <div className="school-item__info">
      <div className="school-item__name__text">{name}</div>
      <div className="school-item__address__text">{address}</div>
    </div>
  );
};

SchoolNameAddress.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
};

SchoolNameAddress.defaultProps = {
  name: null,
  address: null,
};

export default SchoolNameAddress;
