import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useSearch } from '../../../hooks/SearchContext';

import Chevron from './Chevron';

import checkboxes from '../SmartFilters/checkboxFilters';

import './Accordion.css';

function Accordion({ title, boolContent, religionsContent }) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setReligionsHeight, setReligionsHeightActive] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');
  const { filters } = useSearch();

  const elemRef = useRef(null);
  const religionsRef = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${elemRef.current.scrollHeight}px`,
    );
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate',
    );
    setReligionsHeightActive(!setActive && filters['religious-religious'] ? `${religionsRef.current.scrollHeight}px` : '0px');
  }

  useEffect(() => {
    setReligionsHeightActive(filters['religious-religious'] ? `${religionsRef.current.scrollHeight}px` : '0px');
  }, [filters]);

  return (
    <div className="accordion__section">
      <button type="button" className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">{title}</p>
        <Chevron className={`${setRotate}`} width={10} fill="#455a64" />
      </button>
      <div
        ref={elemRef}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {checkboxes.renderBoolReligionCheckboxes(
          boolContent.handleShowCheckboxes,
          boolContent.schoolsData,
          religionsContent.filters,
          religionsContent.loading,
        )}
      </div>
      <div
        ref={religionsRef}
        style={{ maxHeight: `${setReligionsHeight}` }}
        className="accordion__content"
      >
        {religionsContent.table && checkboxes.renderCheckboxes(
          religionsContent.table,
          religionsContent.handleFilters,
          religionsContent.schools,
          religionsContent.filterId,
          religionsContent.filters,
          religionsContent.loading,
          religionsContent.stepsData || [],
          religionsContent.customClass || '',
        )}
      </div>
    </div>
  );
}

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  boolContent: PropTypes.shape({
    handleShowCheckboxes: PropTypes.func,
    showFilters: PropTypes.shape({}),
    schoolsData: PropTypes.shape({}),
  }).isRequired,
  religionsContent: PropTypes.shape({
    table: PropTypes.arrayOf(PropTypes.shape({})),
    handleFilters: PropTypes.func,
    schools: PropTypes.arrayOf(PropTypes.shape({})),
    filterId: PropTypes.string,
    customClass: PropTypes.string,
    filters: PropTypes.shape({}),
    loading: PropTypes.bool.isRequired,
    stepsData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
