import React from 'react';
import { FaAngleDown, FaAngleLeft } from 'react-icons/fa';

export default function renderFAIcon(key, show, toggle) {
  if (show[key]) {
    return (
      <FaAngleDown
        size={20}
        color="#333333"
        onClick={() => toggle({ ...show, [key]: !show[key] })}
        style={{ cursor: 'pointer' }}
        className="responsive-none"
      />
    );
  }

  return (
    <FaAngleLeft
      size={20}
      color="#333333"
      onClick={() => toggle({ ...show, [key]: !show[key] })}
      style={{ cursor: 'pointer' }}
    />
  );
}
