import { useState } from 'react';

const useLoading = () => {
  const [pageLoading, setPageLoading] = useState({ show: false });

  const onSetPageLoading = (payload) => {
    if (typeof payload === 'boolean') {
      return setPageLoading({ show: payload });
    }
    return setPageLoading(payload);
  };

  return {
    pageLoading,
    setPageLoading: onSetPageLoading,
  };
};

export default useLoading;
