import React from 'react';

import './benefits-modal-styles.scss';

const BenefitsModal = () => (
  <div className="experience-benefits-modal">
    <div className="experience-benefits-modal__title__container">
      <div className="experience-benefits-modal__title__image__container">
        <img
          src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_gift_color.png"
          className="experience-benefits-modal__image"
          alt="beneficios"
        />
      </div>
      <h1 className="experience-benefits-modal__title">
        Eligiendo centro educativo en VacantED accedés a importantes beneficios.
      </h1>
    </div>
    <div className="experience-benefits-modal__content__container">
      <div className="experience-benefits-modal__content__item">
        <div className="experience-benefits-modal__content__item__image__container">
          <img
            src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_check_blue.png"
            className="experience-benefits-modal__content__item__image"
            alt="item"
          />
        </div>
        <div className="experience-benefits-modal__content__item__details">
          <div className="experience-benefits-modal__content__item__title">
            Participás en el sorteo de un año de colegio gratis.
          </div>
          <div className="experience-benefits-modal__content__item__subtitle">
            Aplica únicamente para un hijo o hija.
          </div>
        </div>
      </div>

      <div className="experience-benefits-modal__content__item">
        <div className="experience-benefits-modal__content__item__image__container">
          <img
            src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_check_blue.png"
            className="experience-benefits-modal__content__item__image"
            alt="item"
          />
        </div>
        <div className="experience-benefits-modal__content__item__details">
          <div className="experience-benefits-modal__content__item__title">
            Beneficios adicionales de los centros educativos.
          </div>
          <div className="experience-benefits-modal__content__item__subtitle">
            Aplica a centros seleccionados.
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BenefitsModal;
