/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  MapContainer, TileLayer, Marker, Circle, useMapEvent, useMap,
} from 'react-leaflet';
import PropTypes from 'prop-types';
import { getMapboxKey } from 'urlConfig';
import center from './centerIcon';

const MapEventsHandler = ({ handleClickLatLong }) => {
  useMapEvent('click', (event) => {
    const { lat, lng } = event.latlng || {};
    if (lat && lng && handleClickLatLong) {
      handleClickLatLong(lat, lng);
    }
  });
  return null;
};

const getZoom = (hidePin) => (hidePin ? 12 : 13);

const FlyToComponent = ({ flyToLocation }) => {
  const map = useMap();

  useEffect(() => {
    if (!(map && flyToLocation)) return;
    const { latitude: lat, longitude: lng } = flyToLocation;

    map.flyTo([lat, lng], getZoom(), {
      duration: 1,
    });
  }, [flyToLocation, map]);

  return null;
};

MapEventsHandler.propTypes = {
  handleClickLatLong: PropTypes.func.isRequired,
};

const SimpleMap = ({
  latitude,
  longitude,
  heightSize,
  radius,
  hidePin,
  handleClickLatLong,
  locationToRecenter,
}) => {
  /* const handleUpdateZoom = (newZoom) => {
    map?.setZoom(newZoom);
  };

  useEffect(() => {
    if (radius) {
      const newZoom = getZoom();
      handleUpdateZoom(newZoom);
    }
  }, [hidePin, radius]); */

  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={getZoom()}
      style={{ height: heightSize }}
      doubleClickZoom={false}
    >
      <TileLayer url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${getMapboxKey()}`} />
      {false && (
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      )}
      <FlyToComponent flyToLocation={locationToRecenter} />
      {handleClickLatLong && <MapEventsHandler handleClickLatLong={handleClickLatLong} />}
      {!hidePin && <Marker icon={center} position={[latitude, longitude]} />}
      {!hidePin && radius && (
        <Circle
          center={[latitude, longitude]}
          pathOptions={{ fillColor: '#36a7e8' }}
          radius={radius}
        />
      )}
    </MapContainer>
  );
};

SimpleMap.propTypes = {
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  heightSize: PropTypes.string.isRequired,
  radius: PropTypes.number,
  hidePin: PropTypes.bool,
  handleClickLatLong: PropTypes.func,
  locationToRecenter: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

SimpleMap.defaultProps = {
  radius: null,
  hidePin: false,
  handleClickLatLong: null,
  locationToRecenter: null,
};

export default SimpleMap;
