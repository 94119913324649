import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.72479 13.6146L7.82736 10.5121C7.95129 10.3881 8.09765 10.327 8.26646 10.3286C8.43525 10.3302 8.58161 10.3956 8.70554 10.5249C8.82093 10.6508 8.87863 10.7976 8.87863 10.9654C8.87863 11.1333 8.82093 11.2791 8.70554 11.4031L5.27286 14.8486C5.12222 14.9992 4.94647 15.0745 4.74563 15.0745C4.54477 15.0745 4.36902 14.9992 4.21838 14.8486L2.51486 13.1451C2.39948 13.0297 2.34313 12.8846 2.34579 12.71C2.34846 12.5353 2.40749 12.3903 2.52288 12.2749C2.63825 12.1595 2.78372 12.1018 2.95929 12.1018C3.13486 12.1018 3.27945 12.1595 3.39304 12.2749L4.72479 13.6146ZM4.72479 7.26852L7.82736 4.16593C7.95129 4.042 8.09765 3.98084 8.26646 3.98245C8.43525 3.98405 8.58161 4.04948 8.70554 4.17875C8.82093 4.30462 8.87863 4.45147 8.87863 4.61931C8.87863 4.78713 8.82093 4.933 8.70554 5.05693L5.27286 8.50245C5.12222 8.65309 4.94647 8.72841 4.74563 8.72841C4.54477 8.72841 4.36902 8.65309 4.21838 8.50245L2.51486 6.79893C2.39948 6.68354 2.34313 6.53852 2.34579 6.36385C2.34846 6.18917 2.40749 6.04413 2.52288 5.92875C2.63825 5.81337 2.78372 5.75568 2.95929 5.75568C3.13486 5.75568 3.27945 5.81337 3.39304 5.92875L4.72479 7.26852ZM11.4668 13.7973C11.2897 13.7973 11.1412 13.7374 11.0214 13.6175C10.9017 13.4977 10.8418 13.3492 10.8418 13.172C10.8418 12.9949 10.9017 12.8465 11.0214 12.7268C11.1412 12.6072 11.2897 12.5473 11.4668 12.5473H17.3001C17.4772 12.5473 17.6256 12.6073 17.7454 12.7271C17.8652 12.8469 17.9251 12.9954 17.9251 13.1726C17.9251 13.3498 17.8652 13.4982 17.7454 13.6178C17.6256 13.7375 17.4772 13.7973 17.3001 13.7973H11.4668ZM11.4668 7.45116C11.2897 7.45116 11.1412 7.39124 11.0214 7.27139C10.9017 7.15156 10.8418 7.00306 10.8418 6.82589C10.8418 6.64874 10.9017 6.50034 11.0214 6.38068C11.1412 6.26103 11.2897 6.2012 11.4668 6.2012H17.3001C17.4772 6.2012 17.6256 6.26112 17.7454 6.38095C17.8652 6.5008 17.9251 6.6493 17.9251 6.82645C17.9251 7.00362 17.8652 7.15203 17.7454 7.27168C17.6256 7.39134 17.4772 7.45116 17.3001 7.45116H11.4668Z"
      fill={fill}
    />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
  fill: '#1A1A1A',
};

export default Icon;
