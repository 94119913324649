import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SchoolItem from '../../school-item';
import SchoolPicture from '../../school-item/src/school-item/SchoolPicture';
import SchoolAction from '../../school-item/src/school-item/SchoolAction';
import SchoolNameAddress from '../../school-item/src/school-item/SchoolNameAddress';
import SchoolSearch from '../../school-search';

import '../styles/school-finder.scss';

const SchoolFinder = ({
  title, onChange, existing, hiddenSelected, ageNumbersList,
}) => {
  const [schoolSelected, setSchoolSelected] = useState(existing?.map((fav) => fav.id));

  const onAddFavoriteSchool = (school) => {
    const newFavs = [...existing, school];
    setSchoolSelected(newFavs.map((fav) => fav.id));
    if (onChange) {
      onChange(newFavs);
    }
  };

  const onRemoveFavoriteSchool = (school) => {
    const newFavs = existing.filter((fav) => fav.id !== school.id);
    setSchoolSelected(newFavs.map((fav) => fav.id));
    if (onChange) {
      onChange(newFavs);
    }
  };

  const onClickHandler = (school, action, onReset) => {
    if (action === 'add') {
      onAddFavoriteSchool(school);
    } else {
      onRemoveFavoriteSchool(school);
    }

    if (onReset) onReset();
  };

  useEffect(() => {
    setSchoolSelected(existing?.map((fav) => fav.id));
  }, []);

  return (
    <div className="school-finder">
      <div className="school-finder__title">{title}</div>
      <SchoolSearch
        onClick={onClickHandler}
        selected={schoolSelected}
        ageNumbersList={ageNumbersList}
      />
      {!hiddenSelected && (
        <div className="school-finder__school-list">
          {
            existing?.map((school) => (
              <SchoolItem school={school} key={school.id}>
                <SchoolPicture />
                <SchoolNameAddress />
                <SchoolAction
                  onClick={() => onRemoveFavoriteSchool(school)}
                >
                  Quitar
                </SchoolAction>
              </SchoolItem>
            ))
          }
        </div>
      )}
    </div>
  );
};

SchoolFinder.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  existing: PropTypes.arrayOf(PropTypes.shape({})),
  hiddenSelected: PropTypes.bool,
  ageNumbersList: PropTypes.arrayOf(PropTypes.number),
};

SchoolFinder.defaultProps = {
  existing: [],
  hiddenSelected: false,
  ageNumbersList: [],
};

export default SchoolFinder;
