import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { /* Link,  */useLocation, useNavigate } from 'react-router-dom';
import { useUserData } from 'hooks/UserContext';
import useResponsive from '../../hooks/useResponsive';
import Button from '../button';
import { getUrl } from '../../../../../urlConfig';
import paths from '../../../../../routes/paths';
import ListIcon from '../../../../../components/Icons/ListIcon';
import MessageIcon from '../../../../../components/Icons/MessageIcon';
/* import ReportIcon from '../../../../../components/Icons/ReportIcon'; */
import StarIcon from '../../../../../components/Icons/StarIcon';

import './experience-slide-menu.scss';

const menuOptions = [
  {
    text: 'Recomendaciones',
    path: paths.EXPERIENCE_RECOMMENDATIONS,
    Icon: () => (
      <ListIcon className="menu-icon" />
    ),
  },
  {
    text: 'Mis candidatos',
    path: paths.EXPERIENCE_CANDIDATES,
    Icon: () => (
      <StarIcon className="menu-icon" />
    ),
  },
];

const ExperienceSlideMenu = ({
  show, setShow, alwaysCollapsable,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    activeExperience,
    userData,
  } = useUserData();
  const { isNotDesktop } = useResponsive();
  const panelUrl = getUrl('panel');
  const sidebarRef = useRef(null);

  const classNamesMenu = () => classNames('slide-menu', {
    'slide-menu--collapsable': isNotDesktop || alwaysCollapsable,
    'slide-menu--show': show,
  });

  const handleClickOutside = (event) => {
    if (sidebarRef.current
        && !sidebarRef.current.contains(event.target)
        && !event.target.closest('#slidebar-toggle-btn')
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (!setShow) return () => {};

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  return (
    <div ref={sidebarRef} className={classNamesMenu()}>
      {activeExperience && (
        <>
          <div className="menu-block">
            <div className="experience-info">
              <div className="experience-info-title">Búsqueda Personalizada</div>
              <div className="experience-info-datas">
                <div className="experience-info-data">
                  <div className="experience-info-data-title">Niveles Educativos</div>
                  {activeExperience.context.basic.levels.ages.map(({ level_name: name }) => (
                    <div key={name} className="experience-info-data-value">{name}</div>
                  ))}
                </div>
                {!!activeExperience.season && (
                  <div className="experience-info-data">
                    <div className="experience-info-data-title">Ingreso</div>
                    <div className="experience-info-data-value">{activeExperience.season.name}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="divider">
            <div className="rectangle-533" />
          </div>
          <ul className="list">
            {menuOptions.map(({ path, text, Icon }) => (
              <li key={path} className="list-item">
                <div className={`item-menu ${pathname === path ? 'item-menu--selected' : ''}`}>
                  <button type="button" onClick={() => navigate(path)} className="item-menu-button action">
                    <Icon />
                    {text}
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="divider">
            <div className="rectangle-533" />
          </div>
        </>
      )}
      {userData?.loginStatus && (
        <>
          <ul className="list">
            {/* <li className="list-item">
              <div className="item-menu">
                <button
                  type="button"
                  onClick={() => {
                    window.location.replace(`${panelUrl}/${userData?.data?.token}/information`):
                  }
                  className="item-menu-button action"
                >
                  <ReportIcon className="menu-icon" />
                  Informe de familia
                </button>
              </div>
            </li> */}
            <li className="list-item">
              <div className="item-menu">
                <button type="button" onClick={() => window.location.replace(`${panelUrl}/${userData?.data?.token}/messages`)} className="item-menu-button action">
                  <MessageIcon className="menu-icon" />
                  Mensajes
                </button>
              </div>
            </li>
          </ul>
          <div className="divider">
            <div className="rectangle-533" />
          </div>
        </>
      )}
      {activeExperience && (
        <>
          <div className="menu-block">
            <div className="single-button">
              <Button size="small" onClick={() => navigate(paths.END_EXPERIENCE)}>
                <img
                  src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_check.png"
                  className="menu-icon"
                  alt="finalizar"
                />
                Finalizar búsqueda
              </Button>
            </div>
          </div>
          <div className="divider">
            <div className="rectangle-533" />
          </div>
        </>
      )}
      <div className="menu-block menu-block--rest" />
    </div>
  );
};

ExperienceSlideMenu.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  alwaysCollapsable: PropTypes.bool,
};

ExperienceSlideMenu.defaultProps = {
  show: false,
  setShow: null,
  alwaysCollapsable: false,
};

export default ExperienceSlideMenu;
