import * as geolib from 'geolib';
import { updateSession } from 'utils/Session/sessionMethods';
import {
  getDataInSessionStorage, getDataInStorage, saveDataInStorage, saveCoordsInSessionStorage,
} from 'utils/Storage/storageFunctions';
import generateComparison from 'utils/Math/comparison';
// import googleApi from 'services/googleApi';
import countryService from 'services/countryService';
import api from '../../services/api';

const getLocationCoords = (locationType, schools) => {
  const coords = { latitude: '0', longitude: '0' };

  const firstSchool = schools[0];

  if (firstSchool) {
    coords.latitude = firstSchool[locationType].latitude;
    coords.longitude = firstSchool[locationType].longitude;
    return coords;
  }
  return coords;
};

function getRouteType(searchRoute) {
  if (searchRoute.match(/city/i)) {
    return 'city';
  }

  return 'neighborhood';
}

export const getCoordsBasedOnRoute = (searchRoute, schools) => {
  const routeType = getRouteType(searchRoute);
  const locationCoordinates = getLocationCoords(routeType, schools);
  return locationCoordinates;
};

export const calculateDistance = (coordinates, school) => {
  if (!coordinates?.latitude && !coordinates?.longitude) {
    return null;
  }

  if (!school.latitude && !school.longitude) {
    return null;
  }

  let distance = geolib.getDistance(coordinates,
    { latitude: school.latitude, longitude: school.longitude });
  distance /= 1000;
  distance = Number(distance.toFixed(1));
  return distance;
};

export const isPointWithinRadius = (point, centerPoint, radius) => (
  geolib.isPointWithinRadius(point, centerPoint, radius));

export const saveCoordinatesInStorage = (coordinates, storageKey) => {
  saveDataInStorage(coordinates, storageKey);
};

export const getCoordinatesInStorage = (storageKey) => getDataInStorage(storageKey);

export function updateCoordinatesInStorage(addressCoordinates) {
  const storagedCoordinates = getCoordinatesInStorage('search');

  const comparison = generateComparison();
  const comparisonResult = comparison.isObjsEqual(storagedCoordinates, addressCoordinates);
  if (comparisonResult === false) {
    saveCoordinatesInStorage('search', addressCoordinates);
  }
}

// export function selectCorrectCoordinates(userCoords, searchCoords) {
//   return (searchCoords?.latitude !== '0'
//     ? searchCoords
//     : userCoords);
// }

export async function fetchAddressByCoordinates(coords) {
  return api.get(`google/findme?lat=${coords.latitude}&long=${coords.longitude}`);
}

async function fetchSuggestions(address) {
  const sessionToken = getDataInSessionStorage('sessionId');
  const countryId = countryService.getCurrentCountryId();

  const googleResponse = await api.get(`google/autocomplete?address=${address}&sessionToken=${sessionToken}&country=${countryId}`);

  return googleResponse;
}

async function fetchCoordinatesByPlaceId(placeid) {
  const response = await api.get(`google/coords?placeId=${placeid}`);

  return response;
}

export async function getCoordsByPlaceId(placeid, setFetchError) {
  const apiCoords = {};

  try {
    const { data } = await fetchCoordinatesByPlaceId(placeid);

    updateSession();

    apiCoords.latitude = data.result.geometry.location.lat;
    apiCoords.longitude = data.result.geometry.location.lng;
  } catch (err) {
    setFetchError('ubicacion no encontrada');
  }

  return apiCoords;
}

export async function getAddressSugestions(address, updateSuggestions) {
  const googleResponse = await fetchSuggestions(address);
  if (googleResponse.data.status === 'OK') updateSuggestions(googleResponse.data.predictions);
}

function updateUserPosition(position, updateUserCoordinates) {
  const coords = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  };
  updateUserCoordinates(coords);
  saveCoordsInSessionStorage(coords, 'coords');
}

export function getLocation(updateUserCoordinates, updateUserCoordinatesError) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      updateUserPosition(position, updateUserCoordinates);
    });
  } else {
    updateUserCoordinatesError('navigator not supported');
  }
}
