import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import Dropdown from '../../../Vacantest/VacantestCards/Dropdown/VacantestDropdown';
import familyCombos from '../familyInfoConstants';
import specificStyle from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';

const PageTwo = ({ card, disableNext }) => {
  const parentsKey = 1;
  const parentsCollection = familyCombos[parentsKey];

  const [familyInfoData, setFamilyInfoData] = useState({ parent: null, description: null });
  const [description, setDescription] = useState('');
  const [parent, setParent] = useState({ name: 'Integrante que completa el informe' });
  const didMountRef = useRef(false);

  const getInitialData = () => {
    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);

      if (defaultValues) {
        setParent(defaultValues.parent);
        setDescription(defaultValues.description);
        setFamilyInfoData(defaultValues);
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => getInitialData(), []);

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageFamilyInfo.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      setFamilyInfoData({ parent, description });
    }
  }, [description, parent]);

  const isCardDataValid = () => {
    if (typeof (familyInfoData.parent?.id) === 'number') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (!isCardDataValid()) removeCardFromLocalStorage();
      else saveCardInLocalStorage(familyInfoData);
    }
  }, [familyInfoData]);

  return (

    <div className={rStyles.vacantest__card__content}>
      <>
        <div>
          <h3>Presentación de quién completa el informe.</h3>
          <p>
            Por favor, selecciona quién es el integrante familiar que completará este formulario.
          </p>
        </div>
        <div className={rStyles.vacantest__section__container}>
          <Dropdown
            className={specificStyle.parents__dropdown}
            option={parent}
            setOption={setParent}
            options={parentsCollection}
          />
        </div>
        <div className={rStyles.description__box}>
          <p className={specificStyle.text__area__label}>
            Puedes realizar una breve descripción si lo deseas.
          </p>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={specificStyle.text__area}
            placeholder="Descripción"
          />
        </div>
      </>
    </div>
  );
};

export default PageTwo;

PageTwo.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
