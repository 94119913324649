const saveItem = (key, value) => {
  if (!key || !value) return false;

  return sessionStorage.setItem(key, JSON.stringify(value));
};

const removeItem = (key) => {
  if (!key) return false;

  return sessionStorage.removeItem(key);
};

const getItem = (key) => {
  if (!key) return false;

  return JSON.parse(sessionStorage.getItem(key));
};

export default {
  saveItem,
  removeItem,
  getItem,
};
