/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import paths from '../../routes/paths';

import './styles.css';

const VacantestBanner = ({ experiencesEnabled }) => (
  <aside className="vacantestbanner">
    <h1>
      Encontrá el centro educativo ideal para tus hijos
    </h1>
    {!experiencesEnabled && (
      <>
        <h3>
          Realizá nuestro Test de Compatibilidad para comenzar con una pre&#8209;selección inteligente.
        </h3>
        <button
          type="button"
          onClick={() => window.open('/vacantest', '_blank')}
          className="vacantest-button"
        >
          Realizar test
        </button>
      </>
    )}
    {!!experiencesEnabled && (
      <>
        <h3>
          Comenzá tu búsqueda con nuestras recomendaciones personalizadas.
        </h3>
        <button
          type="button"
          onClick={() => window.open(paths.NEW_EXPERIENCE, '_blank')}
          className="vacantest-button"
        >
          Comenzar búsqueda
        </button>
      </>
    )}
  </aside>
);

VacantestBanner.propTypes = {
  experiencesEnabled: PropTypes.bool.isRequired,
};

export default VacantestBanner;
