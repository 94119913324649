import React from 'react';
import PropTypes from 'prop-types';

const InstituteIcon = ({ className }) => (
  <svg className={className} width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.25 19H20.75M2.75 1V19M13.25 1V19M19.25 5.5V19M5.75 4.75H6.5M5.75 7.75H6.5M5.75 10.75H6.5M9.5 4.75H10.25M9.5 7.75H10.25M9.5 10.75H10.25M5.75 19V15.625C5.75 15.004 6.254 14.5 6.875 14.5H9.125C9.746 14.5 10.25 15.004 10.25 15.625V19M2 1H14M13.25 5.5H20M16.25 9.25H16.258V9.258H16.25V9.25ZM16.25 12.25H16.258V12.258H16.25V12.25ZM16.25 15.25H16.258V15.258H16.25V15.25Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

InstituteIcon.propTypes = {
  className: PropTypes.string,
};

InstituteIcon.defaultProps = {
  className: null,
};

export default InstituteIcon;
