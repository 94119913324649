import React from 'react';
import { useNavigate } from 'react-router-dom';
import countryService from '../../../../services/countryService';
import { useSearch } from '../../../../hooks/SearchContext';
import logo from '../../../../assets_vacanted/Vacanted_LOGO_header.png';
import logoMobile from '../../../../assets_vacanted/logo_v.png';

const vacantedEnvironment = process.env.REACT_APP_VACANTED_ENV || 'local';
const countryCode = countryService.getCurrentCountryCode();

function redirectHome(navigate, clearAll) {
  clearAll();
  navigate('/');
}

const renderHeaderMobileLogo = () => {
  const navigate = useNavigate();
  const { clearAll } = useSearch();

  return (
    <div className="logo-container logo-container-mobile">
      <button
        type="button"
        className="logo-home-button"
        onClick={() => redirectHome(navigate, clearAll)}
      >
        <img src={logoMobile} alt="logo vacanted" />
        <div className="flag-icon" data-country={countryCode} />
      </button>
    </div>
  );
};

const renderHeaderLogo = () => {
  const navigate = useNavigate();
  const { clearAll } = useSearch();

  return (
    <div className="logo-container">
      <button
        type="button"
        className="logo-home-button"
        onClick={() => redirectHome(navigate, clearAll)}
      >
        <img src={logo} alt="logo vacanted" data-env={vacantedEnvironment} />
        <div className="flag-icon" data-country={countryCode} />
      </button>
    </div>
  );
};

export default { renderHeaderLogo, renderHeaderMobileLogo };
