function mutateActiveSchedules(filters, type) {
  const activeSteps = Object.entries(filters)
    .filter((f) => f[0].includes(type) && f[1]);
  const mutatedActiveSchedules = activeSteps.reduce((acc, period) => ({
    ...acc,
    [period[0]]: period[1],
  }), []);
  const scheduleskeys = Object.keys(mutatedActiveSchedules);

  return scheduleskeys;
}

function checkSteps(s, ages) {
  const activeOptions = {};
  s.schedules.map((schedule) => ages.forEach((a) => {
    const minAge = a[0];
    const maxAge = a[1];
    const thisAge = Number(schedule.ages.replace(' años', ''));
    if (thisAge >= minAge && thisAge <= maxAge && schedule.level) {
      activeOptions[a] = true;
    }
  }));

  return Object.keys(activeOptions).length;
}

function checkPeriods(s, periods) {
  const activeOptions = {};
  s.schedules.map((schedule) => periods.forEach((p) => {
    if (schedule[p] && schedule.level) {
      activeOptions[p] = true;
    }
  }));

  return Object.keys(activeOptions).length;
}

function filterSteps(school, activeSteps) {
  const bySteps = school.onlyActivePeriods.filter((fp) => activeSteps.find((as) => {
    const minAge = as[0];
    const maxAge = as[1];
    const thisAge = Number(fp.ages.replace(' años', ''));

    return thisAge >= minAge && thisAge <= maxAge;
  }));

  return bySteps;
}

function mutateSchoolsByActiveSteps(data, treatedAges) {
  return data.reduce((acc, s) => [
    ...acc,
    ({
      ...s,
      activeSteps: checkSteps(s, treatedAges),
      onlyActiveSteps: filterSteps(s, treatedAges),
    }),
  ], []);
}

const filterPeriods = (school, activePeriods) => {
  const byPeriods = school.filteredPeriods
    .filter((fp) => activePeriods.every((ap) => fp[ap]));

  return byPeriods;
};

function mutateSchoolsByActivePeriods(data, treatedActivePeriods) {
  return data.reduce((acc, s) => [
    ...acc,
    ({
      ...s,
      activePeriods: checkPeriods(s, treatedActivePeriods),
      onlyActivePeriods: filterPeriods(s, treatedActivePeriods),
    }),
  ], []);
}

function matchedSchedules(school) {
  const matched = [];
  school.onlyActivePeriods.forEach((ap) => {
    school.onlyActiveSteps.forEach((as) => {
      if (as.id === ap.id) matched.push(ap);
    });
  });

  return matched;
}

const filterSchoolsByMatchedSchedules = (schools) => schools
  .reduce((acc, s) => [
    ...acc,
    ({ ...s, onlyMatchedSchedules: matchedSchedules(s) }),
  ], []);

const filteredByStep = (data, filters, treatedAges) => {
  const mutatedSchools = mutateSchoolsByActiveSteps(data,
    treatedAges);
  const nrOfSteps = Object.entries(filters).filter((f) => f[0].includes('steps') && f[1]).length;

  const finalSchools = filterSchoolsByMatchedSchedules(mutatedSchools);

  return finalSchools.filter((s) => s.activeSteps === nrOfSteps
    && s.onlyMatchedSchedules.length > 0);
};

const filteredByPeriod = (data, treatedActivePeriods) => {
  const mutatedSchools = mutateSchoolsByActivePeriods(data,
    treatedActivePeriods);

  const finalSchools = filterSchoolsByMatchedSchedules(mutatedSchools);

  return finalSchools.filter((s) => s.onlyActiveSteps
    .every((as) => treatedActivePeriods.every((ap) => as[ap])));
};

const filterBySteps = (filteredData, filters) => {
  const activeSteps = mutateActiveSchedules(filters, 'steps');
  const treatedActiveSteps = activeSteps.map((s) => s?.replace('steps-', ''));
  const treatedAges = treatedActiveSteps?.map((a) => a.split('~').reduce((acc, e) => [
    ...acc,
    Number(e),
  ], []));

  let data = filteredData;

  if (treatedActiveSteps.length > 0) data = filteredByStep(data, filters, treatedAges);

  return data;
};

const filterByPeriods = (filteredData, filters) => {
  const activePeriods = mutateActiveSchedules(filters, 'schedules');
  const treatedActivePeriods = activePeriods.map((p) => p?.replace('schedules-', ''));

  let data = filteredData;
  if (treatedActivePeriods.length > 0) data = filteredByPeriod(data, treatedActivePeriods);

  return data;
};

export default { filterBySteps, filterByPeriods };
