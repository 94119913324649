/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useBouncer from 'hooks/useBouncer';
import { BsSearch } from 'react-icons/bs';
import { FaTimes } from 'react-icons/fa';
// import countryService from '../../../../services/countryService';
import api from '../../../../services/api';
import { getAgesMessageToShow } from '../../../../utils/schools/schoolFunctions';
import { useSearch } from '../../../../hooks/SearchContext';
import AppComponents from '../../../../constants/components';

import './styles.css';

const Searchbar = ({ shown }) => {
  const {
    /* searchTerm,
    setSearchTerm, */
    locationInfo,
    setLocationInfo,
    querySchoolsWithCoordinates,
    // clearAll,
  } = useSearch();

  const [inquire, setInquire] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [rawInput, setRawInput] = useState(searchTerm ?? '');
  const [showDropdown, toggleShowDropdown] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [emptyMsgActive, setEmptyMsgActive] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  // const countryId = countryService.getCurrentCountryId();

  useEffect(() => {
    setEmptyMsgActive(false);
  }, [searchTerm]);

  function handleClear(focus = true) {
    // clearAll();
    toggleShowDropdown(false);
    setInquire({});
    setSearchTerm('');
    setRawInput('');
    setLoadingState(false);
    setEmptyMsgActive(false);
    if (focus) inputRef.current?.focus();
    // else containerRef.current?.blur();
  }

  useEffect(() => {
    if (shown) {
      inputRef.current.focus();
    } else {
      handleClear();
    }
  }, [shown]);

  const fetchInquire = () => {
    if (searchTerm.trimStart() !== '') {
      setLoadingState(true);
      return setTimeout(
        async () => api
          .get(`school/searchnew?q=${searchTerm.trimStart()}`)
          .then((r) => r.data)
          .then((data) => {
            setLoadingState(false);
            if ([
              ...data.schools,
              // ...data.cities,
              ...data.neighborhoods].length < 1) {
              setEmptyMsgActive(true);
              toggleShowDropdown(false);
            } else {
              setInquire(data);
              toggleShowDropdown(true);
            }
          }),
        1000,
      );
    }

    return null;
  };

  const fetchInquireDebounced = useBouncer(
    async () => fetchInquire(),
    500,
  );

  const handleInput = (e) => {
    const { target: { value } } = e;
    setRawInput(value);

    // find accented characters and replace them with the non-accented version
    const accentedCharacters = /[\u0300-\u036f]/g; // find unicode accents and other symbols
    const noAccents = value.normalize('NFD').replace(accentedCharacters, '');

    // remove all spaces and replace with '%'
    const hasSpace = /\s/g;
    const noSpace = noAccents.replace(hasSpace, '%');
    const modifiedValue = noSpace.replace(/'/g, '%');

    const emptyInput = modifiedValue === '';
    const maxInput = modifiedValue.length > 100;

    if (!maxInput) setSearchTerm(modifiedValue);

    return !emptyInput ? fetchInquireDebounced() : setInquire({});
  };

  const schoolInfo = (school) => {
    const msg = getAgesMessageToShow(school.schedules, locationInfo.countryId);

    return (
      <button
        key={school.id}
        className="dropdown-body-school"
        type="button"
        onClick={() => navigate(`/school/${school.id}?from=${AppComponents.SEARCH_BAR.name}`)}
      >
        <p className="school-name">
          <strong>{school.name}</strong>
          <span className="school-city">{` - ${school.city.name}`}</span>
        </p>
        <p className="school-info">{`${school.public.name} de Educación ${msg}`}</p>
      </button>
    );
  };

  async function handleSearchByLocation(location, id, step, city, nName, latitude, longitude) {
    if (location === 'cities') {
      const info = {
        ...locationInfo, cityId: id, table_step: step, name: city.name,
      };
      setLocationInfo(info);
      querySchoolsWithCoordinates(`school/radius?lat=${latitude}&lng=${longitude}`, { latitude, longitude });
    }
    if (location === 'neighborhoods') {
      const info = {
        ...locationInfo, cityId: city.id, table_step: step, name: `${nName}, ${city.name}`,
      };
      setLocationInfo(info);
      querySchoolsWithCoordinates(`school/radius?lat=${latitude}&lng=${longitude}`, { latitude, longitude });
      toggleShowDropdown(false);
      return navigate(`/search/?latitude=${latitude}&longitude=${longitude}`);
    }

    handleClear();

    return navigate('/search');
  }

  const getLocationInfo = (id, name, location, state, nName, latitude, longitude, city = '') => (
    <button
      type="button"
      key={id}
      onClick={() => handleSearchByLocation(location, id, state.table_step,
        city, nName, latitude, longitude)}
    >
      {`Ver instituciones en las cercanías del centro de ${name}`}
    </button>
  );
  const getNeighborhoodButton = (label, link) => (
    <button
      type="button"
      key={label}
      onClick={() => {
        handleClear();
        navigate(link);
      }}
    >
      {`Ver centros educativos ubicados en ${label}`}
    </button>
  );

  const getCityShownName = (city, state, country) => `${city} (${state.name}, ${country.name})`;
  /* const getNeighborhoodShownName =
    (neighborhood, city, state) => `${neighborhood} (${city.name}, ${state.name})`; */

  const renderCities = () => (
    inquire.cities && inquire.cities.length > 0 && (
      <div className="dropdown-location-content">
        <div className="dropdown-header">
          <p>Ciudades</p>
        </div>
        <div className="dropdown-body">
          {inquire.cities.map((city) => (
            getLocationInfo(city.id,
              getCityShownName(city.name,
                city.state,
                city.country),
              'cities', city.state, '', city.latitude, city.longitude, city)
          ))}
        </div>
      </div>
    )
  );

  const renderNeighborhoods = () => (
    inquire.neighborhoods && inquire.neighborhoods.length > 0 && (
      <div className="dropdown-location-content">
        <div className="dropdown-header">
          <p>Barrios</p>
        </div>
        <div className="dropdown-body">
          {inquire.neighborhoods.map(({
            label,
            link,
          }) => getNeighborhoodButton(label, link))}
        </div>
      </div>
    )
  );

  const renderSchools = () => (
    inquire.schools && inquire.schools.length > 0 && (
      <div className="dropdown-school-content">
        <div className="dropdown-header">
          <p>Centros Educativos encontrados</p>
        </div>
        <div className="dropdown-schools">
          {inquire.schools.map((s, i) => {
            if (i < 4) return schoolInfo(s);

            return null;
          })}
        </div>
      </div>
    )
  );

  const useLoading = (msg) => (
    <div className="dropdown-message">
      <ClipLoader color="#0096ed" loading={loadingState} size={25} />
      <p>{msg}</p>
    </div>
  );

  return (
    <div className="searchbar-wrapper" ref={containerRef}>
      <div className="input-container">
        <BsSearch className="input-icon" size={16} color="rgba(0, 0, 0, 0.55)" />
        <input
          className="input-searchbar"
          value={rawInput}
          type="text"
          onChange={handleInput}
          placeholder="Buscar por zona o centro educativo"
          ref={inputRef}
        />
        {!!rawInput && (
          <button type="button" className="input-close" onClick={handleClear}>
            <FaTimes size={18} />
          </button>
        )}
      </div>
      {loadingState && useLoading('Buscando colegios...')}
      {emptyMsgActive && (
        <div className="dropdown-message">
          <p>No se han encontrado colegios o localizaciones con esta busqueda...</p>
        </div>
      )}
      {showDropdown && !loadingState && (
        <div className="dropdown-container">
          <div className="dropdown-options">{renderCities()}</div>
          <div className="dropdown-options">{renderNeighborhoods()}</div>
          <div className="dropdown-options">{renderSchools()}</div>
        </div>
      )}
    </div>
  );
};

Searchbar.propTypes = {
  shown: PropTypes.bool,
};

Searchbar.defaultProps = {
  shown: true,
};

export default Searchbar;
