/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ControlledTextField from '../text-field';
import ControlledDropdown from '../dropdown';
import Button from '../button';
import PlusIcon from '../../../../../components/Icons/PlusIcon';
import TrashIcon from '../../../../../components/Icons/TrashIcon';
import api from '../../../../../services/api';
import useResponsive from '../../hooks/useResponsive';
import Modal from '../modal';
import BenefitsWarningModal from '../benefits-warning-modal';
import FlowContext from '../flow/context';

import './registration-info.scss';

const dataKey = 'benefitsData';

const RegistrationInfo = ({ current, onChange }) => {
  const { onEnd, setCustomForwardAction } = useContext(FlowContext);
  const [levelOptions, setLevelOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };

  const { isMobile } = useResponsive();

  const benefitsDataIsComplete = (data) => {
    const hasBeneficiary = data?.beneficiary?.name
      && data?.beneficiary?.document;
    const hasStudents = !!data?.students?.length
      && data.students.every((student) => student.name && student.level);
    return !!(hasBeneficiary && hasStudents);
  };

  const sendChange = (update) => {
    const hasBenefits = benefitsDataIsComplete(current[dataKey]);
    if (hasBenefits) {
      setCustomForwardAction(null);
    } else {
      setCustomForwardAction(() => showModal);
    }
    const updatedData = { ...current[dataKey], ...update, hasBenefits };
    onChange(dataKey, updatedData);
  };

  const addStudent = () => {
    sendChange({ students: (current[dataKey]?.students ?? []).concat([{}]) });
  };

  const deleteStudentByIndex = (ix) => {
    sendChange({ students: current[dataKey]?.students?.filter((_, index) => index !== ix) });
  };

  useEffect(() => {
    api.getAges().then((ages) => {
      if (ages.length) {
        setLevelOptions(ages.map(({ id, level_name }) => ({ value: `${id}`, label: level_name })));
      }
    });
    if (!current[dataKey]?.students?.length) {
      addStudent();
    } else {
      sendChange({});
    }

    return () => {
      setCustomForwardAction(null);
    };
  }, []);

  const handleChange = (key, ix, value) => {
    const updatedData = { ...current[dataKey] };
    switch (key) {
      case 'beneficiary_document':
        if (!updatedData.beneficiary) updatedData.beneficiary = {};
        updatedData.beneficiary.document = value;
        break;
      case 'beneficiary_name':
        if (!updatedData.beneficiary) updatedData.beneficiary = {};
        updatedData.beneficiary.name = value;
        break;
      case 'student_name':
        if (!updatedData.students) updatedData.students = new Array(ix + 1).fill({});
        updatedData.students[ix].name = value;
        break;
      case 'student_level':
        if (!updatedData.students) updatedData.students = new Array(ix + 1).fill({});
        updatedData.students[ix].level = value;
        break;
      default:
        break;
    }
    sendChange(updatedData);
  };

  return (
    <div className="registration-info">
      <div className="registration-info__content">
        <Box className="registration-info__content__section">
          <span className="registration-info__content__section__title">Datos del beneficiario (mayor de 18 años)</span>
          <ControlledTextField
            id="beneficiary_name"
            label="Nombre y apellido"
            size="medium"
            value={current[dataKey]?.beneficiary?.name || ''}
            onChange={(newValue) => {
              handleChange('beneficiary_name', null, newValue);
            }}
            errorMessage="Debe ingresar nombre y apellido del beneficiario"
            required
          />
          <ControlledTextField
            id="beneficiary_document"
            label="Documento de identidad"
            size="medium"
            value={current[dataKey]?.beneficiary?.document || ''}
            onChange={(newValue) => {
              handleChange('beneficiary_document', null, newValue);
            }}
            errorMessage="Debe ingresar el documento del beneficiario"
            required
          />
        </Box>
        {current[dataKey]?.students?.map((student, ix) => (
          <Box className="registration-info__content__section">
            <span className="registration-info__content__section__title">
              Datos del alumno/a
              {ix !== 0 && (
              <Button
                hirachy="transparent"
                size="small"
                onClick={
                      () => deleteStudentByIndex(ix)
                    }
                iconOnly={!!isMobile}
              >
                <TrashIcon className="icon--fill" />
                <span>Eliminar</span>
              </Button>
              )}
            </span>
            <ControlledTextField
              id={`student_name_${ix}`}
              label="Nombre y apellido"
              size="medium"
              value={student.name || ''}
              onChange={(newValue) => {
                handleChange('student_name', ix, newValue);
              }}
              errorMessage="Debe ingresar nombre y apellido del alumno/a"
              required
            />
            <ControlledDropdown
              id={`student_level_${ix}`}
              label="Nivel educativo en el que se inscribió"
              size="medium"
              options={levelOptions}
              value={student.level}
              onChange={(newValue) => {
                handleChange('student_level', ix, newValue);
              }}
              errorMessage="Debe ingresar el nivel educativo en el que se inscribió"
              required
            />
          </Box>
        ))}
        <Button
          className="fit-content"
          hirachy="default"
          size="small"
          onClick={addStudent}
          // iconOnly={!!isNotDesktop}
        >
          <PlusIcon className="icon--fill" />
          <span>Agregar alumno/a</span>
        </Button>
      </div>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="large">
        <BenefitsWarningModal
          close={() => setOpenModal(false)}
          accept={() => {
            setOpenModal(false);
            onEnd();
          }}
        />
      </Modal>
    </div>
  );
};

RegistrationInfo.propTypes = {
  current: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RegistrationInfo;
