import React from 'react';
import PropTypes from 'prop-types';
import iStyles from './Input.module.css';

const VacantestInput = ({
  placeholder, loading, isActive, inputValue, setInputValue, dropdownOptions, setSelectedOption,
}) => {
  const normalizeString = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const handleAddressInput = (e) => {
    const normalizedString = normalizeString(e.target.value);
    setInputValue({ search: true, value: normalizedString });
  };

  return (
    <div className={iStyles.input__container}>
      <input
        type="text"
        className={iStyles.vacantest__input}
        placeholder={placeholder}
        value={inputValue}
        maxLength={30}
        onChange={(e) => handleAddressInput(e)}
      />
      {isActive && (
        <div className={iStyles.dropdown__content}>
          {loading ? (
            <p className={iStyles.loading__text}>...cargando</p>
          ) : (
            dropdownOptions.map((item) => (
              <button
                type="button"
                className={iStyles.dropdown__item}
                key={item.place_id}
                onClick={() => setSelectedOption(item)}
              >
                {item.description}
              </button>
            )))}
        </div>
      )}
    </div>
  );
};

export default VacantestInput;

VacantestInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedOption: PropTypes.func.isRequired,
};
