import sessionStorageServices from 'services/sessionStorageServices';
import Services from 'services/Services';
import { getUrl } from 'urlConfig';
import pagesConfig from 'pagesConfig';
import saveVacantest from 'utils/Favorites/saveVacantest';
import paths from '../../routes/paths';

const panelUrl = getUrl('panel');
const schoolPanelUrl = getUrl('schoolPanel');

const schoolUserRoles = [2, 4, 6, 7];

export default async function loginController(userInfo, navigate, beforeRedirect) {
  if (!userInfo) return false;
  if (!userInfo.userData.data) return false;

  const { data } = userInfo.userData;
  if (!data.token) return false;
  if (!data.user) return false;
  const { user } = data;
  if (!user.role_id) return false;
  const roleUser = user.role_id;

  if (schoolUserRoles.includes(roleUser)) {
    sessionStorageServices.removeItem('temp_save');
    sessionStorageServices.removeItem('userFrom');
    sessionStorageServices.removeItem('temp_save');

    return window.location.replace(`${schoolPanelUrl}/${data.token}`);
  }

  if (roleUser !== 5) return false;

  const tempSave = sessionStorageServices.getItem('temp_save');
  if (tempSave) {
    const { type } = tempSave;

    switch (type) {
      case 'favorite':
        await Services.saveFavoriteSchool(data.token);
        break;
      case 'filters':
        break;
      case 'vacantest':
        await saveVacantest();
        break;
      default:
        break;
    }

    sessionStorageServices.removeItem('temp_save');
  }

  if (beforeRedirect) beforeRedirect();

  const userFrom = sessionStorageServices.getItem('userFrom');
  if (userFrom) {
    sessionStorageServices.removeItem('userFrom');

    if (['experience'].includes(userFrom) && data.active_experience) {
      return navigate(paths.EXPERIENCE_RECOMMENDATIONS);
    }

    if (['advancedSearch'].includes(userFrom)) {
      return navigate(`/${pagesConfig.advancedSearch}`);
    }

    if (/schoolProfile/.test(userFrom)) {
      const schoolId = userFrom.split('-')[1];
      return navigate(`/${pagesConfig.schoolProfile}/${schoolId}`);
    }

    if (/messages/.test(userFrom)) {
      return window.location.replace(`${panelUrl}/${data.token}/${userFrom}`);
    }

    if (['vacantest'].includes(userFrom)) {
      return window.location.replace(`${panelUrl}/${data.token}/vacantests`);
    }
  }

  if (data.active_experience && window.location.pathname !== paths.NEW_EXPERIENCE) {
    return navigate(paths.EXPERIENCE_RECOMMENDATIONS);
  }

  return true;
  // return window.location.replace(`${panelUrl}/${data.token}`);
}
