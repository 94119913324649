export const saveDataInStorage = (data, storageKey = 'vacanted') => {
  localStorage.setItem(storageKey, JSON.stringify(data));
};

export const saveCreatedExpInStorage = (data) => {
  saveDataInStorage(data, 'created_exp');
};

export const setTooltipShown = (tooltip) => {
  saveDataInStorage(true, `tooltip_${tooltip}`);
};

export const getDataInStorage = (storageKey) => localStorage.getItem(storageKey);

export const parsedDataInStorage = (key = 'vacanted') => {
  const data = getDataInStorage(key);
  const result = JSON.parse(data);
  return result;
};

export const getCreatedExpFromStorage = () => parsedDataInStorage('created_exp');

export const isTooltipShown = (tooltip) => parsedDataInStorage(`tooltip_${tooltip}`);

export const removeDataInStorage = (key) => localStorage.removeItem(key);

export const saveDataInSessionStorage = (data, storageKey) => {
  sessionStorage.setItem(data, storageKey);
};

export const saveCoordsInSessionStorage = (coords, storageKey) => {
  sessionStorage.setItem(storageKey, JSON.stringify(coords));
};

export const getCoordsInSessionStorage = () => JSON.parse(sessionStorage.getItem('coords'));

export const getDataInSessionStorage = (key) => (sessionStorage.getItem(key));

export const removeDataInSessionStorage = (storageKey) => sessionStorage.removeItem(storageKey);
