/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { useField, ErrorMessage } from 'formik';

const InputLogin = (props) => {
  const [field] = useField(props);
  return (
    <div className="login-input-container">
      <input {...field} {...props}/>
      <ErrorMessage name={props.name} component="span" className="error-message"/>
    </div>
  );
};

export default InputLogin;
