/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import '../styles/check-selector.scss';
import classNames from 'classnames';

const CheckSelector = ({
  onChange, label, checked, name, value, children, disabled,
}) => {
  const inputRef = useRef(null);

  const hasChildren = () => {
    if (children?.length && typeof children === 'object') {
      return children.some((it) => !!it);
    } if (children?.length === 0) {
      return false;
    }
    return !!children;
  };

  const classnameLabel = classNames('checkbox-selector', {
    clickable: !hasChildren(),
    'checkbox-selector--checked': checked,
    'checkbox-selector--disabled': disabled,
  });

  return (
    <div
      className={classnameLabel}
      onClick={!hasChildren()
        ? (e) => inputRef?.current && e.target !== inputRef.current && inputRef.current.click()
        : null}
      onKeyDown={!hasChildren()
        ? (e) => inputRef?.current && e.target !== inputRef.current && inputRef.current.click()
        : null}
    >
      <div className="checkbox-selector__input">
        <div className="checkbox-selector__label">{label}</div>
        <input
          ref={inputRef}
          className="checkbox-selector__checkboxbutton"
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      {children}
    </div>
  );
};

CheckSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

CheckSelector.defaultProps = {
  checked: false,
  value: null,
  children: null,
  disabled: false,
};

export default CheckSelector;
