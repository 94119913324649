import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { BiDollar } from 'react-icons/bi';
import countryService from 'services/countryService';
import moneyMask from 'utils/MoneyMask/moneyMask';
import Dropdown from '../Dropdown/VacantestDropdown';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import specificStyles from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';

const PageNine = ({ card, disableNext }) => {
  const [familyInfoData, setFamilyInfoData] = useState({
    divorced: null,
    placeOfLiving: null,
    typeOfHousing: null,
    typeOfHousingTwo: null,
    costOfHousing: null,
    costOfHousingTwo: null,
    houseOwners: null,
    houseOwnersTwo: null,
  });
  const [divorced, setDivorced] = useState('');
  const [placeOfLiving, setPlaceOfLiving] = useState('');
  const [typeOfHousing, setTypeOfHousing] = useState({ name: 'Concepto' });
  const [typeOfHousingTwo, setTypeOfHousingTwo] = useState({ name: 'Concepto' });
  const [costOfHousing, setCostOfHousing] = useState('');
  const [costOfHousingTwo, setCostOfHousingTwo] = useState('');
  const [houseOwners, setHouseOwners] = useState(false);
  const [houseOwnersTwo, setHouseOwnersTwo] = useState(false);

  const didMountRef = useRef(false);

  const housingCollection = [
    { id: 1, name: 'Alquiler' },
    { id: 2, name: 'Cuota de hipoteca' },
    { id: 3, name: 'Otro' },
  ];

  const getInitialData = () => {
    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);
      if (defaultValues) {
        setDivorced(defaultValues.divorced);
        setPlaceOfLiving(defaultValues.placeOfLiving);
        setTypeOfHousing(defaultValues.typeOfHousing);
        setTypeOfHousingTwo(defaultValues.typeOfHousingTwo);
        setCostOfHousing(defaultValues.costOfHousing);
        setCostOfHousingTwo(defaultValues.costOfHousingTwo);
        setHouseOwners(defaultValues.houseOwners);
        setHouseOwnersTwo(defaultValues.houseOwnersTwo);
        setFamilyInfoData(defaultValues);
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => getInitialData(), []);

  useEffect(() => {
    setFamilyInfoData({
      divorced,
      placeOfLiving,
      typeOfHousing,
      typeOfHousingTwo,
      costOfHousing,
      costOfHousingTwo,
      houseOwners,
      houseOwnersTwo,
    });
  }, [
    divorced,
    placeOfLiving,
    typeOfHousing,
    typeOfHousingTwo,
    costOfHousing,
    costOfHousingTwo,
    houseOwners,
    houseOwnersTwo,
  ]);

  const isCardDataValid = () => {
    if (!divorced) return false;
    if (!placeOfLiving) return false;

    if (placeOfLiving === 'Yes') {
      if (!houseOwnersTwo) {
        if (typeOfHousingTwo.name === 'Concepto') return false;
        if (!costOfHousingTwo) return false;
      }
    }

    if (!houseOwners) {
      if (typeOfHousing.name === 'Concepto') return false;
      if (!costOfHousing) return false;
    }

    return true;
  };

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageFamilyInfo.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (!isCardDataValid()) removeCardFromLocalStorage();
    else saveCardInLocalStorage(familyInfoData);
  }, [familyInfoData]);

  useEffect(() => {
    if (houseOwners) {
      setTypeOfHousing({ name: 'Proprietario' });
      setCostOfHousing('0');
    } else if (!typeOfHousing) {
      setTypeOfHousing({ name: 'Concepto' });
      setCostOfHousing('');
    }
  }, [houseOwners]);

  useEffect(() => {
    if (houseOwnersTwo) {
      setTypeOfHousingTwo({ name: 'Proprietario' });
      setCostOfHousingTwo('0');
    } else if (!typeOfHousingTwo) {
      setTypeOfHousingTwo({ name: 'Concepto' });
      setCostOfHousingTwo('');
    }
  }, [houseOwnersTwo]);

  const validateNumbers = (value) => {
    const numberRegex = /\D+/g;
    const onlyNumbers = value;
    if (value) {
      return onlyNumbers.replace(numberRegex, '');
    }

    return value;
  };

  const handleCostOfHousing = (value, nr) => {
    const validatedValue = validateNumbers(value);
    if (nr === 1) setCostOfHousing(validatedValue);
    else setCostOfHousingTwo(validatedValue);
  };

  const handleHouseOwners = (checked, nr) => {
    if (nr === 1) setHouseOwners(checked);
    else setHouseOwnersTwo(checked);
  };

  return (
    <div className={rStyles.vacantest__card__content}>
      <>
        <div className={specificStyles.page__title}>
          <h3>Capacidad de pago - Vivienda</h3>
          <p>
            ¿Los padres del núcleo familiar están separados o divorciados?
          </p>
        </div>
        <div
          className={rStyles.vacantest__section__container}
          style={{ marginTop: '40px' }}
        >
          <form action="">
            <div className={specificStyles.radio_inputs}>
              <input
                className={specificStyles.vacantest__checkbox}
                type="radio"
                checked={divorced === 'Yes'}
                value="Yes"
                name="divorced"
                onChange={(e) => setDivorced(e.target.value)}
              />
              <p>Si</p>
            </div>
            <div className={specificStyles.radio_inputs}>
              <input
                className={specificStyles.vacantest__checkbox}
                type="radio"
                name="divorced"
                checked={divorced === 'No'}
                value="No"
                onChange={(e) => setDivorced(e.target.value)}
              />
              <p>No</p>
            </div>
            <hr />
            <div
              className={rStyles.vacantest__section__container}
              style={{
                padding: '0',
                marginTop: '-10px',
                marginLeft: '0px',
              }}
            >
              <p>
                ¿Los integrantes de la familia en edad estudiantil viven en más de un hogar?
              </p>
              <div className={specificStyles.radio_inputs}>
                <input
                  className={specificStyles.vacantest__checkbox}
                  type="radio"
                  checked={placeOfLiving === 'Yes'}
                  value="Yes"
                  name="placeOfLiving"
                  onChange={(e) => setPlaceOfLiving(e.target.value)}
                />
                <p>Si</p>
              </div>
              <div className={specificStyles.radio_inputs}>
                <input
                  className={specificStyles.vacantest__checkbox}
                  type="radio"
                  name="placeOfLiving"
                  checked={placeOfLiving === 'No'}
                  value="No"
                  onChange={(e) => setPlaceOfLiving(e.target.value)}
                />
                <p>No</p>
              </div>
              <hr />
            </div>
            <div
              className={rStyles.vacantest__section__container}
              style={{
                padding: '0',
                marginTop: '-10px',
                marginLeft: '0px',
              }}
            >
              <p
                className={specificStyles.bold__subtitle}
                style={{
                  marginTop: '-30px',
                }}
              >
                Costos mensuales de vivienda del Hogar 1.
              </p>
              <p>
                Agrega concepto de gasto
                {` (${countryService.getCurrencySymbol()})`}
              </p>
              <Dropdown
                option={typeOfHousing}
                setOption={setTypeOfHousing}
                options={housingCollection}
                disabled={houseOwners}
              />
              <div className={specificStyles.costInput__container}>
                <input
                  className={specificStyles.cost_input}
                  type="text"
                  value={moneyMask(costOfHousing)}
                  onChange={(e) => handleCostOfHousing(e.target.value, 1)}
                  placeholder="Costo promedio mensual"
                  disabled={houseOwners}
                  maxLength={15}
                />
                <BiDollar size={20} color="black" />
              </div>
              <div className={specificStyles.radio_inputs}>
                <input
                  className={specificStyles.vacantest__checkbox}
                  type="checkbox"
                  checked={houseOwners}
                  name="specialNeedsRadio"
                  onChange={(e) => handleHouseOwners(e.target.checked, 1)}
                />
                <p>Propietarios</p>
              </div>
            </div>
            {placeOfLiving === 'Yes'
              && (
                <div
                  className={rStyles.vacantest__section__container}
                  style={{
                    padding: '0',
                    marginTop: '-10px',
                  }}
                >
                  <hr style={{ marginBottom: '30px' }} />
                  <p className={specificStyles.bold__subtitle}>
                    Costos mensuales de vivienda del Hogar 2.
                  </p>
                  <p>Agrega concepto de gastos</p>
                  <Dropdown
                    option={typeOfHousingTwo}
                    setOption={setTypeOfHousingTwo}
                    options={housingCollection}
                    disabled={houseOwnersTwo}
                  />
                  <div className={specificStyles.costInput__container}>
                    <input
                      className={specificStyles.cost_input}
                      type="text"
                      value={moneyMask(costOfHousingTwo)}
                      onChange={(e) => handleCostOfHousing(e.target.value, 2)}
                      placeholder="Costo promedio mensual"
                      disabled={houseOwnersTwo}
                    />
                    <BiDollar size={20} color="black" />
                  </div>
                  <div className={specificStyles.radio_inputs}>
                    <input
                      className={specificStyles.vacantest__checkbox}
                      type="checkbox"
                      checked={houseOwnersTwo}
                      name="houseOwnersTwo"
                      onChange={(e) => handleHouseOwners(e.target.checked, 2)}
                    />
                    <p>Propietarios</p>
                  </div>
                </div>
              )}
          </form>
        </div>
      </>
    </div>
  );
};

export default PageNine;

PageNine.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
