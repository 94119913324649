import React, { useContext } from 'react';
import SchoolItemContext from '../SchoolItemContext';

const SchoolItemAddress = () => {
  const { school } = useContext(SchoolItemContext);
  return (
    <div className="school-feature school-item__address">
      <img src="https://vacanted-assets-dev.s3.us-east-1.amazonaws.com/icons/icon_location.png" alt="ubicacion" />
      {school.address}
    </div>
  );
};

export default SchoolItemAddress;
