import Header from 'components/Header/Header';
import React from 'react';
import Footer from 'components/Footer';
// import { Link } from 'react-router-dom';
import SchoolFormModal from 'components/SchoolFormModal';
import SchoolLoginDescription from '../../components/SchoolLoginDescription';
import SchoolLoginCard from '../../components/SchoolLoginCard';
// import bottomStyle from './styles.module.css';
import styles from '../school_login.module.scss';

function schoolLogin() {
  return (
    <>
      <Header />
      <div className={styles.background}>
        <div className={styles.background__overlay}>
          <div className={styles.body__content}>
            <div
              className={styles.description__container}
            >
              <SchoolLoginDescription />
            </div>
            <div className={styles.form__container}>
              <SchoolLoginCard />
            </div>
          </div>
        </div>

        <div className={styles.footer__container}>
          <Footer />
        </div>
      </div>
      <SchoolFormModal />
    </>
  );
}

schoolLogin.propTypes = {};

export default schoolLogin;
