import { useEffect, useState } from 'react';
import api from 'services/api';
import { getCreatedExpFromStorage } from 'utils/Storage/storageFunctions';
import experienceService from '../../../../services/experienceService';
import { experienceMapper, searchProfileMapper } from '../helpers/mappers';

const useExperience = () => {
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [experienceToWarn, setExperienceToWarn] = useState();
  const [typeSchool, setTypeSchool] = useState([]);
  const [religion, setReligion] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [level, setLevel] = useState([]);
  const [initCost, setInitCosts] = useState();
  const [schoolCost, setSchoolCosts] = useState();
  const [seasons, setSeasons] = useState([]);
  const [preschoolOrSchool] = useState([
    {
      value: 'preschool',
      label: 'Jardines de infantes',
      name: 'preschoolOrSchool',
    },
    {
      value: 'school',
      label: 'Colegios donde puedan continuar sus estudios',
      name: 'preschoolOrSchool',
    },
    {
      value: '',
      label: 'Es indiferente',
      name: 'preschoolOrSchool',
    },
  ]);
  const [acceptsPrivateSuggestions] = useState([
    {
      value: '1',
      label: 'Sí, me interesa!',
      name: 'acceptsPrivateSuggestions',
    },
    {
      value: '0',
      label: 'No, busco educación pública solamente',
      name: 'acceptsPrivateSuggestions',
    },
  ]);

  const mapperPublic = ({ id, name, is_public: isPublic }) => ({
    value: id.toString(),
    label: name,
    checked: false,
    name: 'typeSchool',
    isPublic,
  });

  const mapperLanguages = ({ id, name, is_native: isNative }) => ({
    value: id.toString(),
    label: name,
    checked: !!isNative,
    disabled: !!isNative,
    name: 'languages',
    isNative,
  });

  const mapperLevel = ({
    id, name, age_number: ageNumber, level_name: levelName,
  }) => ({
    value: id.toString(),
    label: levelName,
    checked: false,
    age: name,
    ageNumber,
    name: 'level',
    counter: {
      label: 'Cantidad de hijos en este nivel',
      count: 1,
    },
  });

  const mapperReligion = (options) => [
    {
      name: 'religion',
      value: `${options.find(({ name }) => name === '')?.id || 0}`,
      label: 'Laica',
      checked: false,
    },
    {
      name: 'religion',
      value: 'yes',
      label: 'Religiosa',
      checked: false,
      list: options.filter(({ name }) => !!name).map(({ id, name }) => ({ id: `${id}`, name })),
    },
    {
      name: 'religion',
      value: '',
      label: 'Es indiferente',
    },
  ];

  const buildRangeMap = ({ from, to, name: defaultName }, ix, key) => {
    const finalRange = { id: ix + 1, from, to };
    let rangeName = '';
    if (to === 0) rangeName = '$0 (No puedo pagar una cuota)';
    if (typeof to === 'number' && to) rangeName = `Hasta $${to.toLocaleString('es-UY')}`;
    if (!to && typeof from === 'number' && from) rangeName = `Más de $${from.toLocaleString('es-UY')}`;
    finalRange.name = defaultName || rangeName;
    return {
      name: key,
      value: finalRange.id.toString(),
      checked: false,
      label: finalRange.name,
      toMap: finalRange,
    };
  };

  const buildRangeMapInit = (data, ix) => buildRangeMap(data, ix, 'initCost');

  const buildRangeMapSchool = (data, ix) => buildRangeMap(data, ix, 'schoolCost');

  const mapperSeasons = (apiSeasons) => {
    const seasonOptions = [];
    if (apiSeasons.current) {
      const { id, name } = apiSeasons.current;
      seasonOptions.push(
        {
          value: id.toString(),
          label: `${name} (En curso)`,
          checked: false,
          name: 'year',
        },
      );
    }
    if (apiSeasons.next) {
      const { id, name } = apiSeasons.next;
      seasonOptions.push(
        {
          value: id.toString(),
          label: `${name} (Próximo comienzo)`,
          checked: false,
          name: 'year',
        },
      );
    }
    seasonOptions.push(
      {
        value: '',
        label: 'Estoy explorando',
        checked: false,
        name: 'year',
      },
    );
    return seasonOptions;
  };

  useEffect(() => {
    const expFromDevice = getCreatedExpFromStorage();
    api.get(`/experiences/init?previous_experience_id=${expFromDevice?.id || ''}&previous_experience_email=${expFromDevice?.email || ''}`)
      .then((experienceInitData) => {
        const {
          publics,
          religions,
          languages: availableLangs,
          ages,
          rangeprices,
          seasons: availableSeasons,
          active_exp_warning: expToWarn,
        } = experienceInitData.data;
        const { school, preschool } = rangeprices;
        setInitCosts(preschool.map(buildRangeMapInit));
        setSchoolCosts(school.map(buildRangeMapSchool));
        setTypeSchool(publics.sort((a, b) => {
          if (!a.is_public && b.is_public) {
            return -1;
          }
          if (a.is_public && !b.is_public) {
            return 1;
          }
          return 0;
        }).map(mapperPublic));
        setReligion(mapperReligion(religions));
        setLanguages(availableLangs.map(mapperLanguages));
        setLevel(ages.map(mapperLevel));
        setSeasons(mapperSeasons(availableSeasons));
        setFinishedLoading(true);
        setExperienceToWarn(expToWarn);
      })
      .catch(console.log);
  }, []);

  const onCreateExperience = async (experience, optionData) => {
    const mapExperience = experienceMapper(experience, optionData);
    return experienceService.createExperience(mapExperience);
  };

  const onCreateSearchProfile = async (experienceId, experience, optionData) => {
    const mappedProfile = searchProfileMapper(experience, optionData);
    return experienceService.addSearchProfile(experienceId, mappedProfile);
  };

  const onFinalizeExperience = async (experienceId, data) => experienceService.finishExperience(
    experienceId, data,
  );

  return {
    finishedLoading,
    experienceToWarn,
    initCost,
    schoolCost,
    typeSchool,
    religion,
    languages,
    level,
    year: seasons,
    preschoolOrSchool,
    acceptsPrivateSuggestions,
    onCreateExperience,
    onCreateSearchProfile,
    onFinalizeExperience,
  };
};

export default useExperience;
