import React from 'react';
import View from './view';
import Context from './context/Context';
import useRecommendations from './hooks/useRecommendations';

const Recommendations = () => {
  const context = useRecommendations();

  return (
    <Context.Provider value={context}>
      <View />
    </Context.Provider>
  );
};

export default Recommendations;
