import api from 'services/api';
import { getUrl } from 'urlConfig';
import sessionStorageServices from './sessionStorageServices';

const apiUrl = getUrl('api');
const panelUrl = getUrl('panel');

async function redirectToPanel(userData) {
  if (!userData || !userData.user || !userData.token) return false;

  return window.location.replace(`${panelUrl}/${userData.token}`);
}

async function getCollection(type) {
  const { token } = JSON.parse(localStorage.getItem('vacanted'));

  return api.get(`${apiUrl}/collection/type/${type}`, {
    headers: {
      authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error);
}

async function getSchoolSchedules(schoolId) {
  return api.get(`${apiUrl}/school/schedules/${schoolId}`)
    .then((resp) => resp)
    .catch((error) => error);
}

async function getSchoolCalendar(id) {
  return api.get(`${apiUrl}/school/calendar/${id}`)
    .then((resp) => resp)
    .catch((error) => error);
}

async function savePostulationAlert(payload) {
  const user = JSON.parse(localStorage.getItem('vacanted'));

  if (!user || !user.token) return false;

  const data = payload;
  const config = {
    headers: {
      authorization: user.token,
    },
  };

  return api.post(`${apiUrl}/postulate/create`, data, config)
    .then((resp) => resp)
    .catch((error) => error);
}

async function getSchoolById(id) {
  return api.get(`${apiUrl}/school/${id}`)
    .then((resp) => resp)
    .catch((error) => error);
}

async function saveFavoriteSchool(token, school) {
  let context = [];

  if (!school) {
    const objToSave = sessionStorageServices.getItem('temp_save');

    if (!objToSave) return false;
    if (!objToSave.school) return false;

    context = objToSave.school;
  } else context = school;

  const config = {
    headers: {
      authorization: token,
    },
  };

  const data = {
    type: 'favorite',
    context: [context],
  };

  return api.post(`${apiUrl}/collection`, data, config);
}

async function removeFavoriteSchool(token, collectionId, schoolId) {
  const config = {
    headers: {
      authorization: token,
    },
  };

  return api.delete(`${apiUrl}/collection/${collectionId}/${schoolId}`, config);
}

async function getFavoritedSchools(token, setFn) {
  const config = {
    headers: {
      authorization: token,
    },
  };

  const response = await api.get('/collection/type/favorite', config);
  if ([200].includes(response.status)) {
    if (response.data.favoriteSchools) {
      const favIds = response.data.favoriteSchools.map((fav) => fav.id);
      setFn({
        favoriteSchools: [...favIds],
        id: response.data.id,
      });
    }
  }
}

export default {
  redirectToPanel,
  getCollection,
  getSchoolCalendar,
  getSchoolSchedules,
  savePostulationAlert,
  getSchoolById,
  saveFavoriteSchool,
  removeFavoriteSchool,
  getFavoritedSchools,
};
