import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SchoolRating from '../SchoolRating';
import SchoolItemScore from './SchoolItemScore';
import SchoolItemContext from '../SchoolItemContext';

const SchoolItemInfoName = ({ includeAffinity }) => {
  const { school } = useContext(SchoolItemContext) || {};
  const {
    id,
    name,
    rating,
    user_ratings_total: totalRatings,
  } = school;

  const hasRating = Number(rating || 0) > 0 && !!totalRatings;

  return (
    <div className="school-basic-info">
      <div className="school-item__name">
        <a href={`/school/${id}`} target="_blank" className="school-item__name__text" rel="noreferrer">
          { name }
          <img src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_link.png" alt="navegar" />
        </a>
        <div className="school-item__scores">
          {includeAffinity && <SchoolItemScore />}
          {hasRating && (
          <div className="school-item__rating">
            <SchoolRating rating={rating} totalRatings={totalRatings} />
            <div className="school-item__rating__count">
              (
              {totalRatings}
              )
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

SchoolItemInfoName.propTypes = {
  includeAffinity: PropTypes.bool,
};

SchoolItemInfoName.defaultProps = {
  includeAffinity: false,
};

export default SchoolItemInfoName;
