const familyCombos = {
  1: [
    { id: 0, name: 'Padre' },
    { id: 1, name: 'Madre' },
    { id: 2, name: 'Padre político' },
    { id: 3, name: 'Madre política' },
    { id: 4, name: 'Tutor' },
    { id: 5, name: 'Abuela' },
    { id: 6, name: 'Abuelo' },
    { id: 7, name: 'Tío' },
    { id: 8, name: 'Tía' },
    { id: 9, name: 'Hermana + 18 años' },
    { id: 10, name: 'Hermano + 18 años' },
    { id: 11, name: 'Otro' },
  ],
  2: [
    { id: 0, name: '0 años' },
    { id: 1, name: '1 año' },
    { id: 2, name: '2 años' },
    { id: 3, name: '3 años' },
    { id: 4, name: '4 años' },
    { id: 5, name: '5 años' },
    { id: 6, name: '6 años' },
    { id: 7, name: '7 años' },
    { id: 8, name: '8 años' },
    { id: 9, name: '9 años' },
    { id: 10, name: '10 años' },
    { id: 11, name: '11 años' },
    { id: 12, name: '12 años' },
    { id: 13, name: '13 años' },
    { id: 14, name: '14 años' },
    { id: 15, name: '15 años' },
    { id: 16, name: '16 años' },
    { id: 17, name: '17 años' },
  ],
  3: [
    { id: 0, name: 'Aporte variable' },
    { id: 1, name: 'Aporte fijo' },
  ],
  4: [
    { id: 0, name: 'Institución' },
    { id: 1, name: 'Tío' },
    { id: 2, name: 'Tía' },
    { id: 3, name: 'Abuela' },
    { id: 4, name: 'Abuelo' },
    { id: 5, name: 'Hermana + 18 años' },
    { id: 6, name: 'Hermano + 18 años' },
    { id: 7, name: 'Otro' },
  ],
  5: [
    { id: 1, name: 'Institución privada' },
    { id: 2, name: 'Institución pública' },
  ],
  6: [
    { id: 1, name: 'Ingreso variable' },
    { id: 2, name: 'Ingreso fijo' },
    { id: 3, name: 'Ingreso fijo con variable' },
    { id: 4, name: 'Sin ingresos' },
  ],
};

export default familyCombos;
