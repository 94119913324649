import utils from '../selectorsUtils';

export const selectMainFeatures = (state) => (
  [
    {
      name: utils.getTypeOfLanguage([state.language1, state.language2, state.language3]),
      title: 'Idiomas',
      icon: 'https://images.vacanted.com.uy/school-icons/idiomas.svg',
    },
    {
      name: state.religious ? state.religion.name : 'Institución Laica',
      title: 'Religión',
      icon: state.religion.icon,
    },
    {
      name: state.genre.name,
      title: 'Tipo de Institución',
      icon: state.genre.icon,
    },
    {
      name: `Zona ${state.urban.name === 'Urbano' ? 'Urbana' : state.urban.name}`,
      title: 'Ubicación',
      icon: state.urban.icon,
    },
    {
      name: state.cost.id === 3 ? `De ${state.cost.name}` : state.cost.name,
      title: 'Costo',
      icon: state.cost.icon,
    },
    {
      name: state.public.name,
      title: 'Gestión Educativa',
      icon: state.public.icon,
    },
  ]
);

export default selectMainFeatures;
