import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const SchoolsContext = createContext();

function SchoolsProvider({ children }) {
  const [schools, setSchools] = useState({ filteredData: [], rawData: [] });
  const [schoolsLoadingState, setSchoolsLoadingState] = useState(false);

  return (
    <SchoolsContext.Provider value={{
      schoolsData: schools,
      setSchoolsData: setSchools,
      schoolsLoadingState,
      setSchoolsLoadingState,
    }}
    >
      {children}
    </SchoolsContext.Provider>
  );
}

SchoolsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useSchools = () => {
  const context = useContext(SchoolsContext);
  return context;
};

export default SchoolsProvider;
