/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSearch } from 'hooks/SearchContext';
import { useUserData } from 'hooks/UserContext';
import Modal from 'react-modal';
import api from 'services/api';
import { getUrl } from 'urlConfig';
import closeIcon from '../../assets_vacanted/close.svg';
import styles from './styles.module.css';

Modal.setAppElement('#root');

function SchoolFormModal() {
  const [loading, setLoading] = useState(false);
  const [schoolsOptions, setSchoolsOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [schools, setSchools] = useState();
  const [error, setError] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [schoolPhone, setSchoolPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [selectedCity, setSelectedCity] = useState();
  const [selectedSchool, setSelectedSchool] = useState();
  const appURL = getUrl('app');

  const {
    schoolRegisterModal,
    setSchoolRegisterModal,
  } = useUserData();

  const { cities } = useSearch();

  useEffect(() => {
    if (cities?.length) {
      const newOptions = cities.sort((a, b) => {
        if (a.name < b.name) return -1;
        return 1;
      }).map(({ id, name }) => ({ label: name, id }));
      setCitiesOptions(newOptions);
    }
  }, [cities]);

  const handleCityChange = async (theCity) => {
    setSelectedSchool(null);
    const getSchools = await api.get(`/school/searchcity/${theCity.id}`);
    const gotSchools = getSchools.data.schools;
    setSchools(gotSchools);
    const getSchoolsOptions = gotSchools.sort((a, b) => {
      if (a.name.trim() < b.name.trim()) return -1;
      return 1;
    }).map(({ id, name }) => ({ label: name, id }));
    setSchoolsOptions(getSchoolsOptions);
  };

  useEffect(() => {
    if (selectedCity) {
      handleCityChange(selectedCity);
    }
  }, [selectedCity]);

  const handleCloseModal = () => {
    setSchoolRegisterModal(false);
  };

  const handleSelectSchool = (theSchool) => {
    const findSchool = schools.find((item) => (
      item.id === theSchool.id
    ));
    setAddress(findSchool.address);
    setSelectedSchool(theSchool);
  };

  function hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  const handleSendForm = async () => {
    if (!password || !email || !adminName || !selectedSchool || !adminPhone || !schoolPhone) {
      setError('Rellena todos los campos');
      return;
    }
    if (hasWhiteSpace(password)) {
      setError('No se permiten espacios en la contraseña');
      return;
    }
    setLoading(true);
    const data = {
      name: adminName,
      role_id: 4,
      school_id: selectedSchool.id,
      personal_phone: adminPhone,
      email,
      password,
      active: 0,
    };

    api.post('/user/registry', data).then(() => {
      window.location.replace(appURL);
    }).finally(() => setLoading(false));
  };

  return (
    <Modal
      isOpen={schoolRegisterModal}
      onRequestClose={handleCloseModal}
      overlayClassName={styles.react_modal_overlay}
      className={styles.react_modal_content}
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.header_keep}>
        <div className={styles.header_close}>
          <button
            type="button"
            onClick={handleCloseModal}
            className={styles.modal_close}
          >
            <img src={closeIcon} alt="Cerrar modal" />
          </button>
        </div>
        <h2>Área Privada Institución Educativa</h2>
        <p>
          Active su usuario administrador y solicite asignación a la institución educativa.
          Complete los datos y nos pondremos en contacto con usted para comenzar
          el proceso de validación y asignación de la Institución Educativa
        </p>

      </div>
      <form>
        <p>Nombre del administrador de la Institución</p>
        <input
          className={styles.input}
          type="text"
          placeholder="Nombre completo"
          onChange={(e) => setAdminName(e.target.value)}
        />
        <Autocomplete
          disablePortal
          size="small"
          style={{ marginBottom: '1rem' }}
          options={citiesOptions}
          onChange={(_event, newValue) => {
            setSelectedCity(newValue);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} label="Seleccione la ciudad donde se encuentra su institución" />}
          noOptionsText="No hay ciudades disponibles"
          fullWidth
        />

        <div className={styles.schoolsContainer}>
          <Autocomplete
            disablePortal
            size="small"
            style={{ marginBottom: '1rem' }}
            options={schoolsOptions}
            onChange={(_event, newValue) => {
              handleSelectSchool(newValue);
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} label="Seleccione su institución" />}
            noOptionsText="No se encontraron instituciones"
            fullWidth
          />
          <p>Su Institución no está en la lista? Envianos un email a info.centros@vacanted.com</p>
        </div>

        <p>Dirección de la Insitución Educativa que Representa</p>
        <input
          className={styles.input}
          type="text"
          placeholder="Dirección de la Institución"
          value={address}
          readOnly
        />

        <p>Teléfono Móvil de contacto</p>
        <input
          className={styles.input}
          type="tel"
          placeholder="Teléfono"
          onChange={(e) => setAdminPhone(e.target.value)}
        />

        <p>Teléfono de la Institución</p>
        <input
          className={styles.input}
          type="tel"
          placeholder="Teléfono"
          onChange={(e) => setSchoolPhone(e.target.value)}
          value={schoolPhone}
        />

        <p>eMail</p>
        <input
          className={styles.input}
          type="text"
          placeholder="Ingrese su email"
          onChange={(e) => setEmail(e.target.value)}
        />

        <p>Clave</p>
        <input
          className={styles.input}
          type="password"
          placeholder="Ingrese su contraseña"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <p>
          {' '}
          Debe contener como mínimo: 8 (ocho) caracteres, 1 (una) mayúscula, 1 (un)
          número, 1 (un) caracter especial.
        </p>
        <p className={styles.error}>{error}</p>
        <button type="button" className={styles.keep_button} onClick={handleSendForm} disabled={loading}>ENVIAR SOLICITUD</button>

      </form>
    </Modal>
  );
}

export default SchoolFormModal;
