import React from 'react';
import PropTypes from 'prop-types';

import RatingStars from 'components/RatingStars';
import { AiOutlineCalendar } from 'react-icons/ai';

import styles from './styles.module.css';

export default function ReviewerCard({ reviewer }) {
  return (
    <div className={styles.container}>
      <div className={styles['image-container']}>
        <img src={reviewer.profile_photo_url} alt="" />
      </div>
      <div className={styles['information-container']}>
        <div className={styles['name-and-rating-container']}>
          <h5>{reviewer.author_name}</h5>
          <div>
            <RatingStars ratingValue={reviewer.rating} />
          </div>
        </div>
        <div className={styles['date-created-review']}>
          <AiOutlineCalendar />
          {reviewer.relative_time_description}
        </div>
      </div>
      <div className={styles.review}>
        <p>{`" ${reviewer.text} "`}</p>
      </div>
    </div>
  );
}

ReviewerCard.propTypes = {
  reviewer: PropTypes.object,
}.isRequired;
