import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileSection from '../ProfileSection';
import styles from './styles.module.css';
import CircularIconRow from '../../CircularIconRow/CircularIconRow';
import { languageData, languageDescription } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';

function Languages({ isFetching }) {
  const renderCircularIconTextLanguage = (language) => (
    <>
      <p className={styles.circularRowTitle}>{language.name}</p>
      <p className={styles.circularRowText}>{language.multiLingualType}</p>
    </>
  );

  if (isFetching) {
    return <div>Loading...</div>;
  }
  const languages = useSelector(languageData);
  const description = useSelector(languageDescription);

  return (
    <ProfileSection
      title="Idiomas"
      subtitleLyrics={{
        subtitle: 'Información sobre los Idiomas de la Institución',
        lyrics: description,
      }}
    >
      <div className={styles.sectionLanguageDiscription}>
        <section>
          <p>
            <b>Lengua Primaria:</b>
            {' '}
            Todas las asignaturas se imparten en
            este idioma según los programas de estudio oficiales del país.
          </p>
          <p>
            <b>Lengua Extranjera:</b>
            {' '}
            Incluye horas de estudio del idioma.
          </p>
          <p>
            <b>Bilingüe:</b>
            {' '}
            Incluye horas de estudio del idioma
            así como diferentes asignaturas impartidas en dicho idioma.
          </p>
        </section>
      </div>
      <div className={styles.iconRowContainer}>
        <CircularIconRow
          elements={languages}
          renderCircularIconText={renderCircularIconTextLanguage}
        />
      </div>
    </ProfileSection>
  );
}

Languages.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};

export default Languages;
