import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer/index';
import Header from '../../components/Header/Header';
import topImg from '../../assets/img/terms-conditions.jpg';
import styles from './styles.module.scss';

const TermsAndConditions = () => (
  <div>
    <div className="nav-header-wrapper">
      <Header />
      <Helmet>
        <title>Términos y condiciones - VacantED</title>
      </Helmet>
    </div>
    <div className={styles.terms__and__conditions__content}>
      <div className={styles.img_content}>
        <img className={styles.img_file} src={topImg} alt="computer" />
      </div>
      <div className={styles.text_content}>
        <div className={styles.terms_header}>
          <h4>Terminos y Condiciones del Servicio</h4>
        </div>
        <div className={styles.terms_wrapper}>
          <div className={styles.term}>
            <h5>1. Condiciones</h5>
            <p>
              Al acceder al sitio web en
              {' '}
              <a href="https://vacanted.com">
                vacanted.com
              </a>
              {' o '}
              <a href="https://vacanted.com.uy">
                vacanted.com.uy
              </a>
              , usted acepta estar sujeto a estos términos de servicio,
              todas las leyes y regulaciones aplicables, y acepta que es responsable
              del cumplimiento de las leyes locales aplicables.
              <br />
              Si no está de acuerdo con alguno de estos términos, tiene prohibido usar o acceder
              a este sitio.
              Los materiales contenidos en este sitio web están protegidos por las
              leyes de derechos de autor y marcas registradas aplicables.
            </p>
          </div>

          <div className={styles.term}>
            <h5>2. Licencia de uso</h5>
            <p>
              Se concede permiso para descargar temporalmente una copia de los materiales
              (información o software)
              en el sitio web de VACANTED™ solo para visualización transitoria personal
              y no comercial.
              <br />
              Esta es la concesión de una licencia, no una transferencia de título,
              y bajo esta licencia, usted no puede:
            </p>
            <ul className={styles.term_ul}>
              <li>modificar o copiar los materiales;</li>
              <li>
                utilizar los materiales para cualquier propósito comercial o para
                cualquier exhibición pública
                (comercial o no comercial);
              </li>
              <li>
                intentar descompilar o aplicar ingeniería inversa a cualquier software
                contenido en el sitio web de VACANTED™;
              </li>
              <li>
                eliminar los derechos de autor u otras anotaciones de propiedad de los materiales;
              </li>
              <li>
                o transferir los materiales a otra persona o reflejar los materiales
                en cualquier otro servidor.
              </li>
            </ul>
            <br />
            <p>
              Esta licencia terminará automáticamente si viola cualquiera de estas restricciones y
              VACANTED™
              puede cancelarla en cualquier momento. Al finalizar la visualización de estos
              materiales o al finalizar esta licencia, debe destruir cualquier
              material descargado que tenga en su poder, ya sea en formato electrónico o impreso.
            </p>
          </div>

          <div className={styles.term}>
            <h5>3. Descargo de responsabilidad</h5>
            <p>
              La informacion que ofrece el sitio web de VACANTED™ se proporciona
              tal cual. VACANTED™ no ofrece ninguna garantía, expresa o implícita,
              y por la presente renuncia y niega todas las demás garantías,
              incluidas, entre otras, las garantías implícitas o las condiciones de
              comerciabilidad, idoneidad para un propósito particular o no infracción de la
              propiedad intelectual o otra violación de derechos.
              <br />
              <br />
              Además, VACANTED™ no garantiza ni hace ninguna declaración con respecto a
              la precisión, los resultados probables o la confiabilidad del uso de la
              informacion en su sitio web o en relación con los centros educativos que
              se muestran en el sitio o en cualquier sitio vinculado a este sitio.
            </p>
          </div>

          <div className={styles.term}>
            <h5>4. Limitaciones</h5>
            <p>
              En ningún caso VACANTED™ o sus proveedores serán responsables
              de ningún daño (incluidos, entre otros, daños por pérdida de datos o ganancias,
              o debido a la interrupción del negocio) que surjan del uso de los servicios de
              VACANTED™,
              sitio web, incluso si VACANTED™ o un representante autorizado de VACANTED™ han
              sido notificados verbalmente o por escrito de la posibilidad de tal daño.
              <br />
              <br />
              Debido a que algunas jurisdicciones no permiten limitaciones sobre garantías
              implícitas o
              limitaciones de responsabilidad por daños consecuentes o incidentales, es
              posible que estas
              limitaciones no se apliquen en su caso.
            </p>
          </div>

          <div className={styles.term}>
            <h5>5. Precisión de los materiales</h5>
            <p>
              Los materiales que aparecen en el sitio web de VACANTED™ pueden
              incluir errores técnicos, tipográficos o fotográficos. VACANTED™ no
              garantiza que los materiales de su sitio web sean precisos, completos o actualizados.
              <br />
              VACANTED™ puede realizar cambios en los materiales contenidos en su sitio web
              en cualquier momento
              sin previo aviso. Sin embargo, VACANTED™ no se compromete a actualizar los materiales.
            </p>
          </div>

          <div className={styles.term}>
            <h5>6. Enlaces</h5>
            <p>
              VACANTED™ no ha revisado todos los sitios vinculados a su sitio web y
              no es responsable del contenido de dichos sitios vinculados.
              <br />
              La inclusión de cualquier enlace no implica el respaldo de VACANTED™ del sitio.
              El uso de cualquier sitio web vinculado es por cuenta y riesgo del usuario.
            </p>
          </div>

          <div className={styles.term}>
            <h5>7. Modificaciones</h5>
            <p>
              VACANTED™ puede revisar estos términos de servicio para su sitio web en
              cualquier momento sin previo aviso. Al utilizar este sitio web, acepta
              estar sujeto a la versión actual de estos términos de servicio.
            </p>
          </div>

          <div className={styles.term}>
            <h5>8. Experiencia VacantED</h5>
            <p>
              El servicio denominado EXPERIENCIA VACANTED implica que un usuario por
              voluntad propia genera en nuestra web un nuevo proceso de búsqueda de centro educativo
              en el cual introduce datos personales y datos no personales y recibe información
              o sugerencias de nuestra página web o nuestros colaboradores.
              <br />
              En dicho servicio el usuario acepta implícitamente recibir por medios electrónicos
              recomendaciones de nuevos centros educativos afines u otras sugerencias que Vacanted
              considere relevantes para la búsqueda del usuario. En todos los casos el usuario podrá
              en cualquier momento finalizar la experiencia solicitando que no se le envíen
              más comunicaciones al respecto a través de un correo a soporte@vacanted.com .
              <br />
              La finalización de la experiencia con la confirmación por parte del usuario a VacantED
              del centro educativo en el cual matriculó, es el hecho generador de la acreditación de
              ciertos beneficios que Vacanted puede brindar a todas las familias que matriculen
              a través de su plataforma.
              <br />
              Los beneficios constan de la participación en un sorteo de un año de escolaridad
              gratis
              (ver condiciones en Anexo I) y de otros posibles beneficios que tanto VacantED como
              los centros educativos podrían ofrecer en determinados períodos.
            </p>
          </div>

          <div className={styles.term}>
            <h5>8. Ley aplicable</h5>
            <p>
              Estos términos y condiciones se rigen e interpretan de acuerdo con las
              leyes de la República Oriental del Uruguay y usted se somete irrevocablemente a la
              jurisdicción exclusiva de los tribunales de ese estado o ubicación.
            </p>
          </div>
        </div>
        <div className={styles.terms_header}>
          <h4>Anexo I - Condiciones del sorteo de una anualidad</h4>
        </div>
        <div className={styles.terms_wrapper}>
          <div className={styles.term}>
            <h5>¿Qué es VacantED?</h5>
            <p>
              Es una plataforma de búsqueda y recomendación de centros educativos
              en la cual las familias pueden generar su experiencia de búsqueda personalizada,
              pudiendo informarse, realizar consultas, solicitar entrevistas, conformar
              la lista de centros candidatos, recibir recomendaciones personalizadas y
              confirmar la matriculación al centro elegido.
            </p>
          </div>

          <div className={styles.term}>
            <h5>¿Quiénes podrán participar del sorteo anual de VacantED?</h5>
            <p>
              1- Todos los usuarios de familia que confirmen su matriculación en VacantED
              para el año 2025.
              <br />
              2- Todos los usuarios de familia que confirmen su matriculación en VacantED
              para el año 2024, a partir del 1/05/2024.
              <br />
              3- Todos los usuarios de familia que al 31 de marzo de 2025 pertenezcan
              a la Comunidad VacantED.
              <br />
              4- Todos los usuarios de familia que hayan participado en alguna actividad
              de investigación de mercado realizada por VacantED (encuestas o entrevistas).
            </p>
          </div>

          <div className={styles.term}>
            <h5>¿En qué mercado es el sorteo?</h5>
            <p>
              Participan del sorteo quienes se hayan matriculado en cualquier centro público o
              privado de Uruguay o pertenezcan a la Comunidad VacantED de Uruguay o hayan
              participado en actividades de investigación (entrevistas o encuestas) sobre VacantED.
            </p>
          </div>

          <div className={styles.term}>
            <h5>¿Qué se debe hacer para participar del sorteo?</h5>
            <p>
              Para quien esté buscando centro educativo, basta con generar una experiencia de
              búsqueda y culminar el proceso confirmando el centro educativo público o privado
              al cual se matriculó y completar los datos requeridos de: nombre del padre, madre
              o tutor, cédula de identidad, nombre del alumno y nivel educativo al cual inscribe.
              <br />
              Para quien ya tiene centro educativo seleccionado, participa siempre y cuando
              complete la actividad de investigación sobre su experiencia, ya sea participando
              de una entrevista o completando una encuesta sobre VacantED.
            </p>
          </div>

          <div className={styles.term}>
            <h5>¿Cuándo se realiza el sorteo?</h5>
            <p>
              Viernes 11 de abril de 2025 a las 12:00hs de Uruguay.
            </p>
          </div>

          <div className={styles.term}>
            <h5>¿Qué incluye la bonificación de escolaridad?</h5>
            <p>
              El pago de la matrícula y las cuotas mensuales correspondientes a un año lectivo
              en el centro educativo privado donde se compruebe asistencia con
              un máximo de $ 200.000 (pesos uruguayos doscientos mil) totales en premios,
              o el pago de $ 60.000
              (pesos uruguayos sesenta mil) en el caso de asistir a un centro público.
            </p>
          </div>

          <div className={styles.term}>
            <h5>¿En qué centro educativo se obtendrá la bonificación en caso de ganar?</h5>
            <p>
              En el centro educativo público o privado (Inicial, primaria o secundaria)
              que se haya matriculado y fuere informado a través de VacantED, pudiendo ser
              cualquiera dentro de Uruguay.
            </p>
          </div>

          <div className={styles.term}>
            <h5>¿Por dónde se comunicará al ganador?</h5>
            <p>
              El sorteo será comunicado en las redes sociales de VacantED y se
              contactará al ganador por correo electrónico y telefónicamente.
            </p>
          </div>

          <div className={styles.term}>
            <h5>¿Qué seguridad tiene el sorteo?</h5>
            <p>
              El mismo será presenciado y validado por escribano público.
            </p>
          </div>

          <div className={styles.term}>
            <h5>La participación en el sorteo, ¿tiene costo?</h5>
            <p>
              Los usuarios de familia podrán participar del sorteo sin costo.
              Buscar centro educativo en VacantED, recibir recomendaciones, realizar consultas,
              solicitar entrevistas y confirmar matriculación no tienen costo para las familias.
              Existen distintos servicios opcionales con costo, tales como la atención
              personalizada.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="foter_wrapper">
      <Footer />
    </div>
  </div>
);

export default TermsAndConditions;
