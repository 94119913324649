import getAllActivities from './getAllActivities';

export function removeDuplicates(arrayWithDuplicates) {
  const seen = new Set();

  const removeDuplicate = arrayWithDuplicates.filter((activity) => {
    const duplicate = seen.has(activity.activity_id);
    seen.add(activity.activity_id);
    return !duplicate;
  });

  return removeDuplicate;
}

export async function assignValueById(arrayOfActivities, activityIdCompare) {
  const activities = await getAllActivities();

  const activitiesWithText = [];

  const mainActivities = removeDuplicates(arrayOfActivities)
    .filter((activity) => activity.activity_type_id === activityIdCompare);

  mainActivities.map((item) => activities.filter((activity) => {
    if (activity.id === item.activity_id) {
      return activitiesWithText.push({
        ...activity,
        description: item.description,
      });
    }

    return activitiesWithText;
  }));

  return activitiesWithText;
}
