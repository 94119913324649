const getObjectValue = (obj, keyString) => {
  const keys = keyString.split('.');
  let value = obj;

  for (let i = 0; i < keys.length; i += 1) {
    if (!value) return null;
    value = value[keys[i]];
  }

  return value;
};

const getObjectKey = (keyString) => {
  const keys = keyString.split('.');
  return keys[keys.length - 1];
};

/**
 * @param {string}    id                              The ID of the current question.
 * @param {Object}    optionData                      Options by id.
 * @param {Object[]}  optionData.question             Question used as example question id.
 * @param {string}    optionData.question[].label     Option label.
 * @param {string}    optionData.question[].value     Option string value.
 * @param {string}    optionData.question[].name      Name for the HTML input.
 * @param {object}    experienceCurrent               Current selections to preserve.
 */
export const onResolveOptions = (id, optionData, experienceCurrent) => {
  const experienceToUse = experienceCurrent;
  let newOptions = [];
  const optionId = getObjectKey(id);
  const optionCurrentValue = getObjectValue(experienceToUse, id);
  // console.log('onResolveOptions', id, optionCurrentValue, optionData, experienceCurrent);
  const auxOption = optionData[optionId];
  if (Array.isArray(optionCurrentValue)) {
    // There's currently a multi selection
    newOptions = auxOption.map((option) => {
      const selected = optionCurrentValue.find((select) => (typeof select === 'object' ? select.value === option.value : select === option.value));
      return ({
        ...option,
        checked: !!selected,
        counter: option.counter ? {
          ...option.counter,
          count: selected?.count ?? 1,
        } : undefined,
        text: option.otherOptionData ? selected?.text : undefined,
      });
    });
  } else if (optionCurrentValue) {
    // There's currently a single selection
    newOptions = auxOption?.map((option) => ({
      ...option,
      checked: option.value === optionCurrentValue.value,
      text: option.otherOptionData ? optionCurrentValue?.text : undefined,
    }));
  } else {
    // No selection - Then checking default value
    newOptions = auxOption?.map((option) => ({
      ...option,
      checked: !!option.checked,
    }));
  }
  return newOptions;
};

export const applicablePages = (pagesList, experience) => {
  const newPages = {};
  pagesList.filter(({ skip }) => !skip || !skip(experience)).forEach((page, ix) => {
    newPages[ix + 1] = page;
  });
  return newPages;
};

export const applicablePagesNew = (pagesList, experience) => (
  pagesList.filter(({ skip }) => !skip || !skip(experience))
);

export const isOtherAndEmpty = (item) => {
  if (typeof item === 'object') {
    return item.value === 'OTHER' && !item.text;
  }
  return false;
};
