import React from 'react';
import PropTypes from 'prop-types';

import Logo from './imgs/facebook.png';

import './styles/facebook-button.scss';

const FacebookButton = ({
  handleToken, disabled, loading, className,
}) => {
  const loginWithFacebook = () => {
    window.FB?.login((response) => {
      if (response.authResponse) {
        // console.log('User logged in successfully:', response);
        handleToken(response.authResponse.accessToken);
      } else {
        // console.log('User cancelled login or did not fully authorize.', response);
      }
    }, { scope: 'public_profile,email' }); // Request permission to access user's email
  };

  const onClick = () => {
    window.FB?.getLoginStatus((response) => {
      // console.log('FB login check', response);
      const { status, authResponse } = response;
      if (status === 'connected' && authResponse?.accessToken) {
        return handleToken(authResponse.accessToken);
      }
      return loginWithFacebook();
    });
  };

  return (
    <button id="facebook-button" type="button" className={`facebook-button ${className ?? ''}`} onClick={onClick} disabled={disabled}>
      <img className="facebook-logo" src={Logo} alt="Facebook" />
      Continuar con Facebook
      {' '}
      {
        loading && (<i className="fa fa-spinner fa-pulse fa-2x" />)
      }
    </button>
  );
};

FacebookButton.propTypes = {
  handleToken: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

FacebookButton.defaultProps = {
  disabled: false,
  loading: false,
  className: null,
};

export default FacebookButton;
