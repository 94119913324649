import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const ExperienceLoading = ({
  className,
  show,
  fullscreen,
  size,
  children,
}) => {
  const classNameLoading = classNames('experience-loading', {
    'experience-loading--fullscreen': fullscreen,
    'experience-loading--small': size === 'small',
  }, className);

  if (show) {
    return (
      <div className={classNameLoading}>
        <div className="experience-loading__spinner" />
        {children && (
          <div className="experience-loading__body">
            {children}
          </div>
        )}
      </div>
    );
  }
  return null;
};

ExperienceLoading.propTypes = {
  show: PropTypes.bool,
  fullscreen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small']),
};

ExperienceLoading.defaultProps = {
  show: false,
  fullscreen: false,
  children: null,
  className: null,
  size: null,
};

export default ExperienceLoading;
