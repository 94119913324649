import React from 'react';

import styles from './styles.module.css';

function DefaultDescription() {
  return (
    <div className={styles.description__container}>
      <div className={styles.center}>
        <div className={styles.paragraph__container}>
          <div className={styles.description__top__holder}>
            <div className={styles.icon__holder}>
              <i className="now-ui-icons media-2_sound-wave" />
            </div>
            <h3>¿Su institución necesita mayor exposición en el mercado objetivo?</h3>
          </div>
          <div className={styles.description__text}>
            <p>
              VACANTED™ pone a disposición una
              plataforma en la cual su institución puede hacer visible de forma
              integral y ordenada toda la información de sus características y servicios brindados.
            </p>
          </div>
        </div>
        <div className={styles.paragraph__container}>
          <div className={styles.description__top__holder}>
            <div className={styles.icon__holder}>
              <i className="now-ui-icons media-1_button-pause" />
            </div>
            <h3>¿Su institución cuenta con vacantes a ocupar en uno o más niveles educativos?</h3>
          </div>
          <div className={styles.description__text}>
            <p>
              VACANTED™ contribuye a la estabilidad financiera
              y eficiencia locativa de su institución haciendo posible el
              ingreso de nuevas familias gracias a la
              mayor exposición en el mercado objetivo y al sistema de postulación de VACANTED™.
            </p>
          </div>
        </div>
        <div className={styles.paragraph__container}>
          <div className={styles.description__top__holder}>
            <div className={styles.icon__holder}>
              <i className="now-ui-icons users_single-02" />
            </div>
            <h3>
              ¿Cómo puede contribuir a su
              institución el sistema de postulación de VACANTED™?

            </h3>
          </div>
          <div className={styles.description__text}>
            <p>
              El sistema de postulación de VACANTED™ permite
              a las familias enviar una comunicación de interés
              en su institución, la que es acompañada por un
              informe socioeconómico de la familia. Luego,
              su institución, según la afinidad analizada
              entre ambas partes podrá descartar o validar
              la postulación comenzando así el proceso tradicional
              de ingreso mediante entrevistas personales.

            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultDescription;
