/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoginPasswordInput from '../input/LoginPasswordInput';

const PasswordError = 'Ingrese su contraseña';

const PasswordForm = ({
  onSubmit, loading, onGoToRecovery,
}) => (
  <Formik
    initialValues={{
      password: '',
    }}
    onSubmit={async (values) => {
      onSubmit(values);
    }}
    validationSchema={Yup.object({
      password: Yup.string().required(PasswordError),
    })}
  >
    {
      ({ errors }) => (
        <Form className="login-modal__form-password">
          <LoginPasswordInput className={`input-text ${errors.password ? 'has-error' : ''}`} name="password" placeholder="Contraseña" autoFocus />
          <button type="submit" className="button-lading" disabled={loading}>
            Continuar
            {' '}
            {
              loading && (<i className="fa fa-spinner fa-pulse fa-2x" />)
            }
          </button>
          <div className="login-modal__form-recovery__link">
            <a
              role="presentation"
              onKeyUp={onGoToRecovery}
              onClick={onGoToRecovery}
              onKeyDown={onGoToRecovery}
            >
              ¿Olvidaste tu contraseña?
            </a>
          </div>
        </Form>
      )
    }
  </Formik>
);

PasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onGoToRecovery: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PasswordForm;
