import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getUrl } from 'urlConfig';

const apiUrl = getUrl('api');
const userData = JSON.parse(localStorage.getItem('vacanted'));

const requestObj = (id) => {
  const request = {
    url: `school/${id}?complete`,
    method: 'GET',
  };

  if (userData) {
    if (userData.token) {
      const { token } = userData;
      request.headers = { authorization: token };
    }
  }

  return request;
};

const apiSlice = createApi({
  reducerPath: 'fetchSchools',
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),

  endpoints: (builder) => ({
    fetchSchool: builder.query({ query: (id) => (requestObj(id)) }),
    fetchSchools: builder.query({
      query: () => ({
        url: 'schools',
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchSchoolQuery, useFetchSchoolsQuery } = apiSlice;

export default apiSlice;
