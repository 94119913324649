import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export default function CharacteristicsDetails({ title, urlIcon }) {
  return (
    <div className={styles.characteristics}>
      <img src={urlIcon} alt="" />
      <span>{title}</span>
    </div>
  );
}

CharacteristicsDetails.propTypes = {
  title: PropTypes.string,
  urlIcon: PropTypes.string,
}.isRequired;
