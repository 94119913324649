import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import PropTypes from 'prop-types';
import './styles.css';

const Spinner = ({ schoolsLoadingState, size, msg }) => (
  <div className="loading-schools-spinner-container">
    <ClipLoader color="#0096ed" loading={schoolsLoadingState} size={size} />
    <p>{msg}</p>
  </div>
);

export default Spinner;

Spinner.propTypes = {
  schoolsLoadingState: PropTypes.bool,
  size: PropTypes.number,
  msg: PropTypes.string,
};

Spinner.defaultProps = {
  schoolsLoadingState: true,
  size: 35,
  msg: '',
};
