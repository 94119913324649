import React, { useState } from 'react';
import {
  MapContainer, TileLayer, Marker, Popup, Pane, Circle,
} from 'react-leaflet';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getMapboxKey } from 'urlConfig';
import ContactModal from 'components/ContactModal/ContactModal';
/* import FavoriteHeart from 'components/Favorite'; */
import { fetchSchoolById } from '../../hooks/SchoolProfileContext/SchoolProfileSlice';
import AddRemoveCandidateButton from '../../pages/Experience/src/components/buttons/add-remove-candidate';
import RequestVisitButton from '../../pages/Experience/src/components/buttons/request-visit';
import { useSearch } from '../../hooks/SearchContext';
import happyMapIcon from './mapIcon';
import './styles.css';
import precio from '../../assets_vacanted/precio.png';
import idioma from '../../assets_vacanted/idiomas.png';
import religion from '../../assets_vacanted/religion.png';
import center from './centerIcon';
import { getAgesMessageToShow } from '../../utils/schools/schoolFunctions';

import specificStyles from './mapView.module.css';

const MapView = ({
  latitude, longitude, heightSize, widthSize, pane, specific,
}) => {
  const dispatch = useDispatch();

  const { schoolsData, filters, locationInfo } = useSearch();
  const schools = schoolsData.filteredData;
  const range = filters['range-value'];
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState();

  function handleOpenContactModal(id, action) {
    dispatch(fetchSchoolById(id));
    setModalAction(action);
    setIsContactModalOpen(true);
  }

  function handleCloseContactModal() {
    setIsContactModalOpen(false);
  }

  /* eslint no-underscore-dangle: 0 */
  const handleClosePopup = (event) => {
    event.target._popup._closeButton.addEventListener('click', (ev) => {
      ev.preventDefault();
    });
  };

  return (
    <div id="page-map" className="vacanted__mapView">
      <MapContainer
        center={[latitude, longitude]}
        zoom={13}
        style={{ maxWidth: widthSize, height: heightSize }}
      >
        <TileLayer url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${getMapboxKey()}`} />
        {pane && (
          <>
            <Marker icon={center} position={[latitude, longitude]} />
            <Pane style={{ zIndex: 100 }}>
              <Circle center={[latitude, longitude]} radius={range * 1000} />
            </Pane>
          </>
        )}
        {specific && (
          <Marker icon={happyMapIcon} position={[specific.latitude, specific.longitude]} key={specific.id} className="map-popup">
            <Popup closeButton maxHeight={250} width={780} className="map-popup">
              <h4 className={`${specificStyles['popup-title']} ${specificStyles['limit-text']}`}>{specific.name}</h4>
              <div className="popup-address">
                <p className={specificStyles['limit-text']}>
                  {`${specific.address}, ${specific.neighborhood.name}`}
                </p>
                <p className="school-public">
                  {specific.city.name}
                </p>
              </div>
              <div className="popup-info">
                <h4 className="school-public">
                  {`${specific.public.name} de Educación ${getAgesMessageToShow(specific.schedules, locationInfo.countryId)}`}
                </h4>
                <div className="item">
                  <p className="info-icon">
                    <img src={precio} alt="Precio" className="icons-item" />
                    {specific.cost.name}
                  </p>
                  {specific.religious ? (
                    <p className="info-icon">
                      <img src={religion} alt="Precio" className="icons-item" />
                      Religioso
                    </p>
                  ) : (
                    <p className="info-icon">
                      <img src={religion} alt="Precio" className="icons-item" />
                      Laico
                    </p>
                  )}
                  {specific.languages.length > 1 ? (
                    <p className="info-icon">
                      <img src={idioma} alt="Idioma" className="icons-item" />
                      {specific.languages.length === 2 ? 'Bilingüe' : 'Trilingüe'}
                    </p>
                  ) : (
                    <p className="info-icon">
                      <img src={idioma} alt="Idioma" className="icons-item" />
                      {specific.language1.name}
                    </p>
                  )}
                </div>
              </div>
              <div className="line" />
              <div className="popup-buttons">
                {/* <FavoriteHeart school={specific} from="mapView" /> */}
                <RequestVisitButton
                  schoolId={specific.id}
                  disabled={specific.contact_disabled || specific.public?.is_public}
                />
                <AddRemoveCandidateButton
                  schoolId={specific.id}
                />
                <ContactModal
                  isOpen={isContactModalOpen}
                  onRequestClose={() => handleCloseContactModal()}
                  schoolName={specific.name}
                  schoolId={specific.id}
                  modalAction={modalAction}
                />
              </div>
            </Popup>
          </Marker>
        )}
        {!specific && schools.map((school) => (
          <Marker
            icon={happyMapIcon}
            position={[school.latitude, school.longitude]}
            key={school.id}
            className="map-popup"
            eventHandlers={{ click: (e) => handleClosePopup(e) }}
          >
            <Popup closeButton maxHeight={250} width={780} className="map-popup">
              <h4 className={`${specificStyles['popup-title']} ${specificStyles['limit-text']}`}>
                <a
                  href={`/school/${school.id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={specificStyles.school__name}
                >
                  {school.name}
                </a>
              </h4>
              <div className="popup-address">
                <p className={specificStyles['limit-text']}>
                  {`${school.address}, ${school.neighborhood.name}`}
                </p>
                {/* <p className="school-public">{school.city.name}</p> */}
              </div>
              <div className="popup-info">
                <h4 className="school-public">
                  {`${school.public.name} de Educación ${getAgesMessageToShow(school.schedules, locationInfo.countryId)}`}
                </h4>
                <div className="item">
                  <p className="info-icon">
                    <img src={precio} alt="Precio" className="icons-item" />
                    {school.cost.name}
                  </p>
                  {school.religious ? (
                    <p className="info-icon">
                      <img src={religion} alt="Precio" className="icons-item" />
                      Religioso
                    </p>
                  ) : (
                    <p className="info-icon">
                      <img src={religion} alt="Precio" className="icons-item" />
                      Laico
                    </p>
                  )}
                  {school.languages.length > 1 ? (
                    <p className="info-icon">
                      <img src={idioma} alt="Idioma" className="icons-item" />
                      {school.languages.length === 2 ? 'Bilingüe' : 'Trilingüe'}
                    </p>
                  ) : (
                    <p className="info-icon">
                      <img src={idioma} alt="Idioma" className="icons-item" />
                      {school.language1.name}
                    </p>
                  )}
                </div>
              </div>
              <div className="line" />

              <div className="popup-buttons">
                {/* <FavoriteHeart school={school} from="mapView" /> */}
                <RequestVisitButton
                  schoolId={school.id}
                  disabled={school.contact_disabled || school.public?.is_public}
                />
                <AddRemoveCandidateButton
                  schoolId={school.id}
                />
                {false && (
                  <button
                    type="button"
                    className={school.contact_disabled || school.public_id === 2 || school.public_id === 7 ? 'button-contact-disabled' : 'button-contact'}
                    onClick={() => handleOpenContactModal(school.id)}
                    disabled={
                      school.contact_disabled || school.public_id === 2 || school.public_id === 7
                    }
                  >
                    Contactar
                  </button>
                )}
                <ContactModal
                  isOpen={isContactModalOpen}
                  onRequestClose={() => handleCloseContactModal()}
                  schoolName={school.name}
                  schoolId={school.id}
                  modalAction={modalAction}
                />
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

MapView.defaultProps = {
  specific: undefined,
};

MapView.propTypes = {
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  widthSize: PropTypes.string.isRequired,
  heightSize: PropTypes.string.isRequired,
  pane: PropTypes.bool.isRequired,
  specific: PropTypes.arrayOf(PropTypes.number),
};

export default MapView;
