import React, { useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import { IoMdClose } from 'react-icons/io';

import style from './styles.module.css';

const Chips = ({ allChipsLevel, setChipsLevel, doNotAllowInfos }) => {
  function deleteLevel(level) {
    const newChipsLevel = allChipsLevel.filter((levelChip) => levelChip !== level);
    setChipsLevel(newChipsLevel);
  }

  useEffect(() => {
    setChipsLevel([]);
  }, [doNotAllowInfos]);

  return (
    <div className={style.chipContainer}>
      {
        allChipsLevel.map((level) => {
          if (level === undefined || level === null) return null;

          return (
            <div className={style.chipContente} key={`levelChips${level}`}>
              <p>{`${level}`}</p>
              <IoMdClose onClick={() => deleteLevel(level)} />
            </div>
          );
        })
      }
    </div>
  );
};

export default Chips;

Chips.propTypes = {
  allChipsLevel: PropTypes.arrayOf(string).isRequired,
  setChipsLevel: PropTypes.func.isRequired,
  doNotAllowInfos: PropTypes.bool.isRequired,
};
