import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill, stroke }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.79662 10.8128L5.20303 12.3769C5.10646 12.4333 5.00967 12.457 4.91267 12.4481C4.81566 12.4391 4.72783 12.4064 4.6492 12.35C4.57058 12.2936 4.5099 12.2199 4.46717 12.1288C4.42442 12.0378 4.41758 11.9372 4.44665 11.8269L5.1351 8.88208L2.84537 6.90003C2.7599 6.82568 2.70542 6.73957 2.68192 6.64172C2.65842 6.54385 2.66461 6.44877 2.7005 6.35647C2.7364 6.26415 2.78811 6.18873 2.85563 6.13018C2.92316 6.07164 3.01546 6.03339 3.13255 6.01545L6.15433 5.75135L7.3274 2.97058C7.37013 2.86717 7.43487 2.79068 7.52162 2.7411C7.60837 2.69153 7.70004 2.66675 7.79662 2.66675C7.89319 2.66675 7.98486 2.69153 8.07162 2.7411C8.15836 2.79068 8.2231 2.86717 8.26583 2.97058L9.4389 5.75135L12.4607 6.01545C12.5778 6.03339 12.6701 6.07164 12.7376 6.13018C12.8051 6.18873 12.8568 6.26415 12.8927 6.35647C12.9286 6.44877 12.9348 6.54385 12.9113 6.64172C12.8878 6.73957 12.8333 6.82568 12.7479 6.90003L10.4581 8.88208L11.1466 11.8269C11.1756 11.9372 11.1688 12.0378 11.1261 12.1288C11.0833 12.2199 11.0227 12.2936 10.944 12.35C10.8654 12.4064 10.7776 12.4391 10.6806 12.4481C10.5836 12.457 10.4868 12.4333 10.3902 12.3769L7.79662 10.8128Z"
      fill={fill}
      stroke={stroke}
    />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
  fill: null,
  stroke: '#1A1A1A',
};

export default Icon;
