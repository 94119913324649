/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const formatAddress = (address) => {
  if (!address) return '';
  const addressArray = address.split(', ');
  return addressArray[0];
};

const SearchProfileRecomendations = ({
  children, onCollapsedToggle, searchProfile, collapsed,
}) => (
  <div className="serach-profile-recommendations">
    <div className="serach-profile-recommendations__header">
      <div className="header__container">
        <div className="header__info">
          <div className="header__search-profile-name">
            <div className="header__search-profile-name__text">{searchProfile.name}</div>
          </div>
          <div className="header__datas">
            <div className="header__data date">
              {/* <i className="fa fa-calendar fa-lg" /> */}
              <img src="https://vacanted-assets-dev.s3.us-east-1.amazonaws.com/icons/icon_calendar.png" alt="fecha" />
              <div className="search-profile-data">
                {/* <div className="search-profile-data__title">Fecha de creación</div> */}
                <div className="search-profile-data__value">
                  {moment(searchProfile.created_at).format('LLL')}
                </div>
              </div>
            </div>
            <div className="header__data">
              {/* <i className="fa fa-map-marker fa-2x" /> */}
              <img src="https://vacanted-assets-dev.s3.us-east-1.amazonaws.com/icons/icon_location.png" alt="ubicacion" />
              <div className="search-profile-data">
                {/* <div className="search-profile-data__title">Dirección ingresada</div> */}
                <div className="search-profile-data__value">
                  {formatAddress(searchProfile.context.location.address)}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="header__data">
            <div className="ellipse" />
            <div className="search-profile-data">
              <div className="search-profile-data__title">Rango de búsqueda</div>
              <div className="search-profile-data__value">{searchProfile.range}</div>
            </div>
          </div> */}
        </div>
        <div
          className="chevron"
        >
          <i
            className={`fa fa-solid fa-chevron-${collapsed ? 'down' : 'up'}`}
            onClick={() => onCollapsedToggle(searchProfile.id)}
          />
        </div>
      </div>
      <hr />
    </div>
    {!collapsed && children}
  </div>

);

SearchProfileRecomendations.propTypes = {
  children: PropTypes.node.isRequired,
  onCollapsedToggle: PropTypes.func,
  collapsed: PropTypes.bool,
  searchProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    context: PropTypes.shape({
      location: PropTypes.shape({
        range_kms: PropTypes.number.isRequired,
        address: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

SearchProfileRecomendations.defaultProps = {
  onCollapsedToggle: null,
  collapsed: false,
};

export default SearchProfileRecomendations;
