import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import passwordRecoveryRequest from 'Authentication/actions/passwordRecovery/passwordRecovery';
import logo from 'assets/icons/VACANTED_iso.png';
import validate from '../RegisterCard/registerFunctions';

// most of the css comes from register styles
import registerStyles from '../RegisterCard/RegisterCard.module.css';
import passwordStyles from './PasswordRecovery.module.css';

function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState({ valid: false, showWarning: false });
  const [emailInputStyles, toggleEmailInputStyles] = useState(registerStyles.input);
  const [recoveryError, setRecoveryError] = useState({ status: false, msg: '', type: 'login' });
  const [emailSuccess, setEmailSuccess] = useState({ status: false, msg: 'Recibirás un correo electrónico para restablecer la contraseña. Puedes cerrar esta ventana.' });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleRedirectRegister = () => navigate('/auth/register');
  const handleRedirectLogin = () => navigate('/auth/login');
  const handleRedirectEmail = () => navigate('/auth/email-recovery');

  useEffect(() => {
    validate.validateEmailPattern(email, setEmailValid);
    setRecoveryError({ status: false, msg: '', type: 'login' });
  }, [email]);

  // check the styles for the inputs
  useEffect(
    () => toggleEmailInputStyles((emailValid.showWarning || recoveryError.status)
      ? registerStyles.invalid__input : registerStyles.input),
    [emailValid.showWarning, recoveryError],
  );

  const handlePasswordRecovery = async () => {
    if (!emailValid.valid) return setEmailValid({ ...emailValid, showWarning: true });

    setLoading(true);
    const response = await passwordRecoveryRequest(email);
    setLoading(false);
    const responseStatus = response.status;

    switch (responseStatus) {
      case 404:
        return setRecoveryError({ status: true, msg: 'Correo no encontrado', type: 'email' });
      case 409:
      case 403:
        return setRecoveryError({ status: true, msg: 'Error: Este usuario no puede recuperar la contraseña', type: 'login' });
      case 400:
        return setRecoveryError({ status: true, msg: `Error: usuario sin contraseña registrado con ${response.data?.provider}`, type: 'login' });
      case 500:
        return setRecoveryError({ status: true, msg: 'Error: correo no enviado', type: 'login' });
      case 202:
        return setEmailSuccess({ ...emailSuccess, status: true });
        // return setTimeout(() => navigate('/auth/login'), 1000);
      default:
        return setRecoveryError({ status: true, msg: 'Error', type: 'login' });
    }
  };

  return (
    <div className={registerStyles.card__container}>
      <div className={registerStyles.logo__container}>
        <img src={logo} alt="Vacanted" />
      </div>
      <h2 className={registerStyles.subtitle}>
        Restablezca su contraseña
      </h2>
      <form className={registerStyles.form}>
        <input
          className={emailInputStyles}
          placeholder="Ingrese su email"
          type="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailValid.showWarning
          && <p className={registerStyles.invalid__input__text}>Formato de correo inválido</p>}
        {recoveryError.status && recoveryError.type === 'email'
          && <p className={registerStyles.invalid__input__text}>{recoveryError.msg}</p>}
        <span className={registerStyles.email__info}>
          Verifica que ingresaste correctamente tu email.
        </span>
        <button
          className={passwordStyles.btn__send}
          type="button"
          onClick={handlePasswordRecovery}
        >
          Restablecer contraseña
        </button>
        <BarLoader loading={loading} color="#3e95e6" width="100%" />
        {emailSuccess.status
          && <p className={registerStyles.successful__input__text}>{emailSuccess.msg}</p>}
        {recoveryError.status && recoveryError.type === 'login'
          && <p className={registerStyles.invalid__input__text}>{recoveryError.msg}</p>}
        <div className={`${registerStyles.form__link} ${passwordStyles.card__footer__link}`}>
          <button type="button" onClick={handleRedirectLogin} className={registerStyles.link__btn}>
            Inicia Sesión
          </button>
          <button type="button" onClick={handleRedirectRegister} className={registerStyles.link__btn}>
            Regístrate
          </button>
        </div>
        <button type="button" onClick={handleRedirectEmail} className={registerStyles.link__btn}>
          ¿No recibiste el correo de confirmación?
        </button>
      </form>
    </div>
  );
}

export default PasswordRecovery;
