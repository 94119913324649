import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import vStyles from '../../FamilyInfo.module.scss';

const tips = [
  {
    title: 'Aclaración',
    content: 'Agregar todos los hijos en etapa estudiantil en los niveles de inicial, primaria, secundaria o bachillerato. Deben agregarse todos los menores, incluso los que aún no están escolarizados.',
  },
];

const PageTenTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
      </div>
    </div>
  ))
);

export default PageTenTips;
