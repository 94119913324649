import apiLogin from 'Authentication/Data/Services/api';

async function loginWithEmail(payload, type, route) {
  try {
    const editedPayload = {
      ...payload,
      type,
      route,
    };
    const response = await apiLogin.post(editedPayload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export default loginWithEmail;
