import React from 'react';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export default function RatingStars({ ratingValue }) {
  return (
    <div className={styles.ratingContainer}>
      <Rating name="read-only" value={ratingValue} readOnly />
      <Typography component="legend">{ratingValue}</Typography>
    </div>
  );
}

RatingStars.propTypes = {
  ratingValue: PropTypes.number,
}.isRequired;
