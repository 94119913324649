import React from 'react';
import PropTypes from 'prop-types';
import SimpleMap from 'components/MapView/SimpleMap';
import { useSelector } from 'react-redux';
import { selectCoords } from 'hooks/SchoolProfileContext/SchoolProfileSlice';
import { useUserData } from 'hooks/UserContext';
import profileSectionConstants from '../profileSectionConstants';
import styles from '../styles.module.css';
import specificStyles from './SchoolProfileMap.module.css';

const SchoolProfileMap = ({ isFetching }) => {
  if (isFetching) return <>...Cargando </>;
  const coords = useSelector(selectCoords);
  const { appLanguage: lan } = useUserData();

  return (
    <section className={`${styles.section} shadow`}>
      <h3 className={styles.sectionTitle}>
        {profileSectionConstants.geoLocation[lan]}
      </h3>
      <hr className={styles.sectionLine} />
      <div className={styles.sectionContentWrapper}>
        <div className={specificStyles.map__container}>
          <SimpleMap
            latitude={coords.lat}
            longitude={coords.lng}
            heightSize="100%"
          />
        </div>
      </div>
    </section>
  );
};

SchoolProfileMap.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};

export default SchoolProfileMap;
