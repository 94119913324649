import React from 'react';
import PropTypes from 'prop-types';
import ListCheckSelector from './src/ListCheckSelector';
import ListRadioSelector from './src/ListRadioSelector';

import './styles/value-selector.scss';
import InfoMessage from './src/InfoMessage';

const ValueSelector = ({
  id, title, subtitle, options, onChange, type, info, maxOptions, infoPlacing,
}) => (
  <div className="value-selector" id={id}>
    {!!title && (
    <div className="value-selector__title">
      {title}
    </div>
    )}
    <div className="value-selector__options">
      {!!subtitle && (
      <div className="value-selector__subtitle">
        {subtitle}
      </div>
      )}
      {
        !!info && infoPlacing === 'top' && (
          <InfoMessage marginBottom>
            {info}
          </InfoMessage>
        )
      }
      {
          type === 'multy'
            ? (
              <ListCheckSelector
                id={title}
                options={options}
                onChange={onChange}
                maxOptions={maxOptions}
              />
            )
            : <ListRadioSelector id={title} options={options} onChange={onChange} />
      }
      {
        !!info && infoPlacing !== 'top' && (
          <InfoMessage marginTop>
            {info}
          </InfoMessage>
        )
      }
    </div>
  </div>
);

ValueSelector.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
      checked: PropTypes.bool,
      counter: PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
      }),
    }),
  ).isRequired,
  type: PropTypes.oneOf(['single', 'multy']),
  maxOptions: PropTypes.number,
  info: PropTypes.string,
  infoPlacing: PropTypes.oneOf(['bottom', 'top']),
};

ValueSelector.defaultProps = {
  id: null,
  type: 'single',
  title: null,
  subtitle: null,
  info: null,
  maxOptions: null,
  infoPlacing: 'bottom',
};

export default ValueSelector;
