const religiousArr = [
  {
    label: 'Laico',
    name: 'secular',
    type: 'religious',
    rValue: false,
  },
  {
    label: 'Religioso',
    name: 'religious',
    type: 'religious',
    rValue: true,
  },
];

const schedulesArr = [
  {},
  {
    name: 'Matutino',
    id: 'morning',
  },
  {
    name: 'Vespertino',
    id: 'afternoon',
  },
  {
    name: 'Nocturno',
    id: 'evening',
  },
  {
    name: 'Doble turno',
    id: 'double_schedule',
  },
  {
    name: 'Extensión horaria',
    id: 'time_extension',
  },
];

const hardArrays = {
  religiousArr,
  schedulesArr,
};

export default hardArrays;
