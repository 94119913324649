import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import sessionStorageServices from 'services/sessionStorageServices';
import ExperienceButton from '../../../../components/button';
import ExprienceMain from '../../../../components/main/view';
import ExperienceContext from '../../../../context/experience-context';
import FlowContext from '../../../../components/flow/context';
import RowThenColumn from '../../../../components/row-then-column';
import DeviceWarningIcon from '../../../../../../../components/Icons/DeviceWarningIcon';
import MaintenanceIcon from '../../../../../../../components/Icons/MaintenanceIcon';

import './styles.scss';

const WarningExperience = ({ experienceData, login, next }) => {
  const {
    setCurrentPageId: setCurrentPageIdOld,
  } = useContext(ExperienceContext);
  const { setCurrentPageId: setCurrentPageIdFlow } = useContext(FlowContext);
  const setCurrentPageId = setCurrentPageIdFlow || setCurrentPageIdOld;
  if (setCurrentPageId) setCurrentPageId('existing_warning');

  const handleLoginClick = () => {
    sessionStorageServices.saveItem('userFrom', 'experience');
    login();
  };

  return (
    <ExprienceMain>
      <div className="existing-experience">
        <div className="existing-experience__heading">
          <DeviceWarningIcon className="existing-experience__heading__icon" />
          <div className="existing-experience__heading__title">
            Ya tenés una búsqueda activa desde este dispositivo con la siguiente información:
          </div>
        </div>
        <div className="existing-experience__details">
          <div className="detail-list">
            <RowThenColumn
              className="detail"
              rowProps={{ style: { gap: '16px' } }}
              columnProps={{ style: { gap: '2px' } }}
            >
              <div className="detail__title">Email</div>
              <div
                className="detail__value"
              >
                {experienceData.email}
              </div>
            </RowThenColumn>
            <div className="detail-list__separator" />
            <RowThenColumn
              className="detail"
              rowProps={{ style: { gap: '16px' } }}
              columnProps={{ style: { gap: '2px' } }}
            >
              <div className="detail__title">Niveles educativos</div>
              <div
                className="detail__value"
              >
                {experienceData.level_labels?.map((label) => (<span>{label}</span>))}
              </div>
            </RowThenColumn>
            {!!experienceData?.season_name && <div className="detail-list__separator" />}
            {!!experienceData?.season_name && (
              <RowThenColumn
                className="detail"
                rowProps={{ style: { gap: '16px' } }}
                columnProps={{ style: { gap: '2px' } }}
              >
                <div className="detail__title">Ingreso</div>
                <div
                  className="detail__value"
                >
                  {experienceData.season_name}
                </div>
              </RowThenColumn>
            )}
          </div>
        </div>
        <div className="existing-experience__explanation">
          <MaintenanceIcon className="existing-experience__explanation__icon" />
          <p className="existing-experience__explanation__text">
            Por el momento no es posible tener dos búsquedas activas
            con un mismo correo electrónico.
          </p>
        </div>
        <div className="existing-experience__actions">
          <div className="existing-experience__actions__description">
            <div className="existing-experience__actions__description__question">¿Cómo podés continuar?</div>
            <div className="existing-experience__actions__description__option">
              <span>Podés iniciar sesión</span>
              {' '}
              y continuar la búsqueda que tienes activa.
            </div>
            <div className="existing-experience__actions__description__option">
              <span>Podés crear una búsqueda nueva</span>
              {' '}
              con otro correo electrónico.
            </div>
          </div>
          <RowThenColumn
            className="existing-experience__actions__buttons"
            minRowWidth="584px"
          >
            <ExperienceButton
              hirachy="success"
              onClick={handleLoginClick}
              reportClickEvent="existing_experience_continue_search"
            >
              Iniciar sesión y continuar búsqueda
            </ExperienceButton>
            <ExperienceButton
              hirachy="default"
              onClick={next}
              reportClickEvent="existing_experience_new_search"
            >
              Crear una búsqueda nueva
            </ExperienceButton>
          </RowThenColumn>
        </div>
      </div>
    </ExprienceMain>
  );
};

WarningExperience.propTypes = {
  experienceData: PropTypes.shape({
    email: PropTypes.string,
    level_labels: PropTypes.arrayOf(PropTypes.string),
    season_name: PropTypes.string,
  }).isRequired,
  login: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

export default WarningExperience;
