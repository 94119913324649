import React from 'react';
import PropTypes from 'prop-types';
import { MdLanguage } from 'react-icons/md';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { FaPrayingHands } from 'react-icons/fa';
import cardStyles from './cardStyles.module.css';

function IconsWrapper({
  price, language, religious,
}) {
  return (
    <div className={`${cardStyles.icons__container} icons-container-wrapper`}>
      { price !== '' && (
      <div className={`${cardStyles.icon} d-inline-flex align-items-center`}>
        <div className="icon-card-container">
          <RiMoneyDollarCircleLine size={24} color="#677897" />
        </div>
        <strong className={cardStyles.icon__text}>{price}</strong>
      </div>
      )}
      <div className={`${cardStyles.icon} d-inline-flex align-items-center`}>
        <div className="icon-card-container">
          <FaPrayingHands size={22} color="#677897" />
        </div>
        <strong className={cardStyles.icon__text}>{religious === true ? 'Religioso' : 'Laico'}</strong>
      </div>
      {
        language !== '' && (
        <div className={`${cardStyles.icon} d-inline-flex align-items-center`}>
          <div className="icon-card-container">
            <MdLanguage size={22} color="#677897" />
          </div>
          <strong className={cardStyles.icon__text}>{language}</strong>
        </div>
        )
      }
    </div>
  );
}

export default IconsWrapper;

IconsWrapper.defaultProps = {
  language: '',
  price: '',
  religious: false,
};

IconsWrapper.propTypes = {
  language: PropTypes.string,
  price: PropTypes.string,
  religious: PropTypes.bool,
};
