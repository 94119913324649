/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'hooks/ApplicationContext';
import { useUserData } from 'hooks/UserContext';
import Flow from '../../components/flow';
import FlowContext from '../../components/flow/context';
import finalizeExperienceStep from '../../components/flow/steps/finalizeExperience';
import paths from '../../../../../routes/paths';
import useExperience from '../../hooks/useExperience';
import Congrats from './congrats';

const FinalizeExperience = () => {
  const navigate = useNavigate();
  const {
    loadingContext: { setPageLoading },
    snackbarContext: { setSnackbar, closeSnackbar },
  } = useAppContext();
  const {
    activeExperience,
    updateActiveExperience,
    schoolToRegister,
    setSchoolToRegister,
  } = useUserData();

  const [finalizeData, setFinalizeData] = useState({});
  const [showCongrats, setShowCongrats] = useState(false);

  const { onFinalizeExperience } = useExperience();
  const [currentPageId, setCurrentPageId] = useState();

  useEffect(() => () => {
    setSchoolToRegister(null);
    if (typeof window.Tawk_API?.hideWidget === 'function') window.Tawk_API.hideWidget();

    return () => {
      if (typeof window.Tawk_API?.showWidget === 'function') window.Tawk_API.showWidget();
    };
  }, []);

  useEffect(() => {
    if (activeExperience === null && !showCongrats) {
      navigate('/');
    } else if (activeExperience) {
      const flowData = {
        candidates: activeExperience.candidates,
      };
      if (schoolToRegister) {
        flowData.forceSchool = true;
        flowData.didYouRegister = { value: '1' };
        flowData.registeredSchool = schoolToRegister;
      }
      setFinalizeData((prevExperience) => ({
        ...flowData,
        ...prevExperience,
      }));
    }
  }, [activeExperience]);

  useEffect(() => {
    if (currentPageId) {
      window.history.replaceState(null, '', `${window.location.pathname}?page=${currentPageId}`);
    }
  }, [currentPageId]);

  const onChangeStepValue = (key, value, currentExp, merge) => {
    // console.log('onChangeStepValue3', key, value, currentExp, merge);
    setFinalizeData((prevExperience) => ({
      ...prevExperience,
      [key]: merge ? { ...prevExperience[key], ...value } : value,
    }));
  };

  const steps = [
    finalizeExperienceStep(finalizeData, onChangeStepValue),
  ];

  const onEnd = async (data) => {
    setPageLoading(true);
    onFinalizeExperience(activeExperience.id, data)
      .then(() => {
        updateActiveExperience();
        setCurrentPageId('congrats');
        setShowCongrats(true);
      })
      .catch(() => {
        setSnackbar({
          hirachy: 'error',
          text: 'Ocurrió un error al finalizar tu búsqueda.',
          action: 'Entendido',
          onClick: closeSnackbar,
        });
      })
      .finally(() => setPageLoading(false));
  };

  return (
    <FlowContext.Provider
      value={{
        isFlow: true,
        currentData: finalizeData,
        setCurrentPageId,
        steps,
        onEnd: () => onEnd(finalizeData),
        onExit: showCongrats ? null : () => {
          navigate(paths.EXPERIENCE_RECOMMENDATIONS);
        },
      }}
    >
      {!showCongrats && <Flow />}
      {!!showCongrats && <Congrats hasBenefits={!!finalizeData.benefitsData?.hasBenefits} />}
    </FlowContext.Provider>
  );
};

export default FinalizeExperience;
