import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { shouldDisplayGoogleReviewsSection } from 'urlConfig';
import getGoogleReview from 'utils/GoogleReview/getGoogleReview';
import ReviewerCard from 'components/ReviewerCard';

import generateRandomString from 'utils/stringManipulation';
import { selectSchool } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import ProfileSection from '../ProfileSection';

import styles from './styles.module.css';

function GoogleReviews({ isFetching }) {
  const [useReviews, setUseReviews] = useState();

  const descriptionData = useSelector(selectSchool);

  useEffect(() => {
    if (!descriptionData.place_id) return;
    getGoogleReview(descriptionData.place_id, 'es')
      .then((resp) => {
        if (shouldDisplayGoogleReviewsSection()) setUseReviews(resp);
      })
      .then((err) => err);
  }, []);

  if (isFetching) {
    return <>...loading</>;
  }

  if (!useReviews) {
    return <>{' '}</>;
  }

  return (
    <ProfileSection title="Reseñas">
      <div className={styles.reviwesContainer}>
        {
          useReviews.map((review) => (
            <ReviewerCard
              reviewer={review}
              key={generateRandomString()}
            />
          ))
        }
      </div>
    </ProfileSection>
  );
}

GoogleReviews.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};

export default GoogleReviews;
