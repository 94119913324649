import { useSearch } from 'hooks/SearchContext';
import React from 'react';
import LocationFilters from './LocationFilters/LocationFilters';
// import SaveFilters from './SaveFilters';
import SmartFilters from './SmartFilters/SmartFilters';

const Sidebar = () => {
  const { refFilter } = useSearch();

  return (
    <div className="sidebar-wrapper col-lg-4 col-sm-12" style={{ position: 'relative' }}>
      <div className="sidebar-address">
        <LocationFilters />
      </div>
      {/* <div className="sidebar-big-container responsive-none shadow ">
        <SaveFilters />
      </div> */}
      <div className="smart__filters" ref={refFilter}>
        <SmartFilters />
      </div>
    </div>
  );
};

export default Sidebar;
