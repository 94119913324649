import React from 'react';
import PropTypes from 'prop-types';
import registerStyles from './RegisterCard.module.css';

function ChargeBar({
  width,
  fill,
}) {
  return (
    <svg
      className={registerStyles.password__svg}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 35.618 35.619"
    >
      <path
        fill={fill}
        d="M35.618,14.826v5.965c0,2.027-1.642,3.668-3.666,3.668H3.666C1.643,24.458,0,22.818,0,20.791v-5.965c0-2.022,1.643-3.666,3.666-3.666h28.285C33.977,11.16,35.618,12.802,35.618,14.826z"
      />
    </svg>
  );
}

export default ChargeBar;

ChargeBar.propTypes = {
  // className: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
};
