import React from 'react';
import Card from '../Card/Card';
import { useSearch } from '../../hooks/SearchContext';
import './styles.css';

const CardList = () => {
  const { schoolsData, schoolsLoadingState } = useSearch();
  const schools = schoolsData.filteredData;

  const renderNoSchoolsMessage = () => (
    !schoolsLoadingState
      && (
        <div className="shadow empty-search-container">
          <p>No se han encontrado centros educativos con estos filtros</p>
        </div>
      )
  );

  return (
    <div>
      {schools?.length > 0 && schools
        .map((school) => (
          <Card
            key={school.id}
            school={school}
          />
        ))}
      {!schoolsLoadingState && schools.length < 1 && renderNoSchoolsMessage()}
    </div>
  );
};

export default CardList;
