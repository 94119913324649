import api from './api';

const features = {
  EXPERIENCES: {
    key: 'experiences',
    defaultValue: 'ON',
  },
};

const checkFeature = async (feature) => {
  try {
    const savedValue = sessionStorage.getItem(feature.key);
    if (savedValue) return savedValue;

    const response = await api.get(`/features/${feature.key}`);
    const { value } = response.data;
    sessionStorage.setItem(feature.key, value);
    return value;
  } catch (_) {
    sessionStorage.setItem(feature.key, feature.defaultValue);
    return feature.defaultValue;
  }
};

const experiencesEnabled = async () => {
  const value = await checkFeature(features.EXPERIENCES);
  return value === 'ON';
};

export default {
  experiencesEnabled,
};
