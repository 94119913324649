import React from 'react';
import './styles.scss';

import aniiImage from '../../assets_vacanted/logo_anni.png';
import ingenioImage from '../../assets_vacanted/logo_ingenio.png';

const SupportLogos = () => (
  <div className="support-logos">
    <div className="support-logos__title">Somos un emprendimiento apoyado por:</div>
    <div className="support-logos__logos">
      <img alt="Ingenio" src={ingenioImage} className="logo-ingenio" height={80} />
      <img alt="Anii" src={aniiImage} className="logo-anii" height={45} />
    </div>
  </div>
);

export default SupportLogos;
