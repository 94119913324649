import { initializeApp, getApps, getApp } from 'firebase/app';

import {
  getAuth, GoogleAuthProvider, signInWithPopup, getIdToken,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
if (!firebaseConfig?.projectId) throw Error('Missing or invalid REACT_APP_FIREBASE_CONFIG env variable');

const app = getApps().length ? getApp() : initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export default async function googleLoginWithFireBase() {
  const data = {};
  try {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope('email');
    googleProvider.addScope('profile');
    await signInWithPopup(auth, googleProvider)
      .then((result) => {
        data.user = result.user;
      });
    await getIdToken(auth.currentUser, /* force refresh */ true)
      .then((idToken) => {
        data.token = idToken;
      });
  } catch (err) {
    data.err = err;
  }
  return data;
}

export async function registerAnalytics(tag, params) {
  // console.log('Logging event', tag, params);
  return logEvent(analytics, tag, params);
}
