import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SchoolItemContext from '../../context/school-item-context';

const SchoolPicture = ({ picture: pictureProp, alt: altProp }) => {
  const { picture: pictureContext, name: altContext } = useContext(SchoolItemContext);
  const alt = altProp || altContext;
  const src = pictureProp || pictureContext;

  return (
    <div className="school-image">
      <img alt={alt} className="school-item__picture__img" src={src} />
    </div>
  );
};

SchoolPicture.propTypes = {
  picture: PropTypes.string,
  alt: PropTypes.string,
};

SchoolPicture.defaultProps = {
  picture: null,
  alt: null,
};

export default SchoolPicture;
