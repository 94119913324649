import React from 'react';
import PropTypes from 'prop-types';

import generateRandomString from 'utils/stringManipulation';

import CharacteristicsDetails from 'components/CharacteristicsDetails/CharacteristicsDetails';

import styles from './styles.module.css';

export default function SingleSectionComponent({ arrayOfPropertys }) {
  let lyrics = '';

  if (arrayOfPropertys.length === 0) {
    return <>{' '}</>;
  }

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.containerOfActivities}>
        {
          arrayOfPropertys.map((activity) => {
            if (activity.description) {
              lyrics += `${activity.description} `;
            }
            if (activity.activity !== 'description') {
              return (
                <CharacteristicsDetails
                  key={generateRandomString()}
                  title={activity.activity}
                  urlIcon={activity.icon}
                />
              );
            }
            return '';
          })
        }
      </div>
      <hr />
      <p>
        {lyrics}
      </p>
    </div>
  );
}

SingleSectionComponent.propTypes = {
  title: PropTypes.string,
  arrayOfPropertys: PropTypes.arrayOf(PropTypes.object),
}.isRequired;
