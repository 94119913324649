/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { parsedDataInStorage } from 'utils/Storage/storageFunctions';
import api from 'services/api';

const PrivateRoute = ({ Component, ...props }) => {
  const userInLocalStorage = parsedDataInStorage('vacanted');
  if (userInLocalStorage) {
    api.checkFamilySessionIsValid();
    return <Component {...props} />;
  }
  return <Navigate to="/" />;
};

export default PrivateRoute;
