import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import style from './styles.module.css';

// chamar a API para disparar o EMAIL

Modal.setAppElement('#root');

function CannotPostulateModal({ isOpen, onRequestClose }) {
  const [infoAboutPostulation, setInfoAboutPostulation] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={style['react-modal-overlay']}
      className={style['postulation__modal-wrapper']}
    >
      <p className={style['postulation__modal-header']}>
        Lamentablemente esta Institución
        no permite
        postulaciones en este momento a través de VACANTED™
      </p>
      <div className={style.radio_inputs}>
        <div className={style.checkbox__holder}>
          <input
            type="checkbox"
            className={style.vacantest__checkbox}
            checked={infoAboutPostulation}
            onChange={(e) => setInfoAboutPostulation(e.target.checked)}
          />
        </div>
        <div className={style.span__holder}>
          <span className={style.input_span}>
            Deseo recibir un aviso cuando las postulaciones de esta
            institución estén habilitadas en VACANTED™
          </span>
        </div>
      </div>
      <button
        type="button"
        className={style.postulation__close_btn}
        onClick={() => onRequestClose(infoAboutPostulation)}
      >
        ACEPTAR
      </button>
    </Modal>
  );
}

CannotPostulateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default CannotPostulateModal;
