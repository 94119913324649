import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CircularActivity from 'components/CircularActivity';

import generateRandomString from 'utils/stringManipulation';
import { assignValueById } from 'utils/Activities/activitiesRemoveDuplicates';
// import getAllActivities from 'utils/Activities/getAllActivities';
import ProfileSection from '../ProfileSection';
import { MainFeaturesData as mainFeatures, activityData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';

import Card from './Card/Card';
import styles from './styles.module.css';

function MainFeatures({ isFetching, sections }) {
  const [useAllactivities, setUseAllActivities] = useState([]);
  const [useDescription, setUseDescription] = useState('');
  const mainFeaturesList = useSelector(mainFeatures);
  const schoolActivities = useSelector(activityData);
  let lyrics = '';

  useEffect(() => {
    assignValueById(schoolActivities, 1).then((array) => {
      const activityFilter = array.find((activities) => activities.id === 142);
      const description = !activityFilter?.description ? '' : activityFilter?.description;
      setUseAllActivities(array);
      setUseDescription(description);
    });
  }, []);

  if (isFetching && !useAllactivities) {
    return <>Loading... </>;
  }

  if (useAllactivities.length === 0) {
    return (
      <ProfileSection
        title="Características principales"
        subtitleLyrics={{
          subtitle: 'teste',
          lyrics: useDescription,
        }}
      >
        <div className={styles.cardListContainer}>
          {mainFeaturesList.map((feat) => (
            <div key={feat.name} className={styles.cardBlock}>
              <Card
                title={feat.title}
                description={feat.name}
                icon={feat.icon}
                sections={sections}
              />
            </div>
          ))}
        </div>
      </ProfileSection>
    );
  }

  return (
    <ProfileSection
      title="Características principales"
      subtitleLyrics={{
        subtitle: '',
        lyrics: useDescription,
      }}
    >
      <div className={styles.cardListContainer}>
        {mainFeaturesList.map((feat) => (
          <div key={feat.name} className={styles.cardBlock}>
            <Card title={feat.title} description={feat.name} icon={feat.icon} sections={sections} />
          </div>
        ))}
      </div>
      <div className={styles.activityListContainer}>
        {
          useAllactivities.map((activity) => {
            if (activity.id === 142) return null;

            if (activity.description) {
              lyrics += `${activity.description} `;
            }

            return (
              <CircularActivity
                key={generateRandomString()}
                title={activity.activity}
                icon={activity.icon}
              />
            );
          })
        }
      </div>
      <p className={styles.activitiesLyrics}>
        {lyrics}
      </p>
    </ProfileSection>
  );
}

MainFeatures.defaultProps = {
  sections: [],
};

MainFeatures.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape()),
};

export default MainFeatures;
