import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ExperienceButton from '../../button';
import ExperienceProgressFooterBar from '../../progress-bar';
import FlowContext from '../context';

import './flow-footer.scss';

const FlowFooter = () => {
  const {
    steps,
    currentStep,
    descreasePage,
    increasePage,
    onEnd,
    allowForwardAction,
    customForwardAction,
    backActionOnFirstPage,
  } = useContext(FlowContext);

  const progressBar = Array.from(
    { length: steps.length },
    (_, index) => {
      if (index < currentStep.index) return 100;
      if (index === currentStep.index) {
        return (((currentStep.pageIndex + 1) * 100) / currentStep.applicablePages.length);
      }
      return 0;
    },
  );

  const isFirstPage = () => currentStep.index === 0 && currentStep.pageIndex === 0;

  const isLastPage = () => currentStep.index === steps.length - 1
    && currentStep.pageIndex === currentStep.applicablePages.length - 1;

  return (
    <div className="experience-footer">
      <div className="experience-footer__progress">
        {
          progressBar.map((progress) => (
            <ExperienceProgressFooterBar key={uuidv4()} progress={progress} />
          ))
        }
      </div>
      <div className="experience-footer__actions">
        <ExperienceButton
          id="experience-button-back"
          chevron="left"
          onClick={isFirstPage() ? backActionOnFirstPage : descreasePage}
          disabled={isFirstPage() && !backActionOnFirstPage}
        >
          Atrás
        </ExperienceButton>

        {!isLastPage() && (
          <ExperienceButton id="experience-button-next" chevron="right" hirachy="primary" onClick={customForwardAction || increasePage} disabled={!allowForwardAction}>
            Siguiente
          </ExperienceButton>
        )}
        {!!isLastPage() && (
          <ExperienceButton id="experience-button-next" chevron="right" hirachy="primary" onClick={customForwardAction || onEnd} disabled={!allowForwardAction}>
            Finalizar
          </ExperienceButton>
        )}
      </div>
    </div>
  );
};

export default FlowFooter;
