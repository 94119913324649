import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import countryService from 'services/countryService';
import vStyles from '../../FamilyInfo.module.scss';

const tips = [
  {
    title: 'Ejemplos de situaciones especiales',
    content: 'Compra de medicamentos especiales por $ 3.000 mensuales o cuota de pago de préstamo por $ 5.000 mensuales',
  },
  {
    title: 'Moneda',
    content: `Recuerde que los montos se expresan en ${countryService.getCurrencySymbolWithDescription()}.`,
  },
];

const PageTenTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
      </div>
    </div>
  ))
);

export default PageTenTips;
