/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { useUserData } from 'hooks/UserContext';
/* import FavoriteHeart from 'components/Favorite'; */
import constants from './upperLeftActionsConstants';
import styles from './styles.module.css';

const renderWebsiteBtn = (website, text) => (
  <div className={styles.ula__btn}>
    <a
      href={website}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  </div>
);

const renderDisabledBtn = (text) => (
  <div className={styles.ula__btn__disabled}>
    <p>{text}</p>
  </div>
);

const UpperLeftActions = ({
  website, schoolObj, /* setMsg,  setNotLoggedAction, */
}) => {
  const { appLanguage: lang } = useUserData();
  const hasWebsite = !!website;

  /* const updateNotLoggedModalData = () => {
    setMsg('Favorito');
    setNotLoggedAction('Guardar');
  }; */

  if (schoolObj.place_id) {
    return (
      <div className={styles.upperLeftActions__container}>
        {hasWebsite
          ? renderWebsiteBtn(website, constants.website[lang])
          : renderDisabledBtn(constants.website[lang])}
        <div className={styles.ula__btn}>
          <a
            href={`https://search.google.com/local/writereview?placeid=${schoolObj.place_id}`}
            target="_blank"
            rel="noreferrer"
          >
            {constants.review[lang]}
          </a>
        </div>
        {/* <div className={styles.ula__btn}>
          <div className={styles.svg__container}>
            <button
              type="button"
              className={styles.noBtn}
              onClick={updateNotLoggedModalData}
            >
              <FavoriteHeart school={schoolObj} from={`schoolProfile-${schoolObj.id}`} />
            </button>
          </div>
        </div> */}
      </div>
    );
  }

  return (
    <div className={styles.upperLeftActions__container}>
      {hasWebsite
        ? renderWebsiteBtn(website, constants.website[lang])
        : renderDisabledBtn(constants.website[lang])}
      <div className={styles.ula__btn__disabled}>{constants.review[lang]}</div>
      {/* <div className={styles.ula__btn}>
        <div className={styles.svg__container}>
          <button
            type="button"
            className={styles.noBtn}
            onClick={() => setMsg('Favorito')}
          >
            <FavoriteHeart school={schoolObj} from={`schoolProfile-${schoolObj.id}`} />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default UpperLeftActions;

UpperLeftActions.defaultProps = {
  website: '',
};

UpperLeftActions.propTypes = {
  website: PropTypes.string,
  schoolObj: PropTypes.shape().isRequired,
  /* setMsg: PropTypes.func.isRequired, */
  /* setNotLoggedAction: PropTypes.func.isRequired, */
};
