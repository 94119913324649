import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';
import './styles.css';

const MapOrSchoolToggleMin = ({ toggle, loading, schools }) => {
  const selected = {
    backgroundColor: '#e8ecf0',
    color: '#333333',
  };

  const unSelected = {
    backgroundColor: '#fff',
    color: '#333333',
  };

  const [btnStyles, setBtnStyles] = useState({
    cards: selected,
    map: unSelected,
  });

  function renderMessage() {
    if (schools.length === 1) return ' Institución';

    return ' Instituciones';
  }

  function handleClick({ target }) {
    const { name } = target;

    if (name === 'cards') {
      toggle(true);
      setBtnStyles({ cards: selected, map: unSelected });
    } else {
      toggle(false);
      setBtnStyles({ cards: unSelected, map: selected });
    }
  }

  function getNrOfSchools() {
    return (loading) ? <ClipLoader color="#0096ed" loading={loading} size={15} />
      : schools.length;
  }

  return (
    <div className="toggle-container">
      <div className="nr__of__schools">
        <p>
          <span>{getNrOfSchools()}</span>
          {renderMessage()}
        </p>
      </div>
      <div className="toggle__buttons__container">
        <button
          name="cards"
          type="button"
          className="map__toggle__button"
          style={btnStyles.cards}
          onClick={handleClick}
        >
          Vista Fichas
        </button>
        <button
          name="map"
          type="button"
          className="map__toggle__button"
          style={btnStyles.map}
          onClick={handleClick}
        >
          Vista Mapa
        </button>
      </div>
    </div>
  );
};

export default MapOrSchoolToggleMin;

MapOrSchoolToggleMin.propTypes = {
  toggle: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  schools: PropTypes.instanceOf(Array).isRequired,
};
