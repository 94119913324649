import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.8718 13.6666C4.54039 13.6666 4.25668 13.5486 4.02068 13.3126C3.78467 13.0766 3.66667 12.7929 3.66667 12.4615V3.99999H3.5C3.35833 3.99999 3.23958 3.95206 3.14375 3.85618C3.04792 3.76031 3 3.64151 3 3.49978C3 3.35806 3.04792 3.23933 3.14375 3.14361C3.23958 3.04788 3.35833 3.00001 3.5 3.00001H5.99998C5.99998 2.83677 6.05746 2.69767 6.17242 2.58271C6.28737 2.46776 6.42647 2.41028 6.58972 2.41028H9.41025C9.57349 2.41028 9.71259 2.46776 9.82755 2.58271C9.94251 2.69767 9.99998 2.83677 9.99998 3.00001H12.5C12.6416 3.00001 12.7604 3.04795 12.8562 3.14383C12.9521 3.23971 13 3.35851 13 3.50023C13 3.64196 12.9521 3.76069 12.8562 3.85641C12.7604 3.95213 12.6416 3.99999 12.5 3.99999H12.3333V12.4615C12.3333 12.7929 12.2153 13.0766 11.9793 13.3126C11.7433 13.5486 11.4596 13.6666 11.1282 13.6666H4.8718ZM11.3333 3.99999H4.66665V12.4615C4.66665 12.5213 4.68588 12.5705 4.72435 12.609C4.76282 12.6474 4.81197 12.6667 4.8718 12.6667H11.1282C11.188 12.6667 11.2372 12.6474 11.2756 12.609C11.3141 12.5705 11.3333 12.5213 11.3333 12.4615V3.99999ZM6.76945 11.3333C6.91117 11.3333 7.02989 11.2854 7.12562 11.1896C7.22135 11.0937 7.26922 10.975 7.26922 10.8333V5.83331C7.26922 5.69166 7.22128 5.57291 7.1254 5.47708C7.02952 5.38125 6.91072 5.33333 6.769 5.33333C6.62727 5.33333 6.50854 5.38125 6.41282 5.47708C6.31709 5.57291 6.26923 5.69166 6.26923 5.83331V10.8333C6.26923 10.975 6.31717 11.0937 6.41305 11.1896C6.50892 11.2854 6.62772 11.3333 6.76945 11.3333ZM9.23097 11.3333C9.3727 11.3333 9.49143 11.2854 9.58715 11.1896C9.68287 11.0937 9.73073 10.975 9.73073 10.8333V5.83331C9.73073 5.69166 9.68279 5.57291 9.58692 5.47708C9.49105 5.38125 9.37225 5.33333 9.23052 5.33333C9.08879 5.33333 8.97007 5.38125 8.87435 5.47708C8.77862 5.57291 8.73075 5.69166 8.73075 5.83331V10.8333C8.73075 10.975 8.77869 11.0937 8.87457 11.1896C8.97044 11.2854 9.08924 11.3333 9.23097 11.3333Z"
      fill={fill}
    />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
  fill: '#1A1A1A',
};

export default Icon;
