import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export default function CircularActivity({ title, icon }) {
  return (
    <div className={styles.circularContainer}>
      <div>
        <img src={icon} alt="" />
      </div>
      <p>{title}</p>
    </div>
  );
}

CircularActivity.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
}.isRequired;
