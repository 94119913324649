// React-router tem um comportamento padrao de nao redirecionar paginas para o topo
// esta funcao faz renderiza paginas no topo no redirecionamento

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
