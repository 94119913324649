import React from 'react';

function useBouncer(fn, delay = 0) {
  const ref = React.useRef({});

  ref.current.fn = fn;

  const bouncer = React.useCallback(
    (...args) => {
      if (ref.current.timeout) {
        clearTimeout(ref.current.timeout);
      }

      ref.current.timeout = setTimeout(() => {
        ref.current.fn(...args);
        ref.current.timeout = undefined;
      }, delay);
    },
    [delay],
  );

  return bouncer;
}

export default useBouncer;
