/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import '../styles/list-selector.scss';

const ListSelector = ({ list, onClick }) => (
  <div className="list-selector">
    <div className="list-selector__label">
      Seleccioná una de la lista
    </div>

    <div className="list-selector__list">
      {
        !!list.length && list.map(({ id, name, checked }) => (
          <div
            key={name}
            className={`list-selector__item ${checked ? 'list-selector__item--checked' : ''}`}
            onClick={() => onClick(id)}
          >
            <div className="list-selector__item__label">{name}</div>
          </div>
        ))
      }
    </div>
  </div>
);

ListSelector.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      checked: PropTypes.bool,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ListSelector;
