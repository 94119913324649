import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserData } from 'hooks/UserContext';
import loginWithGoogle from 'Authentication/actions/loginWithGoogle/loginWithGoogle';
import loginWithProvider from 'Authentication/actions/loginWithProvider';
import saveUserData from 'Authorization/storage/saveUserData';
import loginController from 'utils/Controllers/loginController';
import apiLogin, { apiRegistry, apiPasswordRecovery, apiPasswordReset } from '../../../../Authentication/Data/Services/api';
import UserNameForm from '../forms/UserNameForm';
import PasswordForm from '../forms/PasswordForm';
import SignInForm from '../forms/SignInForm';
import RecoveryForm from '../forms/RecoveryForm';
import ActivationForm from '../forms/ActivationForm';
import nameSteps from '../steps';

const useAuthentication = (defaultLoginStep, onClose) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUserData } = useUserData();

  const [step, setStep] = useState();
  const [previousStep, setPreviousStep] = useState(null);
  const [userName, setUserName] = useState('');
  const [hashedKey, setHashedKey] = useState(null);
  const [verifyKey, setVerifyKey] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [loading, setLoading] = useState({ google: false, facebook: false, login: false });

  const loadingOn = (type) => {
    setLoading({
      ...loading,
      [type]: true,
    });
  };

  const loadingOff = (type) => {
    setLoading({
      ...loading,
      [type]: false,
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const defaultEmail = searchParams.get('email');
    const defaultHashedKey = searchParams.get('hashed_key');
    const defaultVerifyKey = searchParams.get('verify_key');

    if (userName !== defaultEmail) {
      setUserName(defaultEmail);
    }
    if (hashedKey !== defaultHashedKey) {
      setHashedKey(defaultHashedKey);
    }
    if (verifyKey !== defaultVerifyKey) {
      setVerifyKey(defaultVerifyKey);
    }

    setStep(defaultLoginStep ?? nameSteps.STEP_USER_NAME);
  }, []);

  useEffect(() => {
    if (step === nameSteps.STEP_PASSWORD || step === nameSteps.STEP_SIGN_IN) {
      setPreviousStep(nameSteps.STEP_USER_NAME);
    } else {
      setPreviousStep(null);
    }
  }, [step]);

  const onSubmitUserName = async (values) => {
    try {
      loadingOn('login');
      const { userName: email } = values;
      await apiLogin.checkLoginWithEmail({ email });
      setUserName(email);
      setStep(nameSteps.STEP_PASSWORD);
    } catch (e) {
      if (e?.response?.status === 404) {
        setUserName(values.userName);
        setStep(nameSteps.STEP_SIGN_IN);
      } else if (e?.response?.status === 400) {
        setErrorMessage(`Inicie sesión con ${e.response.data?.provider}`);
      } else if (e?.response?.status === 401) {
        setErrorMessage('Inicie sesión en el área correcta');
      } else {
        setErrorMessage('Ocurrió un error, inténtelo de nuevo');
      }
    } finally {
      loadingOff('login');
    }
  };

  const successfulLogin = () => {
    loadingOff('login');
    if (onClose) onClose();
  };

  const onSubmitPassword = async (values) => {
    const { password } = values;
    try {
      loadingOn('login');
      const response = await apiLogin.post({ email: userName, password, type: 'user' });
      if (response.status === 201) {
        const loginData = response.data;
        // const { token, user, routes, active_experience } = loginData;

        const userDataFromLogin = {
          loginStatus: true,
          data: loginData,
        };

        saveUserData(loginData);

        await loginController(
          { userData: userDataFromLogin },
          navigate,
          () => setUserData(userDataFromLogin),
        );
        successfulLogin();
      }
    } catch (e) {
      setErrorMessage('Usuario y contraseña incorrectos');
    } finally {
      loadingOff('login');
    }
  };

  const onSubmitSignIn = async (values) => {
    const { name, password } = values;
    try {
      loadingOn('login');
      const response = await apiRegistry.post({
        name,
        email: userName,
        password,
      });
      if (response.status === 201) {
        setHashedKey(response.data?.hashed_key);
        setStep(nameSteps.STEP_ACTIVATION);
      }
    } catch (e) {
      setErrorMessage('Ocurrió un error, inténtelo de nuevo');
    } finally {
      loadingOff('login');
    }
  };

  const onSubmitActivation = async (values) => {
    const { activationdNumber } = values;
    try {
      loadingOn('login');
      await apiRegistry.userActivate({
        email: userName,
        code: Number(activationdNumber),
        hashedKey,
      });
      setStep(nameSteps.STEP_PASSWORD);
    } catch (e) {
      setErrorMessage('Ocurrió un error, inténtelo de nuevo');
    } finally {
      loadingOff('login');
    }
  };

  const onPasswordRecovery = async () => {
    try {
      loadingOn('login');
      await apiPasswordRecovery.passwordRecovery(userName);
      // setStep(nameSteps.STEP_RECOVERY);
      setInfoMessage('Te enviamos un correo para recuperar tu contraseña. Puedes cerrar esta ventana.');
    } catch (e) {
      setErrorMessage('Ocurrió un error, inténtelo de nuevo');
    } finally {
      loadingOff('login');
    }
  };

  const onSubmitRecovery = async (values) => {
    try {
      loadingOn('login');
      const body = {
        email: userName,
        hashedKey,
        verifyKey,
        newPassword: values.password,
      };
      await apiPasswordReset.passwordReset(body);
      setStep(nameSteps.STEP_PASSWORD);
    } catch (e) {
      setErrorMessage('Ocurrió un error, inténtelo de nuevo');
    } finally {
      loadingOff('login');
    }
  };

  const handleLoginWithProvider = async (response, provider) => {
    if (!response || !response?.user || !response?.user?.role_id) {
      setLoading({
        ...loading,
        [provider]: false,
      });
      return false;
    }

    await loginController(
      { userData: { data: response } },
      navigate,
      () => setUserData({ loginStatus: true, data: response }),
    );

    successfulLogin();

    return true;
  };

  const handleGoogleClick = async () => {
    const provider = 'google';
    loadingOn(provider);
    const response = await loginWithGoogle();

    return handleLoginWithProvider(response, provider);
  };

  const handleFacebookTokenAfterClick = async (token) => {
    const provider = 'facebook';
    loadingOn(provider);
    const response = await loginWithProvider(token, provider);

    return handleLoginWithProvider(response, provider);
  };

  const steps = {
    [`${nameSteps.STEP_USER_NAME}`]: {
      title: 'Te damos la bienvenida',
      Component: () => (
        <UserNameForm
          onSubmit={onSubmitUserName}
          loading={loading.login}
          userName={userName}
        />
      ),
    },
    [`${nameSteps.STEP_PASSWORD}`]: {
      title: 'Ingresa tu contraseña',
      Component: () => (
        <PasswordForm
          onSubmit={onSubmitPassword}
          loading={loading.login}
          onGoToRecovery={onPasswordRecovery}
        />
      ),
    },
    [`${nameSteps.STEP_SIGN_IN}`]: {
      title: 'Crea tu cuenta',
      Component: () => (
        <SignInForm
          onSubmit={onSubmitSignIn}
          userName={userName}
          loading={loading.login}
        />
      ),
    },
    [`${nameSteps.STEP_ACTIVATION}`]: {
      title: 'Activa tu cuenta con el número de activación que te enviamos a tu correo',
      subtitle: 'Sí no lo encuentras revisa en tu bandeja de spam o en la carpeta de correos no deseados.',
      Component: () => (
        <ActivationForm
          onSubmit={onSubmitActivation}
          loading={loading.login}
        />
      ),
    },
    [`${nameSteps.STEP_RECOVERY}`]: {
      title: 'Ingresa una contraseña nueva',
      subtitle: 'Debe contener como mínimo: 8 (ocho) caracteres, 1 (una) mayúscula, 1 (un) número, 1 (un) caracter especial.',
      Component: () => (
        <RecoveryForm
          onSubmit={onSubmitRecovery}
          loading={loading.login}
        />
      ),
    },
  };

  return {
    steps,
    step,
    setStep,
    previousStep,
    errorMessage,
    infoMessage,
    handleGoogleClick,
    handleFacebookTokenAfterClick,
    loading,
    showSocialMedias: step === nameSteps.STEP_USER_NAME,
  };
};

export default useAuthentication;
