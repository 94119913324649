import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import countryService from 'services/countryService';
import vStyles from '../../FamilyInfo.module.scss';

const tips = [
  {
    title: 'Aclaración',
    content: 'Todos los integrantes de la familia deben estar en una u otra opción, de modo que integrando ambas opciones (“Integrantes en edad estudiantil” y “Composición familiar restante”) se obtenga el total de integrantes del núcleo familiar.',
  },
  {
    title: 'Moneda',
    content: `Los montos se expresan en ${countryService.getCurrencySymbolWithDescription()}.`,
  },
];

const PageEightTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
      </div>
    </div>
  ))
);

export default PageEightTips;
