import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const scrollToRef = (ref) => {
  if (ref) window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });
};

function Card({
  title,
  description,
  icon,
  sections,
}) {
  const section = sections.find((s) => s.name.includes(title) && s.ref);
  let sectionRef;
  if (section) sectionRef = section.ref;
  const executeScroll = () => scrollToRef(sectionRef);

  return (
    <div className={styles.cardContainer} key={title}>
      <div className={styles.cardWrapper}>
        <div className={styles.mainFeaturesImg}>
          <img src={icon} width={25} alt={description} />
        </div>
        <h3 className={styles.cardTitle}>{title}</h3>
        {sections.filter((s) => s.active).map((s) => {
          if (s.name.includes(title)) {
            return (
              <button key={s.id} type="button" onClick={() => executeScroll()} className={styles.refBtn}>
                <p key={s.id} className={`${styles.cardText} ${styles.blue}`}>{description}</p>
              </button>
            );
          }

          return <p key={s.id} className={styles.cardText}>{description}</p>;
        })}
      </div>
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Card;
