import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const SchoolRating = ({ rating, totalRatings }) => {
  if (!totalRatings) return null;

  const score = Number(rating);
  const classNameStars = [];

  for (let i = 1; i <= 5; i += 1) {
    if (score >= i) {
      classNameStars.push('fa fa-regular fa-star');
    } else if (score > i - 1) {
      classNameStars.push('fa fa-regular fa-star-half-o');
    } else {
      classNameStars.push('fa fa-regular fa-star-o');
    }
  }

  return classNameStars.map((className) => (
    <i key={uuidv4()} className={className} style={{ color: '#ED8C46' }} />
  ));
};

SchoolRating.propTypes = {
  rating: PropTypes.string.isRequired,
  totalRatings: PropTypes.number.isRequired,
};

export default SchoolRating;
