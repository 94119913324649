/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import { TiChevronLeft } from 'react-icons/ti';
import { CgClose } from 'react-icons/cg';
import countryService from 'services/countryService';
import { parsedDataInStorage, saveDataInStorage } from 'utils/Storage/storageFunctions';

// import { registerAnalytics } from 'Authentication/Data/Services/firebase';

import vacantedLogo from 'assets_vacanted/logo_v.png';
import { getUrl } from 'urlConfig';
import Snackbar from '../Snackbar';
import localStorageFamilyInfo from './localStorageFamilyInfo';
import PageOne from './FamilyInfoCards/PageOne/PageOne';
import PageOneTips from './FamilyInfoCards/PageOne/PageOneTips';
import PageTwo from './FamilyInfoCards/PageTwo/PageTwo';
import PageThree from './FamilyInfoCards/PageThree/PageThree';
import PageThreeTips from './FamilyInfoCards/PageThree/PageThreeTips';
import PageFour from './FamilyInfoCards/PageFour/PageFour';
import PageFourTips from './FamilyInfoCards/PageFour/PageFourTips';
import PageFive from './FamilyInfoCards/PageFive/PageFive';
import PageFiveTips from './FamilyInfoCards/PageFive/PageFiveTips';
import PageSix from './FamilyInfoCards/PageSix/PageSix';
import PageSixTips from './FamilyInfoCards/PageSix/PageSixTips';
import PageSeven from './FamilyInfoCards/PageSeven/PageSeven';
import PageSevenTips from './FamilyInfoCards/PageSeven/PageSevenTips';
import PageEight from './FamilyInfoCards/PageEight/PageEight';
import PageEightTips from './FamilyInfoCards/PageEight/PageEightTips';
import PageNine from './FamilyInfoCards/PageNine/PageNine';
import PageTen from './FamilyInfoCards/PageTen/PageTen';
import PageTenTips from './FamilyInfoCards/PageTen/PageTenTips';
import PageEleven from './FamilyInfoCards/PageEleven/PageEleven';
import PageElevenTips from './FamilyInfoCards/PageEleven/PageElevenTips';
import PageTwelve from './FamilyInfoCards/PageTwelve/PageTwelve';

import vStyles from './FamilyInfo.module.scss';

const FamilyInfo = () => {
  const [card, setCard] = useState(0);
  const [barPercentage, setBarPercentage] = useState();
  const [closeTestModal, setCloseTestModal] = useState(false);
  const [disabledBtn, toggleDisabledBtn] = useState(false);
  const [editFamilyInfo, setEditFamilyInfo] = useState(false);
  const [collectionId, setCollectionId] = useState(null);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const countryId = countryService.getCurrentCountryId();

  const getFamilyInfo = async (tokn) => {
    const config = {
      headers: {
        Authorization: tokn,
      },
    };
    const familyInfodata = await api.get('/collection/type/familyReport', config);

    return familyInfodata;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [card]);

  const redirectToLogin = () => {
    setError({ message: 'Tu sesión expiró. Ya guardamos tu progreso y te redirigiremos al login.', onClose: () => window.location.replace('/auth/login') });
  };

  const showErrorMessage = (message) => {
    setError({ message });
  };

  useEffect(() => {
    const checkForFamilyInfo = async () => {
      const vacantedData = parsedDataInStorage('vacanted');

      if (vacantedData) {
        const { token: userToken } = vacantedData;
        await getFamilyInfo(userToken)
          .then((data) => {
            const { data: [dataFamily] } = data;

            if (dataFamily) {
              localStorageFamilyInfo.clear();
              setCollectionId(dataFamily.id);
              saveDataInStorage(dataFamily.familyReport, 'familyInfo');
              setEditFamilyInfo(true);
            }
          }).catch(redirectToLogin);
      } else {
        redirectToLogin();
      }
    };

    checkForFamilyInfo();
  }, []);

  const cardsArray = [
    {
      content: <PageOne />,
      tips: <PageOneTips />,
      percentage: 5,
    },
    {
      content: <PageTwo card={card} disableNext={toggleDisabledBtn} />,
      percentage: 10,
    },
    {
      content: <PageThree card={card} disableNext={toggleDisabledBtn} />,
      tips: <PageThreeTips />,
      percentage: 15,
    },
    {
      content: <PageFour card={card} disableNext={toggleDisabledBtn} />,
      tips: <PageFourTips />,
      percentage: 20,
    },
    {
      content: <PageFive card={card} disableNext={toggleDisabledBtn} />,
      tips: <PageFiveTips />,
      percentage: 30,
    },
    {
      content: <PageSix card={card} disableNext={toggleDisabledBtn} />,
      tips: <PageSixTips />,
      percentage: 40,
    },
    {
      content: <PageSeven card={card} disableNext={toggleDisabledBtn} />,
      tips: <PageSevenTips />,
      percentage: 50,
    },
    {
      content: <PageEight card={card} disableNext={toggleDisabledBtn} />,
      tips: <PageEightTips />,
      percentage: 60,
    },
    {
      content: <PageNine card={card} disableNext={toggleDisabledBtn} />,
      percentage: 70,
    },
    {
      content: <PageTen card={card} disableNext={toggleDisabledBtn} />,
      tips: <PageTenTips />,
      percentage: 80,
    },
    {
      content: <PageEleven card={card} disableNext={toggleDisabledBtn} />,
      tips: <PageElevenTips />,
      percentage: 90,
    },
    {
      content: <PageTwelve card={card} disableNext={toggleDisabledBtn} />,
      percentage: 100,
    },
  ];

  useEffect(() => setBarPercentage(cardsArray[card].percentage), [card]);

  const callPreviousCard = () => {
    // registerAnalytics(`Informe de familia/previous/page${card}`);
    if (card === 1) {
      toggleDisabledBtn(false);
    }
    setCard((card > 0) ? card - 1 : card);
  };

  const checkValidLoginStatus = () => {
    api.checkFamilySessionIsValid()
      .then((valid) => {
        if (!valid) redirectToLogin();
      });
  };

  const callNextCard = () => {
    // registerAnalytics(`Informe de familia/next/page${card + 2}`);
    checkValidLoginStatus();
    if (card === 0) {
      toggleDisabledBtn(false);
      setCard(card + 1);
    } else {
      const cardIsFilled = localStorageFamilyInfo.check(card);
      setCard((card < cardsArray.length - 1 && cardIsFilled) ? card + 1 : card);
    }
  };

  const saveFamilyInfo = async () => {
    const dataFromLocalStorage = parsedDataInStorage('familyInfo');
    const { user, token } = parsedDataInStorage('vacanted');

    const config = {
      headers: {
        authorization: token,
      },
    };

    const date = Date().toString();

    const data = {
      type: 'familyReport',
      country_id: countryId,
      name: `${user.email} - ${date}`,
      context: { ...dataFromLocalStorage, completed: true },
      results: null,
      savedAt: date,
    };

    const panelUrl = getUrl('panel');

    try {
      if (editFamilyInfo) {
        await api.put(`/collection/familyreport/${collectionId}`, data, config);
      } else {
        await api.post('/collection', data, config);
      }
    } catch (err) {
      if ([401, 403].includes(err.response?.status)) {
        return redirectToLogin();
      }
      return showErrorMessage(err.message);
    }

    return setTimeout(() => window.location.replace(`${panelUrl}/${token}/information`), 1000);
  };

  const callToActions = (styles) => (
    <div className={vStyles[styles]}>
      {card !== 0 && (
        <button
          className={vStyles.vacantest__back__cta}
          type="button"
          onClick={() => callPreviousCard()}
        >
          <TiChevronLeft color="#333" size={20} />
          <p>ATRÁS</p>
        </button>
      )}
      <button
        disabled={disabledBtn}
        type="button"
        className={vStyles.vacantest__next__button}
        onClick={card === cardsArray.length - 1 ? () => saveFamilyInfo() : () => callNextCard()}
      >
        {card === cardsArray.length - 1 ? 'FINALIZAR Y GUARDAR' : 'SIGUIENTE'}
      </button>
    </div>
  );

  const handleCloseTest = () => {
    localStorageFamilyInfo.clear();
    navigate('/');
  };

  const renderCloseTestModal = () => (
    <div className={vStyles.close__test__modal}>
      <div className={vStyles.close__modal__btn__container}>
        <button
          type="button"
          className={vStyles.close__modal__btn}
          onClick={() => setCloseTestModal(!closeTestModal)}
        >
          <CgClose color="#fff" size={20} />
        </button>
      </div>
      <h3>Estás a punto de salir de tu Informe de Familia</h3>
      <p>Si abandonas perderás la información que hayas cargado</p>
      <div className={vStyles.close__modal__test}>
        <button type="button" onClick={() => handleCloseTest()}>Cerrar</button>
      </div>
    </div>
  );

  return (
    <div className={vStyles.vacantest__container}>
      {closeTestModal && (
        <>
          <div className={vStyles.vacantest__masked} />
          <div className={vStyles.close__test__modal__container}>
            {renderCloseTestModal()}
          </div>
        </>
      )}
      <div className={vStyles.vacantest__header}>
        <div className={vStyles.vacantest__header__top}>
          <div className={vStyles.vacanted__logo__container}>
            <img className={vStyles.vacanted__logo} src={vacantedLogo} alt="Vacanted" />
            <p>Informe de Familia</p>
          </div>
          <div className={vStyles.vacantest__close}>
            <button type="button" onClick={() => setCloseTestModal(!closeTestModal)}>X Cerrar</button>
          </div>
        </div>
        <div className={vStyles.vacantest__percentage__bar__holder}>
          <div
            className={vStyles.vacantest__percentage__bar}
            style={{ width: `${barPercentage}%` }}
          >
            <div
              className={vStyles.vacantest__percentage__value}
            >
              {`${barPercentage}%`}
            </div>
          </div>
        </div>
      </div>
      <div className={vStyles.vacantest__dynamic__content}>
        <div className={vStyles.vacantest__card}>
          <div className={vStyles.vacantest__empty} />
          <div className={vStyles.vacantest__filled}>
            {cardsArray[card].content}
            {callToActions('vacantest__actions__desktop')}
          </div>
        </div>
        <div className={vStyles.vacantest__card__tips}>
          {cardsArray[card].tips}
        </div>
        {callToActions('vacantest__actions__mobile')}
      </div>
      {
          error && (
            <Snackbar message={error.message} type="error" onClose={error.onClose} autoClose />
          )
      }
    </div>
  );
};

export default FamilyInfo;
