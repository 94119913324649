import React from 'react';
import Header from 'components/Header/Header';
import Footer from 'components/Footer';
import EmailRecovery from '../components/EmailRecovery';
import DefaultDescription from '../components/DefaultDescription/DefaultDescription';
import emailStyles from './login.module.scss';

function emailRecovery() {
  return (
    <>
      <Header />
      <div className={emailStyles.background}>
        <div className={emailStyles.background__overlay}>
          <div className={emailStyles.body__content}>
            <div
              className={emailStyles.description__container}
            >
              <DefaultDescription />
            </div>
            <div
              className={emailStyles.form__container}
              style={{ padding: '10px 0' }}
            >
              <EmailRecovery />
            </div>
          </div>
        </div>
      </div>
      <div className={emailStyles.footer__container}>
        <Footer />
      </div>
    </>
  );
}

emailRecovery.propTypes = {};

export default emailRecovery;
