import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import Dropdown from '../Dropdown/VacantestDropdown';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import familyCombos from '../familyInfoConstants';

import specificStyles from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';

const PageSeven = ({ card, disableNext }) => {
  const [familyInfoData, setFamilyInfoData] = useState([]);
  const [age, setAge] = useState([{ name: 'Edad' }]);
  const [typeOfInstitution, setTypeOfInstitution] = useState([{ name: 'Tipo de institución actual' }]);
  const [dropdownController, setDropdownController] = useState({ active: 1, max: 6 });
  const agesKey = 2;
  const institutionsKey = 5;
  const agesCollection = familyCombos[agesKey];
  const institutionsCollection = [...familyCombos[institutionsKey], { id: 3, name: 'No escolarizado aún' }];

  // these two are used to escape Linter rules for quotation marks
  const string1 = ' ';
  const string2 = '"No escolarizado aún"';

  const didMountRef = useRef(false);

  const getInitialData = () => {
    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);

      if (defaultValues.length > 0) {
        const ages = [];
        const types = [];
        defaultValues.forEach((dv) => {
          ages.push(dv.age);
          types.push(dv.type);
        });
        setDropdownController((dd) => ({ ...dd, active: defaultValues.length }));
        setAge([...ages]);
        setTypeOfInstitution([...types]);
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => getInitialData(), []);

  const isCardDataValid = () => {
    if (age.length > 0) {
      let counter = 0;
      while (counter < dropdownController.active) {
        if (typeof familyInfoData[counter]?.age.id !== 'number') return false;
        if (typeof familyInfoData[counter]?.type.id !== 'number') return false;
        counter += 1;
      }
      return true;
    }
    return false;
  };

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageFamilyInfo.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    const treatedFamilyInfo = age.map((a, i) => ({
      age: { ...a },
      type: { ...typeOfInstitution[i] },
    }));
    setFamilyInfoData(treatedFamilyInfo);
  }, [age, typeOfInstitution]);

  useEffect(() => {
    if (!isCardDataValid()) removeCardFromLocalStorage();
    else saveCardInLocalStorage(familyInfoData);
  }, [familyInfoData]);

  const addLevelDropdown = () => {
    if (dropdownController.active < dropdownController.max) {
      setDropdownController({ ...dropdownController, active: dropdownController.active + 1 });
    }
  };

  const renderAddDropdownButton = () => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => addLevelDropdown()}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlinePlus size={25} color="#333" />
      </div>
      <p>Agrega otro integrante</p>
    </button>
  );

  const removeLevelDropdown = (id) => {
    const auxA = age;
    const auxT = typeOfInstitution;
    auxA.splice(id - 1, 1);
    auxT.splice(id - 1, 1);
    setAge([...auxA]);
    setTypeOfInstitution([...auxT]);
    setDropdownController((dd) => ({ ...dd, active: dd.active - 1 }));
  };

  const renderRemoveDropdownButton = (id) => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => removeLevelDropdown(id)}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlineMinus size={25} color="#333" />
      </div>
      <p>Quita un nivel</p>
    </button>
  );

  const saveAge = (ageObj, id) => {
    const aux = age;
    aux[id - 1] = ageObj;
    setAge([...aux]);
  };

  const saveTypeOfInstitution = (typeOfInstObj, id) => {
    const aux = typeOfInstitution;
    aux[id - 1] = typeOfInstObj;
    setTypeOfInstitution([...aux]);
  };

  const checkAddDropdownConditions = (id) => {
    if (dropdownController.active >= dropdownController.max) return false;
    if (familyInfoData.length < 1) return false;
    if (id !== dropdownController.active) return false;
    const lastData = familyInfoData[dropdownController.active - 1];
    if (!lastData) return false;
    const lastAge = lastData.age;
    const lastType = lastData.type;
    if (typeof (lastAge?.id) !== 'number') return false;
    if (typeof (lastType.id) !== 'number') return false;

    return true;
  };

  const checkRemoveDropdownConditions = (id) => {
    if (dropdownController.active <= 1) return false;
    if (dropdownController.active !== id) return false;

    return true;
  };

  const renderDropdown = () => {
    const dropdonwsArray = [];

    for (let i = 1; i <= dropdownController.active; i += 1) {
      dropdonwsArray.push(i);
    }

    return dropdonwsArray.map((id) => (
      <div
        key={id}
        style={{
          marginTop: '50px',
        }}
      >
        <p className={specificStyles.bold__subtitle} style={{ marginTop: '-30px' }}>{`Integrante ${id}`}</p>
        <div className={specificStyles.first_dropdown}>
          <Dropdown
            option={age[id - 1] || { name: 'Edad' }}
            setOption={(e) => saveAge(e, id)}
            options={agesCollection}
          />
        </div>
        <div>
          <Dropdown
            option={typeOfInstitution[id - 1] || { name: 'Tipo de institución actual' }}
            setOption={(e) => saveTypeOfInstitution(e, id)}
            options={institutionsCollection}
          />
        </div>
        <hr />
        {checkAddDropdownConditions(id) && renderAddDropdownButton()}
        {checkRemoveDropdownConditions(id) && renderRemoveDropdownButton(id)}
      </div>
    ));
  };

  return (

    <div className={rStyles.vacantest__card__content}>
      <div className={specificStyles.page__title}>
        <h3>Composición familiar en etapa estudiantil (Máximo 6 integrantes)</h3>
        <p>
          A continuación, agrega los integrantes de la familia
          en etapa estudiantil para los niveles de inicial,
          primaria, secundaria o bachillerato. Además,
          selecciona la edad y el tipo de institución a la que hoy concurren.
          En caso de que no estén escolarizados aún,
          seleccionar edad y la opción
          {string1}
          {string2}
          .
        </p>
      </div>
      {renderDropdown()}
    </div>
  );
};

export default PageSeven;

PageSeven.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
