import axios from 'axios';
import { getUrl } from 'urlConfig';

const api = axios.create({
  baseURL: getUrl('api'),
});

export const apiRegistry = {
  async post(data) {
    return api.post('/user/registry', data);
  },

  async userActivate({ email, code, hashedKey }) {
    return api.get(`/user/activate?email=${email}&verify_key=${code}&hashed_key=${hashedKey}`);
  },
};

const apiLogin = {
  async post({ email, password, type }) {
    return api.post('/login', { email, password, type });
  },

  async checkLoginWithEmail({ email }) {
    return api.post('/login/email', { email });
  },

  async logout(email) {
    return api.post('/logout', { email });
  },
};

export const apiLoginWithProvider = {
  async post(token, provider = null) {
    return api.post('/login/auth', { idtoken: token, provider });
  },
};

export const apiEmailRecovery = {
  async emailRecovery(email) {
    return api.put('/user/email-recovery', { email });
  },
};

export const apiPasswordRecovery = {
  async passwordRecovery(email) {
    return api.put('/user/password-recovery', { email });
  },
};

export const apiPasswordReset = {
  async passwordReset(payload) {
    return api.put('/user/password-reset', payload);
  },
};

export default apiLogin;
