import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './dropdown-styles.scss';

const ControlledDropdown = ({
  id, label, size, options, value, onChange, required, errorMessage, className,
}) => {
  const [error, setError] = useState();

  const hasValue = (val) => !!val;

  const validate = (newVal) => {
    if (required && !hasValue(newVal)) {
      setError(errorMessage || 'Dato requerido');
      return false;
    }
    setError(null);
    return true;
  };

  return (
    <FormControl className={`vacanted__mui__dropdown ${className ?? ''}`}>
      {!!label && <InputLabel error={!!error}>{label}</InputLabel>}
      <Select
        variant="outlined"
        id={id}
        name={id}
        size={size}
        value={value ?? ''}
        label={label}
        onBlur={(e) => {
          validate(e.target.value);
        }}
        onChange={(e) => {
          if (error) validate(e.target.value);
          onChange(e.target.value);
        }}
        error={!!error}
      >
        {options?.map(({ value: optionValue, label: optionLabel }) => (
          <MenuItem value={optionValue}>{optionLabel}</MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

ControlledDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
};

ControlledDropdown.defaultProps = {
  required: false,
  errorMessage: null,
  className: null,
};

export default ControlledDropdown;
