import regExps from '../utils/Regex/regex';

const countryCodesToIds = {
  uy: 101,
  ar: 102,
};

const countryCodesToNames = {
  uy: 'Uruguay',
  ar: 'Argentina',
};

const countryCodesToCurrencySymbol = {
  uy: '$UY',
  ar: '$AR',
};

const countryCodesToCurrencySymbolWithDesc = {
  uy: 'Pesos Uruguayos ($UY)',
  ar: 'Pesos Argentinos ($AR)',
};

const getCurrentCountryCode = () => {
  const result = regExps.hostWithCountryRegex.exec(window.location.host?.toLowerCase() || '');
  if (result && result[1]) {
    const countryCode = result[1];
    return countryCode;
  }
  // if (process.env.REACT_APP_VACANTED_ENV === 'local') {
  return 'uy';
  // }
  // throw new Error('No country detected!');
};

const getCurrentCountryId = () => {
  const countryCode = getCurrentCountryCode();
  return countryCodesToIds[countryCode];
};

const getCurrentCountryName = () => {
  const countryCode = getCurrentCountryCode();
  return countryCodesToNames[countryCode];
};

const getCurrencySymbol = () => {
  const countryCode = getCurrentCountryCode();
  return countryCodesToCurrencySymbol[countryCode];
};

const getCurrencySymbolWithDescription = () => {
  const countryCode = getCurrentCountryCode();
  return countryCodesToCurrencySymbolWithDesc[countryCode];
};

export default {
  getCurrentCountryCode,
  getCurrentCountryId,
  getCurrentCountryName,
  getCurrencySymbol,
  getCurrencySymbolWithDescription,
};
