import React from 'react';
import SectionComponent from 'components/SectionComponent/SectionComponent';
import generateRandomString from 'utils/stringManipulation';

export default function renderListOfActivitiesTypes(arr) {
  return arr.map((activity) => (
    <div key={generateRandomString()}>
      <SectionComponent
        title={activity.title}
        arrayOfPropertys={activity.properties}
      />
    </div>
  ));
}
