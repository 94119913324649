import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputLogin from '../input/LoginInput';

const userNameError = 'Debe ingresar un correo eléctronico';

const UserNameForm = ({ onSubmit, loading, userName }) => (
  <Formik
    initialValues={{
      userName: userName || '',
    }}
    onSubmit={async (values) => {
      onSubmit(values);
    }}
    validationSchema={Yup.object({
      userName: Yup.string().email(userNameError).required(userNameError),
    })}
  >
    {
      ({ errors }) => (
        <Form className="login-modal__form-username">
          <InputLogin
            className={`input-text ${errors.userName ? 'has-error' : ''}`}
            name="userName"
            placeholder="Correo eléctronico"
            autoCapitalize="off"
            autoFocus
          />
          <button type="submit" className="button-lading" disabled={loading}>
            Continuar
            {' '}
            {
              loading && (<i className="fa fa-spinner fa-pulse fa-2x" />)
            }
          </button>
        </Form>
      )
    }
  </Formik>
);

UserNameForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

UserNameForm.defaultProps = {
  userName: '',
};

export default UserNameForm;
