import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { assignValueById } from 'utils/Activities/activitiesRemoveDuplicates';
import { activityData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import ProfileSection from '../ProfileSection';

import styles from './styles.module.css';
import renderListOfActivitiesTypes from '../utils/renderListOfActivitiesTypes';

export default function IncludedServices({ isFetching }) {
  const [useAllactivities, setUseAllActivities] = useState([]);
  const schoolActivities = useSelector(activityData);
  const [count, setCount] = useState(0);

  const useEffect1 = async () => {
    const servicesProvided = {
      properties: await assignValueById(schoolActivities, 7),
      title: 'Servicios Prestados por la institución',
    };
    const refectory = {
      properties: await assignValueById(schoolActivities, 8),
      title: 'Comedor',
    };
    const technologies = {
      properties: await assignValueById(schoolActivities, 9),
      title: 'Tecnología y plataformas educativas',
    };
    const clothing = {
      properties: await assignValueById(schoolActivities, 10),
      title: 'Vestimenta',
    };

    if (servicesProvided.length === 0) {
      setUseAllActivities(null);
    } else {
      setUseAllActivities([
        servicesProvided,
        refectory,
        technologies,
        clothing,
      ]);
    }
  };

  useEffect(() => {
    useEffect1();
  }, []);

  useEffect(() => {
    useAllactivities.forEach((activity) => {
      if (activity.properties.length > 0) {
        setCount((prevCount) => prevCount + 1);
      }
    });
  }, [useAllactivities]);

  if (isFetching) {
    return <>...loading</>;
  }

  if (!count) {
    return <>{' '}</>;
  }

  return (
    <ProfileSection
      title="Servicios Incluidos"
      subtitleLyrics={{
        subtitle: '',
        lyrics: useAllactivities?.description,
      }}
    >
      <div className={styles.sectionsContainer}>
        { renderListOfActivitiesTypes(useAllactivities) }
      </div>
    </ProfileSection>
  );
}

IncludedServices.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};
