import api from './api';

const checkVersionAndReloadIfNecessary = async () => {
  const response = await api.get('/versions/app');
  const { version: latestVersion } = response.data;
  const savedVersion = Number(localStorage.getItem('version'));

  if (!savedVersion) {
    localStorage.setItem('version', latestVersion);
  } else if (savedVersion < latestVersion) {
    localStorage.setItem('version', latestVersion);
    window.location.reload(true);
  }
};

export default {
  checkVersionAndReloadIfNecessary,
};
