import React from 'react';
import PropTypes from 'prop-types';

const PlaneTailIcon = ({ className }) => (
  <svg className={className} width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.5528 1.76645C71.8132 2.20672 72.9215 3.18094 73.6187 4.41744C73.6813 4.52049 73.6545 4.66101 73.5472 4.71721C73.4489 4.78278 73.3148 4.75468 73.2612 4.64227C73.2612 4.64227 73.2522 4.6329 73.2433 4.62353C72.6981 3.3964 71.7595 2.35659 70.5528 1.76645Z" fill="url(#paint0_linear_235_296)" />
    <path d="M74.9599 11.3121C74.9062 12.736 74.7275 14.1598 74.4236 15.5462C74.3431 15.9116 74.0034 16.1364 73.6548 16.0521C73.3062 15.9678 73.0917 15.6118 73.1722 15.2465C73.1811 15.2371 73.1811 15.2184 73.1811 15.1996C73.5655 13.8976 73.8515 12.5861 74.0035 11.2278C74.0303 10.9562 74.2716 10.7595 74.5308 10.7876C74.7811 10.825 74.9599 11.0499 74.9509 11.3028L74.9599 11.3121Z" fill="url(#paint1_linear_235_296)" />
    <path d="M72.5641 22.2907L71.116 26.225C70.9283 26.7215 70.401 26.9744 69.9183 26.7871C69.4446 26.5903 69.2032 26.0376 69.382 25.5318C69.382 25.5318 69.3999 25.5131 69.3999 25.4943L69.4178 25.4382L70.9999 21.6818L71.0356 21.6443C71.2055 21.1853 71.697 20.9886 72.1171 21.1853C72.5283 21.3727 72.716 21.8691 72.5551 22.3L72.5641 22.2907Z" fill="url(#paint2_linear_235_296)" />
    <path d="M68.1844 32.576L66.1107 36.2106C65.7979 36.7632 65.1096 36.9412 64.5822 36.6134C64.0549 36.2855 63.885 35.5642 64.1979 35.0115C64.1979 35.0115 64.2068 35.0022 64.2158 34.9928L64.2605 34.946L66.3431 31.4707L66.3878 31.4238C66.6917 30.8992 67.3442 30.74 67.8448 31.0772C68.3364 31.4051 68.4794 32.0795 68.1844 32.5947L68.1844 32.576Z" fill="url(#paint3_linear_235_296)" />
    <path d="M62.2048 41.9713L59.5858 45.2031C59.1479 45.7371 58.3792 45.812 57.8607 45.3436C57.3512 44.8846 57.2798 44.079 57.7267 43.5357L57.7803 43.4795L60.3367 40.3883L60.3903 40.3321C60.8193 39.8075 61.5791 39.7419 62.0797 40.2103C62.5802 40.6599 62.6338 41.4468 62.2048 41.9713Z" fill="url(#paint4_linear_235_296)" />
    <path d="M54.8214 50.196L51.7377 52.9406C51.2014 53.409 50.4058 53.3434 49.9589 52.7814C49.512 52.2193 49.5745 51.3856 50.1109 50.9173L50.1734 50.8517L53.1589 48.2288L53.2125 48.1726C53.7399 47.7136 54.5354 47.7792 54.9823 48.3412C55.4292 48.9033 55.3666 49.7182 54.8303 50.1866L54.8214 50.196Z" fill="url(#paint5_linear_235_296)" />
    <path d="M46.205 57.0154L42.7101 59.1605C42.138 59.5165 41.3872 59.3104 41.0475 58.7109C40.7079 58.1114 40.8956 57.3339 41.4676 56.9779L41.5213 56.9217L44.8821 54.8421L44.9357 54.7859C45.5346 54.4393 46.2944 54.636 46.643 55.2449C46.9916 55.8538 46.8039 56.65 46.2229 57.0153L46.205 57.0154Z" fill="url(#paint6_linear_235_296)" />
    <path d="M36.6141 62.1768L32.8332 63.6662C32.2701 63.8816 31.6444 63.5819 31.4299 63.0011C31.2153 62.4203 31.4924 61.774 32.0377 61.5398L32.1002 61.5117L35.7292 59.9754L35.7918 59.9473C36.3728 59.6944 37.0342 59.9942 37.2755 60.603C37.5169 61.2119 37.2309 61.9051 36.6499 62.158L36.632 62.1767L36.6141 62.1768Z" fill="url(#paint7_linear_235_296)" />
    <path d="M26.3528 65.5683L22.393 66.3646C21.9104 66.4582 21.4545 66.1304 21.3562 65.6339C21.2668 65.1468 21.5618 64.669 22.0176 64.566L22.0713 64.5473L25.888 63.6012L25.9416 63.5824C26.46 63.4513 26.9785 63.7885 27.1036 64.3318C27.2287 64.8751 26.907 65.4184 26.3885 65.5496C26.3885 65.5496 26.3617 65.5589 26.3438 65.5589L26.3528 65.5683Z" fill="url(#paint8_linear_235_296)" />
    <path d="M15.7342 67.1043L11.7208 67.1886C11.4169 67.1886 11.1577 66.9356 11.1577 66.6171C11.1488 66.308 11.3722 66.0551 11.6672 66.027L11.7029 66.027L15.6269 65.6617L15.6627 65.6617C16.0381 65.6242 16.3777 65.924 16.4135 66.3174C16.4492 66.7108 16.1632 67.0668 15.7878 67.1043C15.7878 67.1043 15.752 67.1043 15.7342 67.1043Z" fill="url(#paint9_linear_235_296)" />
    <path d="M5.06137 66.7109C3.73849 66.5048 2.42456 66.2893 1.11956 66.0645C1.07486 66.0551 1.03016 66.0083 1.0391 65.9427C1.04804 65.8959 1.10168 65.8584 1.14638 65.8491C2.46926 65.924 3.78319 66.0083 5.10607 66.102L5.11501 66.1114C5.2759 66.1114 5.40103 66.2612 5.39209 66.4205C5.38315 66.5797 5.24016 66.7109 5.08821 66.7015C5.08821 66.7015 5.0882 66.7015 5.07032 66.7015L5.06137 66.7109Z" fill="url(#paint10_linear_235_296)" />
    <defs>
      <linearGradient id="paint0_linear_235_296" x1="73.6532" y1="4.62074" x2="71.009" y2="2.09765" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint1_linear_235_296" x1="75.3483" y1="14.7247" x2="73.1567" y2="12.6335" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint2_linear_235_296" x1="72.1894" y1="25.216" x2="70.0737" y2="23.1972" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint3_linear_235_296" x1="67.319" y1="34.9951" x2="65.3296" y2="33.0969" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint4_linear_235_296" x1="60.9457" y1="43.7833" x2="59.1961" y2="42.1138" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint5_linear_235_296" x1="53.4832" y1="51.6063" x2="51.6502" y2="49.8572" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint6_linear_235_296" x1="45.0851" y1="58.262" x2="42.8623" y2="56.1411" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint7_linear_235_296" x1="35.7389" y1="63.2436" x2="33.2654" y2="60.8834" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint8_linear_235_296" x1="25.6854" y1="66.4984" x2="23.0965" y2="64.0281" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint9_linear_235_296" x1="15.208" y1="67.9455" x2="12.6836" y2="65.5368" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint10_linear_235_296" x1="4.4874" y1="67.4702" x2="2.2512" y2="65.3364" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
    </defs>
  </svg>

);

PlaneTailIcon.propTypes = {
  className: PropTypes.string,
};

PlaneTailIcon.defaultProps = {
  className: null,
};

export default PlaneTailIcon;
