import React from 'react';
import PropTypes from 'prop-types';
import ExperienceButton from '../../button';
import '../styles/counter-selector.scss';

const CounterSelector = ({ count, label, onChange }) => (
  <div className="counter-selector">
    <div className="counter-selector__label">
      {label}
    </div>
    <div className="counter-selector__actions">
      <ExperienceButton iconOnly size="small" disabled={count === 1} onClick={() => onChange(count - 1)}>
        <svg
          className="counter-selector__icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.33398 8H12.6673"
            stroke="black"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </ExperienceButton>
      <div className="counter-selector__counter">{count}</div>
      <ExperienceButton iconOnly size="small" onClick={() => onChange(count + 1)}>
        <svg
          className="counter-selector__icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.49935 8.49997H4.16602C4.02435 8.49997 3.9056 8.45203 3.80977 8.35617C3.71393 8.26029 3.66602 8.14149 3.66602 7.99977C3.66602 7.85803 3.71393 7.73931 3.80977 7.64358C3.9056 7.54786 4.02435 7.5 4.16602 7.5H7.49935V4.16667C7.49935 4.025 7.54728 3.90625 7.64315 3.81042C7.73903 3.71458 7.85783 3.66667 7.99955 3.66667C8.14128 3.66667 8.26001 3.71458 8.35573 3.81042C8.45145 3.90625 8.49932 4.025 8.49932 4.16667V7.5H11.8326C11.9743 7.5 12.0931 7.54793 12.1889 7.6438C12.2847 7.73968 12.3327 7.85848 12.3327 8.0002C12.3327 8.14193 12.2847 8.26066 12.1889 8.35638C12.0931 8.45211 11.9743 8.49997 11.8326 8.49997H8.49932V11.8333C8.49932 11.975 8.45138 12.0937 8.35552 12.1896C8.25964 12.2854 8.14084 12.3333 7.99912 12.3333C7.85738 12.3333 7.73865 12.2854 7.64293 12.1896C7.54721 12.0937 7.49935 11.975 7.49935 11.8333V8.49997Z"
            fill="black"
          />
        </svg>
      </ExperienceButton>
    </div>
  </div>
);

CounterSelector.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CounterSelector;
