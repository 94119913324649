import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DescriptionData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import ProfileSection from '../ProfileSection';

function Description({ isFetching }) {
  const descriptionData = useSelector(DescriptionData);

  if (isFetching) {
    return <>...loading</>;
  }

  return (
    <ProfileSection title="Descripción">

      <div>
        {descriptionData}
      </div>
    </ProfileSection>
  );
}

Description.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};

export default Description;
