import { useUserData } from 'hooks/UserContext';
import React from 'react';
import ReactPlayer from 'react-player';

import loginStyles from '../RegisterCard/RegisterCard.module.css';
import styles from './styles.module.css';

function SchoolRegisterCard() {
  const video = 'https://www.youtube.com/watch?v=R1xgKf0_OKw';
  const {
    setSchoolRegisterModal,
  } = useUserData();
  function openModal() {
    setSchoolRegisterModal(true);
  }
  return (
    <div className={`${loginStyles.card__container}`}>
      <form className={loginStyles.form}>
        <ReactPlayer
          url={video}
          muted
          loop
          playing
          width="100%"
          height="214px"
          controls
        />
        <div className={styles.content}>
          <p>
            Para acceder a los servicios sin costo que VACANTED™
            le ofrece a las instituciones educativas, active su acceso privado.
          </p>
          <div className={styles.active}>
            <p>
              <button type="button" onClick={openModal}>
                ACTIVAR ACCESO PRIVADO GRATUITO
              </button>
            </p>
          </div>
          <div className={styles.bottom}>
            <p>¿Tienes dudas? Solicite más información a: </p>
            <p className={styles.email}>info.centros@vacanted.com </p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SchoolRegisterCard;
