import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import { BarLoader } from 'react-spinners';
import loginController from 'utils/Controllers/loginController';

import loginWithEmail from 'Authentication/actions/loginWithEmail/loginWithEmail';
import girl from 'assets_vacanted/girl.png';
import loginControl from '../RegisterCard/registerFunctions';
import loginStyles from '../RegisterCard/RegisterCard.module.css';

const emailRegex = /\?email=([^&]+)&?/;

function SchoolLoginCard() {
  let forcedEmail = '';
  const matches = emailRegex.exec(window.location.search);
  if (matches && matches.length === 2) {
    // eslint-disable-next-line prefer-destructuring
    forcedEmail = matches[1];
  }
  const [loginData, setLoginData] = useState({ email: forcedEmail, password: '' });
  const [validEmail, setValidEmail] = useState({ valid: false, showWarning: false });
  const [emailInputStyles, toggleEmailInputStyles] = useState(loginStyles.input);
  const [passwordShow, togglePasswordShow] = useState({ status: false, type: 'password' });
  const [passwordInputStyles, togglePasswordInputStyles] = useState(loginStyles.input);
  const [loginError, setLoginError] = useState({ type: 'login', status: false, msg: '' });
  const [emailSuccess, setEmailSuccess] = useState({ status: false, msg: 'Inicio de sesión exitoso' });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleInput = (e) => {
    const { name, value } = e.target;

    setLoginData({ ...loginData, [name]: value });
  };

  // clear login warnings on any input change
  useEffect(() => setLoginError({ type: 'login', status: false, msg: '' }), [loginData]);

  // check email pattern
  useEffect(() => loginControl
    .validateEmailPattern(loginData.email, setValidEmail), [loginData.email]);

  // check for input error styles
  useEffect(() => toggleEmailInputStyles((validEmail.showWarning || (loginError.type === 'email' && loginError.status))
    ? loginStyles.invalid__input : loginStyles.input), [validEmail.showWarning, loginError]);

  useEffect(() => togglePasswordInputStyles((loginError.type === 'password' && loginError.status)
    ? loginStyles.invalid__input : loginStyles.input), [loginError]);

  // toggle password type
  const handlePasswordShow = () => {
    togglePasswordShow((passwordShow.status) ? { status: !passwordShow.status, type: 'password' }
      : { status: !passwordShow.status, type: 'text' });
  };

  // login attempt
  const handleLoginAttempt = async () => {
    if (!validEmail.valid) setValidEmail({ ...validEmail, showWarning: true });

    setLoading(true);
    const response = await loginWithEmail(loginData, 'school');
    setLoading(false);
    const loginStatus = response.status;

    if (loginStatus === 401) {
      const errorMessage = response.data.message;

      if (errorMessage.includes('email')) {
        return setLoginError({ type: 'email', status: true, msg: 'Correo no encontrado' });
      }

      if (errorMessage.includes('correct')) {
        return setLoginError({ type: 'email', status: true, msg: 'Iniciar sesión en el área correcta' });
      }

      if (errorMessage.includes('password')) {
        return setLoginError({ type: 'password', status: true, msg: 'Contraseña incorrecta' });
      }

      if (errorMessage.includes('inactive')) {
        return setLoginError({ type: 'login', status: true, msg: 'Usuario inactivo' });
      }
    }

    if (loginStatus === 201) {
      setEmailSuccess({ ...emailSuccess, status: true });

      const userDataFromLogin = { loginStatus: true, data: response.data };

      return loginController({ userData: userDataFromLogin });
    }

    return setLoginError({ type: 'login', status: true, msg: 'Error' });
  };

  return (
    <>
      <div className={`${loginStyles.card__container}`}>
        <h1 className={loginStyles.title}>Acceso Privado Institución</h1>
        <p className={loginStyles.text}>
          Bienvenido al área privada de instituciones.
          Ingrese su usuario y contraseña para administrar sus instituciones asignadas.

        </p>
        <h2 className={loginStyles.subtitle}>Inicio de sesión</h2>
        <form className={loginStyles.form}>
          <input
            className={emailInputStyles}
            type="email"
            value={loginData.email}
            name="email"
            placeholder="Ingrese su email"
            onChange={handleInput}
          />
          {(loginError.type === 'email') && loginError.status && validEmail.valid
          && <p className={loginStyles.invalid__input__text}>{loginError.msg}</p>}
          {validEmail.showWarning
          && <p className={loginStyles.invalid__input__text}>Formato de correo inválido</p>}
          <div className={loginStyles.input__with__icon}>
            <input
              className={`${passwordInputStyles} ${loginStyles.last__input}`}
              type={passwordShow.type}
              name="password"
              maxLength={20}
              placeholder="Ingrese su contraseña"
              onChange={handleInput}
            />
            {passwordShow.status
              ? (
                <FaRegEye
                  className={`${loginStyles.input__icon} ${loginStyles.last__input__icon}`}
                  size={18}
                  onClick={handlePasswordShow}
                />
              )
              : (
                <FaRegEyeSlash
                  className={`${loginStyles.input__icon} ${loginStyles.last__input__icon}`}
                  size={18}
                  onClick={handlePasswordShow}
                />
              )}
          </div>
          {(loginError.type === 'password') && loginError.status && validEmail.valid
          && <p className={loginStyles.invalid__input__text}>{loginError.msg}</p>}
          <button
            className={loginStyles.buttonSubmit}
            type="button"
            onClick={handleLoginAttempt}
          >
            INGRESAR
          </button>
          <BarLoader loading={loading} color="#3e95e6" width="100%" />
          {(loginError.type === 'login') && loginError.status
          && <p className={loginStyles.invalid__input__text}>{loginError.msg}</p>}
          <br />
          <button type="button" onClick={() => navigate('/auth/password-recovery')} className={loginStyles.link__btn}>
            ¿Olvidaste tu contraseña?
          </button>
        </form>
      </div>
      <div className={loginStyles.cardFree}>
        <div className={loginStyles.cardContent}>
          <img src={girl} alt="girl" className={loginStyles.cardImg} />
          <p>
            Soy administrador de un centro educativo
            y no cuento con mi clave de acceso gratuito.
          </p>
        </div>
        <div>
          <Link to="/auth/school_register">
            <button type="button" className={loginStyles.cardButton}>
              INGRESAR AQUÍ
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default SchoolLoginCard;
