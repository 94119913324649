/* eslint-disable no-multi-spaces */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/schools-list.scss';
import '../../../school-item/styles/school-item.scss';

const SchoolsList = ({ children }) => (
  <div className="experience-school-list">
    {children}
  </div>

);

SchoolsList.propTypes = {
  children: PropTypes.node,
};

SchoolsList.defaultProps = {
  children: null,
};

export default SchoolsList;
