const sidebarConstants = {
  setYourLocation: {
    es: 'Indica tu ubicación',
    pt: 'Indique sua localização',
  },
  selectYourPreferences: {
    es: 'Selecciona tus preferencias',
    pt: 'Selecione suas preferências',
  },
};

export default sidebarConstants;
