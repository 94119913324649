import React from 'react';
import PropTypes from 'prop-types';
import ProfileSection from '../ProfileSection';

function Religion({ religionDescription, religionName }) {
  if (!religionName || !religionDescription) return <></>;

  return (
    <ProfileSection
      title="Religión"
      subtitleLyrics={{
        subtitle: religionName,
        lyrics: religionDescription,
      }}
    />
  );
}

Religion.propTypes = {
  religionDescription: PropTypes.string,
  religionName: PropTypes.string,
}.isRequired;

Religion.defaultProps = {
  religionDescription: '',
  religionName: '',
};

export default Religion;
