import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import { BarLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import logo from 'assets/icons/VACANTED_iso.png';
import passwordResetRequest from 'Authentication/actions/passwordReset/passwordReset';
import regControl from '../RegisterCard/registerFunctions';
import ChargeBar from '../RegisterCard/ChargeBar';

// most of the css comes from register styles
import registerStyles from '../RegisterCard/RegisterCard.module.css';
import passwordStyles from '../PasswordRecovery/PasswordRecovery.module.css';
import passwordResetStyles from './PasswordReset.module.css';

function PasswordReset({ props }) {
  const [renderCard, setRenderCard] = useState(false);
  const [resetPasswordData, setResetPasswordData] = useState({});

  function checkQueryParameters() {
    const params = props;
    if (params && params.includes('&') && params.split('&').length === 3) {
      const paramsArr = params.split('&');
      const paramsEmail = (paramsArr[0].split('='))[1];
      const paramsVerifyKey = (paramsArr[1].split('='))[1];
      const paramsHashedKey = (paramsArr[2].split('='))[1];

      const paramsKeys = { email: false, verifyKey: false, hashedKey: false };
      paramsArr.forEach((e) => {
        if (e.includes('email')) paramsKeys.email = true;
        if (e.includes('verify_key')) paramsKeys.verifyKey = true;
        if (e.includes('hashed_key')) paramsKeys.hashedKey = true;
      });

      const paramsIncludeAllKeys = Object.values(paramsKeys).every((v) => v);

      if (paramsIncludeAllKeys) {
        setResetPasswordData({
          email: paramsEmail, verifyKey: Number(paramsVerifyKey), hashedKey: paramsHashedKey,
        });
        setRenderCard(true);
      }
    }
  }

  useEffect(() => checkQueryParameters(), []);

  const [password, setPassword] = useState({ password: '', passwordCheck: '' });
  const [passwordInputStyles, togglePasswordInputStyles] = useState({
    password: registerStyles.input, passwordCheck: registerStyles.input,
  });
  const [validPassword, setValidPassword] = useState({
    valid: false, showWarning: false, bar: { fill: '#f8f9fa', count: 0, msg: '' },
  });
  const [passwordShow, togglePasswordShow] = useState({
    password: { status: false, type: 'password' },
    passwordCheck: { status: false, type: 'password' },
  });
  const [passwordCheckError, setPasswordCheckError] = useState({ status: false, msg: 'Las contraseñas no coinciden' });
  const [passwordResetError, setPasswordResetError] = useState({ status: false, msg: '' });
  const [passwordResetSuccess, setPasswordResetSuccess] = useState({ status: false, msg: 'Contraseña actualizada exitosamente' });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleRedirectRegister = () => navigate('/auth/register');
  const handleRedirectLogin = () => navigate('/auth/login');

  function handlePasswordInput(e) {
    const { name, value } = e.target;

    setPassword({ ...password, [name]: value });
  }

  useEffect(() => {
    togglePasswordInputStyles({
      password: registerStyles.input, passwordCheck: registerStyles.input,
    });
    setPasswordCheckError({ ...passwordCheckError, status: false });
  }, [password]);

  useEffect(() => togglePasswordInputStyles((!validPassword.showWarning)
    ? { ...passwordInputStyles, password: registerStyles.input }
    : { ...passwordInputStyles, password: registerStyles.invalid__input }),
  [validPassword.showWarning]);
  useEffect(() => togglePasswordInputStyles((!passwordCheckError.status)
    ? { ...passwordInputStyles, passwordCheck: registerStyles.input }
    : { ...passwordInputStyles, passwordCheck: registerStyles.invalid__input }),
  [passwordCheckError]);

  const handlePasswordReset = async () => {
    if (!validPassword.valid) return setValidPassword({ ...validPassword, showWarning: true });
    if (password.password !== password.passwordCheck) {
      return setPasswordCheckError({ ...passwordCheckError, status: true });
    }

    setLoading(true);
    const response = await passwordResetRequest({
      ...resetPasswordData, newPassword: password.password,
    });
    setLoading(false);

    const responseStatus = response.status;

    switch (responseStatus) {
      case 400:
      case 406:
        return setPasswordResetError({ status: true, msg: 'Error, datos inválidos' });
      case 404:
        return setPasswordResetError({ status: true, msg: 'Error, usuario no encontrado o datos inválidos' });
      case 202:
        setPasswordResetSuccess({ ...passwordResetSuccess, status: true });
        return setTimeout(() => navigate('/auth/school'), 1000);
      default:
        return setPasswordResetError({ status: true, msg: 'Error' });
    }
  };

  const handlePasswordShow = (type) => {
    togglePasswordShow((passwordShow[type].status)
      ? { ...passwordShow, [type]: { status: !passwordShow[type].status, type: 'password' } }
      : { ...passwordShow, [type]: { status: !passwordShow[type].status, type: 'text' } });
  };

  useEffect(() => regControl
    .validatePassword(password.password, setValidPassword), [password.password]);

  const renderPasswordStrengthBar = () => {
    const { bar } = validPassword;
    const chargeArr = [];
    let i = 1;
    const total = 4;
    while (i <= total) {
      if (i <= bar.count) {
        chargeArr.push({ id: i, fill: bar.fill });
      } else {
        chargeArr.push({ id: i, fill: '#f8f9fa' });
      }
      i += 1;
    }

    return (
      <div className={registerStyles.password__strength}>
        {chargeArr.map((c) => <ChargeBar key={c.id} width={30} fill={c.fill} />)}
        <span className={registerStyles.password__bar__msg} style={{ color: bar.fill }}>
          {bar.msg}
        </span>
      </div>
    );
  };

  return renderCard ? (
    <div className={registerStyles.card__container}>
      <div className={registerStyles.logo__container}>
        <img src={logo} alt="Vacanted" />
      </div>
      <h2 className={registerStyles.subtitle}>
        Restablezca su contraseña
      </h2>
      <form className={registerStyles.form}>
        <div className={registerStyles.input__with__icon}>
          <input
            className={passwordInputStyles.password}
            placeholder="Define una nueva contraseña"
            type={passwordShow.password.type}
            name={Object.keys(password)[0]}
            maxLength={20}
            onChange={handlePasswordInput}
          />
          {passwordShow.password.status
            ? (
              <FaRegEye
                className={registerStyles.input__icon}
                size={18}
                onClick={() => handlePasswordShow(Object.keys(password)[0])}
              />
            )
            : (
              <FaRegEyeSlash
                className={registerStyles.input__icon}
                size={18}
                onClick={() => handlePasswordShow(Object.keys(password)[0])}
              />
            )}
        </div>
        {validPassword.showWarning
          && <p className={registerStyles.invalid__input__text}>Contraseña inválida</p>}
        {renderPasswordStrengthBar()}
        <span className={registerStyles.email__info}>
          Debe contener como mínimo: 08 (ocho) caracteres, 1 (una) mayúscula, 1 (un)
          número, 1 (un) caracter especial.
        </span>
        <div className={registerStyles.input__with__icon}>
          <input
            className={`${passwordInputStyles.passwordCheck} ${passwordResetStyles.last__input}`}
            placeholder="Ingrese la nueva contraseña una vez más"
            type={passwordShow.passwordCheck.type}
            name={Object.keys(password)[1]}
            maxLength={20}
            onChange={handlePasswordInput}
          />
          {passwordShow.passwordCheck.status
            ? (
              <FaRegEye
                className={`${registerStyles.input__icon} ${passwordResetStyles.last__input__icon}`}
                size={18}
                onClick={() => handlePasswordShow(Object.keys(password)[1])}
              />
            )
            : (
              <FaRegEyeSlash
                className={`${registerStyles.input__icon} ${passwordResetStyles.last__input__icon}`}
                size={18}
                onClick={() => handlePasswordShow(Object.keys(password)[1])}
              />
            )}
        </div>
        {passwordCheckError.status
          && (
            <p className={`${registerStyles.invalid__input__text} ${passwordResetStyles.invalid__last__input__text}`}>
              {passwordCheckError.msg}
            </p>
          )}
        <button
          className={passwordStyles.btn__send}
          type="button"
          onClick={handlePasswordReset}
        >
          Restablecer contraseña
        </button>
        <BarLoader loading={loading} color="#3e95e6" width="100%" />
        {passwordResetSuccess.status
          && <p className={registerStyles.successful__input__text}>{passwordResetSuccess.msg}</p>}
        {passwordResetError.status
          && <p className={registerStyles.invalid__input__text}>{passwordResetError.msg}</p>}
        <div className={`${registerStyles.form__link} ${passwordStyles.card__footer__link}`}>
          <button type="button" onClick={handleRedirectLogin} className={registerStyles.link__btn}>
            Inicia Sesión
          </button>
          <button type="button" onClick={handleRedirectRegister} className={registerStyles.link__btn}>
            Regístrate
          </button>
        </div>
      </form>
    </div>
  )
    : (
      <p style={{ textAlign: 'center' }}>Ooops.. página no encontrada</p>
    );
}

export default PasswordReset;

PasswordReset.propTypes = { props: PropTypes.string.isRequired };
