import React from 'react';
import PropTypes from 'prop-types';

const PlaneIcon = ({ className }) => (
  <svg className={className} width="155" height="101" viewBox="0 0 155 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M154.58 14.9836C151.718 19.1225 149.055 23.4228 146.536 27.799C144.009 32.1753 141.618 36.6275 139.254 41.0891L132.179 54.4646C129.788 58.8978 127.36 63.3025 124.788 67.6123L126.11 67.7832C124.815 64.1569 122.985 60.8818 121.119 57.6542C119.217 54.4456 117.261 51.3035 115.431 48.0664C116.146 49.7941 116.962 51.4743 117.804 53.1451L120.304 58.1479C121.147 59.8091 121.953 61.4799 122.714 63.1601C123.475 64.8404 124.181 66.5491 124.715 68.2863L125.177 69.7862L126.029 68.4477C128.755 64.1948 131.31 59.8186 133.728 55.3759C136.174 50.9427 138.457 46.4241 140.739 41.9245L147.542 28.3876C149.825 23.888 152.153 19.4073 154.58 14.9741V14.9836Z" fill="url(#paint0_linear_235_308)" />
    <path d="M153.557 15.3159C145.323 22.2552 137.089 29.1471 128.774 35.9345L116.255 46.0634L103.719 56.1829L104.679 56.724C104.706 54.1419 105.059 51.5314 105.413 48.9208C105.775 46.3103 106.237 43.7092 106.527 41.0797L104.724 48.7784C104.145 51.351 103.665 53.971 103.465 56.667L103.375 57.977L104.434 57.2081C108.773 54.047 113.058 50.8194 117.306 47.5253L123.629 42.5131L129.852 37.3774C138.094 30.4476 146.075 23.157 153.557 15.3254V15.3159Z" fill="url(#paint1_linear_235_308)" />
    <path d="M154.544 14.2339C150.305 15.8667 146.138 17.6513 142.017 19.5499C137.886 21.439 133.819 23.4895 129.815 25.6823C125.839 27.9132 121.889 30.2389 118.157 32.8874C114.389 35.4695 110.648 38.118 107.016 40.909C111.056 38.7826 114.987 36.4947 118.882 34.1595C119.86 33.5804 120.83 32.9919 121.799 32.3938L124.743 30.6946C126.699 29.5554 128.674 28.4448 130.667 27.3626C134.625 25.1507 138.629 23.0433 142.623 20.8884C146.618 18.743 150.622 16.5786 154.544 14.2339Z" fill="url(#paint2_linear_235_308)" />
    <path d="M106.391 41.4121C105.648 40.1211 104.643 39.0199 103.547 38.0611C102.451 37.1023 101.273 36.2669 100.041 35.5455C98.8095 34.8145 97.5323 34.207 96.237 33.6659C94.9326 33.1248 93.6101 32.6596 92.2061 32.3179C93.2568 33.3526 94.4163 34.1975 95.5938 34.9854C96.7714 35.7733 97.958 36.5043 99.1627 37.1972L106.391 41.4121Z" fill="url(#paint3_linear_235_308)" />
    <path d="M153.747 14.2336C148.475 15.031 143.23 16.0373 138.031 17.2524C132.822 18.439 127.668 19.8249 122.532 21.3153C117.405 22.8342 112.315 24.467 107.269 26.2706C102.224 28.0553 97.242 30.0298 92.3235 32.1847C97.4685 30.7228 102.586 29.2229 107.686 27.6851L123.012 23.138C128.121 21.6191 133.239 20.1572 138.357 18.6763C143.484 17.2239 148.602 15.7335 153.738 14.2241L153.747 14.2336Z" fill="url(#paint4_linear_235_308)" />
    <path d="M95.1493 62.1561L93.0441 64.8909C93.0441 64.8909 93.0154 64.9862 93.0461 65.0172C93.0767 65.0482 93.137 65.0471 93.1666 65.0149L95.1419 62.1641L95.1493 62.1561Z" fill="url(#paint5_linear_235_308)" />
    <path d="M89.2159 69.1432L86.7503 71.4981L86.7354 71.5142C86.6166 71.6271 86.6119 71.8089 86.7194 71.9332C86.8343 72.0494 87.0003 72.062 87.1191 71.9491C87.1191 71.9491 87.1192 71.949 87.1266 71.941L89.5299 69.4609C89.6114 69.3725 89.6092 69.2303 89.5324 69.137C89.4481 69.0517 89.3199 69.0464 89.2307 69.1271L89.2159 69.1432Z" fill="url(#paint6_linear_235_308)" />
    <path d="M82.3371 75.0481L79.5317 76.9361L79.5095 76.9603C79.293 77.0988 79.2298 77.3924 79.3689 77.611C79.5081 77.8295 79.7881 77.8952 79.9969 77.7488C79.9969 77.7488 80.0043 77.7408 80.0117 77.7328L82.7847 75.703C82.9636 75.5731 83.0049 75.3194 82.8813 75.1322C82.7654 74.9527 82.5309 74.902 82.3517 75.0162L82.3369 75.0322L82.3371 75.0481Z" fill="url(#paint7_linear_235_308)" />
    <path d="M74.6512 79.624L71.5952 80.9641L71.5503 80.9808C71.2507 81.1131 71.0979 81.4717 71.2312 81.7932C71.3568 82.1069 71.6986 82.2662 72.0056 82.1258C72.0056 82.1258 72.0056 82.1258 72.013 82.1177L75.1125 80.6661C75.3896 80.5421 75.5198 80.1918 75.387 79.902C75.2694 79.6119 74.9431 79.4839 74.6734 79.5998L74.6512 79.624Z" fill="url(#paint8_linear_235_308)" />
    <path d="M66.3282 82.6548L63.0952 83.3188L63.0426 83.3277C62.6591 83.4064 62.4161 83.7827 62.4903 84.1843C62.5645 84.586 62.923 84.8399 63.3064 84.7613C63.3064 84.7613 63.3064 84.7612 63.3138 84.7532L66.6432 83.9846C66.9965 83.9065 67.2244 83.5305 67.1433 83.1685C67.0696 82.7984 66.7267 82.5759 66.3732 82.6381L66.3205 82.647L66.3282 82.6548Z" fill="url(#paint9_linear_235_308)" />
    <path d="M57.6263 83.7627L54.3428 83.6451L54.2748 83.6386C53.8295 83.6235 53.4581 83.9945 53.4351 84.4535C53.4198 84.9203 53.7729 85.3086 54.2032 85.324C54.2032 85.324 54.2032 85.3239 54.2183 85.3236L57.6362 85.3596C58.0588 85.3671 58.4003 85.0126 58.4084 84.5697C58.4092 84.1348 58.0867 83.7775 57.6715 83.7619L57.6035 83.7553L57.6263 83.7627Z" fill="url(#paint10_linear_235_308)" />
    <path d="M48.9352 82.8157L45.7603 81.9526L45.692 81.9302C45.207 81.7974 44.7139 82.1075 44.5935 82.608C44.4731 83.1085 44.7605 83.6327 45.2378 83.7578L45.2454 83.7655L48.5784 84.6018C49.048 84.7191 49.5186 84.4173 49.6316 83.9249C49.7372 83.4405 49.458 82.9557 49.0108 82.83L48.9426 82.8076L48.9352 82.8157Z" fill="url(#paint11_linear_235_308)" />
    <path d="M40.6637 79.9164L37.7712 78.328L37.6951 78.2821C37.2308 78.0302 36.6753 78.215 36.4262 78.6944C36.1848 79.1815 36.36 79.7633 36.8168 80.0233L39.8763 81.6717C40.3329 81.9158 40.8884 81.731 41.1223 81.2519C41.3563 80.7728 41.1811 80.191 40.7322 79.9546L40.656 79.9087L40.6637 79.9164Z" fill="url(#paint12_linear_235_308)" />
    <path d="M33.3175 75.063L30.919 72.7607L30.85 72.6908C30.4517 72.319 29.8406 72.3388 29.4698 72.7574C29.1064 73.1679 29.1317 73.816 29.5226 74.196L32.089 76.6215C32.4873 76.9934 33.0907 76.9658 33.4464 76.5475C33.7947 76.1373 33.7697 75.505 33.3865 75.1328L33.3175 75.063Z" fill="url(#paint13_linear_235_308)" />
    <path d="M27.494 68.3792L25.8408 65.4617L25.7787 65.3522C25.4992 64.8515 24.8779 64.6897 24.3995 64.9837C23.9212 65.2778 23.7654 65.9293 24.0449 66.43L25.8368 69.5347C26.1238 70.0273 26.7374 70.1814 27.2007 69.8876C27.6713 69.5858 27.82 68.9581 27.5405 68.4574L27.4861 68.3557L27.494 68.3792Z" fill="url(#paint14_linear_235_308)" />
    <path d="M23.8564 60.2243C23.5678 59.1388 23.362 58.0438 23.2239 56.9396C23.1542 56.34 22.6269 55.9154 22.0547 55.9899C21.4825 56.0643 21.0762 56.6178 21.146 57.2174C21.3008 58.432 21.5385 59.6371 21.8662 60.8088C22.0186 61.383 22.5896 61.7119 23.1378 61.5509C23.6861 61.39 24.0011 60.7908 23.8487 60.2166L23.8564 60.2243Z" fill="url(#paint15_linear_235_308)" />
    <path d="M23.2399 51.3975C23.3217 50.8426 23.389 50.3196 23.5318 49.8109C23.6596 49.3025 23.8403 48.801 24.0364 48.315C24.269 47.7571 24.0323 47.0978 23.5006 46.8552C22.9688 46.6127 22.3542 46.8618 22.1139 47.412C21.8591 47.994 21.6574 48.5987 21.4861 49.2186C21.3148 49.8385 21.2194 50.4885 21.1306 51.0831C21.0422 51.6935 21.4358 52.2628 22.018 52.3542C22.6002 52.4455 23.1443 52.0318 23.2328 51.4214L23.2323 51.3898L23.2399 51.3975Z" fill="url(#paint16_linear_235_308)" />
    <path d="M27.1233 44.4818C27.9236 44.0472 28.7821 43.9513 29.6084 44.2119C30.1467 44.3832 30.7151 44.0716 30.8797 43.5071C31.0446 42.9584 30.7486 42.3634 30.2329 42.1837L30.2252 42.176C28.913 41.7194 27.3695 41.8998 26.1879 42.5553L26.1731 42.5714C25.6645 42.8502 25.4709 43.4943 25.7357 44.0267C26.0005 44.5591 26.6221 44.7525 27.1233 44.4818Z" fill="url(#paint17_linear_235_308)" />
    <path d="M32.6487 47.6768C32.9505 48.6355 33.0499 49.6851 32.9681 50.7224C32.9167 51.2927 33.3168 51.7908 33.8608 51.8434C34.3972 51.8883 34.8736 51.4837 34.9327 50.9212C35.0558 49.63 34.9442 48.2881 34.5547 47.0228C34.3801 46.4728 33.8247 46.1753 33.2995 46.3595C32.7743 46.5438 32.4892 47.1265 32.6638 47.6765L32.6487 47.6768Z" fill="url(#paint18_linear_235_308)" />
    <path d="M31.0902 55.5152C30.7724 55.9244 30.4237 56.2869 30.0367 56.6105C29.8432 56.7724 29.619 56.9032 29.4102 57.0495L28.7836 57.4885L28.6568 57.5779C28.2167 57.8789 28.1057 58.4975 28.3843 58.9504C28.6703 59.3952 29.2454 59.5263 29.6782 59.2333L30.4542 58.7124C30.7078 58.5335 30.9767 58.3702 31.2223 58.1679C31.7136 57.7631 32.1588 57.2961 32.5581 56.7983C32.9059 56.3727 32.8507 55.7416 32.4454 55.3781C32.04 55.0146 31.4377 55.0738 31.09 55.4994L31.0902 55.5152Z" fill="url(#paint19_linear_235_308)" />
    <path d="M23.8641 58.753C22.823 58.7575 21.7797 58.6198 20.772 58.347C20.2871 58.2141 19.7866 58.5323 19.6588 59.0408C19.5309 59.5493 19.8257 60.0654 20.3107 60.1982C21.4699 60.5155 22.6571 60.69 23.8567 60.6902C24.3698 60.696 24.7855 60.2609 24.7921 59.7231C24.7988 59.1854 24.3846 58.7507 23.8715 58.7449L23.8641 58.753Z" fill="url(#paint20_linear_235_308)" />
    <path d="M16.0771 55.971C15.2216 55.3233 14.4247 54.5637 13.6949 53.7475C13.38 53.3977 12.859 53.3841 12.5168 53.7072C12.1898 54.03 12.1681 54.5683 12.4679 54.9184C13.2445 55.8287 14.0955 56.6742 15.0356 57.4231C15.4179 57.732 15.9601 57.6582 16.2558 57.2569C16.5514 56.8556 16.4821 56.2874 16.0998 55.9785L16.0845 55.963L16.0771 55.971Z" fill="url(#paint21_linear_235_308)" />
    <path d="M10.523 49.188C10.281 48.6787 9.98669 48.1941 9.77471 47.6684L9.10141 46.1157L9.07789 46.0608C8.91339 45.6765 8.48821 45.5109 8.12117 45.6841C7.76922 45.8571 7.60256 46.2953 7.75939 46.6718L8.43442 48.3352C8.64691 48.8925 8.93432 49.4167 9.1844 49.9654C9.36423 50.3652 9.8196 50.5303 10.2015 50.341C10.5834 50.1516 10.7419 49.674 10.562 49.2742C10.562 49.2742 10.562 49.2742 10.5544 49.2665L10.5232 49.2038L10.523 49.188Z" fill="url(#paint22_linear_235_308)" />
    <path d="M7.5499 40.6333L7.11924 37.2261L7.1106 37.1552C7.06731 36.8002 6.75425 36.5612 6.42289 36.5993C6.09178 36.6533 5.85513 36.9583 5.89075 37.3055L6.26356 40.872C6.29968 41.2508 6.62831 41.5211 6.98984 41.4824C7.35136 41.4437 7.61001 41.0987 7.57389 40.7199L7.55757 40.6411L7.5499 40.6333Z" fill="url(#paint23_linear_235_308)" />
    <path d="M7.17026 31.4856L7.6522 28.0692L7.65888 28.0138C7.69966 27.7284 7.51445 27.4632 7.24225 27.4211C6.97772 27.3868 6.73176 27.5734 6.68306 27.8352L6.06689 31.3491C6.01152 31.6663 6.21227 31.9628 6.50721 32.0124C6.80982 32.0697 7.09311 31.8586 7.14106 31.5494C7.14106 31.5494 7.14107 31.5494 7.14848 31.5413L7.15516 31.4859L7.17026 31.4856Z" fill="url(#paint24_linear_235_308)" />
    <path d="M9.16289 22.5155L10.5081 19.3361L10.5303 19.3119C10.5879 19.1369 10.5169 18.9408 10.3649 18.8647C10.2055 18.7967 10.0256 18.8634 9.95269 19.0229L8.44935 22.2134C8.35445 22.4128 8.43353 22.6483 8.6236 22.7473C8.81367 22.8464 9.0386 22.7629 9.1335 22.5635C9.1335 22.5635 9.13351 22.5635 9.14092 22.5555L9.17056 22.5233L9.16289 22.5155Z" fill="url(#paint25_linear_235_308)" />
    <path d="M13.4567 14.4284L15.6234 11.7713C15.6901 11.6989 15.6812 11.6122 15.6125 11.5583C15.5589 11.504 15.4762 11.5135 15.4243 11.5698L13.1207 14.1505C13.0392 14.239 13.0414 14.3812 13.1331 14.4584C13.2173 14.5436 13.3529 14.541 13.4268 14.4447L13.4416 14.4286L13.4567 14.4284Z" fill="url(#paint26_linear_235_308)" />
    <defs>
      <linearGradient id="paint0_linear_235_308" x1="154.58" y1="42.4718" x2="120.622" y2="42.4718" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint1_linear_235_308" x1="153.557" y1="36.7178" x2="110.028" y2="36.7178" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint2_linear_235_308" x1="154.544" y1="27.616" x2="113.318" y2="27.616" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint3_linear_235_308" x1="106.391" y1="36.8802" x2="94.0869" y2="36.8802" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint4_linear_235_308" x1="153.747" y1="23.2345" x2="100.468" y2="23.2345" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint5_linear_235_308" x1="94.2929" y1="63.7925" x2="93.9874" y2="63.511" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint6_linear_235_308" x1="88.3358" y1="70.757" x2="87.9119" y2="70.3663" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint7_linear_235_308" x1="81.6651" y1="76.9299" x2="80.6572" y2="76.0014" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint8_linear_235_308" x1="74.1784" y1="81.7428" x2="72.5839" y2="80.2738" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint9_linear_235_308" x1="65.9773" y1="84.8739" x2="63.8481" y2="82.9122" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint10_linear_235_308" x1="57.3722" y1="86.0083" x2="54.722" y2="83.5666" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint11_linear_235_308" x1="48.8029" y1="85.0072" x2="45.7142" y2="82.1615" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint12_linear_235_308" x1="40.6192" y1="81.8589" x2="37.2609" y2="78.7649" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint13_linear_235_308" x1="33.3815" y1="76.6191" x2="29.8769" y2="73.3903" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint14_linear_235_308" x1="27.716" y1="69.3781" x2="24.2366" y2="66.1725" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint15_linear_235_308" x1="24.2557" y1="60.549" x2="21.1023" y2="57.6437" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint16_linear_235_308" x1="23.9599" y1="50.9272" x2="21.5348" y2="48.6929" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint17_linear_235_308" x1="29.7643" y1="44.885" x2="27.0441" y2="42.3788" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint18_linear_235_308" x1="35.4005" y1="50.7252" x2="32.4523" y2="48.0089" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint19_linear_235_308" x1="31.3543" y1="58.1417" x2="29.8296" y2="56.737" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint20_linear_235_308" x1="23.8368" y1="61.1463" x2="20.8897" y2="58.4311" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint21_linear_235_308" x1="16.1515" y1="57.3783" x2="12.8588" y2="54.3447" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint22_linear_235_308" x1="10.8283" y1="49.7347" x2="7.77606" y2="46.9226" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint23_linear_235_308" x1="8.11954" y1="40.4858" x2="5.55751" y2="38.1253" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint24_linear_235_308" x1="7.9494" y1="30.8156" x2="6.01705" y2="29.0353" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint25_linear_235_308" x1="10.1534" y1="21.4844" x2="8.98492" y2="20.4079" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
      <linearGradient id="paint26_linear_235_308" x1="14.5716" y1="13.2182" x2="14.2592" y2="12.9303" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4294E6" />
        <stop offset="1" stopColor="#5EACFA" />
      </linearGradient>
    </defs>
  </svg>

);

PlaneIcon.propTypes = {
  className: PropTypes.string,
};

PlaneIcon.defaultProps = {
  className: null,
};

export default PlaneIcon;
