import Leaflet from 'leaflet';
import mapMarkerImg from '../../assets_vacanted/circulo_ubicacion_institucion.png';

const happyMapIcon = Leaflet.icon({
  iconUrl: mapMarkerImg,

  iconSize: [15, 15],
  // iconAnchor: [29, 68],
  // popupAnchor: [0, -60],
});

export default happyMapIcon;
