export default function clearReligions(name, value, filters, setFilters) {
  setFilters({
    ...filters,
    [name]: value,
    'religion_id-2': false,
    'religion_id-3': false,
    'religion_id-4': false,
    'religion_id-5': false,
    'religion_id-6': false,
    'religion_id-7': false,
    'religion_id-8': false,
    'religion_id-9': false,
    'religion_id-10': false,
    'religion_id-11': false,
    'religion_id-12': false,
    'religion_id-13': false,
    'religion_id-14': false,
  });
}
