import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { BiDollar } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import moneyMask from 'utils/MoneyMask/moneyMask';
import SimpleInput from '../Input/SimpleInput';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import specificStyles from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';

const PageTen = ({ card, disableNext }) => {
  const [familyInfoData, setFamilyInfoData] = useState({
    deceasedParent: null,
    deceasedParentDescription: '',
    details: [{ details: '', monthlyCost: 0 }],
  });
  const [deceasedParent, setDeceasedParent] = useState();
  const [deceasedParentDescription, setDeceasedParentDescription] = useState('');
  const [details, setDetails] = useState([]);
  const [monthlyCost, setMonthlyCost] = useState([]);
  const [dropdownController, setDropdownController] = useState({ active: 1, max: 3 });
  const didMountRef = useRef(false);

  const getInitialData = () => {
    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);

      if (defaultValues) {
        if (defaultValues.details.length > 0) {
          const defaultDetails = [];
          const defaultMonthlyCost = [];

          defaultValues.details.forEach((dv) => {
            defaultDetails.push(dv.details);
            defaultMonthlyCost.push(dv.monthlyCost);
          });

          setDropdownController((dd) => ({ ...dd, active: defaultValues.details.length }));
          setDeceasedParent(defaultValues.deceasedParent);
          setDeceasedParentDescription(defaultValues.deceasedParentDescription);
          setDetails([...defaultDetails]);
          setMonthlyCost([...defaultMonthlyCost]);
        }
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => getInitialData(), []);

  const isCardDataValid = () => {
    if (typeof familyInfoData.deceasedParent !== 'boolean' || (familyInfoData.deceasedParent && familyInfoData.deceasedParentDescription === '')) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    let treatedDetails = [];
    if (didMountRef.current && familyInfoData.details.length > 0) {
      treatedDetails = details.reduce((acc, d, i) => ([
        ...acc,
        { details: d, monthlyCost: monthlyCost[i] || 0 },
      ]), []);
    }

    const treatedData = {
      deceasedParent,
      deceasedParentDescription,
      details: treatedDetails.length > 0 ? treatedDetails : [{ details: '', monthlyCost: 0 }],
    };

    setFamilyInfoData(treatedData);
  }, [deceasedParent, deceasedParentDescription, details, monthlyCost]);

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageFamilyInfo.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (!isCardDataValid()) removeCardFromLocalStorage();
      else saveCardInLocalStorage(familyInfoData);
    }
  }, [familyInfoData]);

  const validateNumbers = (value) => {
    const numberRegex = /\D+/g;
    const onlyNumbers = value;
    if (value) {
      return onlyNumbers.replace(numberRegex, '');
    }

    return value;
  };

  const saveDetails = (detailsObj, id) => {
    const aux = details;
    aux[id - 1] = detailsObj;
    setDetails([...aux]);
  };

  const saveMonthlyCost = (e, id) => {
    const aux = monthlyCost;
    monthlyCost[id - 1] = validateNumbers(e);
    setMonthlyCost([...aux]);
  };

  const addLevelDropdown = () => {
    if (dropdownController.active < dropdownController.max) {
      setDropdownController({ ...dropdownController, active: dropdownController.active + 1 });
    }
  };

  const renderAddDropdownButton = () => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => addLevelDropdown()}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlinePlus size={25} color="#333" />
      </div>
      <p>Agrega otra situación</p>
    </button>
  );

  const checkAddDropdownConditions = (id) => {
    if (dropdownController.active >= dropdownController.max) return false;
    const situations = familyInfoData.details;
    if (!situations) return false;
    if (situations.length < dropdownController.active) return false;
    if (situations[dropdownController.active - 1].details === '') return false;
    if (id !== dropdownController.active) return false;
    if (situations[dropdownController.active - 1].monthlyCost <= 0) return false;

    return true;
  };

  const removeLevelDropdown = (id) => {
    const auxD = details;
    const auxMC = monthlyCost;
    auxD.splice(id - 1, 1);
    auxMC.splice(id - 1, 1);
    setDetails([...auxD]);
    setMonthlyCost([...auxMC]);
    setDropdownController((dd) => ({ ...dd, active: dd.active - 1 }));
  };

  const renderRemoveDropdownButton = (id) => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => removeLevelDropdown(id)}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlineMinus size={25} color="#333" />
      </div>
      <p>Quita una situación</p>
    </button>
  );

  const checkRemoveDropdownConditions = (id) => {
    if (dropdownController.active <= 1) return false;
    if (dropdownController.active !== id) return false;

    return true;
  };

  const renderDropdown = () => {
    const dropdonwsArray = [];

    for (let i = 1; i <= dropdownController.active; i += 1) {
      dropdonwsArray.push(i);
    }

    return dropdonwsArray.map((id) => (
      <div
        key={id}
        style={{
          marginTop: '50px',
        }}
      >
        <p className={specificStyles.bold__subtitle}>{`Detalle de situación ${id}`}</p>
        <textarea
          type="text"
          value={details[id - 1]}
          onChange={(e) => saveDetails(e.target.value, id)}
          className={specificStyles.text__area}
          placeholder="Descripción"
        />
        <div className={specificStyles.input__with__icon}>
          <SimpleInput
            placeholder="0.000,00"
            inputValue={moneyMask(monthlyCost[id - 1])}
            setInputValue={(e) => saveMonthlyCost(e.target.value, id)}
            maxLength={9}
          />
          <div className={specificStyles.img__container}>
            <BiDollar size={20} color="black" />
          </div>
        </div>
        <hr />
        {checkAddDropdownConditions(id) && renderAddDropdownButton()}
        {checkRemoveDropdownConditions(id) && renderRemoveDropdownButton(id)}
      </div>
    ));
  };

  return (
    <div className={rStyles.vacantest__card__content}>
      <>
        <div className={specificStyles.page__title}>
          <h3>Capacidad de pago - Varios</h3>
          <p>
            ¿Algún integrante del núcleo familiar es fallecido?
          </p>
        </div>
        <div
          className={rStyles.vacantest__section__container}
        >
          <form action="">
            <div className={specificStyles.radio_inputs}>
              <input
                className={specificStyles.vacantest__checkbox}
                type="radio"
                checked={deceasedParent}
                value="Yes"
                name="specialNeedsRadio"
                onChange={(e) => setDeceasedParent(e.target.checked)}
              />
              <p>Si</p>
            </div>
            <div className={specificStyles.radio_inputs}>
              <input
                className={specificStyles.vacantest__checkbox}
                type="radio"
                name="specialNeedsRadio"
                checked={deceasedParent === false}
                value="No"
                onChange={() => setDeceasedParent(false)}
              />
              <p>No</p>
            </div>
            <br />
            <div
              className={rStyles.vacantest__section__container}
              style={{
                padding: '0',
                marginTop: '-5%',
              }}
            >
              {deceasedParent && (
                <>
                  <p className={specificStyles.bold__subtitle}>
                    Detalle del integrante fallecido
                  </p>
                  <textarea
                    type="text"
                    value={deceasedParentDescription}
                    onChange={(e) => setDeceasedParentDescription(e.target.value)}
                    className={specificStyles.text__area}
                    placeholder="Descripción libre"
                  />
                </>
              )}
              <hr />
            </div>
            <div
              className={rStyles.vacantest__section__container}
              style={{
                padding: '0',
                marginTop: '-5%',
              }}
            >
              <p style={{ textAlign: 'justify' }}>
                Detalle hasta 3 situaciones especiales que impliquen un
                costo mensual adicional permanente y su impacto en el
                presupuesto familiar.
              </p>
              {renderDropdown()}
            </div>
          </form>
        </div>
      </>
    </div>
  );
};

export default PageTen;

PageTen.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
