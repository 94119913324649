/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from 'services/api';
import useResponsive from '../../hooks/useResponsive';
import SchoolItem, { SchoolAction, SchoolNameAddress, SchoolPicture } from '../school-item';
import InputSearch from '../input-search';

import './search.scss';

const SchoolSearch = ({
  onClick, selected, ageNumbersList, customPositiveAction,
}) => {
  const { isDesktop } = useResponsive();
  const [schoolsState, setSchoolsState] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const schoolsToStateMapper = (schoolList) => schoolList.map(({
    id, name, logo, address,
  }) => ({
    id,
    name,
    address,
    picture: logo,
    selected: selected.includes(id),
  }));

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const query = searchQuery.trim();
    if (query && query.length >= 3) {
      const timeoutId = setTimeout(() => {
        setLoading(true);
        const url = `school/searchschools?q=${query}${ageNumbersList?.length ? `&ages=${ageNumbersList.join(',')}` : ''}`;
        api.get(url).then((schoolsResponse) => {
          const schools = schoolsResponse?.data ?? [];
          setSchoolsState(schoolsToStateMapper(schools));
        }).finally(() => setLoading(false));
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    setSchoolsState([]);
  }, [searchQuery]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const onReset = () => {
    setSearchQuery('');
    setSchoolsState([]);
  };

  useEffect(() => {
    setSchoolsState(schoolsState.map((item) => ({
      ...item,
      selected: selected.includes(item.id),
    })));
  }, [selected]);

  const getActionKey = (isSelected) => (isSelected ? 'remove' : 'add');

  return (
    <div className="school-search">
      <InputSearch
        placeholder="Buscá por nombre del centro"
        value={searchQuery}
        loading={loading}
        onChange={handleSearch}
        onReset={onReset}
      />
      { !!schoolsState.length && (
        <div className="school-search__list">
          {
            schoolsState.map((school) => (
              <SchoolItem
                school={school}
                key={school.id}
                onClick={isDesktop
                  ? null
                  : () => onClick(school, getActionKey(school.selected), onReset)}
              >
                <SchoolPicture />
                <SchoolNameAddress />
                <SchoolAction
                  onClick={!isDesktop
                    ? null
                    : () => onClick(school, getActionKey(school.selected), onReset)}
                >
                  {school.selected ? 'Quitar' : (customPositiveAction || 'Agregar')}
                </SchoolAction>
              </SchoolItem>
            ))
          }
          <div className="school-search__list__footer">
            * * *
          </div>
        </div>
      )}
    </div>
  );
};

SchoolSearch.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number),
  ageNumbersList: PropTypes.arrayOf(PropTypes.number),
  customPositiveAction: PropTypes.string,
};

SchoolSearch.defaultProps = {
  selected: [],
  ageNumbersList: [],
  customPositiveAction: null,
};

export default SchoolSearch;
