import React from 'react';
import PropTypes from 'prop-types';
import iStyles from './SimpleInput.module.css';

const SimpleInput = ({
  placeholder,
  inputValue,
  setInputValue,
  maxLength,
  disabled,
  type,
  backgroundColor,
}) => (
  <div className={iStyles.input__container}>
    <input
      type={type || 'text'}
      className={iStyles.vacantest__input}
      placeholder={placeholder}
      value={inputValue}
      maxLength={maxLength || 30}
      onChange={(e) => setInputValue(e)}
      disabled={disabled}
      style={{ backgroundColor }}
    />
  </div>
);

export default SimpleInput;

SimpleInput.defaultProps = {
  maxLength: 30,
  inputValue: '',
  disabled: false,
  type: 'text',
  backgroundColor: '',
};

SimpleInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  backgroundColor: PropTypes.string,
  setInputValue: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};
