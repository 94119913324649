import React from 'react';
import { Helmet } from 'react-helmet';
import Header from 'components/Header/Header';
import Footer from 'components/Footer';
import notFoundStyles from './notFoundStyles.module.css';

const NotFound = () => (
  <>
    <Header />
    <Helmet>
      <title>Ups! - VacantED</title>
    </Helmet>
    <div className={notFoundStyles.page__container}>
      <h3>404 Pagina no encontrada</h3>
    </div>
    <div>
      <Footer />
    </div>
  </>
);

export default NotFound;
