/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useField, ErrorMessage } from 'formik';

const LoginPasswordInput = (props) => {
  const [field] = useField(props);
  const [show, setShow] = useState(false);

  return (
    <div className="login-input-container">
      <input {...field} {...props} type={!show ? 'password' : 'text'} />
      <button
        type="button"
        className="show-password"
        onClick={() => setShow(!show)}
        title={!show ? 'Mostrar contraseña' : 'Ocultar contraseña'}
      >
        {!show ? <i className="fa fa-eye fa-2x show-password__icon" /> : <i className="fa fa-eye-slash fa-2x show-password__icon" />}
      </button>
      <ErrorMessage name={props.name} component="span" className="error-message"/>
    </div>
  );
};

export default LoginPasswordInput;
