import React from 'react';
import PropTypes from 'prop-types';

import '../styles/textarea-selector.scss';

const TextAreaSelector = ({ placeholder, value, onChange }) => (
  <textarea className="textarea-selector" placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
);

TextAreaSelector.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextAreaSelector;
