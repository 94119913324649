import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { registerAnalytics } from 'Authentication/Data/Services/firebase';

import './experience-button.scss';

const chevronRigth = () => (
  <svg
    className="chevron-right icon--fill icon--stroke"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9467 12L8.87358 7.9269C8.73511 7.78845 8.66428 7.61442 8.66108 7.4048C8.65786 7.1952 8.72869 7.01797 8.87358 6.8731C9.01844 6.72822 9.19408 6.65578 9.40048 6.65578C9.60688 6.65578 9.78251 6.72822 9.92738 6.8731L14.4216 11.3673C14.5152 11.4609 14.5812 11.5596 14.6197 11.6635C14.6581 11.7673 14.6774 11.8795 14.6774 12C14.6774 12.1205 14.6581 12.2327 14.6197 12.3365C14.5812 12.4404 14.5152 12.5391 14.4216 12.6327L9.92738 17.1269C9.78893 17.2654 9.61489 17.3362 9.40527 17.3394C9.19567 17.3426 9.01844 17.2718 8.87358 17.1269C8.72869 16.982 8.65625 16.8064 8.65625 16.6C8.65625 16.3936 8.72869 16.218 8.87358 16.0731L12.9467 12Z"
    />
  </svg>
);

const chevronLeft = () => (
  <svg
    className="chevron-left icon--fill icon--stroke"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65514 9.99606L12.0494 13.3903C12.1648 13.5057 12.2238 13.6507 12.2264 13.8254C12.2291 14.0001 12.1701 14.1478 12.0494 14.2685C11.9286 14.3892 11.7823 14.4496 11.6103 14.4496C11.4383 14.4496 11.2919 14.3892 11.1712 14.2685L7.42601 10.5233C7.34801 10.4453 7.29299 10.363 7.26095 10.2765C7.22889 10.19 7.21287 10.0965 7.21287 9.99606C7.21287 9.89563 7.22889 9.80215 7.26095 9.71563C7.29299 9.62908 7.34801 9.54682 7.42601 9.46883L11.1712 5.72365C11.2866 5.60826 11.4316 5.54923 11.6063 5.54656C11.7809 5.54388 11.9286 5.60291 12.0494 5.72365C12.1701 5.84437 12.2305 5.99073 12.2305 6.16273C12.2305 6.33473 12.1701 6.48109 12.0494 6.60181L8.65514 9.99606Z"
    />
  </svg>
);

const ExperienceButton = ({
  id, className, onClick, children, chevron, hirachy, size, disabled, loading, iconOnly, title,
  fitContent, reportClickEvent,
}) => {
  const classname = classNames('experience-button', {
    [`experience-button--${size}`]: !!size,
    'experience-button--primary': hirachy === 'primary',
    'experience-button--success': hirachy === 'success',
    'experience-button--transparent': hirachy === 'transparent',
    'experience-button--disabled': disabled,
    'experience-button--icon-only': iconOnly,
    'fit-content': fitContent,
  }, className);

  const clickHandler = () => {
    if (reportClickEvent) {
      registerAnalytics(reportClickEvent);
    }
    if (onClick) onClick();
  };

  return (
    <button id={id} className={classname} type="button" onClick={clickHandler} disabled={disabled} title={title}>
      {loading && chevron === 'left' && <i className="fa fa-spinner fa-pulse fa-1x" />}
      {!loading && chevron === 'left' && chevronLeft()}
      {children}
      {loading && chevron === 'right' && <i className="fa fa-spinner fa-pulse fa-1x" />}
      {!loading && chevron === 'right' && chevronRigth()}
    </button>
  );
};

ExperienceButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  chevron: PropTypes.oneOf(['right', 'left']),
  hirachy: PropTypes.oneOf(['default', 'primary', 'success', 'transparent']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  iconOnly: PropTypes.bool,
  title: PropTypes.string,
  fitContent: PropTypes.bool,
  reportClickEvent: PropTypes.string,
};

ExperienceButton.defaultProps = {
  id: null,
  className: null,
  onClick: null,
  children: null,
  chevron: null,
  hirachy: 'default',
  size: 'small',
  disabled: false,
  loading: false,
  iconOnly: false,
  title: null,
  fitContent: false,
  reportClickEvent: null,
};

export default ExperienceButton;
