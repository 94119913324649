import { getCenterDescription } from '../../utils/schools/schoolFunctions';

/* Data normalization is generally considered the development of clean data.
 Diving deeper, however, the meaning or goal of data normalization is twofold:
 Data normalization is the organization of data to appear similar across all records and fields */

const normalizeSchoolWithMiniCard = (miniCardKeys) => {
  const miniCard = {
    ...miniCardKeys,
  };

  const {
    name,
    schedules,
    countryId,
  } = miniCard;

  /* if the the user has coordinates registered, use it. Otherwise use centerCoordinates */

  // const validCoords = userCoords.latitude ? userCoords : centerDistance;

  miniCard.centerDescription = getCenterDescription(name, schedules, countryId);
  return miniCard;
};

export default function normalizeSchool(data) {
  const {
    city,
    neighborhood,
    name,
    address,
    logo,
    schedules,
    countryId,
    verified_by_institution: verified,
    latitude,
    longitude,
    rating,
    public: schoolManagement,
    user_ratings_total: userRatingsQty,
    schoolAffinity,
    postulate,
    website,
  } = data;

  const miniCardKeys = {
    city,
    neighborhood,
    name,
    address,
    logo,
    latitude,
    longitude,
    schedules,
    countryId,
    verified,
    rating,
    userRatingsQty,
    schoolManagement,
    schoolAffinity,
    postulate,
    website,
  };

  const miniCard = normalizeSchoolWithMiniCard(miniCardKeys);

  return {
    ...data,
    miniCard,
  };
}
