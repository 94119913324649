import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from './useSnackbar';
import useLoading from './useLoading';

const ApplicationContext = createContext();

function ApplicationContextProvider({ children }) {
  const snackbarContext = useSnackbar();
  const loadingContext = useLoading();

  return (
    <ApplicationContext.Provider value={{
      snackbarContext,
      loadingContext,
    }}
    >
      {children}
    </ApplicationContext.Provider>
  );
}

ApplicationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useAppContext = () => {
  const context = useContext(ApplicationContext);
  return context;
};

export default ApplicationContextProvider;
