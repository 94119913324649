import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SingleSectionComponent from 'components/SingleSectionComponent/SingleSectionComponent';
import { assignValueById } from 'utils/Activities/activitiesRemoveDuplicates';
import { activityData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import ProfileSection from '../ProfileSection';

export default function CurricularAdaptation({ isFetching }) {
  const [useAllactivities, setUseAllActivities] = useState();
  const schoolActivities = useSelector(activityData);

  useEffect(() => {
    assignValueById(schoolActivities, 6).then((curricularAdaptationActivities) => {
      if (curricularAdaptationActivities.length === 0) {
        setUseAllActivities(null);
      } else {
        setUseAllActivities({
          curricularAdaptationActivities,
        });
      }
    });
  }, []);

  if (isFetching) {
    return <>...loading</>;
  }

  if (!useAllactivities) {
    return <>{' '}</>;
  }
  return (
    <div>
      <ProfileSection
        title="Adaptación Curricular"
        subtitleLyrics={{
          subtitle: '',
          lyrics: '',
        }}
      >
        <div>
          <SingleSectionComponent
            arrayOfPropertys={useAllactivities.curricularAdaptationActivities}
          />
        </div>
      </ProfileSection>
    </div>
  );
}

CurricularAdaptation.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};
