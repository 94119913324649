import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '../../Snackbar';
import VacantedLogo from '../imgs/vacanted_login.png';
import GoogleButton from '../../Buttons/GoogleButton';
import FacebookButton from '../../Buttons/FacebookButton';
import useAuthentication from './hooks/useAuthentication';

import '../styles/desktop.scss';

const LoginModal = ({ onClose, defaultLoginStep }) => {
  const {
    steps,
    step,
    setStep,
    previousStep,
    errorMessage,
    infoMessage,
    showSocialMedias,
    handleGoogleClick,
    handleFacebookTokenAfterClick,
    loading,
  } = useAuthentication(defaultLoginStep, onClose);

  return (
    <div className="login-modal">
      <div className="login-modal__card">
        <div className="login-modal__container">
          <div className="login-modal__header">
            <img className="login-modal__header__img" src={VacantedLogo} alt="Vacanted" />
            <div className="login-modal__header__container">
              <div className="login-modal__header__title">
                {steps[step]?.title}
              </div>
              <div className="login-modal__header__subtitle">
                {steps[step]?.subtitle}
              </div>
            </div>
          </div>
          <div className="login-modal__forms">
            {steps[step]?.Component()}
            {!!errorMessage && (
              <div className="login-modal_message">
                <span className="login-modal_message--error">{errorMessage}</span>
              </div>
            )}
          </div>
          {
            showSocialMedias && (
              <div className="login-modal__social-medias">
                <div className="login-modal__social-medias__container">
                  <div className="line-39" />
                  <div className="">
                    ó
                  </div>
                  <div className="line-40" />
                </div>
                <GoogleButton
                  onClick={handleGoogleClick}
                  disabled={loading.google}
                  loading={loading.google}
                />
                {false && (
                  <FacebookButton
                    handleToken={handleFacebookTokenAfterClick}
                    disabled={loading.facebook}
                    loading={loading.facebook}
                  />
                )}
              </div>
            )
          }
        </div>
        {previousStep && (
          <svg onClick={() => setStep(previousStep)} className="close back" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8498 15.9935L19.2805 21.4243C19.4652 21.6089 19.5596 21.841 19.5639 22.1204C19.5682 22.3999 19.4737 22.6362 19.2805 22.8294C19.0874 23.0226 18.8532 23.1191 18.578 23.1191C18.3028 23.1191 18.0686 23.0226 17.8755 22.8294L11.8832 16.8371C11.7584 16.7123 11.6703 16.5807 11.6191 16.4422C11.5678 16.3038 11.5421 16.1542 11.5421 15.9935C11.5421 15.8328 11.5678 15.6833 11.6191 15.5448C11.6703 15.4063 11.7584 15.2747 11.8832 15.1499L17.8755 9.15764C18.0601 8.97302 18.2921 8.87857 18.5716 8.87431C18.8511 8.87002 19.0874 8.96446 19.2805 9.15764C19.4737 9.3508 19.5703 9.58497 19.5703 9.86017C19.5703 10.1354 19.4737 10.3696 19.2805 10.5627L13.8498 15.9935Z" fill="black" fillOpacity="0.9" />
          </svg>
        )}
        <svg onClick={onClose} className="close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 17.4051L9.23585 24.1692C9.05125 24.3538 8.8192 24.4482 8.53972 24.4525C8.26025 24.4568 8.02394 24.3624 7.83078 24.1692C7.6376 23.976 7.54102 23.7418 7.54102 23.4666C7.54102 23.1914 7.6376 22.9573 7.83078 22.7641L14.5949 16L7.83078 9.23585C7.64616 9.05125 7.55172 8.8192 7.54745 8.53972C7.54316 8.26025 7.6376 8.02394 7.83078 7.83078C8.02394 7.6376 8.25812 7.54102 8.53332 7.54102C8.80852 7.54102 9.04269 7.6376 9.23585 7.83078L16 14.5949L22.7641 7.83078C22.9487 7.64616 23.1808 7.55172 23.4602 7.54745C23.7397 7.54316 23.976 7.6376 24.1692 7.83078C24.3624 8.02394 24.4589 8.25812 24.4589 8.53332C24.4589 8.80852 24.3624 9.04269 24.1692 9.23585L17.4051 16L24.1692 22.7641C24.3538 22.9487 24.4482 23.1808 24.4525 23.4602C24.4568 23.7397 24.3624 23.976 24.1692 24.1692C23.976 24.3624 23.7418 24.4589 23.4666 24.4589C23.1914 24.4589 22.9573 24.3624 22.7641 24.1692L16 17.4051Z" fill="black" fillOpacity="0.9" />
        </svg>
        {
          infoMessage && (
            <Snackbar message={infoMessage} type="info" onClose={onClose} autoClose />
          )
        }
      </div>
    </div>
  );
};

LoginModal.propTypes = {
  onClose: PropTypes.func,
  defaultLoginStep: PropTypes.string,
};

LoginModal.defaultProps = {
  onClose: () => {},
  defaultLoginStep: null,
};

export default LoginModal;
