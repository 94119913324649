import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { activityData } from 'hooks/SchoolProfileContext/SchoolProfileSlice';
import { assignValueById } from 'utils/Activities/activitiesRemoveDuplicates';
import ProfileSection from '../ProfileSection';

export default function AwardsRecognitions({ isFetching }) {
  const schoolActivities = useSelector(activityData);
  const [awardsRecognitions, setAwardsRecognitions] = useState({});

  useEffect(() => {
    assignValueById(schoolActivities, 17).then(setAwardsRecognitions);
  }, []);

  if (isFetching) {
    return <>...loading</>;
  }

  return (
    <>
      {awardsRecognitions.length > 0 ? (
        <ProfileSection
          title="Premios y Reconocimientos"
          subtitleLyrics={{
            subtitle: '',
            lyrics: awardsRecognitions[0]?.description,
          }}
        />
      ) : null}
    </>
  );
}

AwardsRecognitions.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};
