import React, { useEffect, useState, memo } from 'react';
import Flickity from 'react-flickity-component';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const SliderGallery = ({ flkty, galleryImages, isFetching }) => {
  const [arrayImages, setArrayImages] = useState([]);
  const flktyRef = flkty;
  const flickityOptions = {
    initialIndex: 1,
    // draggable: false,
  };

  useEffect(() => {
    setArrayImages(galleryImages);
  }, []);

  if (isFetching) return <>...cargando </>;
  if (!galleryImages) return <></>;

  return (
    <div>
      <Flickity
        flickityRef={(carouselRef) => {
          flktyRef.current = carouselRef;
        }}
        disableImagesLoaded={false}
        reloadOnUpdate
        className={`${styles.gallery}`}
        options={flickityOptions}
      >
        {arrayImages.map((image) => {
          const imagePath = image.image;
          return <img key={imagePath} alt="" src={imagePath} />;
        })}
      </Flickity>
    </div>
  );
};

SliderGallery.propTypes = {
  flkty: PropTypes.object,
  galleryImages: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
}.isRequired;

export default memo(SliderGallery);
