/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const SchoolItemActions = ({ children }) => (
  <div className="school-item__actions">
    <div className="school-item__actions__buttons">
      { children }
    </div>
  </div>
);

SchoolItemActions.propTypes = {
  children: PropTypes.node,
};

SchoolItemActions.defaultProps = {
  children: null,
};

export default SchoolItemActions;
