import { useEffect, useState } from 'react';
import {
  updateCoordinatesInStorage,
  getCoordsByPlaceId, getCoordinatesInStorage, getAddressSugestions,
} from 'utils/Coordinates/coordinatesFunctions';

function useAddressCoordinates() {
  const [fetchError, setFetchError] = useState('');
  const [addressCoordinates, setAddressCoordinates] = useState({ latitude: '0', longitude: '0' });

  useEffect(() => {
    const storagedCoordinates = getCoordinatesInStorage('search');
    setAddressCoordinates(storagedCoordinates);
  }, []);

  useEffect(() => {
    updateCoordinatesInStorage(addressCoordinates);
  }, [addressCoordinates]);

  const getCoordinatesByPlaceId = async (placeid) => {
    const coordinates = await getCoordsByPlaceId(placeid, setFetchError);
    setAddressCoordinates(coordinates);

    return coordinates;
  };

  const updateAddressSuggestions = async (address, setSuggestions) => {
    await getAddressSugestions(address, setSuggestions);
  };

  return {
    addressCoordinates,
    coordinatesError: fetchError,
    getCoordinatesByPlaceId,
    updateAddressSuggestions,
  };
}

export default useAddressCoordinates;
