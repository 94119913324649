/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SchoolItemContext from '../../context/school-item-context';

const SchoolAction = ({ onClick, children }) => {
  const { id } = useContext(SchoolItemContext);
  return (
    <div className="school-item__action">
      <button
        type="button"
        className="school-item__action__link"
        onClick={onClick ? () => onClick(id) : null}
        onKeyDown={onClick ? () => onClick(id) : null}
      >
        {children}
      </button>
    </div>
  );
};

SchoolAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default SchoolAction;
