import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useAppContext } from 'hooks/ApplicationContext';
import Button from '../button';

import './snackbar.scss';
import CloseIcon from './CloseIcon';
import SuccessIcon from './SuccessIcon';
import ErrorIcon from './ErrorIcon';
import InfoIcon from './InfoIcon';

const Icons = {
  cancel: CloseIcon,
  success: SuccessIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const Snackbar = ({
  icon, hirachy, text, action, onClick, show, onMouseEnter, onMouseLeave,
}) => {
  const {
    snackbarContext: { cancelTimer, scheduleCloseSnackbar },
  } = useAppContext();
  const classNameContainer = classNames('experience-snackbar__container', {
    'experience-snackbar__container--show': show,
  });
  const className = classNames('experience-snackbar', {
    [`experience-snackbar--${hirachy}`]: hirachy,
  });

  const Icon = Icons[icon] || Icons[hirachy] || null;

  return (
    <div
      className={classNameContainer}
      onMouseEnter={onMouseEnter || cancelTimer}
      onMouseLeave={onMouseLeave || (() => scheduleCloseSnackbar())}
    >
      {show && (
      <div className={className}>
        {!!Icon && (
        <div className="experience-snackbar__icons">
          <Icon className="experience-snackbar__icon" />
        </div>
        )}
        <div className="experience-snackbar__message">
          <div className="experience-snackbar__text">{text}</div>
        </div>
        {(onClick && action) && (
          <div className="experience-snackbar__action">
            <Button className="experience-snackbar__button" size="small" onClick={onClick}>
              {action}
            </Button>
          </div>
        )}
      </div>
      )}
    </div>
  );
};

Snackbar.propTypes = {
  icon: PropTypes.string,
  hirachy: PropTypes.oneOf(['info', 'success', 'error']),
  text: PropTypes.string,
  action: PropTypes.string,
  onClick: PropTypes.func,
  show: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

Snackbar.defaultProps = {
  icon: null,
  hirachy: 'info',
  text: null,
  action: null,
  onClick: null,
  show: false,
  onMouseEnter: null,
  onMouseLeave: null,
};

export default Snackbar;
