import React from 'react';
import PropTypes from 'prop-types';

import EducLevelsTranslator from '../../services/educLevelsTranslator';

function DropDownSelect({
  allowInfo, schedules, setChipsLevel, allChipsLevel, clearError,
}) {
  const addLevelOnChips = (e) => {
    const { value } = e.target;
    e.target.value = '';
    if (allChipsLevel.includes(value)) return null;
    if (allChipsLevel.length > 3) return null;
    if (value === '') return null;

    clearError((errors) => {
      const errArr = [...errors];
      const errIndex = errArr.indexOf(errArr.find((err) => err.errorName === 'levels'));
      errArr[errIndex] = { ...errArr[errIndex], msg: '', status: false };
      return [...errArr];
    });

    return setChipsLevel([...allChipsLevel, value]);
  };

  /* function renderOption(item) {
    if (item.level) {
      return (
        <option key={`education_level${item.ages}`} value={item.ages}>{`${item.ages}`}</option>
      );
    }
    return null;
  } */

  function renderOptions() {
    if (!schedules) return '';
    return schedules.filter(({ level }) => !!level).map(({ ages }) => {
      const levelName = EducLevelsTranslator.ageToLevelName(ages);
      return <option key={`education_level${levelName}`} value={levelName}>{`${levelName}`}</option>;
    });
  }

  return (
    <select
      className="dropDownSelectLevel"
      onChange={(e) => addLevelOnChips(e)}
      disabled={`${allowInfo ? 1 : ''}`}
    >
      <option value="" selected>
        {allChipsLevel?.length ? 'Puede seleccionar otro nivel' : 'Seleccione al menos un nivel'}
      </option>
      {schedules ? renderOptions(schedules) : ''}
    </select>
  );
}

export default DropDownSelect;

DropDownSelect.defaultProps = {
  schedules: [],
};

DropDownSelect.propTypes = {
  allowInfo: PropTypes.bool.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape(
    {
      ages: PropTypes.string,
      afternon: PropTypes.bool,
      double_schedule: PropTypes.bool,
      evening: PropTypes.bool,
      id: PropTypes.number,
      level: PropTypes.bool,
      morning: PropTypes.bool,
      school_id: PropTypes.number,
      step: PropTypes.string,
      time_extension: PropTypes.bool,
    },
  )),
  allChipsLevel: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChipsLevel: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};
