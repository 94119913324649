import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

function CircularIconRow({ elements, renderCircularIconText }) {
  return (
    <div className={styles.iconRow}>
      {elements.map((element) => (
        <div className={`${styles.iconContainer} col-4`} key={element.id}>
          <div className={`${styles.iconRow}`}>
            <div className={`${styles.imgCol}  col-2`}>
              <div
                className={`${styles.imgContainer}`}
              >
                <img src={element.icon} alt={`${element.name} img`} className={`${styles.imgContainerImg}`} width={30} />
              </div>
            </div>
            <div className={`${styles.textContainer} col-10`}>
              {renderCircularIconText(element)}

            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CircularIconRow;

CircularIconRow.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  renderCircularIconText: PropTypes.func.isRequired,
};
