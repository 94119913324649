import api from 'services/api';
import { getUrl } from 'urlConfig';
import { parsedDataInStorage, removeDataInStorage } from 'utils/Storage/storageFunctions';

const apiUrl = getUrl('api');

async function logoutFromApp(setUserData, tokenParam) {
  const headers = { 'content-type': 'application/json' };
  const token = tokenParam || parsedDataInStorage('vacanted')?.token;
  if (!token) return;

  const body = { token };

  await api.post(`${apiUrl}/logout`, body, headers)
    .then((r) => {
      if (r.status === 201) {
        removeDataInStorage('vacanted');
        setUserData(() => ({ loginStatus: false, data: { loggedOut: true } }));
      }
    })
    // eslint-disable-next-line
    .catch((e) => console.log(e));

  // window.location.replace('/');
}

export default logoutFromApp;
