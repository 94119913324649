import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import localStorageFamilyInfo from '../../localStorageFamilyInfo';
import specificStyle from './specificStyle.module.scss';
import rStyles from '../DefaultcardStyles.module.scss';

const PageFive = ({ card, disableNext }) => {
  const [familyInfoData, setFamilyInfoData] = useState([]);
  const didMountRef = useRef(false);

  const getInitialData = () => {
    if (!didMountRef.current) {
      disableNext(true);
      const defaultValues = localStorageFamilyInfo.get(card);

      if (defaultValues) {
        setFamilyInfoData(defaultValues);
      }

      didMountRef.current = true;
    }
  };

  useEffect(() => getInitialData(), []);

  const isCardDataValid = () => {
    if (familyInfoData.length > 0) {
      return familyInfoData;
    }
    return false;
  };

  const removeCardFromLocalStorage = () => {
    localStorageFamilyInfo.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageFamilyInfo.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (!isCardDataValid()) removeCardFromLocalStorage();
    else saveCardInLocalStorage(familyInfoData);
  }, [familyInfoData]);

  return (

    <div className={rStyles.vacantest__card__content}>
      <>
        <div>
          <h3>Requerimientos de la Familia</h3>
          <p>
            Por favor, describe los requerimientos que debería cumplir
            la Institución Educativa a la que concurran tus hijos.
          </p>
        </div>
        <div
          className={rStyles.vacantest__section__container}
          style={{
            padding: '0',
            marginTop: '0',
          }}
        >
          <p className={specificStyle.text__area__label}>
            Descripción de los requerimientos
          </p>
          <textarea
            value={familyInfoData}
            onChange={(e) => setFamilyInfoData(e.target.value)}
            className={specificStyle.text__area}
            placeholder="Descripción"
          />
        </div>
      </>
    </div>
  );
};

export default PageFive;

PageFive.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
