import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { assignValueById } from 'utils/Activities/activitiesRemoveDuplicates';
import { activityData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import ProfileSection from '../ProfileSection';

import styles from './styles.module.css';
import renderListOfActivitiesTypes from '../utils/renderListOfActivitiesTypes';

export default function InstalationsActivities({ isFetching }) {
  const [useAllactivities, setUseAllActivities] = useState([]);
  const schoolActivities = useSelector(activityData);
  const [instalationActivities, setInstalationActivities] = useState({});
  const [count, setCount] = useState(0);

  const useEffect1 = async () => {
    const educationalFacilitiesActivities = {
      properties: await assignValueById(schoolActivities, 12),
      title: 'Instalaciones Educativas',
    };
    const playFacilitiesActivities = {
      properties: await assignValueById(schoolActivities, 13),
      title: 'Instalaciones Lúdicas',
    };
    const sportFAcilitiesActivities = {
      properties: await assignValueById(schoolActivities, 14),
      title: 'Instalaciones Deportivas',
    };
    const healthAndDevelopmentInstalationsActivities = {
      properties: await assignValueById(schoolActivities, 15),
      title: 'Instalaciones de Salud y Desarrollo',
    };
    const otherInstalationsActivities = {
      properties: await assignValueById(schoolActivities, 16),
      title: 'Otras Instalaciones',
    };

    const instalations = await assignValueById(schoolActivities, 11);
    setInstalationActivities(instalations);

    if (playFacilitiesActivities.length === 0
      && educationalFacilitiesActivities.length === 0
      && sportFAcilitiesActivities.length === 0
      && healthAndDevelopmentInstalationsActivities.length === 0
      && otherInstalationsActivities.length === 0
    ) {
      setUseAllActivities(null);
    } else {
      setUseAllActivities([
        educationalFacilitiesActivities,
        playFacilitiesActivities,
        sportFAcilitiesActivities,
        healthAndDevelopmentInstalationsActivities,
        otherInstalationsActivities,
      ]);
    }
  };

  useEffect(() => {
    useEffect1();
  }, []);

  useEffect(() => {
    useAllactivities.forEach((activity) => {
      if (activity.properties.length > 0) {
        setCount((prevCount) => prevCount + 1);
      }
    });
  }, [useAllactivities]);

  if (isFetching) {
    return <>...loading</>;
  }
  if (!count) {
    return <>{' '}</>;
  }

  return (
    <ProfileSection
      title="Instalaciones"
      subtitleLyrics={{
        subtitle: 'Información general sobre las instalaciones de la Institución',
        lyrics: instalationActivities[0]?.insIalationActivities,
      }}
    >
      <div className={styles.sectionsContainer}>
        {
          renderListOfActivitiesTypes(useAllactivities)
        }
      </div>
    </ProfileSection>
  );
}

InstalationsActivities.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};
