import { useState } from 'react';

const useRecommendations = () => {
  const [collapsedProfiles, setCollapsedProfiles] = useState([]);

  const onCollapsedToggle = (id) => {
    const indexOf = collapsedProfiles.indexOf(id);
    if (indexOf >= 0) {
      const newCollapsedProfiles = [...collapsedProfiles];
      newCollapsedProfiles.splice(indexOf, 1);
      setCollapsedProfiles(newCollapsedProfiles);
    } else {
      const newCollapsedProfiles = [...collapsedProfiles, id];
      setCollapsedProfiles(newCollapsedProfiles);
    }
  };

  return {
    collapsedProfiles,
    onCollapsedToggle,
  };
};

export default useRecommendations;
