import React, { useContext } from 'react';
import SchoolItemContext from '../SchoolItemContext';

const SchoolItemPublicType = () => {
  const { school } = useContext(SchoolItemContext);
  const { public: publicType } = school || {};
  return (
    <div className="school-feature school-item__public">
      <img src="https://vacanted-assets-dev.s3.us-east-1.amazonaws.com/icons/icon_public_type.png" alt="tipo de centro" />
      {publicType?.name?.replace('Institución ', '')}
    </div>
  );
};

export default SchoolItemPublicType;
