const { isPointWithinRadius } = require('utils/Coordinates/coordinatesFunctions');

export default function filterSchoolsByDistance(usedCoords, schools, range) {
  const mutatedUsedCoords = {
    latitude: Number(usedCoords.latitude),
    longitude: Number(usedCoords.longitude),
  };

  return schools.filter((school) => {
    const coords = {
      latitude: Number(school.latitude),
      longitude: Number(school.longitude),
    };

    return isPointWithinRadius(coords, mutatedUsedCoords, Number(range) * 1000);
  });
}
