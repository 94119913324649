import Header from 'components/Header/Header';
import React from 'react';
import Footer from 'components/Footer';
import SchoolFormModal from 'components/SchoolFormModal';
import SchoolLoginDescription from '../../components/SchoolLoginDescription';
import SchoolRegisterCard from '../../components/SchoolRegisterCard';
import registerStyles from '../school_register.module.scss';

function schoolRegister() {
  return (
    <>
      <Header />
      <div className={registerStyles.background}>
        <div className={registerStyles.background__overlay}>
          <div className={registerStyles.body__content}>
            <div
              className={registerStyles.description__container}
            >
              <SchoolLoginDescription />
            </div>
            <div className={registerStyles.form__container}>
              <SchoolRegisterCard />
            </div>
          </div>
        </div>
      </div>
      <div className={registerStyles.footer__container}>
        <Footer />
      </div>
      <SchoolFormModal />
    </>

  );
}

schoolRegister.propTypes = {};

export default schoolRegister;
