export const updateCityInfo = (city, updater) => {
  updater({
    countryId: city.countryId,
    name: city.name,
    cityId: city.cityId,
    table_step: city.table_step,
  });
};

export const removeDuplicateCities = (citiesFromApi) => {
  const filteredCities = citiesFromApi.data;
  const supportArr = [];
  const supportNames = [];
  filteredCities.map(({ name }) => {
    if (!supportNames.includes(name)) return supportNames.push(name);

    return null;
  });

  filteredCities.map((city) => {
    if (supportNames.includes(city.name)) {
      supportArr.push(city);
      supportNames.map((sn, i) => {
        if (city.name === sn) supportNames.splice(i, 1);

        return null;
      });
    }

    return null;
  });

  return supportArr;
};
