import React from 'react';
import PropTypes from 'prop-types';
import SchoolItemContext from '../SchoolItemContext';
import SchoolItemActions from './SchoolItemActions';
import SchoolItemCost from './SchoolItemCost';
import SchoolItemDistance from './SchoolItemDistance';
import SchoolItemAddress from './SchoolItemAddress';
import SchoolItemInfoName from './SchoolItemInfo';
import SchoolItemLanguage from './SchoolItemLanguages';
import SchoolItemLanguageFlags from './SchoolItemLanguageFlags';
import SchoolItemReligion from './SchoolItemReligion';
import SchoolItemPublicType from './SchoolItemPublicType';

const SchoolListItem = ({ school, children }) => (
  <SchoolItemContext.Provider value={school}>
    <div className="school-item">
      <div className="school-item__content">
        { children }
      </div>
    </div>
  </SchoolItemContext.Provider>
);

SchoolListItem.propTypes = {
  school: PropTypes.shape({
    name: PropTypes.string,
    rating: PropTypes.number,
    distance: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string,
    }),
    religion: PropTypes.string,
    score: PropTypes.number,
    cost: PropTypes.number,
    img: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
};

SchoolListItem.defaultProps = {
  children: null,
};

export {
  SchoolItemActions,
  SchoolItemCost,
  SchoolItemDistance,
  SchoolItemAddress,
  SchoolItemInfoName,
  SchoolItemLanguage,
  SchoolItemLanguageFlags,
  SchoolItemReligion,
  SchoolItemPublicType,
};

export default SchoolListItem;
