import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { apiEmailRecovery } from 'Authentication/Data/Services/api';
import logo from 'assets/icons/VACANTED_iso.png';
import validate from '../RegisterCard/registerFunctions';

// most of the css comes from register styles
import registerStyles from '../RegisterCard/RegisterCard.module.css';
import emailStyles from './EmailRecovery.module.css';

function EmailRecovery() {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState({ valid: false, showWarning: false });
  const [emailInputStyles, toggleEmailInputStyles] = useState(registerStyles.input);
  const [emailError, setEmailError] = useState({ status: false, msg: '' });
  const [emailSuccess, setEmailSuccess] = useState({ status: false, msg: 'Correo enviado con éxito' });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleRedirectRegister = () => navigate('/auth/register');
  const handleRedirectLogin = () => navigate('/auth/login');
  const handleRedirectPassword = () => navigate('/auth/password-recovery');

  useEffect(() => {
    validate.validateEmailPattern(email, setEmailValid);
    setEmailError({ status: false, msg: '' });
  }, [email]);

  // check the styles for the inputs
  useEffect(() => toggleEmailInputStyles((emailValid.showWarning || emailError.status)
    ? registerStyles.invalid__input
    : registerStyles.input), [emailValid.showWarning, emailError]);

  const handleEmailRecovery = async () => {
    if (!emailValid.valid) return setEmailValid({ ...emailValid, showWarning: true });

    setLoading(true);
    try {
      const response = await apiEmailRecovery.emailRecovery(email);
      setEmailSuccess({ ...emailSuccess, status: true });
      setTimeout(() => {
        navigate({
          pathname: '/user/activate',
          state: { email, ...response.data },
        });
      }, 1000);
    } catch (err) {
      const errorStatus = err.response.status;

      switch (errorStatus) {
        case 404:
          if (err.response.data.message.toLowerCase().includes('user')) {
            setEmailError({ status: true, msg: 'Correo no encontrado' });
          }
          break;
        case 409:
          if (err.response.data.message.toLowerCase().includes('active')) {
            setEmailError({ status: true, msg: 'Usuario ya activado' });
          } else {
            setEmailError({ status: true, msg: 'Error, correo no enviado' });
          }
          break;
        default:
          setEmailError({ status: true, msg: 'Error' });
      }
    }

    return setLoading(false);
  };

  return (
    <div className={registerStyles.card__container}>
      <div className={registerStyles.logo__container}>
        <img src={logo} alt="Vacanted" />
      </div>
      <h2 className={registerStyles.subtitle}>
        Reenvío de confirmación para completar mi registro
      </h2>
      <form className={registerStyles.form}>
        <input
          className={emailInputStyles}
          placeholder="Ingresa tu email"
          type="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError.status
          && <p className={registerStyles.invalid__input__text}>{emailError.msg}</p>}
        {emailValid.showWarning
          && <p className={registerStyles.invalid__input__text}>Formato de correo inválido</p>}
        <span className={registerStyles.email__info}>
          Verifica que ingresaste correctamente tu email.
        </span>
        <button
          className={emailStyles.btn__send}
          type="button"
          onClick={handleEmailRecovery}
        >
          Volver a enviar el correo de confirmación
        </button>
        <BarLoader loading={loading} color="#3e95e6" width="100%" />
        {emailSuccess.status
          && <p className={registerStyles.successful__input__text}>{emailSuccess.msg}</p>}
        <div className={`${registerStyles.form__link} ${emailStyles.card__footer__link}`}>
          <button type="button" onClick={handleRedirectLogin} className={registerStyles.link__btn}>
            Inicia Sesión
          </button>
          <button type="button" onClick={handleRedirectRegister} className={registerStyles.link__btn}>
            Regístrate
          </button>
        </div>
        <button type="button" onClick={handleRedirectPassword} className={registerStyles.link__btn}>
          ¿Olvidaste tu contraseña?
        </button>
      </form>
    </div>
  );
}

export default EmailRecovery;
