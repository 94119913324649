import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import './text-field-styles.scss';

const ControlledTextField = ({
  id, label, size, value, onChange, required, validation, errorMessage, className,
}) => {
  const [error, setError] = useState();

  const hasValue = (val) => !!val;

  const validate = (newVal) => {
    if (required && !hasValue(newVal)) {
      setError(errorMessage || 'Dato requerido');
      return false;
    }
    if (validation && !validation(newVal)) {
      setError(errorMessage);
      return false;
    }
    setError(null);
    return true;
  };

  return (
    <TextField
      className={`vacanted__mui__textfield ${className ?? ''}`}
      variant="outlined"
      id={id}
      label={label}
      name={id}
      size={size}
      value={value}
      onBlur={(e) => {
        // console.log('Blurred', e.target.id);
        validate(e.target.value);
      }}
      onChange={(e) => {
        if (error) validate(e.target.value);
        onChange(e.target.value);
      }}
      error={!!error}
      helperText={error}
    />
  );
};

ControlledTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  validation: PropTypes.func,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
};

ControlledTextField.defaultProps = {
  required: false,
  validation: null,
  errorMessage: null,
  className: null,
};

export default ControlledTextField;
