import React, { useState } from 'react';
import { useUserData } from 'hooks/UserContext';
import sidebarConstants from '../sidebarConstants';
import { useSearch } from '../../../hooks/SearchContext';
import clearReligions from '../../../utils/clearReligionsFromFilters';
import Accordion from '../Accordion';
import ReligionAccordion from '../Accordion/ReligionAccordion';
import hardArrays from './hardArrays';
import '../styles.css';
import './rangeInput.css';

const SmartFilters = () => {
  const { appLanguage: lang } = useUserData();
  const {
    clearAll,
    filters,
    setFilters,
    schoolsData,
    tableData,
    schoolsLoadingState: loading,
  } = useSearch();
  const [showFilters, toggleShowFilters] = useState({
    religion: false,
    secularShow: true,
    religiousShow: true,
    religions: false,
  });

  // variables
  const religious = 'religious';
  const religions = 'religions';
  const religionId = 'religion_id';
  const management = 'management';
  const publicId = 'public_id';
  const cost = 'cost';
  const costId = 'cost_id';
  const genre = 'genre';
  const genreId = 'genre_id';
  const primaryLanguages = 'language';
  const primaryLanguageId = 'language1_id';
  const taughtLanguages = 'language';
  const taughtLanguagesId = 'language_id';
  const schedules = 'schedules';
  const steps = 'steps';

  // handle filters
  const handleFilters = (name, value) => setFilters({ ...filters, [name]: value });

  // function to get the range value to follow the range thumb
  function getThumbPosition() {
    const calc1 = ((Number(filters['range-value']) * 100) / 50);

    // first magic number sets the start and second sets the ending
    return `calc(${calc1}% + (${9 - calc1 * 0.39}px))`;
  }

  // custom style to set the value position reference the range thumb
  const outputCustomStyle = {
    left: `${getThumbPosition()}`,
    top: '-10px',
    color: '#7a8cb2',
    width: '50px',
  };

  // check religion checkboxes to show
  function handleShowCheckboxes(name, rValue, checked, targetName) {
    if (name === religious && checked) {
      handleFilters(targetName, checked);
      return toggleShowFilters({ ...showFilters, religions: rValue });
    }

    clearReligions(targetName, checked, filters, setFilters);
    return toggleShowFilters({ ...showFilters, religions: false });
  }

  // clearAllFilters
  function clearAllFilters() {
    toggleShowFilters({
      ...showFilters,
      religion: false,
      secularShow: true,
      religiousShow: true,
      religions: false,
    });

    clearAll();
  }

  return (
    <div className="sidebar-big-container shadow">
      <div className="sidebar-header-simple">
        <h5>{sidebarConstants.selectYourPreferences[lang]}</h5>
      </div>

      <div className="clear-filters">
        <button type="button" className="clear-all-filters-button" onClick={() => clearAllFilters()}>
          <strong>BORRAR TODOS LOS FILTROS</strong>
        </button>
      </div>
      <hr />

      <div className="sidebar-header-simple">
        <h5>Distancia (km)</h5>
      </div>
      <div className="sidebar-cell-body-container sidebar-range">
        <output style={outputCustomStyle}><strong>{`${filters['range-value']} km`}</strong></output>
        <input
          type="range"
          name="range-value"
          value={filters['range-value']}
          onChange={({ target }) => handleFilters(target.name, target.value)}
          min="0"
          max="50"
        />
      </div>
      <div className="sidebar-cell-body-container underbar">
        <p className="start-range">0</p>
        <p className="smaller-ranges">10</p>
        <p className="smaller-ranges">20</p>
        <p className="smaller-ranges">30</p>
        <p className="smaller-ranges">40</p>
        <p className="end-range">50</p>
      </div>
      <hr />

      <Accordion
        title="Gestión Educativa"
        content={{
          table: tableData[management],
          handleFilters,
          schools: schoolsData.filteredData,
          filterId: publicId,
          filters,
          loading,
        }}
      />
      <hr />

      <Accordion
        title="Costo"
        content={{
          table: tableData[cost],
          handleFilters,
          schools: schoolsData.filteredData,
          filterId: costId,
          filters,
          loading,
        }}
      />
      <hr />

      {/* FASE 2.0 */}
      {/* <div className="sidebar-header">
        <h5>Rango de Precios</h5>
      </div> */}

      <Accordion
        title="Etapas Estudiantiles"
        content={{
          table: tableData[steps],
          handleFilters,
          schools: schoolsData.filteredData,
          filterId: steps,
          filters,
          loading,
        }}
      />
      <hr />

      <ReligionAccordion
        title="Religión"
        boolContent={{
          handleShowCheckboxes,
          schoolsData,
        }}
        religionsContent={{
          table: tableData[religions],
          handleFilters,
          schools: schoolsData.filteredData,
          filterId: religionId,
          customClass: religions,
          filters,
          loading,
        }}
      />
      <hr />

      <Accordion
        title="Diferenciación"
        content={{
          table: tableData[genre],
          handleFilters,
          schools: schoolsData.filteredData,
          filterId: genreId,
          filters,
          loading,
        }}
      />
      <hr />

      <Accordion
        title="Horarios"
        content={{
          table: hardArrays.schedulesArr,
          handleFilters,
          schools: schoolsData.filteredData,
          filterId: schedules,
          filters,
          loading,
          stepsData: tableData[steps],
        }}
      />
      <hr />

      <Accordion
        title="Lenguas Primarias"
        content={{
          table: tableData[primaryLanguages],
          handleFilters,
          schools: schoolsData.filteredData,
          filterId: primaryLanguageId,
          filters,
          loading,
        }}
      />
      <hr />

      <Accordion
        title="Lenguas Extranjeras"
        content={{
          table: tableData[taughtLanguages],
          handleFilters,
          schools: schoolsData.filteredData,
          filterId: taughtLanguagesId,
          filters,
          loading,
        }}
      />
    </div>
  );
};

export default SmartFilters;
