import saveUserData from '../../../Authorization/storage/saveUserData';
import { apiLoginWithProvider } from '../../Data/Services/api';

export default async function loginWithProvider(token, provider) {
  const result = await apiLoginWithProvider.post(token, provider)
    .then((res) => res.data)
    .catch(() => null);

  if (!result) return false;

  saveUserData({ ...result });

  return result;
}
