import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SchoolsProvider from 'hooks/SchoolContext';
import ApplicationContextProvider from 'hooks/ApplicationContext';
import { UserContextProvider } from 'hooks/UserContext';
import { SearchContextProvider } from './hooks/SearchContext';
import ScrollTop from './utils/ScrollTop/scrollTop';
import Routes from './routes';
import versionService from './services/versionService';

import './app.sass';

function App() {
  useEffect(() => {
    versionService.checkVersionAndReloadIfNecessary()
      .catch((err) => console.error('Error checking version', err));
  }, []);

  return (
    <ApplicationContextProvider>
      <UserContextProvider>
        <SchoolsProvider>
          <SearchContextProvider>
            <Router>
              <ScrollTop />
              <Routes />
            </Router>
          </SearchContextProvider>
        </SchoolsProvider>
      </UserContextProvider>
    </ApplicationContextProvider>
  );
}

export default App;
