import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { stepsData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import styles from './styles.module.css';
import Accordion from '../../Accordion/Accordion';

function Schedules({ isFetching, subtitle }) {
  if (isFetching) {
    return <p>Loading...</p>;
  }

  const schedules = useSelector(stepsData);
  return (
    <div style={{ fontWeight: 600 }}>
      <h3 className={styles.subtitleSection}>{subtitle}</h3>
      {schedules.map((schedule) => (
        <Accordion
          key={schedule.id}
          disabled={!schedule.level}
          firstColumn={schedule.step}
          secondColumn={schedule.ages}
        >
          <strong style={{ marginLeft: 10, marginTop: 10 }}>

            {schedule.stepsAvailable}
          </strong>
        </Accordion>
      ))}
    </div>

  );
}

Schedules.defaultProps = {
  isFetching: false,
  subtitle: '',
};

Schedules.propTypes = {
  isFetching: PropTypes.bool,
  subtitle: PropTypes.string,
};

export default Schedules;
