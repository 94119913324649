// just letters, accented letters, apostrophe and tilde
const nameRegex = /^[a-zA-Z\u00C0-\u00FF '-]*$/;

// simple email regex
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

// accepts numbers, spaces, - and ()
const phoneRegex = /^[\d \-()]*$/;

// host with country
const hostWithCountryRegex = /\.com\.(\w\w)/i;

export default {
  nameRegex,
  emailRegex,
  phoneRegex,
  hostWithCountryRegex,
};
