import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { assignValueById } from 'utils/Activities/activitiesRemoveDuplicates';
import { activityData } from '../../../../hooks/SchoolProfileContext/SchoolProfileSlice';
import ProfileSection from '../ProfileSection';
import Schedules from '../Schedules/Schedules';

export default function EducationalLevels({ isFetching }) {
  const schoolActivities = useSelector(activityData);
  const [educationalLevels, setEducationalLevels] = useState({});

  useEffect(() => {
    assignValueById(schoolActivities, 22).then(setEducationalLevels);
  }, []);

  if (isFetching) {
    return <>...loading</>;
  }

  return (
    <ProfileSection
      title="Propuesta educativa"
      subtitleLyrics={{
        subtitle: 'Descripción del modelo educativo',
        lyrics: educationalLevels[0]?.description,
      }}
    >
      <Schedules subtitle="Niveles Educativos / Horarios" />
    </ProfileSection>
  );
}

EducationalLevels.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};
