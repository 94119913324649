/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SchoolItemContext from '../../context/school-item-context';

import '../../styles/school-item.scss';

const SchoolItem = ({
  school, children, onClick, highlighted,
}) => {
  const classes = classNames('school-item', {
    'school-item--highlighted': highlighted,
    'school-item--actionable': !!onClick,
  });

  return (
    <SchoolItemContext.Provider value={school}>
      <div className={classes} onClick={onClick}>
        {children}
      </div>
    </SchoolItemContext.Provider>
  );
};

SchoolItem.propTypes = {
  school: PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  highlighted: PropTypes.bool,
};

SchoolItem.defaultProps = {
  onClick: null,
  highlighted: false,
};

export default SchoolItem;
