/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useAppContext } from 'hooks/ApplicationContext';
import useResponsive from '../../../hooks/useResponsive';
import Header from '../../../../../../components/Header/Header';
import ExperienceBody from '../../body';
import ExperienceSlideMenu from '../../slide-menu';
import Loading from '../../loading';
import FlowContext from '../../flow/context';

import './experience-main.scss';

const ExprienceMain = ({
  children,
  benefitsBanner,
  withFooter,
}) => {
  const {
    loadingContext: { pageLoading },
  } = useAppContext();
  const {
    isFlow,
  } = useContext(FlowContext);
  const { isDesktop } = useResponsive();

  const classNameMain = classNames('experience-main', {
    'with-footer': !!withFooter,
  });
  const classNameMainContainer = classNames('experience-main-content-container', {
    'experience-main-content-container--is-flow': isFlow,
    'with-footer': !!withFooter,
  });
  const classNameBody = classNames({
    'with-footer': !!withFooter,
  });

  return (
    <div className={classNameMain}>
      <Header hideSlideMenu={isDesktop} />
      <div className={classNameMainContainer}>
        {(isDesktop && !isFlow) && <ExperienceSlideMenu />}
        <ExperienceBody className={classNameBody} benefitsBanner={benefitsBanner}>
          {children}
          <Loading {...pageLoading} className={(isDesktop && !isFlow) ? 'left-240' : null} />
        </ExperienceBody>
      </div>
      {/* {!!isFlow && (
        <ExperienceFooter />
      )} */}
    </div>
  );
};

ExprienceMain.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  benefitsBanner: PropTypes.bool,
  withFooter: PropTypes.bool,
};

ExprienceMain.defaultProps = {
  children: null,
  benefitsBanner: false,
  withFooter: false,
};

export default ExprienceMain;
