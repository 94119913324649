import countryService from './services/countryService';

const urlMappings = {
  local: {
    api: 'http://local.vacanted.com.{countryCode}:8080',
    app: 'http://local.vacanted.com.{countryCode}:3000',
    panel: 'http://local.vacanted.com.{countryCode}:3002',
    schoolPanel: 'http://local.vacanted.com.{countryCode}:3001',
  },
  development: {
    api: 'https://develop.vacanted.com.{countryCode}/api',
    app: 'https://develop.vacanted.com.{countryCode}',
    panel: 'https://panel-dev.vacanted.com.{countryCode}',
    schoolPanel: 'https://colegio-dev.vacanted.com.{countryCode}',
  },
  qa: {
    api: 'https://qa.vacanted.com.{countryCode}/api',
    app: 'https://qa.vacanted.com.{countryCode}',
    panel: 'https://panel-qa.vacanted.com.{countryCode}',
    schoolPanel: 'https://colegio-qa.vacanted.com.{countryCode}',
  },
  production: {
    api: 'https://vacanted.com.{countryCode}/api',
    app: 'https://vacanted.com.{countryCode}',
    panel: 'https://panel.vacanted.com.{countryCode}',
    schoolPanel: 'https://colegio.vacanted.com.{countryCode}',
  },
};

const vacantedEnvironment = process.env.REACT_APP_VACANTED_ENV || 'local';
const vacantedWithoutRegister = process.env.REACT_APP_VACANTEST_WITHOUT_REGISTER === 'true';
const googleReviewsSection = process.env.REACT_APP_GOOGLE_REVIEWS_SECTION === 'true';

if (!urlMappings[vacantedEnvironment]) {
  throw new Error(`Env ${vacantedEnvironment} is not valid to initialize app`);
}

export const getUrl = (component) => {
  const detectedCountryCode = countryService.getCurrentCountryCode();
  const urlTemplate = urlMappings[vacantedEnvironment][component];
  const url = urlTemplate.replace('{countryCode}', detectedCountryCode);
  return url;
};

export const getMapboxKey = () => {
  const key = process.env.REACT_APP_MAPBOX_KEY;
  return key;
};

export const isAnonymusVacantedAllowed = () => vacantedWithoutRegister;

export const shouldDisplayGoogleReviewsSection = () => googleReviewsSection;
